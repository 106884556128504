import React, { useState } from 'react';
import banner1 from '../components/images/banner-1.jpg';
import banner2 from '../components/images/banner-2.jpg';
import '../Home/Home.css';
import Header from '../components/Header/Header';
import Carousel from 'react-bootstrap/Carousel';
import Legaltech from '../components/Legaltech/Legaltech';
import MainFeatures from '../components/MainFeatures/MainFeatures';
import WhyChooseUs from '../components/WhyChooseUs/WhyChooseUs';
import Footer from '../components/Footer';
import { Translate, I18n } from "react-redux-i18n";
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div>
      <Header isHeader={false} purchase={true} />
      {/* Main Carousel */}
      <div class="hero-slider">
        <Carousel
          // activeIndex={index}
          // onSelect={handleSelect}
          indicators={false}
          controls={false}
        // nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" >
        // <i class="fas fa-arrow-circle-right"></i>
        // </span>}
        // prevIcon={<span aria-hidden="true" className="carousel-control-next-icon" >
        // <i class="fas fa-arrow-circle-left"></i>
        // </span>}
        >
          <Carousel.Item className='slider-item'>
            <div class="slider-item th-fullpage hero-area">
              <img
                className="d-block w-100"
                src={banner1}
                alt="First slide"
              />
            </div>
            <Carousel.Caption className='slide-caption d-none d-sm-block'>
              <div class="col-md-12 text-center">
                <h1>{t('Empower Lawyers')} <br />
                  {t('for Better Results')}.</h1>
                <p data-duration-in=".3" data-animation-in="fadeInUp" data-delay-in=".5">{t('More analysis, consectetur less reading')}.</p>
              </div>
            </Carousel.Caption>
          </Carousel.Item >
          {/* another crousel */}
          {/* <Carousel.Item className='slider-item'>
            <div class="slider-item th-fullpage hero-area">
              <img
                className="d-block w-100"
                src={banner2}
                alt="second slide"
              />
            </div>
            <Carousel.Caption className='slide-caption d-none d-sm-block'>
              <div class="col-md-12 text-center">
                <h1 data-duration-in=".3" data-animation-in="fadeInUp" data-delay-in=".1" class="animationheader">{t('Empower Lawyers')} <br />
                  {t(' for Better Results')}.</h1>
                <p data-duration-in=".3" data-animation-in="fadeInUp" data-delay-in=".5">{t('More analysis, consectetur less reading')}.</p>
              </div>
            </Carousel.Caption>
          </Carousel.Item> */}
        </Carousel>
      </div>
      {/* Main Carousel Ends */}
      <Legaltech />
      <MainFeatures />
      <WhyChooseUs />
      <Footer />
    </div>
  );
}

export default Home;