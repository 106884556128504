import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Topbar from "../components/Topbar/Topbar";
import './style.css'
import CustomerModal from "../components/Modal/CustomerModal";
import Navs from "../components/TopNav/TopNav";
import { FileUploader } from "react-drag-drop-files";
import FileUploadProgress from 'react-fileupload-progress';
import axios from "axios";
import config from "../config/config";
import ProgressBar from 'react-bootstrap/ProgressBar';
import LinearProgress from '@mui/material/LinearProgress';
// import Divider from '@material-ui/core/Divider';
import useDrivePicker from 'react-google-drive-picker'
import Divider from '@mui/material/Divider';
import { useDropboxChooser } from 'use-dropbox-chooser'
import DropboxChooser from 'react-dropbox-chooser'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import SweetAlert from "react-bootstrap-sweetalert";
import { displayError, displaySuccess, getFileListDetail } from "../Utils/utils";
import { Translate, I18n } from "react-redux-i18n";
import { useTranslation } from "react-i18next";
import { Col, Row } from 'react-bootstrap';

let path = config.siteUrl;

const AddFiles = () => {
  const { t } = useTranslation();

  const navigate = useNavigate()
  const fileTypes = ["PDF", "MOV", "MP4", "DOC"];
  const [progress, setProgress] = useState([]);
  const [customerModalShow, setCustomerModalShow] = useState(false);
  const [file, setFile] = useState(null);
  // const [progressShow, setProgressShow] = useState(false)
  const [progressColor, setProgressColor] = useState([]);
  const [fileProgress, setFileProgress] = useState([])
  const { id } = useParams();
  const [fileName, setFileName] = useState('')
  const [fileList, setFileList] = useState([])
  const [filesUploaded, setFilesUploaded] = useState([]);
  const [uploadAlert, setUploadAlert] = useState(false);
  const [arr, setArr] = useState([])
  const [count, setCount] = useState(0);
  const [arr_count, setArrCount] = useState(0);
  const [color, setColor] = useState([])
  const [fileProgress_temp, setFileProgressTemp] = useState([])
  const [is_alert, setIsAlert] = useState(false);
  const [files, setFiles] = useState('');
  const [filedetail, setFileDetail] = useState([]);

  // const [uploadIndex, setUploadIndex] = useState(0);
  const handleChangeApi = async (file) => {
    const arr1 = arr.concat(Array.from(file))
    setArr(arr1)
    let arr_len = arr_count + arr1.length;
    let file_count = 0 + count;
    let temp_file = [];
    let color1 = [];
    color1 = color.concat(color1);
    setColor(color1)
    let progress_temp = []
    let fileProgress_temp1 = []
    fileProgress_temp1 = fileProgress_temp.concat(fileProgress_temp1);
    setFileProgressTemp(fileProgress_temp1)
    for (let i = file_count; i < arr_len; i++) {
      let file = arr1[i];
      temp_file.push(file)
      var token = localStorage.getItem('token');
      let path = config.siteUrl;
      const formData = new FormData();
      formData.append("process_id", id)
      formData.append("file[0]",
        file,
        file.name
      )
      await axios.post(path + '/auth/files/upload-files', formData, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadAlert(true)
          progress_temp[i] = percentCompleted
          setProgress(progress_temp);
          color1[i] = "warning";
          setProgressColor(color1)
          fileProgress_temp1[i] = "Uploading"
          setFileProgress(fileProgress_temp1)
          let newFile = file;
          newFile.progress = percentCompleted;
          let filesCopy = [...temp_file];
          let fileIndex = filesCopy.findIndex((el) => el.name === file.name);
          filesCopy[fileIndex] = newFile;
          setFilesUploaded([...filesUploaded, ...filesCopy]);
          setCount(i + 1)
          setArrCount(arr_len)
          // temp_i = ++temp_i
          file.progress = percentCompleted;
        },
        onDownloadProgress: (progressEvent) => {
          setUploadAlert(true)
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          progress_temp[i] = percentCompleted
          setProgress(progress_temp);
          color1[i] = "success";
          setProgressColor(color1)
          fileProgress_temp1[i] = "Completed"
          setFileProgress(fileProgress_temp1)
        },
      })
        .then((response) => {
          if (response.status === 200) {
            setUploadAlert(false)
            getFilesList(id)
          }
        })
        .catch((error) => {
          displayError(error, t)
          navigate(`/purchase`, {
            state: {
              isPricing: true
            }
          })
        });
    }
  }
  // const { signIn, profile } = useGapi({
  //   apiKey: "AIzaSyC5f-aGRME4fgGoFPRtsALphdJwwU9JmiA",
  //   clientId: "279195570031-p2hhk03au0k02j0fp6aqh6q3847o1u9c.apps.googleusercontent.com",
  // })
  // const { p, auth } = profile;

  const [openPicker, authResponse] = useDrivePicker();
  // const customViewsArray = [new google.picker.DocsView()]; // custom view
  const handleOpenPicker = () => {
    openPicker({
      // clientId:"1032233627231-cecddpg5jiku2lc3vhh5vbrpv4gjc5di.apps.googleusercontent.com"
      clientId: "279195570031-p2hhk03au0k02j0fp6aqh6q3847o1u9c.apps.googleusercontent.com",
      developerKey: "AIzaSyCCaLE989NzXfeQQIVn1wUWBxS67BdMvLQ",
      viewId: "PDFS",
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: (data) => {
        if (data.action === 'cancel') {
        } else if (data.action === 'picked') {
          var temp_file_arr = []
          var temp_file = {}
          data.docs.map((doc) => {
            var blob = new Blob([doc], {
              type: 'application/pdf'
            })
            temp_file = new File([blob], doc.name, {
              type: "application/pdf",
            });
            temp_file_arr.push(temp_file)
          })
          handleChangeApi(temp_file_arr)
        }
      },
    })
  }

  // function start() {
  //   // 2. Initialize the JavaScript client library.
  //   gapi.client.init({
  //     'apiKey': 'AIzaSyC5f-aGRME4fgGoFPRtsALphdJwwU9JmiA',
  //     'discoveryDocs': ['https://people.googleapis.com/$discovery/rest'],
  //     'clientId': '279195570031-p2hhk03au0k02j0fp6aqh6q3847o1u9c.apps.googleusercontent.com',
  //     'scope': 'profile',
  //   }).then(function () {
  //     // 3. Initialize and make the API request.
  //     return gapi.client.people.people.get({
  //       'resourceName': 'people/me',
  //       'requestMask.includeField': 'person.names'
  //     });
  //   }).then(function (response) {
  //     console.log(response.result);
  //   }, function (reason) {
  //     console.log('Error: ' + reason.result.error.message);
  //   });
  // };
  // gapi.load('client', start);
  // const gapi = useGoogleApi({
  //   scopes: [
  //     'profile',
  //   ],
  // })

  // const auth = gapi?.auth2.getAuthInstance()
  const handleOpenPickerVideo = async () => {
    openPicker({
      // clientId:"1032233627231-cecddpg5jiku2lc3vhh5vbrpv4gjc5di.apps.googleusercontent.com"
      clientId: "279195570031-p2hhk03au0k02j0fp6aqh6q3847o1u9c.apps.googleusercontent.com",
      developerKey: "AIzaSyCCaLE989NzXfeQQIVn1wUWBxS67BdMvLQ",
      viewId: "DOCS_VIDEOS",
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: (data) => {
        if (data.action === 'cancel') {
        } else if (data.action === 'picked') {
          var temp_file_arr = []
          var temp_file = {}
          // start()
          data.docs.map(async (doc) => {
            // gapi.load('client', start(doc.name));

            // const { client } = useGapi({
            //   apiKey: "AIzaSyC5f-aGRME4fgGoFPRtsALphdJwwU9JmiA",
            //   clientId: "279195570031-p2hhk03au0k02j0fp6aqh6q3847o1u9c.apps.googleusercontent.com",
            //   discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/drive/v3/files/"],
            // })
            // const driveApiCall = async () => {
            //   await client.drive.files.list({
            //     'pageSize': 15,
            //     'fields': "nextPageToken, files(id, name)"
            //   }).then(function (response) {
            //     let res = response.result.files;
            //   });
            // }
            // var fileId = doc.id
            // const auth = new google.auth.JWT(
            //   'service-user@project-title.iam.gserviceaccount.com',
            //   null,
            //   '',
            //   ['https://www.googleapis.com/auth/drive.readonly'],
            // )
            // const drive = google.drive({ version: 'v3' ,auth})
            // const data = await drive.files.get({ fileId, supportsAllDrives: true })
            // res.json({ data })
            // var token = localStorage.getItem('token');
            // var response_final = await axios.get("https://www.googleapis.com/drive/v3/files/" + doc.id + "?key=AIzaSyC5f-aGRME4fgGoFPRtsALphdJwwU9JmiA", {
            //   headers: { 'Accept': 'application/json', 'Authorization': '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' },
            // })
            //   .then(function (response) {
            //     return response.data.data;
            //   })
            //   .catch(function (error) {
            //     // if (error.response.status === 401) {
            //     //   window.location.href = '/';
            //     // } else {
            //     return null
            //     // }
            //   });
            var blob = new Blob([doc], {
              type: "video/mp4"
            })
            temp_file = new File([blob], doc.name, {
              type: "video/mp4",
            });
            temp_file_arr.push(temp_file)
          })
          handleChangeApi(temp_file_arr)
        }
      },
    })
  }

  async function getFileFromUrl(url, name, defaultType = 'video/mp4') {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: data.type || defaultType,
    });
  }
  function generateBlob(doc) {
    doc.toBlob(
      (blob) => {
        var dataURL = new File([blob], "video.mp4", { type: "video/mp4", lastModified: new Date().getTime() })
      },
      'video/mp4',
      1
    );
  }
  function readFile(input) {
    alert("hii")
    let file = input;

    let reader = new FileReader();
    reader.readAsText(file);

    reader.onload = function () {
    };

    reader.onerror = function () {
    };

  }

  const { open, isOpen } = useDropboxChooser({
    appKey: 'r3b9q6sfxwenzxj',
    chooserOptions: { multiple: true, linkType: 'direct' },
  })
  const showAlert = () => {
    if (uploadAlert === true) {
      setIsAlert(true)
    }
    else {
      setIsAlert(false)
      if (localStorage.getItem('process_slug')) {
        navigate(`/process-dashboard/${localStorage.getItem('process_slug')}`)
      } else {
        navigate(`/process-list`)
      }
    }

  }
  const getFilesList = async (id) => {
    // setIsloading(true)
    var var_file = await getFileListDetail(id);
    let temp_arr = var_file.files.data.slice(0, 5)
    setFileDetail(temp_arr)
  }
  useEffect(() => {
    getFilesList(id)
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };

  const confirmALert = () => {
    navigate(`/process-dashboard`)
  }
  return (
    <>
      <div id="wrapper">
        <Navs title={t('Upload File')} />
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title={t('Upload File')} />
            <div className="container-fluid">
              <Row>
                <Col md={10}></Col>
                <Col md={2}>
                  <div className="back-btn mb-3" style={{ marginLeft: "auto" }} onClick={showAlert}>
                    <div className="text-light back-txt" ><i class="fas fa-long-arrow-alt-left"></i>  {t('Back')} </div>
                  </div>
                </Col>
              </Row>

              <div className="row">
                <div className=" col-md-8 col-lg-8 col-sm-12 col-xs-12 mb-4">
                  <div className="card border-radius-10 shadow h-100 py-2" style={{ borderRadius: '15px' }}>
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col-xl-10 col-md-6">
                          <span className="font-weight-bold text-lg ml-2">{t('Upload File')}</span>
                        </div>
                        <div className="col-xl-2 col-md-6">
                          <div className="btn-warning button-css" onClick={showAlert}>
                            <div className="text-c font-weight-bold" >{t('Return to Process')}</div>
                          </div>
                        </div>

                      </div>
                      <div className="row mt-2 no-gutters">
                        <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                          <div className="file-add">

                            <FileUploader
                              className="file-upload mt-2"
                              multiple={true}
                              handleChange={
                                handleChangeApi}
                              name="file"
                              types={fileTypes}
                              style={{ height: '100px' }}
                            />
                          </div>

                        </div>
                        {/* <div className="col-md-12 col-lg-4 col-sm-12 col-xs-12">
                          <div className="google-box" onClick={() => handleOpenPicker()} style={{ cursor: 'pointer' }}>
                            <div className="box-text"><i class="fab fa-google-drive mr-1"></i> Google Drive (PDF)</div>
                          </div>
                        </div> */}
                        {/* <div className="col-md-12 col-lg-4 col-sm-12 col-xs-12">
                          <div className="google-box" onClick={() => handleOpenPickerVideo()} style={{ cursor: 'pointer' }}>
                            <div className="box-text"><i class="fab fa-google-drive mr-1"></i> Google Drive (Video)</div>
                          </div>
                        </div> */}
                        {/* <div className="col-md-4">
                          <div className="google-box" style={{ cursor: 'pointer' }}
                          // onClick={async () => {
                          //   try {
                          //     const files = await open()
                          //   } catch (e) {
                          //     // if closed: e === undefined
                          //   }
                          // }}
                          // disabled={isOpen}
                          >
                            {/* <DropboxChooser
                              appKey={'r3b9q6sfxwenzxj'}
                              success={files => this.onSuccess(files)}
                              cancel={() => this.onCancel()}
                              multiselect={true}
                              extensions={['.mp4']} >
                              <div className="dropbox-button">Click me!</div>
                            </DropboxChooser> 
                            <div className="box-text"><i class="fab fa-dropbox mr-2"></i> DropBox</div>
                          </div>
                        </div> */}

                      </div>
                      {filesUploaded.map((file, index) => (
                        <div className="pro-box  shadow mb-2 " key={index}>
                          <div className="row mt-2">
                            <div className="col-md-4 ">
                              <i class="fas fa-file-pdf text-success icon-css mt-2"></i>
                            </div>
                            <div className="col-md-4">
                              <div className="text-dark  font-weight-bold ">{file.name}</div>
                              <div className="file-size">{(file.size / 1048576).toFixed(2)}Mb</div>
                            </div>
                            <div className="col-md-4">
                              <div className="mt-2 ml-5 text-success">{fileProgress[index]}</div>
                            </div>
                            {/* <div className="col-md-3">
                              <i class="fas fa-download  mt-2 ml-5" style={{ fontSize: '25px' }}></i>s
                            </div> */}
                          </div>
                          <LinearProgress variant="determinate" value={progress[index]} color={progressColor[index]}
                            className="mt-3 progress-file mb-3" />

                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* Earnings (Monthly) Card Example */}
                <div className=" col-md-4 col-lg-4 col-sm-12 col-xs-12 mb-4">
                  <div className="card  shadow py-2" style={{ borderRadius: '15px', height: 'auto' }}>
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          {/* <div className="row"> */}
                          <div className="font-weight-bold text-lg ml-2">{t('History')}</div>
                          <Divider>{t('Today')}</Divider>
                          {filedetail.length == 0 ?
                            <div className="history-no row shadow mb-2 mt-4">
                              <div className="text-danger no-file-text ">
                                {t('No Files')}
                              </div>
                            </div>
                            : null}
                          {filedetail.map((list) => (
                            <div className="history-box row shadow mb-2">
                              <div className=" col-2" >
                                {list.media.type === "pdf" ?
                                  <i class="fas fa-file-pdf text-success icon-file" style={{ fontSize: '25px' }}></i>
                                  : <i class="fas fa-video text-danger icon-file" style={{ fontSize: '25px' }}></i>
                                }
                              </div>
                              <div className=" col-8">
                                <div className="text-dark mt-2">{list.media.original_name}</div>
                                <div className="file-size">{(list.media.size / 1048576).toFixed(2)}Mb</div>
                              </div>
                              <div className="col-2">
                                <a href={list.media.url} target='_blank' download={list.media.original_name}>
                                  <i class="fas fa-download text-dark icon-file" style={{ fontSize: '23px' }}></i>
                                </a>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="col-auto">
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
        {customerModalShow === true ?
          <CustomerModal customerModalShow={customerModalShow} setCustomerModalShow={setCustomerModalShow} />
          : null
        }
        {is_alert === true ? (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Ok"
            confirmBtnBsStyle="warning"
            title={('Uploading process is going on')}
            onConfirm={(e) => {
              //   deleteProduct(temp_id )
              confirmALert()
              // window.location.reload(false)
            }}
            onCancel={(e) => {
              setIsAlert(false)
            }}
            focusCancelBtn
          >
          </SweetAlert>
        ) : ([]
        )}
      </div>
    </>
  );
}
export default AddFiles;