import React from 'react';
import '../../css/style.css';
import image from '../images/logo_trans.png';
import { Link } from "react-router-dom";
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { Translate, I18n } from "react-redux-i18n";
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const d = new Date()
  let year = d.getFullYear()
  const { t } = useTranslation();
  return (
    <div>
      <footer id="footer" class="bg-one">
        <div class="top-footer">
          <div class="container">
            <div class="row">
              <div class="col-sm-3 col-md-3 col-lg-3">
                <img src={image} class="img-fluid" />
              </div>
              <div class="col-sm-3 col-md-3 col-lg-3">
                {/* <h3>{t('Menu')}</h3> */}
                <ul>
                  <li ><Link to="/">{t('Home')}</Link></li>
                  <li ><Link to="/pricing">{t('Pricing')}</Link></li>
                  <li ><Link to="/login">{t('Login')}</Link></li>
                  <li ><Link to="/contact-us">{t('Contact us')}</Link></li>
                </ul>
              </div>
              {/* <div class="col-sm-3 col-md-3 col-lg-3">
                <h3>{t('Service')}</h3>
                <ul>
                  <li ><Link to="">{t('Business Law')}</Link></li>
                  <li ><Link to="">{t('Employment Law')}</Link></li>
                  <li ><Link to="">{t('Medical Law')}</Link></li>
                  <li ><Link to="">{t('Real Esate Law')}</Link></li>
                </ul>
              </div> */}
              <div class="col-sm-3 col-md-3 col-lg-3 ml-auto text-lg-right">
                <h3>{t('Contact with us')}</h3>
                <ul class="row social_media justify-content-md-end">
                  <li class="col-auto"><a target='_blank' href="https://www.facebook.com/MetodoDutra" style={{ backgroundColor: 'rgba(29, 70, 246, 0.3)' }}><i class="fab fa-facebook-f"></i></a></li>
                  <li class="col-auto"><a target='_blank' href="https://www.instagram.com/metododutra/" style={{ backgroundColor: '#4188F3' }}><i class="fab fa-instagram"></i></a></li>
                  <li class="col-auto"><a target='_blank' href="https://www.linkedin.com/in/m%C3%A9todo-dutra-6180b361/" style={{ backgroundColor: '#FF00E5' }}><i class="fab fa-linkedin"></i></a></li>
                </ul>
                <LanguageSwitcher />
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom">
          <div class="container">
            <div class="row">
              <div class="col-md-8 text-left">
                <h5>{t(`Copyright © ${year} All Rights Reserved | Developed By`)} <a href="https://codiflysoftware.com" target="_blank"> {('CodiFly  IT Solution')}. </a></h5>
              </div>
              <div class="col-md-4 ">
                <ul class="row justify-content-md-end">
                  <li class="col-md-6 col-6 w-100"><a href="/termsofservices" class="text-white">{t('Terms of Use')}</a></li>
                  <li class="col-md-6 col-6 p-0"><a href="/privacypolicy" class="text-white">{t('Privacy Policy')}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer;