import React, { useState, useRef } from 'react';
import './style.css';
import { Link } from "react-router-dom";
import QRCode from "react-qr-code";
import Switch from "react-switch";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import googlePlay from '../components/images/playStore.png'
import appStore from '../components/images/appStore.png'
import Overlay from 'react-bootstrap/Overlay'
import Button from 'react-bootstrap/Button';
import { useNavigate, useLocation } from "react-router-dom";
import image from '../components/images/logo_trans.png';

import config from '../config/config';
import axios from "axios";
import { displaySuccess, displayError } from '../Utils/utils';
import { useTranslation } from 'react-i18next';
const TwoFactorProcessOne = () => {
  const [skipFactor, setSkipFactor] = useState(true);
  const handleChange = () => {
    setSkipFactor(!skipFactor)
  }
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const handle = () => {
    setShow(!show)
  }
  const location = useLocation();
  const navigate = useNavigate()
  const { t } = useTranslation();

  const TwoFactor = async (e) => {
    let path = config.siteUrl;
    await axios.post(path + '/register/disable-2fa', {
      is_2fa_enabled: skipFactor,
      email: location?.state?.email
    })
      .then((response) => {
        displaySuccess(t('Two factor authentication disabled'));
        navigate(`/login`)
      })
      .catch((error) => {
        displayError(error, t);
      });
  };
  const completeRegistration = () => {
    navigate(`/2fa-verify`, {
      state: {
        email: location?.state?.email
      }
    })
  }
  return (
    <>
      <div class="form-body">
        <div class="website-logo">
          <a>
            <div class="logo">
              <img class="logo-size" src="images/logo-light.svg" alt="" />
            </div>
          </a>
        </div>
        <div class="row">
          <div class="img-holder">
            <div class="bg"></div>
            <div class="info-holder" style={{ top: '70%' }}>
              <img src={image} />

            </div>
          </div>
          <div class="form-holder">
            <div class="form-content">
              <div class="form-items">
                <h3>{t('Set up google authenticator')}.</h3>
                <div className='paragraph '>{t('Set up your two factor authentication by scanning the barcode below. Alternatively, you can use the code')} <strong>{location?.state?.google2fa_secret}</strong></div>
                <p>{t('Access to the most powerfull tool in the entire design and web industry')}.</p>
                <img src={location?.state?.qr_url} />
                < div className="paragraph mt-3">{t('You must set up your Google Authenticator app before continuing. You will be unable to login otherwise')}</div>

                <div className='row mt-3'>
                  <div className='ml-2 mt-1 font-weight-bold'>
                    {t(' Enable Two Factor Authentication')}
                  </div>
                  <div>
                    <Button ref={target} onClick={handle}>
                      <Switch
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        handleDiameter={25}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={18}
                        width={40}
                        className="react-switch ml-3"
                        id="material-switch"
                        onChange={e => handleChange()} checked={skipFactor}
                      />
                    </Button>


                  </div>
                  <div>
                    <Overlay target={target.current} show={show} placement="right">
                      {(props) => (
                        <Tooltip id="overlay-example" {...props}>
                          {t('If you skip two factor authentication you might lose all your documents')}.
                        </Tooltip>
                      )}

                    </Overlay>
                  </div>
                </div>
                {skipFactor === true ?
                  <button id="submit" type="submit" className="ibtn mt-2" onClick={completeRegistration}>{t('Complete Registration')}</button>
                  :
                  <button id="submit" type="submit" className="ibtn mt-2" onClick={TwoFactor}>{t('Skip & GoTo Login')}</button>
                }

                <div className='row mt-4 ml-2'>
                  {t('To scan QR code you can download authenticator apps from below links')}:
                </div>
                <div className='row'>
                  <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US" target="_blank">
                    <img src={googlePlay} className="playStore-img mt-2 ml-2" />
                  </a>
                  <a href="https://apps.apple.com/in/app/google-authenticator/id388497605" target="_blank">
                    <img src={appStore} className="playStore-img mt-2 ml-2" />
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default TwoFactorProcessOne;