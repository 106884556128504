import { createSlice } from '@reduxjs/toolkit'

export const userInfo = createSlice({
  name: 'userInfo',
  initialState: {
    value: [],
  },
  reducers: {
    update: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { update } = userInfo.actions

export default userInfo.reducer