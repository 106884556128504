import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Topbar from "../components/Topbar/Topbar";
// import { Table, thead, tbody, tr, th, td } from 'react-super-responsive-table'
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import LoadingSpinner from "../components/LoadingSpinner";
import Navs from "../components/TopNav/TopNav";
import { Modal, Table, Row, Col } from 'react-bootstrap';
import { getNotesList } from "../Utils/utils";
import Moment from "react-moment";
import moment from "moment";
import './style.css';
import EditNotesModal from "../components/Modal/EditNotesModal";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import config from "../config/config";
import axios from "axios";
import { displayError, displaySuccess, getFileListDetail } from "../Utils/utils";
import { useTranslation } from "react-i18next";
import imgnew from '../components/images/data-not-found-2.svg'
import imgnew2 from '../components/images/data-not-found.svg'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

const NotesList = (props) => {
	const { t } = useTranslation();
	const [is_loading, setIsloading] = useState(false)
	const [notes, setNotes] = useState([]);
	const [editModal, setEditModal] = useState(false);
	const [notesId, setNotesId] = useState('')
	const location = useLocation();
	const { id } = useParams();
	const [is_delete, setIsDelete] = useState(false);
	const [temp_id, setTempId] = useState('');
	const [startDate, setStartDate] = useState(new Date())
	const [Search, setSearch] = useState('');
	const [searchUser, setSearchUser] = useState('')
	const navigate = useNavigate()

	const noteListing = async (id) => {
		setIsloading(true)
		var var_notes = await getNotesList(id, Search, searchUser, '');
		setNotes(var_notes.data)
		setIsloading(false)
	}
	const onFilterSubmit = async (date) => {
		setIsloading(true)
		var var_notes = await getNotesList(id, Search, searchUser, date);
		setNotes(var_notes.data)
		setIsloading(false)
	}
	const onFilterClear = async () => {
		setIsloading(true)
		setSearchUser('')
		setSearch('')
		setStartDate(new Date())
		var var_notes = await getNotesList(id, '', '', '');
		setNotes(var_notes.data)
		setIsloading(false)
	}
	useEffect(() => {
		noteListing(id)
	}, [])

	const deleteNote = async (noteid) => {
		var token = localStorage.getItem('token');
		let path = config.siteUrl;
		await axios.post(path + '/notes/delete-process-note/' + noteid, {

		}, {
			headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
		})
			.then(async (response) => {
				displaySuccess(t('Note Deleted Successfully'));
				noteListing(id);

			})
			.catch((error) => {
				displayError(error, t);
			});
	}
	const goBack = () => {
		navigate(`/file-list/${location?.state?.fileId}`)
	}
	const downloadTxtFile = (title, desc) => {
		const element = document.createElement("a");
		element.innerHTML = desc;
		const file = new Blob(["Title: " + title + "\n" + "Description: " + desc],
			{ type: 'text/plain;charset=utf-8' });
		element.href = URL.createObjectURL(file);
		element.download = "myFile.txt";
		document.body.appendChild(element);
		element.click();
	}
	const notesTxt = (list) => {
		var temparr = []
		list.map((data) => {
			temparr.push("Title: " + data.title + "\n" + "Description: " + data.description + "\n\n")
		})
		var lineConcat = temparr.join("\r\n");
		const element = document.createElement("a");
		const file = new Blob([lineConcat],
			{ type: 'text/plain;charset=utf-8' });
		element.href = URL.createObjectURL(file);
		element.download = "myFile.txt";
		document.body.appendChild(element);
		element.click();
	}
	return (
		<>
			<div id="wrapper" style={{ fontSize: "15px" }}>
				<LoadingSpinner is_loading={is_loading} />
				<Navs title={t(' ')} />
				<Sidebar />
				<div id="content-wrapper" className="d-flex flex-column">
					<div id="content">
						<Topbar title={t('Notes')} />
						<div className="container-fluid full-width">
							<div className="d-sm-flex align-items-center justify-content-between mb-4">
								<Row className="w-100 mt-3">
									<Col md={7}>
										<Row>
											<Col md={2}>
												<div className="infobutton">
													<DatePicker className="form-control "
														selected={startDate}
														onChange={(date) => setStartDate(date)} />
												</div>
											</Col>
											<Col md={2} className="p-0">
												<div className="infobutton">
													<input className="form-control" id="validationCustom01" type="text" placeholder={t("search User")} required="" data-bs-original-title="" title=""
														value={searchUser}
														onChange={(e) => setSearchUser(e.target.value)} />
												</div>
											</Col>
											<Col md={2}>
												<div className="infobutton">
													<input className="form-control" id="validationCustom01" type="text" placeholder={t("search")} required="" data-bs-original-title="" title=""
														value={Search}
														onChange={(e) => setSearch(e.target.value)} />
												</div>
											</Col>

											<Col md={2}>
												<div className="infobutton">

													<button className=" btn btn-light w-100"
														style={{ background: "rgb(255, 175, 0)", color: "#fff", borderRadius: "12px", marginLeft: "5px" }}
														onClick={() => {
															if (moment(startDate).format("DD/MM/YYYY") === moment(new Date()).format("DD/MM/YYYY")) {
																// console.log("hhiii")
																onFilterSubmit('')
															} else {
																onFilterSubmit(moment(startDate).format("YYYY-MM-DD"))
															}
														}}>{t('Submit')}</button>
												</div>
											</Col>
											<Col md={2}>
												<div className="infobutton">

													<button className=" btn btn-light w-100"
														style={{ background: "rgb(255, 175, 0)", color: "#fff", borderRadius: "12px", marginLeft: "5px" }}
														onClick={() => { onFilterClear() }}>{t('Clear')}</button>
												</div>
											</Col>
										</Row>

									</Col>
									<Col md={5}>
										<Row>
											<Col md={4}></Col>
											<Col md={4}>
												<div className=" col back-btn" onClick={goBack} style={{ cursor: 'pointer' }}>
													<div className="text-light back-txt" ><i class="fas fa-long-arrow-alt-left"></i> {t('Back')}</div>
												</div>
											</Col>
											<Col md={4}>
												<div className="infobutton">

													<button className=" btn btn-light"
														style={{ background: "#9DCC6B", color: "#fff", borderRadius: "12px", marginLeft: "5px" }}
														onClick={() => notesTxt(notes)}>
														<i class="fas fa-arrow-circle-down mr-3"></i>{t('Download')}</button>
												</div>
											</Col>
										</Row>
									</Col>

								</Row>

							</div>
							<div className="row mb-4">
								<div className="col-md-12">
									<div className="card m-overflow-h">
										<div className="card-body m-p-0">
											<div className="table-responsive">
												<Table className="table table-striped align-items-center">
													<thead>
														<tr>
															<th>{t('Notes Description')}</th>
															<th>{t('File Name')}</th>
															<th>{t('Title')}</th>
															<th>{t('Date')}</th>
															<th>{t('Actions')}</th>
														</tr>
													</thead>
													<tbody>
														{(notes.length === 0) ? <tr>
															<td colSpan="8">
																{localStorage.getItem('langauge_selected') === "pt" ? <img src={imgnew2} className="no-data-img-2" /> :
																	<img src={imgnew} className="no-data-img-2" />}
															</td>
														</tr> : ''}
														{notes.map((list) => (
															<>
																<tr>
																	<td style={{ width: '40%' }} ><h6 >{list.description}</h6></td>
																	<td ><h6 style={{ color: "#EB5757" }}>{list.process_file.media.original_name}</h6></td>
																	<td style={{ width: '25%' }}>{list.title}</td>
																	<td style={{ width: '15%' }}>
																		<Moment format="DD/MM/YYYY">
																			{list.created_at}
																		</Moment></td>
																	<td style={{ width: '30%' }}>
																		<div className="row">
																			<div className="items-center mr-1 btn-outline-success btn btn-sm"
																				title={t("Download")}
																				onClick={() => downloadTxtFile(list.title, list.description)}
																			>
																				<i class="fas fa-download"></i>
																			</div>
																			<div className="items-center mr-1 btn-outline-warning btn btn-sm"
																				title={t("Edit")}
																				onClick={() => {
																					setNotesId(list.id)
																					setEditModal(true)
																				}}>
																				<i class="far fa-edit"></i>
																			</div>
																			<div className="items-center mr-1 btn-outline-danger btn btn-sm"
																				title={t("Delete")}
																				onClick={() => {
																					setIsDelete(true)
																					setTempId(list.id)
																				}}>
																				<i class="far fa-trash-alt"></i>
																			</div>

																		</div>
																	</td>
																</tr>
																{/* ))} */}
															</>

														))}


													</tbody>
												</Table>
											</div>
										</div>
										{is_delete === true ? (
											<SweetAlert
												warning
												showCancel
												cancelBtnText={t("Cancel")}
												confirmBtnText={t("Yes")}
												confirmBtnBsStyle="danger"
												title={t('Are you sure?')}
												onConfirm={(e) => {
													deleteNote(temp_id)
													setIsDelete(false)
												}}
												onCancel={(e) => {
													setIsDelete(false)
													setTempId('')
												}}
												focusCancelBtn
											>
												{t("Do you want to delete this note")}?
											</SweetAlert>
										) : ([]
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{editModal === true ?
					<EditNotesModal editModal={editModal} setEditModal={setEditModal} notesId={notesId} noteListing={() => noteListing(id)} />
					: null}
			</div>

		</>
	);
}

export default NotesList;
