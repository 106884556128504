// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// const resources = {
//   en: {
//     translation: {
//       Storage:
//         "react-i18next is a powerful internationalization framework for React / React Native which is based on i18next.",
//       para: "paraaa1222"
//     }
//   },
//   pt: {
//     translation: {
//       Storage:
//         "react-i18next es un poderoso marco de internacionalización para React / React Native que se basa en i18next.",
//       para: "paraa36446376fhhf"
//     }
//   },


// };

// i18n
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     resources,
//     lng: "hn",
//     fallbackLng: "sp",
//     interpolation: {
//       escapeValue: false // not needed for react as it escapes by default
//     }
//   });

// export default i18n;


import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
const resources = {
  pt: {
    translation: {
      "Payment successful": "Pagamento bem sucedido",
      "The state id field is required": "O campo id do estado é obrigatório",
      "The city id field is required": "O campo id da cidade é obrigatório",
      "Old Cards": "Cartões Antigos",
      "New Card": "Novo cartão",
      "Phone number": "Número de telefone",
      "Old Activity": "Atividade antiga",
      "Process activated": "Processo ativado",
      "added customer in": "cliente adicionado em",
      "added user in": "usuário adicionado em",
      "added manager in": "gerente adicionado em",
      "added contributor in": "contribuidor adicionado em",
      "added you as a manager in": "adicionou você como gerente em",
      "added you as a contributor in": "adicionou você como colaborador em",
      "removed a file from process": "removeu um arquivo do processo",
      "completed payment for your purchase": "finalizou o pagamento da sua compra",
      "Your payment is successfull for purchase": "Seu pagamento foi efetuado com sucesso para compra",
      "File is processed added for": "O arquivo é processado adicionado para",
      "added a new file for": "adicionado um novo arquivo para",
      "added you as a customer in": "adicionou você como cliente em",
      "deleted a manager from process": "excluiu um gerente do processo",
      "deleted a user from process": "excluiu um usuário do processo",
      "deleted a contributor from process": "excluiu um colaborador do processo",
      "deleted a customer from process": "customer",
      "added manager in": "gerente adicionado em",
      "added user in": "usuário adicionado em",
      "added contributor in": "contribuidor adicionado em",
      "added customer in": "cliente adicionado em",
      "added a new note": "adicionou uma nova nota",
      "added new file for": "adicionado novo arquivo para",
      "deactivated a process": "desativou um processo",
      "activated a process": "ativou um processo",
      "Added to new process": "Adicionado ao novo processo",
      "New file added to process": "Novo arquivo adicionado ao processo",
      "File is processed": "O arquivo é processado",
      "Payment Succesful": "Pagamento bem-sucedido",
      "Payment Completed": "Pagamento completo",
      "File removed": "Arquivo removido",
      "Process deactivated": "Processo desativado",
      "New note added": "Nova nota adicionada",
      "Added manager to process": "Adicionado gerente para processar",
      "Added customer to process": "Cliente adicionado ao processo",
      "Added user to process": "Usuário adicionado ao processo",
      "Added contributor to process": "Contribuidor adicionado ao processo",
      "Deleted process manager": "Gerenciador de processos deletado",
      "Deleted process customer": "Cliente de processo deletado",
      "Deleted process user": "Usuário de processo excluído",
      "Deleted process contributor": "Colaborador de processo excluído",
      "removed a file from process": "removeu um arquivo do processo",
      "File removed": "Arquivo removido",
      "added new file for": "adicionado novo arquivo para",
      "New file added to process": "Novo arquivo adicionado ao processo",
      "No records": "Não há registros",
      "Documents": "Documentos",
      "Duration": "Duração",
      "Verify OTP": "Verificar OTP",
      "Enter OTP": "Digite OTP",
      "Reset Password": "Redefinir senha",
      "View": "Visualizar",
      "Select User": "Selecione o usuário",
      "Do you want to delete this note": "Deseja excluir esta nota",
      "search User": "Usuario",
      "One Time Password": "Senha única",
      "Ensure you submit the current one because it refreshes every 30 seconds": "Certifique-se de enviar o atual porque ele é atualizado a cada 30 segundos",
      "Please enter the OTP generated on your Authenticator App": "Por favor, insira o OTP gerado em seu aplicativo autenticador",
      "Verification email has been sent. Please verify your email first": "O e-mail de verificação foi enviado. Por favor, verifique seu e-mail primeiro",
      "See how we can make you earn time and to have better results": "Veja como podemos fazer você ganhar tempo e ter melhores resultados",
      "Main Features": "Principais características",
      "Do you want to generate this file?": "Deseja gerar este arquivo?",
      "Ask your questions and needs here": "Pergunte aqui suas duvidas e necessidades",
      "Artificial Intelligence": "Inteligência artificial",
      "Your processing video duration limit has been exhausted!": "Seu limite de duração de processamento de vídeo foi esgotado!",
      "Assign To User": "Atribuir ao usuário",
      "This File is not processed yet . once it will processed you will be able to review.": "Este arquivo ainda não foi processado. assim que for processado, você poderá revisar.",
      "Queued": "Enfileirados",
      "Processed": "Processado",
      "Pending": "Pendente",
      "Cancel": "Cancelar",
      // dashboard
      "Do you want to process this file?": "Deseja processar este arquivo?",
      "View Summary": "Exibir resumo",
      "Summary": "Resumo",
      "Chat": "Bater papo",
      "Send": "Enviar",
      "Type message here...": "Digite a mensagem aqui...",
      "Today": "Hoje",
      "Clear": "Limpar",
      "Clear Chat": "Limpar chat",
      "Chat-bot": "chat-bot",
      "ChatGPT Summary": "ChatGPT Resumo",
      "No": "Não",
      "Generate Summary": "Gerar resumo",
      "Summarised": "Resumida",
      "Search Summary": "Resumo da pesquisa",
      "Storage": "Armazenamento",
      "Document": "Documento",
      "Images": "Imagens",
      "Files": "arquivos",
      "Upgrade your Storage": "Atualize seu armazenamento",
      "View plan": "Ver plano",
      "Files Processing Monitor": "Monitor de processamento de arquivos",
      "Pending": "Pendente",
      "Processed": "Processado",
      "Files Processed": "Arquivos processados",
      "Files Pending": "Arquivos pendentes",
      "Presents Files": "Apresenta arquivos",
      "than last day": "do que no último dia",
      "List of process": "Lista de processos",
      "Add New": "Adicionar novo",
      "Process Name": "Nome do processo",
      "Customer Name": "Nome do cliente",
      "Last Updated": "Ultima ",
      "prev": "anterior",
      "Phone Number": "Telefone",
      "next": "próximo",
      "Page processing used": "Processamento de página usado",
      "Processed of ": "Processado de ",
      "Page Processing Used": "Processamento de página usado",
      "Processing Available": "Processamento disponível",
      "Get details": "Obter detalhes",
      "Creative Agency": "Agência criativa",
      "Redesign Landing Page Website for Company Profile": "Redesenhe o site da página de destino para o perfil da empresa",
      "My Process": "Meu processo",
      "Due date": "Data de Vencimento",
      "In publishing and graphic design Lorem ipsum is a placeholder text": "Em editoração e design gráfico Lorem ipsum é um espaço reservado para text",
      "View All": "Ver tudo",
      "Latest Notes": "Últimas notas",
      "See All": "ver tudo",
      "No Process": "Nenhum processo",
      "Video processing used": "Processamento de vídeo usado",
      "available": "acessível",
      "search": "Procurar",
      "Total Storage": "Armazenamento total",
      "Video": "Vídeo",
      "Files": "arquivos",
      "Present Files": "Arquivos presentes",
      // SideBar
      "Home": "Painel",
      "Process List": "Lista de processo",
      "Customer Management": "Gestão de Clientes",
      "Settings": "Definições",

      // TopBar
      "Logout": "Sair",

      // ProcessList
      "Customer": "Cliente",
      "Status": "Status",
      "Action": "Ação",
      "Active": "Ativo",
      "Deactive": "Desativado",

      // User List
      "User List": "Lista de usuários",
      "Filter": "Filtro",
      "Customer Only": "Apenas Cliente",
      "Team only": "Somente equipe",
      "New customer": "Novo cliente",
      "New Customer": "Novo Cliente",
      "Download": "Download",
      "Assign to process": "Atribuir ao processo",
      "Name": "Nome",
      "Phone": "Telefone",
      "No Customer": "Nenhum cliente",
      "Do you want to delete this user": "Deseja excluir este usuário",

      // Settings
      "Settings": "Definições",
      "Two Factor Authentication": "Autenticação de dois fatores'",
      "Set up google authenticator": "Configurar o autenticador do Google",
      "Set up your two factor authentication by scanning the barcode below Alternatively you can use the code": "Configure sua autenticação de dois fatores digitalizando o código de barras abaixo Alternativamente, você pode usar o código",
      "Access to the most powerfull tool in the entire design and web industry": "Acesso à ferramenta mais poderosa de toda a indústria de design e web",
      "You must set up your Google Authenticator app before continuing You will be unable to login otherwise": "Você deve configurar seu aplicativo Google Authenticator antes de continuar Você não poderá fazer login de outra forma",
      "Complete Registration": "Registro completo",
      "To scan QR code you can download authenticator apps from below links": "Para digitalizar o código QR, você pode baixar aplicativos autenticadores nos links abaixo",
      // dashboard /addnew button : Add New Process            
      "Add New Process": "Adicionar novo processo",
      "Existing User": "Nome do processoUsuário existente",
      "New User": "Novo usuário",
      "Full Name": "Nome completo",
      "Please enter your name": "Por favor, insira seu nome",
      "Email Address": "Endereço de email",
      "Please enter your email": "Por favor introduza o seu e-mail",
      "Mobile": "Telefone",
      "Please enter your contact no": "Por favor, insira seu número de contato",
      "User Type": "Tipo de usuário",
      "Select User Type": "Selecione o tipo de usuário",
      "Submit": "Enviar",
      "Do you want to summarise this file with AI": "Deseja resumir este arquivo com IA",
      // Process List /Actionsbutton Process : dashboard 
      "Process : ": "Processo: ",
      "Back": "De volta",
      "Number of Files": "Número de arquivos",
      "Number of PDF": "Número de PDF",
      "Number of Videos": "Número de vídeos",
      "No Files": "Nenhum arquivo",
      "This File is not processed yet . once it will processed you will be able to review.": "Este arquivo ainda não foi processado. assim que for processado, você poderá revisar.",
      // Process List /Actionsbutton Document list 
      "Document list": "Lista de arquivos",
      "Existing Customer": "Usuario existente",
      "Add File": "Adicionar arquivo",
      "File Name": "Nome do arquivo",
      "Last Modified": "Última modificação",
      "Notes": "Observação",
      "Notes": "Notas",
      "You have 3 Notes": "Você tem 3 notas",
      "Manage": "Gerenciar",
      "Edit": "Editar",
      "Remove": "Remover",
      "ago": "atrás",
      "No Process": "Nenhum Processo",
      "Process Detail": "Detalhe do processo",
      // Process List /Actionsbutton Customer list 
      "Customer List": "Lista de clientes",
      "No Access": "Sem acesso",
      "Readed": "Lido",
      "Assign to user": "Atribuir ao usuário",
      "File Upload": "Adicionar arquivo",
      "File Management": "Gerenciamento de arquivos",
      "Process Files": "Processar arquivos",
      "Do you want to activate process?": "Deseja ativar o processo?",
      "Are you sure?": "Tem certeza?",
      "Yes": "Sim",
      "Do you want to deactivate process?": "Deseja desativar o processo?",
      "+ Add New": "+ Adicionar novo",
      "New Customer": "Novo cliente",
      // Process List /Actionsbutton UserList 
      "Users / Access list": "Usuários / lista de acesso",
      "Read": "Ler",
      "Do you want to delete user?": "Deseja excluir o usuário?",
      "No Users": "Nenhum usuário",
      // Process List /Actionsbutton Notes  
      "How to manage your working time in this pandemic": "Como gerenciar seu tempo de trabalho nesta pandemia",
      "Read": "Ler",
      "No Notes": "sem notas",
      // Process List /Actionsbutton Add Files 
      "Upload File": "Subir arquivo",
      "Return to Process": "Retornar ao processo",
      "History": "História",
      "Today": "Hoje",
      "Process edited Successfully!": "Processo editado com sucesso!",
      "Assigned": "Atribuído",
      "Assign": "Atribuir",
      "Upload File": "Subir arquivo",
      // Process List /Actionsbutton FileList
      "File List": "Lista de arquivos",
      "Notes : Process Name": "Observações: Nome do Processo",
      "File Size": "Tamanho do arquivo",
      "Search": "Procurar",
      "Submit Now": "Envie agora",
      "Download": "Download",
      "Do you want to delete this File?": "Deseja excluir este arquivo?",
      // Process List /Actionsbutton AddNotesModal
      "Add Note": "Nota",
      "Title": "Título",
      "Description": "Descrição",
      "Please enter your note": "Por favor, insira sua nota",
      "Notes": "Notas",
      "No Notes": "sem notas",
      "Delete": "Excluir",
      "Actions": "Ações",
      "Date": "Data",
      "Note Name": "Nome da nota",

      // Process List /Actionsbutton Add New Customer
      "Add New Customer": "Adicionar novo cliente",
      "Type": "Tipo de usuario",
      "Bio": "Biografia",
      "Please enter your Bio": "Por favor, insira sua biografia",
      "PROFILE PICTURE": "FOTO DO PERFIL",
      "CHANGE PHOTOS": "ALTERAR FOTOS",
      "Add Customer": "Adicionar cliente",
      "Assign to user": "Atribuir ao usuário",
      "Two Factor Authentication": "Autenticação de dois fatores",
      "If you skip two factor authentication you might lose all your documents.": "Se você ignorar a autenticação de dois fatores, poderá perder todos os seus documentos.",
      "Verified": "Verificado",
      // Process List + customer Management/Actionsbutton Add New Customer
      "Edit user": "Editar usuário",
      "Please select the note": "Por favor selecione a nota",
      "Update": "Atualizar",

      // Process List + customer Management/Actionsbutton Edit Process
      "Edit Process": "Editar processo",
      "Process Description": "Descrição do processo",

      // Process List /Actionsbutton Assign To ProcessModal
      "Assign To Process": "Atribuir ao processo",
      "Process Description": "Descrição do processo",

      //  Footer/ Headere 
      "Menu": "Cardápio",
      "Home": "Home",
      "Pricing": "Preços",
      "Login": "Login",
      "Contact us": "Fale Conosco",
      "Service": "Serviço",
      "Business Law": "Direito Empresarial",
      "Employment Law": "Lei trabalhista",
      "Medical Law": "Lei Médica",
      "Real Esate Law": "Direito Imobiliário",
      "Contact with us": "Entre em contato conosco",
      "Copyright © 2023 AdVreader All Rights Reserved | Developed By": "Copyright © 2023 AdVreader Todos os direitos reservados | Desenvolvido  por",
      "CodiFly  IT Solution": "Solução de TI CodiFly",
      "Terms of Use": "Termos de uso",
      "Privacy Policy": "Política de Privacidade",

      // Mian page Crousel
      "Empower Lawyers": "Capacitar advogados",
      "for Better Results": "para melhores resultados",
      "More analysis, consectetur less reading": "Mais análise, menos leitura",
      "Current Password": "Senha atual",
      "New Password": "Nova Senha",
      "Confirm Password": "Confirme a Senha",
      "Change Password": "Mudar senha",
      // Mian page
      "Legaltech": "Legaltech",
      "Bringing technology to your business Analyze data in a collaborative way Search in text videos and work collaboratively sharing insights between your team and your client The technology in your power": "Trazendo tecnologia para seu negócio. Analise dados de forma colaborativa. Pesquise em vídeos de texto e trabalhe colaborativamente compartilhando insights entre sua equipe e seu cliente. a tecnologia ao seu alcance.",
      "Main": "Principal",
      "Features": "Característicos",
      "Document to Data": "Documento para Dados",
      "Video to Data": "Vídeo para dados",
      "Data Search": "Pesquisa de dados",
      "Add Notes": "Adicionar notas",
      "Collaborate": "Colaborar",
      "Safe Environment": "Ambiente seguro",
      "Artificial Intelligence": "Inteligência artificial",
      "Web Platform": "Plataforma Web",
      "Why choose us": "Porque escolher-nos",
      "The union between technology and experience Our system born from real case scenarios and years of experience in court": "A união entre tecnologia e experiência . Nosso sistema nasceu de cenários de casos reais e anos de experiência em tribunal",
      "Experience": "Experiência",
      "Reputation": "Reputação",
      "Easy to use": "Fácil de usar",
      "Commitment to security": "Compromisso com a segurança",
      "Commitment to quality": "Compromisso com a qualidade",
      "Constant evolution": "Evolução constante",

      // Login Page
      "Logo": "Logotipo",
      "Welcome": "Bem-vindo",
      "Login into your account": "Faça login na sua conta",
      "Password": "Senha",
      "Forgot password": "Esqueceu a senha",
      "Go to home": "Vá para casa",
      "Want a new account": "Quer uma nova conta",
      "Start it now": "Comece agora",

      // Forgot Page
      "Forgot Password": "Esqueceu a senha",
      "Enter your email address": "Insira o seu endereço de email",
      "Enter email address": "Insira o seu endereço de email",
      "RESET PASSWORD": "REINICIAR SENHA",
      "Remember Password": "Lembrar senha",
      "Login now": "Conecte-se agora",

      // Register Page
      "Get more things done with Login platform": "Faça mais coisas com a plataforma Login",
      "Access to the most powerfull tool in the entire design and web industry": "Acesso à ferramenta mais poderosa de toda a indústria de design e web",
      "Confirm Password": "Confirme a Senha",
      "Register": "Registro",
      "You have account": "Você tem conta",

      // Theam-Header Page
      "About us": "Sobre nós",
      "Legal Areas": "Áreas Jurídicas",

      // Contact-us Page
      "Contact": "Fale",
      "Us": "Conosco",
      "Practically every single website worth anything out there has a Contact Us page that it can turn to whenever issues about Us come up with users": "Praticamente todos os sites que valem alguma coisa por aí têm uma página de contato que pode ser acessada sempre que problemas sobre nós surgirem com os usuários",
      "Feel Free to Get In Contact With Us": "Sinta-se à vontade para entrar em contato conosco",
      "Available 24 Hours a Day": "Disponível 24 horas por dia",
      "Send Email For Inquiry": "Enviar e-mail para consulta",
      "Subject": "Assunto",
      "Message": "Mensagem",
      "Email": "E-mail",
      "Please enter your Subject": "Por favor, digite seu assunto",

      // Terms of Service Page
      "Terms of Service": "Termos de serviço",
      "Terms": "Termos",
      "Practically every single website worth anything out there has a Terms of Service page that it can turn to whenever issues about Terms come up with users": "Praticamente todos os sites que valem alguma coisa por aí têm uma página de Termos de Serviço que pode ser acessada sempre que problemas sobre os Termos surgirem com os usuários",
      "What is a terms of service agreement": "O que é um contrato de termos de serviço",
      "Terms of service have two synonyms, including Terms and Conditions and Terms of Use This is a phrase that includes a set of rules that must be followed by a user so that he on she can get and use a service or a product": "Termos de serviço têm dois sinônimos, incluindo Termos e Condições e Termos de Uso Esta é uma frase que inclui um conjunto de regras que devem ser seguidas por um usuário para que ele possa obter e usar um serviço ou produto",
      "Although the terms of service page might be the most boring place on your site you still need to have it On this page, your visitors can quickly learn the rules for using the website They will know what’s expected of them and what they can expect from you": "Embora a página de termos de serviço possa ser o lugar mais chato do seu site, você ainda precisa tê-la Nesta página, seus visitantes podem aprender rapidamente as regras para usar o site Eles saberão o que se espera deles e o que podem esperar de você",
      "There are no real legal requirements for an outline of the terms of service page but there are a lot of benefits to getting out of one Both consumers/visitors and the business will get various benefits with the greatest focus on safety": "Não há requisitos legais reais para um esboço da página de termos de serviço, mas há muitos benefícios em sair de uma Tanto os consumidores/visitantes quanto a empresa obterão vários benefícios com o maior foco na segurança",
      "Not only will you be able to protect your business from any lawsuits made by visitors who harmed themselves in some way but a business can also protect all the original content located on its website and claim all the rights for it": "Você não apenas poderá proteger sua empresa de quaisquer ações judiciais feitas por visitantes que se prejudicaram de alguma forma, mas uma empresa também poderá proteger todo o conteúdo original localizado em seu site e reivindicar todos os direitos sobre ele",
      "For example when e-commerce businesses are facing legal lawsuits in court, the court always looks at their site and looks for the terms of service page so that they can decide what the contractual terms were during the moment when the two parties interacted": "Por exemplo, quando as empresas de comércio eletrônico estão enfrentando ações judiciais no tribunal, o tribunal sempre analisa o site e procura a página de termos de serviço para que possam decidir quais eram os termos contratuais durante o momento em que as duas partes interagiram",
      "A lot of businesses keep these potential suits in mind when they are creating a terms of service page": "Muitas empresas mantêm esses possíveis processos em mente quando estão criando uma página de termos de serviço",
      "Generic Terms of Service Template": "Modelo de Termos de Serviço Genéricos",
      "Please read these terms of service": "Por favor, leia estes termos de serviço",
      "carefully before using": "cuidadosamente antes de usar",
      "website": "local na rede Internet",
      "operated by": "operado por",
      "Conditions of Use": "Condições de Uso",
      "We will provide their services to you which are subject to the conditions stated below in this document Every time you visit this website use its services or make a purchase you accept the following conditions This is why we urge you to read them carefully": "Forneceremos seus serviços a você que estão sujeitos às condições indicadas abaixo neste documento Cada vez que você visitar este site, use seus serviços ou faça uma compra, você aceita as seguintes condições É por isso que pedimos que você as leia atentamente",

      // Privacy Policy Page
      "Privacy": "Privacidade",
      "policy": "político",
      "How Can I Use A Privacy Policy": "Como posso usar uma política de privacidade",
      "Usually privacy policy templates are pretty straightforward You can get one from a reputable source copy the template and then paste it on your privacy page Many of the relevant details will either be left blank or will need to be replaced Either way you need to be careful about the information that you put in the page": "Normalmente os modelos de política de privacidade são bastante simples Poderáobter um de uma fonte reputada copie o modelo e cole-o na sua página de privacidade Muitos dos detalhes relevantes serão deixados em branco ou terão de ser substituídos De qualquer forma teráde ter cuidado as informações que você coloca na página",
      "You want to focus on company name or website name the name of the site owner company CEO, or web manager and a few important details about purchases service charges and so on Other than a few tweaks a good template would not require much from you": "Você deseja se concentrar no nome da empresa ou no nome do site o nome do proprietário do site CEO da empresa ou gerente da web e alguns detalhes importantes sobre as taxas de serviço de compras e assim por diante Além de alguns ajustes, um bom modelo não exigiria muito de você",
      "What is A Privacy Policy": "O que é uma política de privacidade",
      "Team": "Equipe",
      "Budget": "Orçamento",
      "Our extensive resources and commitment to client service satisfy the needs of a diverse clientele across multiple industries": "Nossos amplos recursos e compromisso com o atendimento ao cliente atendem às necessidades de uma clientela diversificada em vários setores",
      "No Search Result Found": "Nenhum resultado de pesquisa encontrado",
      "results": "resultados",
      "Go To": "Vá para",
      "Page": "Página",
      "of": "de",
      "Add Note +": "Adicionar nota +",
      "results in this file": "resultados neste arquivo",
      "Notes Description": "Descrição das notas",
      "Forgot password ?": "Esqueceu a senha ?",
      "Go To Home ?": "Ir para casa?",
      "Login Successfull": "Login bem-sucedido",
      "Two factor is enabled": "Dois fatores estão ativados",
      "Coupon Added Successfully": "Cupom adicionado com sucesso",
      "New Customer has been Added": "Novo cliente foi adicionado",
      "Coupon Applied Succesfully": "Cupom aplicado com sucesso",
      "Coupon removed successfully": "Cupom removido com sucesso",
      "Payment made successfully": "Pagamento efetuado com sucesso",
      "Note has been added": "A nota foi adicionada",
      "User has been assigned to the process": "O usuário foi atribuído ao processo",
      "Customer has been assigned to the process": "O cliente foi atribuído ao processo",
      "New process has been added": "Novo processo foi adicionado",
      "New user has been added": "Novo usuário foi adicionado",
      "Logout Successfull": "Sair com sucesso",
      "user deleted successfully": "usuário deletado com sucesso",
      "Contact has been Submited": "O contato foi enviado",
      "User Edit Successfully": "Usuário editado com sucesso",
      "Profile Picture Uploaded Successfully": "Foto do perfil enviada com sucesso",
      "File Delete Successfully": "Arquivo Excluído com Sucesso",
      "File Processed Successfully": "Arquivo processado com sucesso",
      "Password reset email has been sent": "O e-mail de redefinição de senha foi enviado",
      "Verified": "Verificado",
      "Note Deleted Successfully": "Nota excluída com sucesso",
      "Files Processed Successfully": "Arquivos processados ​​com sucesso",
      "Profile Updated Successfully": "Perfil atualizado com sucesso",
      "Profile Picture Uploaded Successfully": "Foto do perfil enviada com sucesso",
      "Transaction Initiated": "Transação Iniciada",
      "Purchase link generated successfully": "Link de compra gerado com sucesso",
      "User registered succesfully . Please verify your email now": "Utilizador registado com sucesso. Verifique seu e-mail agora",
      "Otp verified": "OTP verificado",
      "Password has been changed": "a senha foi alterada",
      "Two factor authentication disabled": "Autenticação de dois fatores desativada",
      "Copied": "Copiado",
      "Boleto Url Copied": "URL do Boleto Copiado",
      "Two factor authentication disabled": "Autenticação de dois fatores desativada",
      "User Delete Successfully": "Usuário excluído com sucesso",
      "Email Verified successfully": "E-mail verificado com sucesso",
      "Update Profile": "Atualizar perfil",
      "User Name": "Nome do usuário",
      "PROFILE PICTURE": "FOTO DO PERFIL",
      "Transactions": "Transações",
      "User Management": "Gerenciamento de usuários",
      "Customer Management": "Gestão de Clientes",
      "Transaction List": "Lista de transações",
      "All": "Todos",
      "Card": "Cartão",
      "Boleto": "boleto",
      "Payment Id": "ID de pagamento",
      "Transaction Number": "Número da transação",
      "Payment Type": "Tipo de pagamento",
      "Paid Amount": "Quantidade paga",
      "Purchase Type": "Tipo de compra",
      "Purchase Date": "data de compra",
      "Status": "Status",
      'View Details': "Ver detalhes",
      "Transaction Details": "Detalhes da transação",
      "Storage Calculation": "Cálculo de armazenamento",
      "Quantity": "Quantidade",
      "Unit Price": "Preço unitário",
      "Discount Price": "Preço com desconto",
      "Price": "Preço",
      "Processing Pages": "Páginas de processamento",
      "Processing Videos": "Processando vídeos",
      "Additional Storage": "Armazenamento Adicional",
      "Discount": "Desconto",
      "Subtotal": "Subtotal",
      "Credit Card Rate": "Taxa de cartão de crédito",
      "Grand Total": "Total geralv",
      "Purchase Pricing": "Preço de compra",
      "Generate purchase link": "Gerar link de compra",
      "Acquire For me": "Adquira para mim",
      "Benefits": "Benefícios",
      "User": "Usuário",
      "Unlimited": 'Ilimitado',
      "Hours Saving": "Economia de horas",
      "Available Storage GB": "GB de armazenamento disponível",
      "Work in a collaborative way": "Trabalhe de forma colaborativa",
      "Do research in all files at the same time": "Faça pesquisas em todos os arquivos ao mesmo tempo",
      "Add Notes": "Adicionar notas",
      "Share with your customer": "Compartilhe com seu cliente",
      "Safe Acess": "Acesso seguro",
      "Checkout": "Confira",
      "Credit Card": "Cartão de crédito",
      "Card Number": 'Número do cartão',
      "Card Holder Name": "Nome do Titular",
      "CVC": "CVC",
      "Card Validity": "Validade do cartão",
      "CPF": "CPF",
      "Email Address": "Endereço de e-mail",
      "ZipCode": "Código postal",
      "First Name": "Primeiro nome",
      "Last Name": "Sobrenome",
      "Address": "Endereço",
      "Country": "País",
      "Select Country": "Selecione o pais",
      "State": "Estado",
      "Select State": 'Selecione o estado',
      "City": "Cidade",
      "Select City": "Selecione a cidade",
      "title": "título",
      "Coupon Code": "Código do cupom",
      "Apply": "Aplicar",
      "Voucher Discount": "Voucher de desconto",
      "Discounted Price": "Preço com desconto",
      "Credit card rate(+)": "Taxa de cartão de crédito (+)",
      "Purchase": "Comprar",
      "Boleto rates (+)": "Taxas de boleto (+)",
      "pending": "pendente",
      "initiated": "iniciado",
      "awaiting": "aguardando",
      "paid": "pago",
      "Link Acquire": "Adquirir link",
      "Self Purchase": "Autocompra",
      "Purchase Link": "Link de Compra",
      "Copy Boleto Url": "Copiar url do boleto",
      "Boleto Url": "url do boleto",
      "View Detail": "Ver detalhes",
      "card": "cartão",
      "Go To Page": "Vá para página",
      "Copy": "cópia de",
      "File / Video reader": "Leitor de arquivo/vídeo",
      "English": "Inglês",
      "Português": "Português",
      "Set up google authenticator": "Configurar o autenticador do Google",
      "Set up your two factor authentication by scanning the barcode below. Alternatively, you can use the code": "Configure sua autenticação de dois fatores digitalizando o código de barras abaixo. Alternativamente, você pode usar o código",
      "Access to the most powerfull tool in the entire design and web industry": "Acesso à ferramenta mais poderosa em toda a indústria de design e web",
      "You must set up your Google Authenticator app before continuing. You will be unable to login otherwise": "Você deve configurar seu aplicativo Google Authenticator antes de continuar. Caso contrário, você não conseguirá fazer login",
      " Enable Two Factor Authentication": "Ativar autenticação de dois fatores",
      "If you skip two factor authentication you might lose all your documents": "Se você pular a autenticação de dois fatores, poderá perder todos os seus documentos",
      "Complete Registration": "Registro completo",
      "Skip & GoTo Login": "Ignorar e Ir Para Login",
      "To scan QR code you can download authenticator apps from below links": "Para digitalizar o código QR, você pode baixar aplicativos autenticadores nos links abaixo",
      "One Time Password": "Senha única",
      "could not scan": "não foi possível digitalizar",
      "Go Back": "Volte",
      "Verify": "Verificar",
      "generated on your Authenticator App": "gerado em seu Aplicativo Autenticador",
      "Ensure you submit the current one because it refreshes every 30 seconds": "Certifique-se de enviar o atual porque ele é atualizado a cada 30 segundos",
      "Please enter the": "Por favor insira o",
      "The process name field is required": "O campo nome do processo é obrigatório",
      "The old user id field is required": "O campo de id do usuário antigo é obrigatório",
      "Invalid login credential": "Credencial de login inválida",
      "Please Enter Correct Otp": "Insira o OTP correto",
      "invalid token": "token inválido",
      "The user name field is required": "O campo de nome de usuário é obrigatório",
      "The user email field is required": "O campo de e-mail do usuário é obrigatório",
      "The type must be a string": "O tipo deve ser uma string",
      "The phone number must be a string": "O número de telefone deve ser uma string",
      "The description field is required": "O campo descrição é obrigatório",
      "The password field is required": "O campo de senha é necessária",
      "The old password field is required": "O campo da senha antiga é obrigatório",
      "The title field is required": "O campo título é obrigatório",
      "Name field cannot be null": "O campo de nome não pode ser nulo",
      "The process role id field is required": "O campo de ID da função do processo é obrigatório",
      "The process id field is required": "O campo id do processo é obrigatório",
      "The phone number field is required": "O campo número de telefone é obrigatório",
      "The card holder name field is required": "O campo do nome do titular do cartão é obrigatório",
      "The card number field is required": "O campo do número do cartão é obrigatório",
      "The card security code field is required": "O campo do código de segurança do cartão é obrigatório",
      "The card validity field is required": "O campo validade do cartão é obrigatório",
      "The coupon code field is required": "O campo do código do cupom é obrigatório",
      "The cpf field is required": "O campo CPF é obrigatório",
      "The first name field is required": "O campo do primeiro nome é obrigatório",
      "The last name field is required": "O campo sobrenome é obrigatório",
      "The email field is required": "O campo e-mail é obrigatório",
      "The zip code field is required": "O campo CEP é obrigatório",
      "The address field is required": "O campo endereço é obrigatório",
      "Invalid coupon code": "Código de cupom inválido",
      "The user email has already been taken": "O e-mail do usuário já foi usado",
      "New user has been updated": "O novo usuário foi atualizado",
      "WHAT WE ARE": "O QUE SOMOS",
      "A business approach to legal service": "Uma abordagem empresarial para o serviço jurídico",
      "You can count on the undivided attention of a team that prizes relationships over profits": "Você pode contar com a atenção total de uma equipe que valoriza os relacionamentos acima dos lucros",
      "Every Person Who Walks Through Our Door Is Important To Us": "Cada pessoa que entra pela nossa porta é importante para nós",
      "Committed to Helping Our Clients Succeed": "Comprometidos em ajudar nossos clientes a ter sucesso",
      "Activity Today": "Atividade hoje",
      "Notification": "Notificação",
      "Password changed successfully": "Senha alterada com sucesso",
      "The password confirmation does not match": "A confirmação da senha não corresponde",
      "Change Password": "Mudar senha",
      "Current Password": "Senha atual",
      "New Password": "Nova Senha",
      "Confirm Password": "Confirme a Senha",
      "Old password is not correct": "A senha antiga não está correta",
      "Please enter OTP": "Insira OTP",
      "Upload cropped image": "Carregar imagem recortada",
      "Close": "Perto",
      "Update Profile Picture": "Atualizar foto do perfil",
      "The name field is required": "O campo nome é obrigatório",
      "The subject field is required": "O campo assunto é obrigatório",
      "The message field is required": "O campo mensagem é de preenchimento obrigatório",
      "Practically every single website worth anything out there has a Privacy Policy page that it can turn to whenever issues about privacy come up with users": "Praticamente todos os sites que valem alguma coisa por aí têm uma página de Política de Privacidade à qual podem recorrer sempre que problemas sobre privacidade surgirem com os usuários"


    }
  },

};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources
  });

export default i18n;




// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';

// import Backend from 'i18next-xhr-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';


// i18n
//   // .use(Backend)
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     fallbackLng: 'en',
//     debug: true,

//     interpolation: {
//       escapeValue: false,
//     }
//   });
// export default i18n;