import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../config/config";
import { displayError, displaySuccess, getNotesList, getProcessNotesList } from "../Utils/utils";
import Highlighter from "react-highlight-words";
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import PdfModal from "../components/Modal/PdfModal";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../components/LoadingSpinner";
import SweetAlert from "react-bootstrap-sweetalert";
import { useParams } from "react-router-dom";

const Summary = (props) => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const [pageNumber, setPageNumber] = useState(1);
  const [view, setView] = useState(false);
  const [key, setKey] = useState('Note');
  const [search, setSearch] = useState('')
  const [searchPdf, setSearchPdf] = useState('');
  const [fileName, setFileName] = useState('');
  const [video, setVideo] = useState('');
  const [notFound, setNotFound] = useState(false);
  const [is_loading, setIsloading] = useState(false);

  const [volume, setVolume] = useState(100)
  const [totalDurationOfVideo, setTotalDurationOfVideo] = useState(0)
  const hostVideo = React.createRef()
  const [pdfModal, setPdfModal] = useState(false)
  const [summary, setSummary] = useState('');
  const [isGenerate, setIsGenerate] = useState(false);

  var total = 0
  const summaryyy = queryParams.get('summary')
  const file = queryParams.get('file_id')
  const { id } = useParams()
  console.log(id)

  const getSummary = async () => {
    setIsloading(true)
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/auth/search-file-summary', {
      file_id: props.fileId,
      text: search
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (response.data.data == null) {
          setIsloading(false)
          setNotFound(true)

        } else {

          setIsloading(false)
          setNotFound(false)
          if (response.data.data?.media?.type === "pdf") {
            setView(false)
            setSearchPdf(response.data.data?.media?.url)
            setFileName(response.data.data?.media?.original_name)
            setPageNumber(response.data.data?.media?.pages)
          } else {
            setView(true)
            setFileName(response.data.data?.media?.original_name)
            setVideo(response.data.data?.media?.url)
          }
          setSummary(response?.data?.data?.summary)

        }
      })
      .catch((error) => {
        setIsloading(false)
        displayError(error, t);
      });
  }

  const generateSummary = async () => {
    setIsloading(true)
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/auth/generate-summary', {
      process_file_id: props.fileId,
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setIsloading(false)
        window.location.href = `/video-reader/${id}?file_id=${file}&&summary=true`
        getSummary()
        displaySuccess(response?.data?.data)

        // getFilesList(id);

      })
      .catch((error) => {
        setIsloading(false)
        displayError(error, t);
      });
  }
  useEffect(() => {
    if (summaryyy === null) {
      setIsGenerate(true)
      // generateSummary()
    } else {
      getSummary()
    }
  }, [])

  useEffect(() => {
    getSummary()
  }, [search])

  return (
    <>
      <LoadingSpinner is_loading={is_loading} />
      <div className="row my-5" >

        <div className="col-md-8 search-result">
          <div className="card shadow " style={{ borderRadius: "15px", height: 'auto' }}>
            <div className="card-body">

              <div className="row">
                <div className="search-box">
                  <input type="text" placeholder={t("search")} className="searchinput" value={search} onChange={(e) => setSearch(e.target.value)} />
                  <i class="fas fa-search text-lg"></i>
                </div>
              </div>

              {notFound === true ?
                <div className="notFound">
                  {t("No Search Result Found")}

                </div>
                :
                <>
                  <div
                    className="textPrcess"
                    style={{
                      color: '#002ADFA3', marginLeft: '17px',
                      fontSize: '20px',
                      marginTop: '10px'
                    }}> {fileName}</div>


                  <div className=" ml-3 mt-3 ">

                    <div className="row mt-2 ml-1">
                      <p className="ml-3 para">
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={[search]}
                          autoEscape={true}
                          textToHighlight={summary}
                        />
                      </p>

                    </div>
                    <hr />
                  </div>
                </>
              }
            </div>
          </div>
        </div>


        {isGenerate === true ? (
          <SweetAlert
            warning
            showCancel
            cancelBtnText={t('Cancel')}
            confirmBtnText={t("Yes")}
            confirmBtnBsStyle="warning"
            title={t('Are you sure?')}
            onConfirm={(e) => {
              generateSummary()
              setIsGenerate(false)
            }}

            onCancel={(e) => {
              setIsGenerate(false)
            }}
            focusCancelBtn
          >
            {t('Do you want to summarise this file with AI')}?
          </SweetAlert>
        ) : ([]
        )}
        {pdfModal === true ?
          <PdfModal
            pdfModal={pdfModal}
            setPdfModal={setPdfModal}
            pageNumber={pageNumber}
            pdfUrl={'https://dutraeducation.codiflysoftware.com/api/pdfbase64?url=' + searchPdf}
          />
          : null}
      </div>
    </>
  );

}
export default Summary;