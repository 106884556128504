import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import config from "../../config/config";
import axios from "axios";
import { Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import Table from 'react-bootstrap/Table'
import { getCustomerList, displayError, displaySuccess, getAssignUserList } from "../../Utils/utils";
import { useSelector, useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';
import LoadingSpinner from "../../components/LoadingSpinner";
import { Translate, I18n } from "react-redux-i18n";
import { useTranslation } from "react-i18next";

const AssignModal = (props) => {
  const { t } = useTranslation();
  const [pageCount, setPageCount] = useState(1);
  const [current_page, setCurrentPage] = useState(1);
  const [is_loading, setIsloading] = useState(false);

  const handleClose = () => {
    props.setAssignListShow(false)
    props.getProcess();
  };
  const [customerList, setCustomerList] = useState([]);
  const userInfo = useSelector((state) => state.userInfo.value)

  const getUserList = async () => {
    setIsloading(true)
    var cust_list_var = await getAssignUserList(props.process_id, current_page);
    setCustomerList(cust_list_var.data)
    setPageCount(cust_list_var.last_page)
    setIsloading(false)
  }
  useEffect(() => {
    getUserList()
  }, [])

  const assignUser = async (id) => {
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/process/assign-user/' + props.processSlug, {
      // process_id: props.process_id,
      // custome_id: id,
      user_id: id,
      language: localStorage.getItem('langauge_selected'),

    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })

      .then(async (response) => {
        displaySuccess(t('User has been assigned to the process'));
        var cust_list_var = await getAssignUserList(props.process_id, current_page);
        setCustomerList(cust_list_var.data)
        setPageCount(cust_list_var.last_page)

        // props.setAssignListShow(false)
        // props.toggleLoader(false);
        // setTimeout(() => window.location.href = "/reset-password", 3000);
      })
      .catch((error) => {
        displayError(error, t);
        // props.toggleLoader(false);
      });
  }

  const handlePageClick = async (e) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected + 1;
    var cust_list_var = await getAssignUserList(props.process_id, selectedPage);
    setCustomerList(cust_list_var.data)
    setPageCount(cust_list_var.last_page)

  };
  return (
    <>
      <LoadingSpinner is_loading={is_loading} />
      <Modal show={props.assignListshow} onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered >
        <Modal.Header >
          <Modal.Title>{t('Assign To Process')}</Modal.Title>
          <div className="close" onClick={handleClose} closeButton>

            <i class="far fa-times-circle"></i>
          </div>
        </Modal.Header>
        <Modal.Body >
          <div className="card-body">
            <div className="table-responsive">
              <Table striped borderless >
                <Tbody>
                  {customerList.map((list) => (
                    <Tr >
                      {list.user_customer !== null ?
                        <Td>{list?.user_customer.name}</Td>
                        : <Td>N/A</Td>}
                      {list.user_customer !== null ?
                        <Td>{list?.user_customer.email}</Td>
                        : <Td>N/A</Td>}
                      {/* {props.process_id === list.process_user.process_id ?
                        props.userId === list.process_user.userId ?
                          <Td><button className=" btn btn-light" style={{ background: "#47ac47", color: "#000" }} onClick={() => assignUser(list.customer_id)}>
                            Assigned</button></Td>
                          :
                          <Td><button className=" btn btn-light" style={{ background: "#8FCFFF", color: "#000" }} onClick={() => assignUser(list.customer_id)}>
                            Assign</button></Td>
                        : <Td><button className=" btn btn-light" style={{ background: "#8FCFFF", color: "#000" }} onClick={() => assignUser(list.customer_id)}>
                          Assignnnnn</button></Td>} */}
                      {list.process_user !== null ?
                        // props.userId === list.process_user.userId ? 
                        <Td>
                          <span className="text-success font-weight-bold">{t('Assigned')}</span>
                        </Td>
                        :
                        <Td><button className=" btn btn-light" style={{ background: "#8FCFFF", color: "#000" }} onClick={() => assignUser(list.customer_id)}>
                          {t("Assign")}</button></Td>
                      }

                    </Tr>
                  ))}
                  {/* #47ac47 */}

                </Tbody>
              </Table>
            </div>
          </div>
        </Modal.Body>
        <div className="row">
          <div className="mx-auto d-block">
            <ReactPaginate
              previousLabel={t('prev')}
              nextLabel={t('next')}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={0}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AssignModal