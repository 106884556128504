import React, { useState } from 'react';
import { Link } from "react-router-dom";
import OtpInput from 'react-otp-input';
import config from '../config/config';
import axios from 'axios';
import { displayError, displaySuccess } from '../Utils/utils';
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import image from '../components/images/logo_trans.png';

const ResetPassword = () => {
  const [otp, setOtp] = useState('');
  const handleChange = (otp) => setOtp(otp);
  const [verify, setVerify] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const location = useLocation();

  const { t } = useTranslation();

  const otpSend = async () => {
    // props.toggleLoader(true);
    let path = config.siteUrl;
    await axios.post(path + '/verify-otp', {
      email: location?.state?.email,
      reset_token: otp
    }, {
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        displaySuccess(t('Otp verified'));
        setVerify(true)
      })
      .catch((error) => {
        displayError(error, t);
      });
  };
  const ResetPass = async () => {
    let path = config.siteUrl;
    await axios.post(path + '/reset-password', {
      email: location?.state?.email,
      password: password,
      password_confirmation: confirmPassword
    }, {
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        displaySuccess(t('Password has been changed'));
        setTimeout(() => window.location.href = "/login", 3000);
      })
      .catch((error) => {
        displayError(error, t);
      });
  };
  return (
    <>
      <div class="form-body">
        <div class="website-logo">
          <a >
            <div class="logo">
              <img class="logo-size" src="images/logo-light.svg" alt="" />
            </div>
          </a>
        </div>
        <div class="row">
          <div class="img-holder">
            <div class="bg"></div>
            <div class="info-holder" style={{ top: '70%' }}>
              <img src={image} />
            </div>
          </div>
          <div class="form-holder">
            <div class="form-content">
              <div class="form-items">
                <h2>{t("Reset Password")}</h2>

                <div>
                  <label>{t("Email")}</label>
                  <input class="form-control" type="text" name="username" placeholder="Enter Email" required readOnly
                    value={location?.state?.email} />
                  {verify !== true ?
                    <>
                      <label>{t("Enter OTP")}</label>
                      <OtpInput
                        value={otp}
                        onChange={handleChange}
                        numInputs={4}
                        isInputNum={true}
                        shouldAutoFocus={true}
                        separator={
                          <span style={{ width: '8px' }}>
                          </span>}
                        inputStyle={{
                          width: "50px",
                        }}
                      />
                    </>
                    : <>
                      <label>{t("New Password")}</label>
                      <input class="form-control" type="password" name="username" placeholder="Enter new password" required
                        onChange={e => setPassword(e.target.value)} />

                      <label>{t("Confirm Password")}</label>
                      <input class="form-control" type="password" name="username" placeholder="Enter confirm password" required
                        onChange={e => setConfirmPassword(e.target.value)} />
                    </>}
                  {verify !== true ?
                    <button id="submit" type="submit" class="ibtn" onClick={otpSend}>{t("Verify OTP")}</button>
                    :
                    <button id="submit" type="submit" class="ibtn" onClick={ResetPass}>{t("Submit")}</button>
                  }
                </div>
                <Link to="/login">
                  <div class="other-links text-center">
                    <span>{t("Remember Password")}?</span><a class="text-warning"> {t("Login now")}</a>
                  </div>
                </Link>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword