import React from "react";
import img from '../images/lector_juris.png';
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Translate, I18n } from "react-redux-i18n";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <ul
        className="navbar-nav bg-gradient-light sidebar sidebar-dark accordion d-none d-sm-block"
        id="accordionSidebar"
      >
        {/* Sidebar - Brand */}
        <a
          className="sidebar-brand d-flex align-items-center justify-content-center"

        >
          <img src={img} style={{ width: '100%' }} />
        </a>
        {/* Divider */}
        <hr className="sidebar-divider my-0" />
        {/* Nav Item - Dashboard */}
        {localStorage.getItem('role_id') == 2 ? null :
          <li className={(window.location.pathname === '/dashboard') ? 'nav-item active' : 'nav-item'}>
            <NavLink className="nav-link" to={'/dashboard'}>
              {/* <i class="fas fa-home"></i> */}
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width={28} stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
              </svg>

              <span>{t('Home')}</span>
            </NavLink>
          </li>}
        {/* Nav Item - Pages Collapse Menu */}
        <li className={(window.location.pathname === '/process-list') || (window.location.pathname === '/file-list') || (window.location.pathname === '/process-dashboard') || (window.location.pathname === '/add-files') || (window.location.pathname === '/notes-list') || (window.location.pathname === '/video-reader') ? 'nav-item active' : 'nav-item'}>
          <NavLink className="nav-link" to={'/process-list'}>
            {/* <i className="fas fa-fw fa-table" /> */}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width={28} stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
            </svg>

            <span>{t('Process List')}</span>
          </NavLink>
          {/* <a
            className="nav-link collapsed"
            href="/process-list"
          // data-toggle="collapse"
          // data-target="#collapseTwo"
          // aria-expanded="true"
          // aria-controls="collapseTwo"
          >
            <i className="fas fa-fw fa-cog" />
          </a> */}
          {/* <div
            id="collapseTwo"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Custom Components:</h6>
              <a className="collapse-item" href="buttons.html">
                Buttons
              </a>
              <a className="collapse-item" href="cards.html">
                Cards
              </a>
            </div>
          </div> */}
        </li>
        {/* Nav Item - Utilities Collapse Menu */}
        {/* <li className={(window.location.pathname === '/customer-list') ? 'nav-item active' : 'nav-item'}>
          <NavLink className="nav-link" to={'/customer-list'}>
            <i class="fas fa-th-list"></i>
            <span>{('Customer List')}</span>
          </NavLink>
        </li> */}
        {localStorage.getItem('role_id') == 2 ? null :
          <li className={(window.location.pathname === '/customer-list') || (window.location.pathname === '/addnewcustomer') || (window.location.pathname.startsWith('/edit-user/')) ? 'nav-item active' : 'nav-item'}>
            <NavLink className="nav-link" to={'/customer-list'}>
              {/* <i class="fas fa-users-cog"></i> */}
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width={28} stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
              </svg>

              <span>{t('Customer Management')}</span>
            </NavLink>
          </li>}
        {localStorage.getItem('role_id') == 2 ? null :
          <li className={(window.location.pathname === '/user-list') ? 'nav-item active' : 'nav-item'}>
            <NavLink className="nav-link" to={'/user-list'}>
              {/* <i class="fas fa-users"></i> */}
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width={28} stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
              </svg>

              <span>{t('User Management')}</span>
            </NavLink>

          </li>}
        {localStorage.getItem('role_id') == 2 ? null :
          <li className={(window.location.pathname === '/transaction-list') ? 'nav-item active' : 'nav-item'}>
            <NavLink className="nav-link" to={'/transaction-list'}>
              {/* <i class="fas fa-money-check"></i> */}
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width={28} stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
              </svg>

              <span>{t('Transactions')}</span>
            </NavLink>

          </li>}
        {/* <li className={(window.location.pathname === '/notes-list') ? 'nav-item active' : 'nav-item'}>
          <NavLink className="nav-link" to={'/notes-list'}>
            <i class="fas fa-clipboard"></i>
            <span>{('Notes List')}</span>
          </NavLink>

        </li> */}
        {/* <li className={(window.location.pathname === '/file-list') ? 'nav-item active' : 'nav-item'}>
          <NavLink className="nav-link" to={'/file-list'}>
            <i class="fas fa-file-download"></i>
            <span>{('File List')}</span>
          </NavLink>

        </li> */}
        {/* <li className={(window.location.pathname === '/process-dashboard') ? 'nav-item active' : 'nav-item'}> */}
        {/* <a
            className="nav-link collapsed"
            href="/process-dashboard"
            data-toggle="collapse"
            data-target="#collapseUtilities"
            aria-expanded="true"
            aria-controls="collapseUtilities"
          >
            <i class="fas fa-fw fa-wrench" />
          </a> */}
        {/* <NavLink className="nav-link" to={'/process-dashboard'}>
            <i className="fas fa-fw fa-tachometer-alt" />
            <span>{('Process Monitor')}</span>
          </NavLink> */}
        {/* <div
            id="collapseUtilities"
            className="collapse"
            aria-labelledby="headingUtilities"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Custom Utilities:</h6>
              <a className="collapse-item" href="utilities-color.html">
                Colors
              </a>
              <a className="collapse-item" href="utilities-border.html">
                Borders
              </a>
              <a className="collapse-item" href="utilities-animation.html">
                Animations
              </a>
              <a className="collapse-item" href="utilities-other.html">
                Other
              </a>
            </div>
          </div> */}
        {/* </li> */}
        {/* Nav Item - Pages Collapse Menu */}
        {/* <li className="nav-item">
          <NavLink className="nav-link" to={'/process-dashboard'}>
            <i class="fas fa-file-invoice-dollar"></i>
            <span>{('Billing')}</span>
          </NavLink> */}

        {/* <div
            id="collapsePages"
            className="collapse"
            aria-labelledby="headingPages"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Login Screens:</h6>
              <a className="collapse-item" href="login.html">
                Login
              </a>
              <a className="collapse-item" href="register.html">
                Register
              </a>
              <a className="collapse-item" href="forgot-password.html">
                Forgot Password
              </a>
              <div className="collapse-divider" />
              <h6 className="collapse-header">Other Pages:</h6>
              <a className="collapse-item" href="404.html">
                404 Page
              </a>
              <a className="collapse-item" href="blank.html">
                Blank Page
              </a>
            </div>
          </div> */}
        {/* </li> */}
        {/* Nav Item - Charts */}
        {/* <li className={(window.location.pathname === '/video-reader') ? 'nav-item active' : 'nav-item'}>
          <NavLink className="nav-link" to={'/video-reader'}>
            <i class="fas fa-file-video"></i>
            <span>{('File/Video ')}</span>
          </NavLink>
        </li> */}
        <li className={(window.location.pathname === '/settings') || (window.location.pathname === '/setting-otp') ? 'nav-item active' : 'nav-item'}>
          <NavLink className="nav-link" to={'/settings'}>
            {/* <i class="fas fa-cog"></i> */}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width={28} stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>

            <span>{t('Settings')}</span>
          </NavLink>
        </li>
        <li className={(window.location.pathname === '/changepassword') ? 'nav-item active' : 'nav-item'}>
          <NavLink className="nav-link" to={'/changepassword'}>
            {/* <i class="fas fa-key"></i> */}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width={28} stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
            </svg>

            <span>{t('Change Password')}</span>
          </NavLink>
        </li>
        {/* <li className={(window.location.pathname === '/addnewuser') ? 'nav-item active' : 'nav-item'}>
          <NavLink className="nav-link" to={'/addnewuser'}>
            <i class="fas fa-user-plus"></i>
            <span>{('New User ')}</span>
          </NavLink>
        </li> */}
        {/* Nav Item - Tables */}
        {/* <li className="nav-item">
          <a className="nav-link" href="tables.html">
            <i className="fas fa-fw fa-table" />
          </a>
        </li> */}
        {/* Divider */}
        <hr className="sidebar-divider d-none d-md-block" />
        {/* Sidebar Toggler (Sidebar) */}
        <div className="text-center d-none d-md-inline">
          <button className="rounded-circle border-0" id="sidebarToggle" />
        </div>
      </ul>
    </>
  )
}
export default Sidebar;