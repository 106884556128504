import React, { useState, useEffect } from "react";
import "../ContactUs/ContactUs.css";
import TheamHeader from '../components/TheamHeader/TheamHeader';
import Footer from "../components/Footer/index.js";
import image1 from "../components/images/image11.png";
import { displayError, displaySuccess } from '../Utils/utils';
import config from '../config/config';
import axios from 'axios';
import { Translate, I18n } from "react-redux-i18n";
import { useTranslation } from "react-i18next";
import Header from "../components/Header/Header";

const ContactUs = () => {
	const { t } = useTranslation();

	const [userName, setUserName] = useState('');
	const [userEmail, setUserEmail] = useState('');
	const [userNote, setUserNote] = useState('');
	const [userSubject, setUserSubject] = useState('');
	const [isPricing, setIsPricing] = useState(true);
	const [reload, setReload] = useState(false)

	useEffect(() => {
		setReload(true)
		if (localStorage.getItem('token')) {
			setIsPricing(true)
		} else {
			setIsPricing(false)
		}
	}, [])

	const oncontactus = async () => {
		let path = config.siteUrl;
		await axios.post(path + '/contact-us', {
			name: userName,
			email: userEmail,
			subject: userSubject,
			message: userNote,
			language: localStorage.getItem('langauge_selected')
		}, {
			headers: { 'Content-Type': 'application/json' },
		})
			.then((response) => {
				setTimeout(() => {
					window.location.reload()
				}, 3000);
				displaySuccess(t('Contact has been Submited'));
			})
			.catch((error) => {
				displayError(error, t);
			});

	};
	useEffect(() => {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	}, [])
	return (
		<>
			{reload &&
				<Header purchase={true} isPricing={isPricing} />}
			<div className="ContactUs">
				<div className="containerA col-xxl-8 px-4 py-5">
					<div className="row flex-lg-row-reverse align-items-center g-5">
						<div className="col-10 col-sm-8 col-lg-6">
							<img src={image1} className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" loading="lazy" width={700} height={400} />
						</div>
						<div className="col-lg-6">
							<div className="column">
								<h1 className="display lh-1 mb-3" style={{ fontSize: '48px' }}>{t('Contact')} <span>{t('Us')}</span> </h1>
								{/* <p className="lead" style={{ fontSize: '18px' }}>{t('Practically every single website worth anything out there has a Contact Us page that it can turn to whenever issues about Us come up with users')}.</p> */}
							</div>
						</div>
					</div>
				</div>
				<div className="container mt-5">
					<div className="row align-items-center justify-content-between mb-4">
						<div className="col-md-6 ">
							<div className="leftside">
								<h1>{t('Feel Free to Get In Contact With Us')}</h1>
								<p></p>
								<div class="mt-md-5 mt-3">
									<div class="text-muted">{t('Available 24 Hours a Day')}
									</div>
									<h4 class="text-warning font-weight">(51) 3527-4145</h4>
								</div><div class="mt-md-4 mt-3">
									<div class="text-muted">{t('Send Email For Inquiry')}</div>
									<h4 class="text-warning font-weight">relacionamento@metododutra.com.br</h4>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="rightside">
								<div className="border" >
									<div>{t('Full Name')}</div>
									<input className="form-control mt-2 mb-2" type="text" placeholder={t('Please enter your name')}
										onChange={e => setUserName(e.target.value)}
									/>
									<div>{t('Email')}</div>
									<input className="form-control mt-2 mb-2" type="email" placeholder={t('Please enter your email')}
										onChange={e => setUserEmail(e.target.value)}
									/>
									<div>{t('Subject')}</div>
									<input className="form-control mt-2 mb-2" type="subject" placeholder={t('Please enter your Subject')}
										onChange={e => setUserSubject(e.target.value)}
									/>
									<div>{t('Message')}</div>
									<textarea className="form-control mt-2 mb-2" as="textarea" placeholder={t('Please enter your note')} rows={4} onChange={e => setUserNote(e.target.value)} />
									<button className="pushbtn"
										onClick={oncontactus}
									>{t('Submit')}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div >
			<Footer />
		</>
	);
}

export default ContactUs;

