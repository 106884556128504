import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Topbar from "../components/Topbar/Topbar";
import { Link, useLocation, useParams } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import { displayError, displaySuccess, getCustomerList, getPaymentList, getPaymentStatus } from "../Utils/utils";
import './style.css'
import Table from 'react-bootstrap/Table';
import Navs from "../components/TopNav/TopNav";
import Alert from 'react-bootstrap/Alert'
import { Translate, I18n } from "react-redux-i18n";
import { useTranslation } from "react-i18next";
import Moment from 'react-moment';
import PurchaseModal from "../components/Modal/PurchaseModal";
import imgnew from '../components/images/data-not-found-2.svg'
import imgnew2 from '../components/images/data-not-found.svg'

const Transaction = () => {
  const { t, i18n } = useTranslation();
  const [transactionList, setTransactionList] = useState([]);
  const [pageCount, setPageCount] = useState(1)
  const [current_page, setCurrentPage] = useState(1);
  const [isPurchaseModal, setIsPurchaseModal] = useState(false)
  const [transId, setTransId] = useState('');
  const [type, setType] = useState('');
  const [status, setStatus] = useState('');
  const [statuses, setStatuses] = useState([]);


  const getTransList = async (type, status) => {
    var res = await getPaymentList(current_page, type, status)
    setTransactionList(res.data)
    setPageCount(res.last_page)
  }


  const location = useLocation();
  useEffect(() => {
    getTransList(type, status);
  }, [type, status])

  const handlePageClick = async (e) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected + 1;
    var res = await getPaymentList(selectedPage)
    setTransactionList(res.data)
    setPageCount(res.last_page)
  };
  const copyToClipboard = (content) => {
    const text = window.location.origin + "/payment/complete/" + content
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    displaySuccess(t("Copied"))
    document.execCommand('copy');
    document.body.removeChild(el);
  };
  const copyUrl = (content) => {
    const text = content
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    displaySuccess(t("Boleto Url Copied"))
    document.execCommand('copy');
    document.body.removeChild(el);
  }
  useEffect(() => {
    getStatus()
  }, [])
  const getStatus = async () => {
    var res = await getPaymentStatus()
    setStatuses(res)
  }
  return (
    <>
      <div id="wrapper">
        {/* <LoadingSpinner is_loading={is_loading} /> */}
        <Navs title={t('Transaction List')} />
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title={t('Transaction List')} />
            {/* <div className="container-fluid">
            </div> */}
            {/* <Card /> */}
            <div className="container-fluid full-width">
              <div className="row my-4">
                <div className="col-md-12">

                  <div className="row ml-2 mb-3 trans-drop">
                    <div className="col-md-2 col-6">
                      <select className="form-control" as="select" type="value"
                        onChange={e => setType(e.target.value)}
                      >
                        <option value="">{t('Payment Type')}</option>
                        <option value="card">{t('Card')}</option>
                        <option value="boleto">{t('Boleto')}</option>
                      </select>
                      {/* <label className="form-check-label" for="radio1">{t('Existing Customer')}</label> */}
                    </div>
                    <div className="col-md-2 col-6">
                      <select className="form-control" as="select" type="value"
                        onChange={e => setStatus(e.target.value)}
                      >
                        <option value="">{t('Status')}</option>
                        {statuses.map((e) => (

                          <option value={e.name}>{t(e.name)}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="card m-overflow-h">
                    <div className="card-body m-p-0 p-0">
                      <div className="table-responsive">
                        <Table className="table table-striped align-items-center">
                          <thead>
                            <tr>
                              <th>{t('Payment Id')}</th>
                              <th>{t('Transaction Number')}</th>
                              <th>{t('Payment Type')}</th>
                              <th>{t('Paid Amount')}</th>
                              <th>{t('Purchase Type')}</th>
                              <th>{t('Purchase Date')}</th>
                              <th>{t('Status')}</th>
                              <th>{t('View Details')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(transactionList.length === 0) ? <tr>
                              <td colSpan="8">
                                {localStorage.getItem('langauge_selected') === "pt" ? <img src={imgnew2} className="no-data-img-2" /> :
                                  <img src={imgnew} className="no-data-img-2" />}
                                {/* <img src={imgnew} className="no-data-img-2" /> */}
                              </td>
                            </tr> :
                              transactionList.map((list) =>
                                <tr>
                                  <td>
                                    {list.id}
                                  </td>
                                  <td>
                                    {list.txn_no}
                                  </td>
                                  <td>
                                    {t(list.payment_type)}
                                  </td>
                                  <td>
                                    {list.paid_amount !== null ? list.paid_amount : ''}
                                  </td>
                                  <td>
                                    {list.purchase_type == '2' ? t("Link Acquire") : t('Self Purchase')}
                                  </td>
                                  <td>
                                    <Moment format="DD/MM/YYYY">
                                      {list.created_at}
                                    </Moment>
                                  </td>
                                  <td>
                                    <div className="status-trans">
                                      {list.status !== null ? t(list.status) : ''}
                                    </div>
                                  </td>

                                  <td>
                                    <div className="items-center mr-1 btn-outline-success btn btn-sm" title={t("View Detail")}
                                      onClick={() => {
                                        setTransId(list.id)
                                        setIsPurchaseModal(true)
                                      }}
                                    >
                                      <i class="fas fa-eye"></i>
                                    </div>
                                    {list.purchase_type == '2' && list.status !== "paid" ?
                                      list.status !== "paid out" ?
                                        <div className="items-center mr-1 btn-outline-primary btn btn-sm" title={t("Purchase Link")}
                                          onClick={() => {
                                            copyToClipboard(list.slug)
                                          }}
                                        >
                                          <i class="fas fa-copy"></i>
                                        </div>
                                        : null : null}
                                    {list.payment_type === null || list.payment_type === "card" ? null :
                                      <div className="items-center mr-1 btn-outline-warning btn btn-sm" title={t("Copy Boleto Url")}
                                        onClick={() => {
                                          copyUrl(list.boleto_url)
                                        }}
                                      >
                                        <i class="fas fa-paste"></i>
                                      </div>
                                    }
                                    {list.payment_type === null || list.payment_type === "card" ? null :
                                      <a href={list.boleto_url} target="_blank">
                                        <div className="items-center mr-1 btn-outline-dark btn btn-sm" title={t("Boleto Url")}
                                        >
                                          <i class="fas fa-share"></i>
                                        </div>
                                      </a>
                                    }

                                  </td>

                                </tr>
                              )
                            }

                          </tbody>
                        </Table>

                      </div>
                    </div>
                    <div className="row">
                      <div className="mx-auto d-block my-3">
                        <ReactPaginate
                          previousLabel={t('prev')}
                          nextLabel={t('next')}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={pageCount}
                          marginPagesDisplayed={0}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                    {isPurchaseModal == true ?
                      <PurchaseModal isPurchaseModal={isPurchaseModal} setIsPurchaseModal={setIsPurchaseModal} transId={transId} />
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div >
    </>
  );
}
export default Transaction;
