import React from 'react';
import image1 from '../images/about-us.png';
import '../Legaltech/Legaltech.css';
import { Translate, I18n } from "react-redux-i18n";
import { useTranslation } from 'react-i18next';

const Legaltech = () => {
  const { t } = useTranslation()
  return (
    <div id="legaltech">
      <section className="service-2 section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-5">
              <img src={image1} className="img-fluid" />
            </div>
            <div className="col-md-6 col-lg-5 offset-lg-1 align-self-center">
              <div className="about_section">
                <h2 className="mb-3">{t('Legaltech')}</h2>
                <p>{t('Bringing technology to your business Analyze data in a collaborative way Search in text videos and work collaboratively sharing insights between your team and your client The technology in your power')}.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Legaltech