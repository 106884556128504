import React, { useState, useRef, useEffect, useCallback } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Topbar from "../components/Topbar/Topbar";
import Button from 'react-bootstrap/Button';
import config from "../config/config";
import axios from "axios";
import Form from 'react-bootstrap/Form';
import { displayError, displaySuccess, getRoles, } from "../Utils/utils";
import Navs from "../components/TopNav/TopNav";
import './style.css';
import { Link } from "react-router-dom";
import { Translate, I18n } from "react-redux-i18n";
import { useTranslation } from "react-i18next";
import ReactInputMask from 'react-input-mask';

const AddNewCustomer = () => {
  const { t } = useTranslation();
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userType, setType] = useState(1);
  const [phone_number, setPhoneNumber] = useState(0);
  const [desc, setDesc] = useState('');
  let [mask, setMask] = React.useState(null);
  const [mob2, setMob2] = useState('');
  const [roles, setRoles] = useState([]);

  const [file, setFile] = useState();

  const fileInput = useRef();
  const selectFile = () => {
    fileInput.current.click();
  }
  //for upload image 
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  // const onFormSubmit = async () => {
  //   // props.toggleLoader(true);
  //   var token = localStorage.getItem('token');
  //   let path = config.siteUrl;

  //   if (show) {
  //     var data1 = {
  //       user_name: userName,
  //       user_email: userEmail,
  //       type: userType,
  //       phone_number: val,
  //     };
  //   } else {
  //     var data1 = {
  //       process_name: processName,
  //       customer: customer,
  //       existing_user: 1,
  //       old_user_id: show,
  //       existing_user: 0,
  //       old_user_id: 1,
  //     };
  //   }
  //   await axios.post(path + 'user/add-new-user', data1, {
  //     headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  //   })
  //     .then((response) => {
  //       displaySuccess('New Customer has been Added!!');       
  //     })
  //     .catch((error) => {
  //       displayError(error);       
  //     });
  // };

  const onFormSubmit = async () => {
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/user/add-new-user', {
      user_name: userName,
      user_email: userEmail,
      type: userType,
      phone_number: phone_number,
      description: desc,
      language: localStorage.getItem('langauge_selected')

    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        displaySuccess(t('New Customer has been Added'));
        setTimeout(() => window.location.href = "/customer-list", 3000);
      })
      .catch((error) => {
        displayError(error, t);
      });

  };

  const getType = async () => {
    var roles_var = await getRoles();
    setRoles(roles_var)
  }
  useEffect(() => {
    // getCustomer()
    getType();
  }, [])
  const mobHandle = ev => {
    let { value } = ev.target;
    let nums = value.replace(/[^0-9]/g, "");
    setMask("+55 99 99999-9999");
    setPhoneNumber(ev.target.value);
    setMob2(nums);
    if (nums.length < 3 && mask != null) {
      setMask(null);
      ev.target.value = nums;
    }
  };
  return (
    <>
      <div id="wrapper">
        <Navs title={t('Add New Customer')} />
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">



            <Topbar title={t('Add New Customer')} />
            <div className="container-fluid full-width">
              <div className="row justify-content-end">
                <div className="col-auto">
                  <Link to="/user-list">
                    <div className="back-btn mt-2 ml-auto mb-3 btn btn-secondary" style={{ marginLeft: "auto" }}>
                      <div className="text-light back-txt" ><i class="fas fa-long-arrow-alt-left"></i> {t('Back')}</div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-8 col-md-8 col-xl-8">
                  <div className="row">
                    <div className="col-sm-6">
                      <label>{t('Full Name')} <span style={{ color: "red" }}>*</span></label>
                      <input className="form-control" type="Username" placeholder={t('Please enter your name')} onChange={e => setUserName(e.target.value)} />
                    </div>
                    <div className="col-sm-6">
                      <label>{t('Email Address')} <span style={{ color: "red" }}>*</span></label>
                      <input className="form-control" type="useremail" placeholder={t('Please enter your email')} onChange={e => setUserEmail(e.target.value)} />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-sm-6">
                      <label>{t('Mobile')} <span style={{ color: "red" }}>*</span></label>
                      <ReactInputMask
                        className="form-control mt-2 form-control-input"
                        mask={mask}
                        onChange={mobHandle}
                        maskChar="_"
                        value={phone_number}
                        placeholder={'mobile no'}
                      // {...props}
                      />
                      {/* <input className="form-control" type="text"
                        pattern="[0-9]*"
                        placeholder={t('Please enter your contact no')}
                        maxlength="10"
                        onChange={(e) => setPhoneNumber(e.target.value)} /> */}
                    </div>
                    <div className="col-sm-6">
                      <label value="">{t('Type')} <span style={{ color: "red" }}>*</span></label>
                      <select className="form-control" as="select" type="value" placeholder={t('Please select the type')} onChange={e => setType(e.target.value)} >
                        <option value={''}>{t('select Type')}</option>
                        {/* {roles.map((list) => ( */}
                        <option value="2">Customer</option>
                        {/* ))} */}
                      </select>
                    </div>
                  </div>
                  <div className="notes mt-3">
                    {/* <label>Notes</label>
                    <textarea placeholder="Please enter your note" rows={6} /> */}
                    <div>{t('Bio')}</div>
                    <textarea className="form-control mt-2 mb-2" as="textarea" placeholder={t('Please enter your Bio')} rows={5}
                      onChange={(e) => setDesc(e.target.value)} />
                  </div>
                  <br />
                  <Button variant="warning" className="button" type="submit"
                    onClick={onFormSubmit}
                  >
                    <span style={{ fontsize: '18px' }}>{t('Add Customer')}</span>
                  </Button>
                </div>
                {/* <div className="col-sm-4 col-md-4 col-xl-4 ">
                  <h6 style={{ color: "#444444" }}>{t('PROFILE PICTURE')}</h6>
                  <img className="border border-white" src={file} alt={""} width="200" height="255" text-align="left" style={{ display: 'block', background: "#D9D9D9", border: "none", borderRadius: "12px" }} />

                  <div className='mt-5' >
                    <input id="fileInput" name="file" type="file" style={{ "display": "none" }} ref={fileInput} onChange={handleChange} />
                    <Button className="border border-secondary" onClick={selectFile} style={{ fontSize: "12px", background: "none", color: "#6418C3", borderRadius: "6px" }} >
                      <i class="fas fa-camera"></i><span style={{ marginLeft: "6px" }}>{t('CHANGE PHOTOS')}</span>
                    </Button>
                  </div>

                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNewCustomer;