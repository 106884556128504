import React, { useEffect, useState } from "react";
import img1 from '../images/user.png';
import { useSelector, useDispatch } from 'react-redux'
// import { decrement, increment } from '../../features/counterSlice'
import { displaySuccess, getNotificationCount, userLoggedIn } from "../../Utils/utils";
import { update } from "../../features/userInfoSlice";
import { Link, useNavigate } from "react-router-dom";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { Initial } from 'react-initial'
const Topbar = (props) => {
  const { t } = useTranslation();

  const userInfo = useSelector((state) => state.userInfo.value)
  const dispatch = useDispatch()
  const [is_loading, setIsloading] = useState(false)
  const navigate = useNavigate()
  const [notCount, setNotCount] = useState(0);

  const userLoginFunction = async () => {
    var final_data = await userLoggedIn()
    if (final_data?.is_first_login === 0) {
      navigate(`/purchase`, {
        state: {
          isPricing: true
        }
      })
    }
    localStorage.setItem('role_id', final_data.role.role_id)
    dispatch(update(final_data))
  }
  useEffect(() => {
    userLoginFunction();
  }, [])
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role_id')
    displaySuccess(t('Logout Successfull'));
    setTimeout(() => window.location.href = "/login", 3000);
  }


  const [currentCount, setCount] = useState(1);

  useEffect(() => {
    if (currentCount <= 0) {
      return;
    }

    const id = setInterval(timer, 15000);
    return () => clearInterval(id);
  }, [currentCount]);

  const timer = async () => {
    setCount(currentCount + 1)
    var res = await getNotificationCount()
    if (res.success == true) {
      setNotCount(res.data)
    }
  };


  // setTimeout(() =>
  //   console.log("helloooo"), 5000)
  return (
    <>
      <nav className="navbar navbar-expand navbar-light topbar static-top " >
        <h2 className=" d-none d-sm-block mb-0">{props.title}</h2>
        <ul className="navbar-nav ml-auto">

          <li className="nav-item dropdown no-arrow d-none d-sm-block mt-3">
            <LanguageSwitcher />
          </li>
          <li className="nav-item dropdown no-arrow mx-1 d-none d-sm-block">
            <a
              className="nav-link dropdown-toggle"
              href="/notification"
              id="alertsDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width={28} stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
              </svg>
              {notCount === 0 ? null :
                <span className="badge badge-primary badge-counter">{notCount}</span>
              }
            </a>
            <div
              className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="alertsDropdown"
            >
              <h6 className="dropdown-header">Alerts Center</h6>
              <a className="dropdown-item d-flex align-items-center" href="#">
                <div className="mr-3">
                  <div className="icon-circle bg-primary">
                    <i className="fas fa-file-alt text-white" />
                  </div>
                </div>
                <div>
                  <div className="small text-gray-500">December 12, 2019</div>
                  <span className="font-weight-bold">
                    A new monthly report is ready to download!
                  </span>
                </div>
              </a>
              <a className="dropdown-item d-flex align-items-center" href="#">
                <div className="mr-3">
                  <div className="icon-circle bg-success">
                    <i className="fas fa-donate text-white" />
                  </div>
                </div>
                <div>
                  <div className="small text-gray-500">December 7, 2019</div>
                  $290.29 has been deposited into your account!
                </div>
              </a>
              <a className="dropdown-item d-flex align-items-center" href="#">
                <div className="mr-3">
                  <div className="icon-circle bg-warning">
                    <i className="fas fa-exclamation-triangle text-white" />
                  </div>
                </div>
                <div>
                  <div className="small text-gray-500">December 2, 2019</div>
                  Spending Alert: We've noticed unusually high spending for your
                  account.
                </div>
              </a>
              <a
                className="dropdown-item text-center small text-gray-500"
                href="#"
              >
                Show All Alerts
              </a>
            </div>
          </li>
          <li className="nav-item dropdown no-arrow mx-1 d-none d-sm-block">
            <div
              className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="messagesDropdown"
            >
              <h6 className="dropdown-header">Message Center</h6>
              <a className="dropdown-item d-flex align-items-center" href="#">
                <div className="dropdown-list-image mr-3">
                  <img
                    className="rounded-circle"
                    src={img1}
                    alt="..."
                  />
                  <div className="status-indicator bg-success" />
                </div>
                <div className="font-weight-bold">
                  <div className="text-truncate">
                    Hi there! I am wondering if you can help me with a problem
                    I've been having.
                  </div>
                  <div className="small text-gray-500">Emily Fowler · 58m</div>
                </div>
              </a>
              <a className="dropdown-item d-flex align-items-center" href="#">
                <div className="dropdown-list-image mr-3">
                  <img
                    className="rounded-circle"
                    src="img/undraw_profile_2.svg"
                    alt="..."
                  />
                  <div className="status-indicator" />
                </div>
                <div>
                  <div className="text-truncate">
                    I have the photos that you ordered last month, how would you
                    like them sent to you?
                  </div>
                  <div className="small text-gray-500">Jae Chun · 1d</div>
                </div>
              </a>
              <a className="dropdown-item d-flex align-items-center" href="#">
                <div className="dropdown-list-image mr-3">
                  <img
                    className="rounded-circle"
                    src="img/undraw_profile_3.svg"
                    alt="..."
                  />
                  <div className="status-indicator bg-warning" />
                </div>
                <div>
                  <div className="text-truncate">
                    Last month's report looks great, I am very happy with the
                    progress so far, keep up the good work!
                  </div>
                  <div className="small text-gray-500">Morgan Alvarez · 2d</div>
                </div>
              </a>
              <a className="dropdown-item d-flex align-items-center" href="#">
                <div className="dropdown-list-image mr-3">
                  <img
                    className="rounded-circle"
                    src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                    alt="..."
                  />
                  <div className="status-indicator bg-success" />
                </div>
                <div>
                  <div className="text-truncate">
                    Am I a good boy? The reason I ask is because someone told me
                    that people say this to all dogs, even if they aren't
                    good...
                  </div>
                  <div className="small text-gray-500">
                    Chicken the Dog · 2w
                  </div>
                </div>
              </a>
              <a
                className="dropdown-item text-center small text-gray-500"
                href="#"
              >
                Read More Messages
              </a>
            </div>
          </li>
          <li className=" d-none d-sm-block">
            <button
              className="dropdown-item mt-3 " onClick={logout}>
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
              {t('Logout')}
            </button>

          </li>

          <div className="topbar-divider d-none d-sm-block" />
          <li className="nav-item dropdown no-arrow">
            <a
              className="nav-link dropdown-toggle"
              href="/profile"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                {userInfo.name}
              </span>
              {userInfo?.media !== null ?
                <img
                  className="img-profile rounded-circle  d-none d-sm-block"
                  src={userInfo?.media?.url}
                />
                :
                <Initial
                  name={userInfo.name}
                  color="#0D5064"
                  seed={5}
                  fontSize={25}
                  height={40}
                  width={40}
                  style={{ borderRadius: '20px' }}
                />
              }
            </a>
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              <a className="dropdown-item" href="#">
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                Profile
              </a>
              <a className="dropdown-item" href="#">
                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />
                Settings
              </a>
              <a className="dropdown-item" href="#">
                <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400" />
                Activity Log
              </a>
              <div className="dropdown-divider" />
              <a
                className="dropdown-item"
                href="#"
                data-toggle="modal"
                data-target="#logoutModal"
              >
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
    </>
  )
}
export default Topbar