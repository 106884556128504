import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Topbar from "../components/Topbar/Topbar";
import ProgressBar from 'react-bootstrap/ProgressBar';
import './style.css';
import CustomerModal from "../components/Modal/CustomerModal";
import DocumentList from "../components/DocumentList/DocumentList";
import CustomerList from "../components/CustomerList/CustomerList";
import UserList from "../components/UserList/UserList";
import Notes from "../components/Notes/Notes";
import Navs from "../components/TopNav/TopNav";
import { Link, useParams } from "react-router-dom";
import { getProcessDetail, getFileListDetail, latestNotesProcess } from "../Utils/utils";
import LoadingSpinner from "../components/LoadingSpinner";
import { useTranslation } from "react-i18next";
import NewUser from "../components/Modal/NewUser";
const ProcessDashboard = () => {
  const { t } = useTranslation();
  const [customerModalShow, setCustomerModalShow] = useState(false);
  const [is_loading, setIsloading] = useState(false);
  const { id } = useParams();
  const [processName, setProcessName] = useState('');
  const [processUser, setProcessUser] = useState([]);
  const [fileDetail, setFileDetail] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [processId, setProcessId] = useState('');
  const [current_page, setCurrentPage] = useState(1);
  const [fileSize, setFileSize] = useState('');
  const [fileCount, setFileCount] = useState('');
  const [pdfCount, setPdfCount] = useState('');
  const [pdfSize, setPdfSize] = useState('');
  const [videoCount, setVideoCount] = useState('');
  const [videoSize, setVideoSize] = useState('');
  const [processRole, setProcessRole] = useState('');
  const [processCustomer, setProcessCustomer] = useState([]);
  const [notes, setNotes] = useState([]);

  const getDetail = async () => {
    setIsloading(true)
    var var_process = await getProcessDetail(id);
    setProcessName(var_process.name)
    setProcessUser(var_process.process_users)
    setProcessCustomer(var_process.process_customers)
    setProcessId(var_process.id)
    setFileCount(var_process.process_files_data.total_files_count)
    setFileSize(var_process.process_files_data.total_files_size)
    setPdfCount(var_process.process_files_data.total_pdf_count)
    setPdfSize(var_process.process_files_data.total_pdf_size)
    setVideoCount(var_process.process_files_data.total_video_count)
    setVideoSize(var_process.process_files_data.total_video_size)
    setProcessRole(var_process.process_role)
    localStorage.setItem('process_slug', id)

    // getFilesList(var_process.id);

    setIsloading(false)
  }

  const getFilesList = async (id) => {
    setIsloading(true)
    var var_file = await getFileListDetail(id, current_page);
    setFileDetail(var_file.data)
    setPageCount(var_file.last_page)
    setIsloading(false)
  }
  useEffect(() => {
    // alert("hi")
    if (processId) {
      getNotes(processId)
    }

  }, [processId])

  useEffect(() => {
    getDetail()

  }, [])
  const getNotes = async (id) => {
    var res = await latestNotesProcess(id);
    setNotes(res)
  }
  return (
    <>
      <div id="wrapper">
        <LoadingSpinner is_loading={is_loading} />
        <Navs title={'Process :  ' + processName} />
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title={t('Process : ') + processName} />

            <div className="container-fluid full-width">
              <div className="row justify-content-between my-3">
                <div className="col-auto">
                  {/* <h3>Process Detail</h3> */}
                </div>
                <div className="col-md-8">
                  <div className="d-flex justify-content-end">
                    <Link to="/process-list">
                      <div className="back-btn mb-3" style={{ marginLeft: "auto" }}>
                        <div className="text-light back-txt" >
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width={20} stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                          </svg>
                          {t('Back')}</div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-7 col-md-6 mb-4">
                  <div className="row">
                    <div className="col-xl-4 col-md-6 mb-4">
                      {/* number file card start */}
                      <div className="card h-100 shadow py-2" style={{ borderRadius: '15px', height: 'auto' }}>
                        <div className="card-body">
                          <div className="row ">
                            <i class="far fa-file file-icon"></i>
                          </div>
                          <div className="font-weight-bold mt-3" >{t('Number of Files')}</div>
                          {/* <ProgressBar variant="warning" now={fileCount} className="progressbar" /> */}
                          <div className="row mt-4">
                            <div className="col">
                              <span className=" fileNum">{fileCount} {t('Files')}</span>
                            </div>
                            <div className="col">
                              <span className="fileSize text-align-right">{fileSize}MB</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* number file card end */}
                    </div>
                    <div className="col-xl-4 col-md-6 mb-4">
                      <div className="card  shadow py-2" style={{ borderRadius: '15px', height: 'auto' }}>
                        <div className="card-body">
                          <div className="row ">
                            <i class="far fa-file-pdf pdf-icon "></i>
                          </div>
                          <div className="font-weight-bold mt-3" >{t('Number of PDF')}</div>
                          <ProgressBar variant="info" now={pdfCount} className="progressbar" max={fileCount} />
                          <div className="row mt-2">
                            <div className="col">
                              <span className=" fileNum">{pdfCount} {t('Files')}</span>
                            </div>
                            <div className="col">
                              <span className="fileSize text-align-right">{pdfSize}MB</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-md-6 mb-4">
                      <div className="card  shadow py-2" style={{ borderRadius: '15px', height: 'auto' }}>
                        <div className="card-body">
                          <div className="row ">
                            <i class="fas fa-video video-icon "></i>
                          </div>
                          <div className="font-weight-bold mt-3" >{t('Number of Videos')}</div>
                          <ProgressBar variant="primary" now={videoCount} className="progressbar" max={fileCount} />
                          <div className="row mt-2">
                            <div className="col">
                              <span className=" fileNum">{videoCount} {t('Files')}</span>
                            </div>
                            <div className="col">
                              <span className="fileSize text-align-right"> {videoSize}MB</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <DocumentList processId={processId} processRole={processRole} />
                </div>
                <div className="col-xl-5 col-md-6 mb-4">
                  <CustomerList processCustomer={processCustomer} processRole={processRole} getDetail={() => getDetail()} />
                  <UserList processUser={processUser} processRole={processRole} processId={processId} getDetail={() => getDetail()} />
                  <Notes notes={notes} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {customerModalShow === true ?
          <CustomerModal customerModalShow={customerModalShow} setCustomerModalShow={setCustomerModalShow} />
          : null
        }
      </div>
    </>
  );
}
export default ProcessDashboard;