import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import config from "../../config/config";
import axios from "axios";
import { displayError, displaySuccess, getCustomerList, getEditNotes, } from "../../Utils/utils";
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next";

const EditNotesModal = (props) => {
  // const userInfo = useSelector((state) => state.userInfo.value)
  const [noteName, setNoteName] = useState('');
  const [noteDesc, setNoteDesc] = useState('');
  const { t } = useTranslation();

  const handleClose = () => {
    props.setEditModal(false)
  }
  const onFormSubmit = async () => {
    // props.toggleLoader(true);
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/notes/edit-process-note/' + props.notesId, {
      // user_id: userInfo.id,
      title: noteName,
      description: noteDesc,
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        displaySuccess(t('Note has been added'));
        props.setEditModal(false)
        props.noteListing(props.notesId)
        // props.toggleLoader(false);
        // setTimeout(() => window.location.href = "/reset-password", 3000);
      })
      .catch((error) => {
        displayError(error, t);
        // props.toggleLoader(false);
      });
  };
  const getDetail = async () => {
    var edit_var = await getEditNotes(props.notesId);
    // setId(edit_var.id);
    setNoteDesc(edit_var.description);
    setNoteName(edit_var.title)
  }
  useEffect(() => {
    getDetail();
  }, [])
  return (
    <>
      <Modal show={props.editModal}>
        <Modal.Header >
          <Modal.Title >
            <h4 className="font-weight-bold">Edit Notes</h4>
          </Modal.Title>
          <div className="button accept" onClick={() => handleClose()}>
            <i class="far fa-times-circle"></i>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>Name</div>
            <input className="form-control mt-2 mb-2" type="text" placeholder="Johan Sinha" variant="dark" onChange={(e) => setNoteName(e.target.value)} value={noteName} />
            <div>Note</div>
            <textarea className="form-control mt-2 mb-2" as="textarea" placeholder="Please enter your note" rows={8} onChange={(e) => setNoteDesc(e.target.value)} value={noteDesc} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="click">
            <Button className="button" variant="warning" style={{ color: "#ffff", alignItems: "center" }} onClick={onFormSubmit} >
              Submit
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default EditNotesModal;
