import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Topbar from "../components/Topbar/Topbar";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import Modal from 'react-bootstrap/Modal';
import { Button, Col, Row, Table } from 'react-bootstrap';
import CustomerModal from "../components/Modal/CustomerModal";
import Navs from "../components/TopNav/TopNav";
import NewProcessModal from "../components/Modal/NewProcessModal";
import { displaySuccess, getDashboardDetail, getProcessList, processAllFiles, ProcessStatus } from "../Utils/utils";
import Moment from 'react-moment';
import EditProcessModal from "../components/Modal/EditProcessModal";
import AssignModal from "../components/Modal/AssignModal";
import AddNotesModal from "../components/Modal/AddNotesModal";
import LoadingSpinner from "../components/LoadingSpinner";
import Switch from "react-switch";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from "react-i18next";
import imgnew from '../components/images/data-not-found-2.svg'
import imgnew2 from '../components/images/data-not-found.svg'
import './style.css'
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

const ProcessList = (props) => {
  const { t, i18n } = useTranslation();

  const [processModalshow, setProcessModalShow] = useState(false);
  const [customerModalShow, setCustomerModalShow] = useState(false);
  const [processDetailModalShow, setProcessDetailModalShow] = useState(false);
  const [notesModalShow, setNotesModalShow] = useState(false);
  const [processList, setProcessList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [current_page, setCurrentPage] = useState(1);
  const [customerList, setCustomerList] = useState([]);
  const [editProcessModal, setEditProcessModal] = useState(false);
  const [processSlug, setProcessSlug] = useState('');
  const [assignListshow, setAssignListShow] = useState(false);
  const [is_active, setIsActive] = useState(false);
  const [userId, setUserId] = useState('');
  const [process_id, setProcessId] = useState('');
  const [is_loading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [thisId, setThisId] = useState(0);
  const [thisStatus, setThisStatus] = useState(0);
  const [alertactive, setAlertActive] = useState(false);
  const [alertdeactive, setAlertDeActive] = useState(false);
  const [process_name, setProcessName] = useState('');
  const [searchModalshow, setSearchModalShow] = useState(false);
  const [fileSearch, setFileSearch] = useState('');

  const [videoSize, setVideoSize] = useState('');
  const [pdfSize, setPdfSize] = useState('');
  const [totalSize, setTotalSize] = useState('');
  const [totalFiles, setTotalFiles] = useState('');
  const [videoCount, setVideoCount] = useState('');
  const [pdfCount, setPdfCount] = useState('');
  const [chartData, setChartData] = useState([30, 30, 60]); // for donutcolorvalue

  const handleProcessDetailClose = () => {
    setProcessDetailModalShow(false)
  }
  const handleNotesClose = () => {
    setNotesModalShow(false)
  }

  const searchhandle = async (e) => {
    setIsloading(true)
    var var_process = await getProcessList(current_page, e);
    setProcessList(var_process.data)
    setPageCount(var_process.last_page)
    setIsloading(false)
  }


  useEffect(() => {
    getProcess();
  }, [pageCount])

  const goToAddFile = (id) => {
    navigate(`/add-files/${id}`, {
      state: {
        process_id: id
      }
    })
  }
  const getProcess = async () => {
    setIsloading(true)
    var var_process = await getProcessList(current_page);
    setProcessList(var_process.data)
    setPageCount(var_process.last_page)
    setIsloading(false)
  }
  const handlePageClick = async (e) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected + 1;
    var var_process = await getProcessList(selectedPage);
    setProcessList(var_process.data)
    setPageCount(var_process.last_page)

  };
  const handleChange = async (id, status) => {
    if (status !== 1) {
      setAlertActive(true);
      setThisId(id);
      setThisStatus(status);
    } else {
      // setAlertActive(false);
      setAlertDeActive(true);
      setThisId(id);
      setThisStatus(status);
    }
  }

  const handleConfirm = async (id, status) => {
    setIsloading(true)
    if (thisStatus === 1) {
      var status_var = await ProcessStatus(thisId, 0)
    } else {
      var status_var = await ProcessStatus(thisId, 1)
    }
    setAlertActive(false);
    var var_process = await getProcessList(current_page);
    setProcessList(var_process.data)
    setPageCount(var_process.last_page)
    setIsloading(false)
  }

  const handleCancel = async (id, status) => {
    setIsloading(true)
    if (thisStatus === 0) {
      var status_var = await ProcessStatus(thisId, 1)
    } else {
      var status_var = await ProcessStatus(thisId, 0)
    }
    setAlertDeActive(false)
    var var_process = await getProcessList(current_page);
    setProcessList(var_process.data)
    setPageCount(var_process.last_page)
    setIsloading(false)
  }

  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const handleClose = () => {
    setSearchModalShow(false)
  }
  const searchApi = () => {
    navigate(`/video-reader/${process_id}`, {
      state: {
        search: fileSearch
      }
    })
  }
  const onKeyDown = (event) => {
    if (event.keyCode == 13 && !event.shiftKey) {
      searchApi()
    }
  }
  const processFiles = async (id) => {
    setIsloading(true)
    var var_process = await processAllFiles(id);
    displaySuccess(t("Files Processed Successfully"))
    setIsloading(false)
  }

  useEffect(() => {
    getDashboard()
  }, [])
  const data = {
    // labels: ['Red', 'Blue'],
    datasets: [
      {
        label: '# of Votes',
        cutout: '80%',
        data: chartData,
        backgroundColor: [
          // '#FF606B',
          // '#F1F6FA',
          '#f1f6fa',
          '#3CC2E0',
          '#7D6AEB'
        ],
      },
    ],
  };
  const options1 = {
    responsive: true,
    legend: {
      display: false,
      position: "bottom",
      labels: {
        fontSize: 18,
        fontColor: "#6D7278",
        fontFamily: "kanit light"
      }
    }
  };
  const getDashboard = async () => {
    var var_dash = await getDashboardDetail();
    if (var_dash !== null) {


      setChartData([var_dash.storage.remaining_percent, var_dash.storage.total_video_percent, var_dash.storage.total_pdf_percent])
      setTotalFiles(var_dash.files.total_files)
      setPdfCount(var_dash.storage.pdf_count)
      setVideoCount(var_dash.storage.video_count)
      setVideoSize(var_dash.storage.video_size_mb)
      setPdfSize(var_dash.storage.pdf_size_mb)
      setTotalSize(var_dash.storage.total_storage)
    }
  }
  const gotoPurchase = () => {
    navigate(`/purchase`, {
      state: {
        isPricing: true
      }
    })
  }
  return (
    <>
      <div id="wrapper">
        <LoadingSpinner is_loading={is_loading} />
        <Navs title={t('Process List')} />
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title={t('Process List')} />
            <Row className="w-100">
              <Col md={12} xs={12}>


                <div className="container-fluid full-width">
                  <div className="row justify-content-between my-3">
                    <div className="col-auto">
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex justify-content-end">
                        <div className="infobutton m-1" style={{ width: '60%' }}>
                          <input className="form-control" id="validationCustom01" type="text" placeholder={t("search")} required="" data-bs-original-title="" title=""
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value)
                              searchhandle(e.target.value)
                            }} />
                        </div>
                        {localStorage.getItem('role_id') == 2 ? null :
                          <div className="infobutton m-1">
                            <button className=" btn btn-outline-dark" style={{ borderRadius: "10px" }}
                              onClick={() => setProcessModalShow(true)}>
                              {t('+ Add New')}</button>
                          </div>
                        }
                      </div>
                    </div>

                  </div>
                  <div className="row mb-4">
                    <div className="col-md-12">
                      <div className="card m-overflow-h">
                        <div className="card-body m-p-0 p-0">
                          <div className="table">
                            <Table responsive striped>
                              <thead>
                                <tr>
                                  <th>{t('Process Name')}</th>
                                  <th>{t('Customer')}</th>
                                  <th></th>
                                  <th>{t('Last Updated')}</th>
                                  <th>{t('Status')}</th>
                                  <th>{t('Action')}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(processList.length === 0) ?
                                  <tr>
                                    <td colSpan="6">
                                      {localStorage.getItem('langauge_selected') === "pt" ? <img src={imgnew2} className="no-data-img-2" /> :
                                        <img src={imgnew} className="no-data-img-2" />}
                                      {/* <img src={imgnew} className="no-data-img-2" /> */}
                                    </td>
                                  </tr>
                                  : ''}
                                {processList.map((list) => (
                                  <tr>
                                    <td>
                                      <div className="pro-name">
                                        {list.status == 0 ? list.name :
                                          <Link to={'/process-dashboard/' + list.slug}>
                                            {list.name}
                                          </Link>}
                                      </div>
                                    </td>
                                    <td>{(list.process_customers[0]?.user?.name)}</td>
                                    <td className="row">
                                      {/* {list?.process_customers.length > 0 ? */}
                                      {list?.process_customers.map((ac, index) => index < 4 && (
                                        <div className="circle-c" style={{ marginLeft: '-7px' }}></div>
                                      ))}
                                      {/* : null
                                  } */}

                                      {list.process_customers_count === 0 ? null :
                                        <div className="circle-c" style={{ marginLeft: '-7px', backgroundColor: '#E328AF', cursor: 'pointer' }} onClick={() => {
                                          setCustomerList(list.process_customers)
                                          setCustomerModalShow(!customerModalShow)
                                        }}>
                                          <div className="count-circle">{list.process_customers_count}+</div>
                                        </div>}
                                    </td>
                                    <td>
                                      <Moment format="DD/MM/YYYY">
                                        {list.updated_at}
                                      </Moment>
                                    </td>
                                    <td>
                                      {/* {list.status == 0 ? null :
                                        <Link to={"/file-list/" + list.id}>
                                          <div className="document-btn">
                                            <i className="fas fa-eye"></i> {t("Documents")}
                                          </div>
                                        </Link>
                                      } */}
                                      {list.status === 1 ?
                                        <span className="ml-2 text-success font-weight-bold">{t('Active')}</span>
                                        :
                                        <span className="ml-2 text-danger font-weight-bold">{t('Deactive')}</span>}
                                    </td>
                                    <td>
                                      <div className="row process-row">

                                        {list.status == 0 ? null :
                                          <>
                                            <Link to={"/process-dashboard/" + list.slug}>
                                              <div className="items-center mr-1 btn-outline-info btn btn-sm" title={t("Process Detail")}>
                                                <i class="fas fa-info-circle" ></i>
                                              </div>
                                            </Link>
                                            {list.process_role == "Customer" || list.process_role == "Contributor" ? null :
                                              <div className="items-center mr-1 btn-outline-warning btn btn-sm"
                                                title={t("Assign to user")}
                                                onClick={() => {
                                                  setProcessSlug(list.slug)
                                                  setProcessId(list.id)
                                                  setAssignListShow(true)
                                                  setUserId(list.user_id)
                                                  setProcessName(list.name)
                                                }}>
                                                <i class="fas fa-user-circle"></i>
                                              </div>
                                            }

                                            {/* {list.process_role == "Customer" || list.process_role == "Contributor" ? null :
                                              <div className="items-center mr-1 btn-outline-dark btn btn-sm" title={t("File Upload")}
                                                onClick={() => {
                                                  localStorage.removeItem('process_slug')
                                                  goToAddFile(list.id)
                                                }}
                                              >
                                                <i class="fas fa-file-upload"></i>
                                              </div>
                                            } */}

                                            {/* </Link> */}
                                            <Link to={"/file-list/" + list.id}>
                                              <div className="items-center mr-1 btn-outline-dark btn btn-sm" title={t("File Management")}
                                              >
                                                <i class="fas fa-folder-open"></i>
                                              </div>
                                            </Link>

                                          </>
                                        }
                                        {list.process_role == "Customer" || list.process_role == "Contributor" ? null :
                                          <div>
                                            <Switch
                                              onColor="#86d3ff"
                                              onHandleColor="#2693e6"
                                              handleDiameter={25}
                                              uncheckedIcon={false}
                                              checkedIcon={false}
                                              boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                                              activeBoxShadow="0px 0px 1px 7px rgba(0, 0, 0, 0.2)"
                                              height={18}
                                              width={40}
                                              className="react-switch ml-3"
                                              id="material-switch"
                                              onChange={e => handleChange(list.id, list.status)}
                                              checked={list.status === 1 ? true : false}
                                            />
                                          </div>
                                        }

                                      </div>
                                    </td>
                                  </tr>
                                ))}


                              </tbody>
                            </Table>

                          </div>
                        </div>
                        <div className="row">
                          <div className="mx-auto d-block my-3">
                            <ReactPaginate
                              previousLabel={t('prev')}
                              nextLabel={t('next')}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={pageCount}
                              marginPagesDisplayed={0}
                              pageRangeDisplayed={3}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                        {alertactive === true ?
                          <SweetAlert
                            success
                            showCancel
                            confirmBtnText={t("Yes")}
                            confirmBtnBsStyle="success"
                            title={t('Are you sure?')}
                            onConfirm={(e) => {
                              handleConfirm()
                              setAlertActive(false)
                            }}
                            onCancel={(e) => {
                              setAlertActive(false)
                            }}
                            focusCancelBtn
                          >
                            {t("Do you want to activate process?")}
                          </SweetAlert>
                          : null}
                        {alertdeactive === true ?
                          <SweetAlert
                            danger
                            showCancel
                            confirmBtnText={t("Yes")}
                            confirmBtnBsStyle="danger"
                            title={t('Are you sure?')}
                            onConfirm={(e) => {
                              handleCancel()
                              setAlertDeActive(false)
                            }}
                            onCancel={(e) => {
                              setAlertDeActive(false)
                            }}
                            focusCancelBtn
                          >
                            {t("Do you want to deactivate process?")}
                          </SweetAlert>
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

            </Row>
          </div>
        </div>
        <Modal show={searchModalshow} >
          <Modal.Header >
            <Modal.Title>{t('Search')}</Modal.Title>
            <div className="close" closeButton onClick={() => handleClose()}>

              <i class="far fa-times-circle"></i>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="process">
              <input className="form-control" placeholder={t('Search')} style={{ borderRadius: '18px' }}
                onKeyDown={onKeyDown}
                value={fileSearch} onChange={(e) => setFileSearch(e.target.value)} />
              <br />
              <Button variant="warning" className="button" type="submit" style={{ borderRadius: "12px" }} onClick={searchApi}>
                <span style={{ color: "#fff", marginRight: "12px", marginLeft: "12px" }}>{t('Submit Now')}</span>
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        {assignListshow === true ?
          <AssignModal assignListshow={assignListshow} setAssignListShow={setAssignListShow} processSlug={processSlug} process_id={process_id} userId={userId} getProcess={() => getProcess()} process_name={process_name} />
          : null}
        {processModalshow === true ?
          <NewProcessModal processModalshow={processModalshow} setProcessModalShow={setProcessModalShow} getProcess={() => getProcess()} /> : null
        }
        {customerModalShow === true ?
          <CustomerModal customerModalShow={customerModalShow} setCustomerModalShow={setCustomerModalShow} customerList={customerList} />
          : null
        }
        {editProcessModal === true ?
          <EditProcessModal editProcessModal={editProcessModal} setEditProcessModal={setEditProcessModal} processSlug={processSlug} getProcess={() => getProcess()} />
          : null}
        {notesModalShow === true ?
          <AddNotesModal notesModalShow={notesModalShow} setNotesModalShow={setNotesModalShow} process_id={process_id} />
          : null}
        <Modal show={processDetailModalShow}  >
          <Modal.Header >
            <Modal.Title >
              <h4 className="font-weight-bold">Terms of donation</h4>
            </Modal.Title>
            <div className="button accept" onClick={handleProcessDetailClose}>
              <i class="far fa-times-circle"></i>
            </div>
          </Modal.Header>
          <Modal.Body>
            <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className="click">

              <Button className="button acceptA mr-2" variant="success" >
                Accept Donate
              </Button>
              <Button className="button acceptB" variant="danger">
                Cancel Donate
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

      </div>
    </>
  );
}

export default ProcessList;
