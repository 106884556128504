import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import axios from "axios";
import config from "../config/config";
import { displayError, getNotesList, getProcessNotesList } from "../Utils/utils";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import './style.css'
import get from "../components/images/get.png";
import send from "../components/images/send.png";
import ChatSpinner from "../components/ChatSpinner/ChatSpinner";

const Chat = (props) => {
  const [is_loading, setIsloading] = useState(false);

  const { t } = useTranslation();
  const [text, setText] = useState('');
  const [name, setName] = useState('');
  const [msgArr, setMsgArr] = useState([{
    name: t("Artificial Intelligence"),
    message: t('Ask your questions and needs here'),
    time: moment(new Date()).format('hh:mm a'),
    sender: 0
  }]);
  const userInfo = useSelector((state) => state.userInfo.value)

  const onSend = (textt) => {
    var temp = [...msgArr]
    var obj = {
      name: name,
      message: textt,
      time: moment(new Date()).format('hh:mm a'),
      sender: 1
    }
    temp.push(obj)
    setMsgArr(temp)
    setText('')
    sendMessage(textt, temp)
  }
  const sendMessage = async (text, temp) => {
    setIsloading(true)
    var temp_arr = [...temp]
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/message-chat-bot', {
      text: text
    }, {
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        setIsloading(false)
        if (response.status == 200) {

          var obj = {
            name: t("Artificial Intelligence"),
            message: response.data.data,
            time: moment(new Date()).format('hh:mm a'),
            sender: 0
          }
          temp_arr.push(obj)
          setMsgArr(temp_arr)
        }

      })
      .catch((error) => {
        displayError(error, t);
      });

  }
  return (
    <>
      <div className="row my-5" >
        <ChatSpinner is_loading={is_loading} />
        <div className="col-md-8 search-result">
          <div className="card shadow " style={{ borderRadius: "15px", height: 'auto' }}>
            <div className="card-body">

              <div className='chat-main'>
                {/* <div className='chat-box'> */}
                <Container>

                  <Row className='head-row-chat'>
                    <Col md={1} xs={3} className="">
                      <div className='chat-img'>
                        <img src={get} className='chat' />
                      </div>
                    </Col>
                    <Col md={7} xs={9}>
                      <div className='chat-head'>
                        {t('Artificial Intelligence')}
                      </div>
                    </Col>
                    <Col md={4} xs={12}>
                      <div className='display-end-css p-0'>
                        <div className='clear-box' onClick={() => setMsgArr([{
                          name: t("Artificial Intelligence"),
                          message: t('Ask your questions and needs here'),
                          time: moment(new Date()).format('hh:mm a'),
                          sender: 0
                        }])}>
                          <i className='fas fa-trash'></i>  {t('Clear Chat')}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div>

                    <div className='date-row' />

                    <div className='date-row-text'>
                      {t('Today')}, {moment(new Date()).format('MMMM DD, YYYY  hh:mm a')}
                    </div>
                  </div>
                  <div className='chat-msgs'>
                    {msgArr.map((msg) => (
                      <>
                        {msg.sender === 0 ?

                          <div className='get-msg-box'>
                            <Container style={{ padding: '10px' }}>

                              <Row>
                                <Col md={1} xs={3} className="get-col">
                                  <div className='chat-img'>
                                    <img src={get} className='chat' />
                                  </div>
                                </Col>
                                <Col md={11} xs={9}>
                                  <div className='get-msg-head'>{msg.name} <span>at {msg.time}</span></div>
                                  <div className='get-msg-sub mt-1' dangerouslySetInnerHTML={{ __html: msg.message }}>
                                    {/* {msg.message} */}
                                  </div>
                                </Col>
                              </Row>
                            </Container>

                          </div>
                          :
                          <div className='send-msg-box'>
                            <Container style={{ padding: '10px' }}>

                              <Row>

                                <Col md={11} xs={9}>
                                  <div className='send-msg-head'><span>at {msg.time}</span>{msg.name} </div>
                                  <div className='send-msg-sub mt-1' dangerouslySetInnerHTML={{ __html: msg.message }}>
                                    {/* {msg.message} */}
                                  </div>
                                </Col>
                                <Col md={1} xs={3} className="get-col">
                                  <div className='chat-img'>
                                    <img src={send} className='chat' />
                                  </div>
                                </Col>
                              </Row>
                            </Container>

                          </div>
                        }
                      </>
                    ))}

                  </div>

                  <div className='chat-text-area'>
                    <textarea className="form-control mt-2 form-control-input chat-area"
                      as="textarea"
                      placeholder={t('Type message here...')}
                      rows={2}
                      cols={6}
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                    />
                    <div className='send-box'>
                      <div className='display-end-css' >
                        <div className='send-chat' onClick={() => {
                          if (text !== '') {

                            onSend(text)
                          }
                        }}>
                          <i className='fas fa-paper-plane'></i>  {t('Send')}
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>

                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

}
export default Chat;