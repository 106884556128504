import React, { useState } from 'react';
import '../css/style.css';
import OtpInput from 'react-otp-input';
import { useNavigate, useLocation } from "react-router-dom";
import config from '../config/config';
import axios from "axios";
import { displaySuccess, displayError, displayErrorString } from '../Utils/utils';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import image from '../components/images/logo_trans.png';

const TwoFactorProcessTwo = () => {
  const [otp, setOtp] = useState('');
  const navigate = useNavigate()
  const location = useLocation();
  const { t } = useTranslation()
  const handleChange = (otp) => setOtp(otp);

  const TwoFactorOtp = async (e) => {
    // e.preventDefault();
    // var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/register/verify-otp-2fa', {
      otp: otp,
      email: location?.state?.email
    })
      .then((response) => {
        if (response.data.data === "success") {
          navigate(`/login`)
          displaySuccess(t('Verified'));
        } else {
          displayErrorString(t("Please Enter Correct Otp"));
        }
      })
      .catch((error) => {
        displayError(error, t);
      });
  };
  return (
    <>
      <div class="form-body">
        <div class="website-logo">
          <a>
            <div class="logo">
              <img class="logo-size" src="images/logo-light.svg" alt="" />
            </div>
          </a>
        </div>
        <div class="row">
          <div class="img-holder">
            <div class="bg"></div>
            <div class="info-holder" style={{ top: '70%' }}>
              <img src={image} />

            </div>
          </div>
          <div class="form-holder">
            <div class="form-content">
              <div class="form-items">
                <div className='mb-4'>{t('Please enter the')}  <strong>{t('OTP')}</strong> {t('generated on your Authenticator App')}. <br /> {t('Ensure you submit the current one because it refreshes every 30 seconds')}.</div>

                <label className='mt-2'>{t('One Time Password')}</label>


                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  separator={
                    <span style={{ width: '8px' }}>

                    </span>}
                  inputStyle={{
                    width: "50px",
                    color: '#000',
                  }}
                  className="mt-2"
                />


                <h6>{t('could not scan')} ?
                  <Link style={{ color: "#FF4500", textDecoration: "none", marginLeft: "2px" }} to={{ pathname: "/2fa-initiate" }}>
                    {t('Go Back')}
                  </Link>
                </h6>
                <button id="submit" type="submit" className="ibtn mt-4" onClick={TwoFactorOtp}>{t('Verify')}</button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default TwoFactorProcessTwo;