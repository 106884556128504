import React from 'react';
import '../MainFeatures/MainFeatures.css';
import image1 from '../images/Icon.png';
import image2 from '../images/Icon-2.png';
import image3 from '../images/Icon-3.png';
import image4 from '../images/Icon-4.png';
import image5 from '../images/Icon-5.png';
import image6 from '../images/Icon-6.png';
import image7 from '../images/Icon-7.png';
import image8 from '../images/Icon-8.png';
import { Translate, I18n } from "react-redux-i18n";
import { useTranslation } from 'react-i18next';

const MainFeatures = () => {
    const { t } = useTranslation();
    return (
        <div>
            <section class="blog" id="blog">
                <div class="container">
                    <div class="row">

                        <div class="col-12">
                            <div class="title text-center ">
                                <h2 class="text-white"> {t('Main Features')}</h2>
                                <p class="text-white text-center ">{t('See how we can make you earn time and to have better results')}.</p>
                            </div>
                        </div>
                        <article class="col-6 col-md-3 col-sm-4 col-xs-6 practice-block clearfix ">
                            <div class="post-item inner-box">
                                <div class="media-wrapper">
                                    <img src={image1} alt="Legal Firm" class="img-fluid" />
                                </div>

                                <div class="content">
                                    <h3>{t('Document to Data')}</h3>

                                </div>
                            </div>
                        </article>
                        <article class="col-6 col-md-3 col-sm-4 col-xs-6 practice-block ">
                            <div class="post-item inner-box">
                                <div class="media-wrapper">
                                    <img src={image2} alt="Legal Firm" class="img-fluid" />
                                </div>

                                <div class="content">
                                    <h3>{t('Video to Data')}</h3>

                                </div>
                            </div>
                        </article>
                        <article class="col-6 col-md-3 col-sm-4 col-xs-6 practice-block ">
                            <div class="post-item inner-box">
                                <div class="media-wrapper">
                                    <img src={image3} alt="Legal Firm" class="img-fluid" />
                                </div>

                                <div class="content">
                                    <h3>{t('Data Search')}</h3>

                                </div>
                            </div>
                        </article>
                        <article class="col-6 col-md-3 col-sm-4 col-xs-6 practice-block ">
                            <div class="post-item border-0">
                                <div class="media-wrapper">
                                    <img src={image4} alt="Legal Firm" class="img-fluid" />
                                </div>

                                <div class="content">
                                    <h3>{t('Add Notes')}</h3>
                                </div>
                            </div>
                        </article>
                        <article class="col-6 col-md-3 col-sm-4 col-xs-6 practice-block ">
                            <div class="post-item inner-box border-top-1">
                                <div class="media-wrapper">
                                    <img src={image5} alt="Legal Firm" class="img-fluid" />
                                </div>
                                <div class="content">
                                    <h3>{t('Collaborate')}</h3>

                                </div>
                            </div>
                        </article>
                        <article class="col-6 col-md-3 col-sm-4 col-xs-6 practice-block ">
                            <div class="post-item inner-box border-top-1">
                                <div class="media-wrapper">
                                    <img src={image6} alt="Legal Firm" class="img-fluid" />
                                </div>

                                <div class="content">
                                    <h3>{t('Safe Environment')}</h3>
                                </div>
                            </div>
                        </article>
                        <article class="col-6 col-md-3 col-sm-4 col-xs-6 practice-block ">
                            <div class="post-item inner-box border-top-1">
                                <div class="media-wrapper">
                                    <img src={image7} alt="Legal Firm" class="img-fluid" />
                                </div>
                                <div class="content">
                                    <h3>{t('Artificial Intelligence')}</h3>
                                </div>
                            </div>
                        </article>
                        <article class="col-6 col-md-3 col-sm-4 col-xs-6 practice-block ">
                            <div class="post-item border-right-0 border-top-1">
                                <div class="media-wrapper">
                                    <img src={image8} alt="Legal Firm" class="img-fluid" />
                                </div>
                                <div class="content">
                                    <h3>{t('Web Platform')}</h3>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default MainFeatures