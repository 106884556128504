import React, { useRef, useState } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Topbar from "../components/Topbar/Topbar";
// import './style.css'
import CustomerModal from "../components/Modal/CustomerModal";
import Navs from "../components/TopNav/TopNav";
import { FileUploader } from "react-drag-drop-files";
import FileUploadProgress from 'react-fileupload-progress';
import axios from "axios";
import config from "../config/config";
import ProgressBar from 'react-bootstrap/ProgressBar';
import LinearProgress from '@mui/material/LinearProgress';
// import Divider from '@material-ui/core/Divider';
import useDrivePicker from 'react-google-drive-picker'
import Divider from '@mui/material/Divider';
import { useDropboxChooser } from 'use-dropbox-chooser'
import DropboxChooser from 'react-dropbox-chooser'
import { Link, useLocation } from 'react-router-dom'
import UploadFilesComponent from "./UploadFilesComponent";
const UploadFiles = () => {
  return (
    <>
      <div id="wrapper">
        <Navs title={''} />
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title={' '} />
            <div className="container-fluid full-width">
              <Link to="/process-list">
                <div className="back-btn mb-3">
                  <div className="text-light back-txt" >Back <i class="fas fa-long-arrow-alt-left"></i></div>
                </div>
              </Link>
              <div className="row">
                {/* Earnings (Monthly) Card Example */}
                <div className="col-xl-8 col-md-6 mb-4">
                  <div className="card  shadow py-2" style={{ borderRadius: '15px', height: 'auto' }}>
                    <div className="card-body">
                      <div className="container" style={{ width: "600px" }}>
                        <div className="my-2">
                          <h3>bezkoder.com</h3>
                          <h4>React upload multiple Files</h4>
                        </div>
                        <UploadFilesComponent />
                      </div>
                    </div>
                  </div>

                </div>
                {/* Earnings (Monthly) Card Example */}
                <div className="col-xl-4 col-md-6 mb-4">
                  <div className="card  shadow py-2" style={{ borderRadius: '15px', height: 'auto' }}>
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          {/* <div className="row"> */}
                          <div className="font-weight-bold text-lg ml-2">History</div>
                          <Divider>Today</Divider>
                          <div className="history-box row shadow mb-2">
                            <div className="col-md-2" >
                              <i class="fas fa-file-pdf text-success icon-file" style={{ fontSize: '25px' }}></i>
                            </div>
                            <div className="col-md-8">
                              <div className="text-dark mt-2">LegalFile_upload.doc</div>
                              <div className="file-size">2.1Mb</div>
                            </div>
                            <div className="col-md-2">
                              <i class="fas fa-download text-dark icon-file" style={{ fontSize: '23px' }}></i>
                            </div>
                          </div>
                          <div className="history-box row shadow mt-3 mb-2">
                            <div className="col-md-2" >
                              <i class="fas fa-video text-danger icon-file" style={{ fontSize: '25px' }}></i>
                            </div>
                            <div className="col-md-8">
                              <div className="text-dark mt-2">LegalFile_upload.doc</div>
                              <div className="file-size">2.1Mb</div>
                            </div>
                            <div className="col-md-2">
                              <i class="fas fa-download text-dark icon-file" style={{ fontSize: '23px' }}></i>
                            </div>
                          </div>
                          <div className="history-box row shadow mt-3 mb-2">
                            <div className="col-md-2" >
                              <i class="fas fa-file text-success icon-file" style={{ fontSize: '25px' }}></i>
                            </div>
                            <div className="col-md-8">
                              <div className="text-dark mt-2">LegalFile_upload.doc</div>
                              <div className="file-size">2.1Mb</div>
                            </div>
                            <div className="col-md-2">
                              <i class="fas fa-download text-dark icon-file" style={{ fontSize: '23px' }}></i>
                            </div>
                          </div>
                          <div className="history-box row shadow mt-3 mb-2">
                            <div className="col-md-2" >
                              <i class="fas fa-file-pdf text-dark icon-file" style={{ fontSize: '25px' }}></i>
                            </div>
                            <div className="col-md-8">
                              <div className="text-dark mt-2">LegalFile_upload.doc</div>
                              <div className="file-size">2.1Mb</div>
                            </div>
                            <div className="col-md-2">
                              <i class="fas fa-download text-dark icon-file" style={{ fontSize: '23px' }}></i>
                            </div>
                          </div>
                        </div>

                        <div className="col-auto">
                          {/* <i className="fas fa-dollar-sign fa-2x text-gray-300" /> */}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default UploadFiles