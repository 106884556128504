import React, { useEffect, useState } from "react";
// import './style.css';
import { Link } from "react-router-dom";
import config from '../config/config';
import axios from "axios";
import { displaySuccess, displayError, displayErrorString, verifyEmail } from '../Utils/utils'
import queryString from 'query-string';
import Home from "../Home";
import { useTranslation } from "react-i18next";

const Verify = () => {
  const { t } = useTranslation()
  // const navigate = useNavigate()

  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  useEffect(async () => {
    // props.toggleLoader(true);
    const token = queryString.parse(window.location.search);
    var verify_email = await verifyEmail(token.reset_token)
    if (verify_email === true) {
      displaySuccess(t('Email Verified successfully'));
      setTimeout(() => window.location.href = "/login", 3000);
    }
    else {
      displayErrorString(t('invalid token'));
      setTimeout(() => window.location.href = "/", 3000);

    }
    // props.toggleLoader(false);
  }, [])

  // const Login = (e) => {
  //   e.preventDefault();
  //   // toggleLoader(true);
  //   let path = config.siteUrl;
  //   path = path + '/auth/login';
  //   axios.post(path, {
  //     email: email,
  //     password: password,
  //   })
  //     .then(function (response) {
  //       displaySuccess('Login Successfull');
  //       localStorage.setItem('token', response.data.data.access_token);
  //     })
  //     .catch((error) => {
  //       displayError(error);
  //     });
  // };
  return (
    <>
      <Home />
      {/* <div class="form-body">
        <div class="website-logo">
          <a href="index.html">
            <div class="logo">
              <img class="logo-size" src="images/logo-light.svg" alt="" />
            </div>
          </a>
        </div>
        <div class="row">
          <div class="img-holder">
            <div class="bg"></div>
            <div class="info-holder">
              <h1>Logo</h1>
              <h2>Reference site about Lorem Ipsum, giving inf</h2>
            </div>
          </div>
          <div class="form-holder">
            <div class="form-content">
              <div class="form-items">
                <h3>Welcome</h3>
                <p>Login into your account</p>
                <div class="page-links">
                  <a href="login.html" class="active">Login</a>
                </div>
                <div>
                  <label>Email Address</label>
                  <input class="form-control" type="text" name="username" placeholder="E-mail Address" required
                    onChange={e => setEmail(e.target.value)} />

                  <label>Password</label>
                  <input class="form-control" type="password" name="password" placeholder="Password" required
                    onChange={e => setPassword(e.target.value)} />


                  <div class="form-button d-flex justify-content-between">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="customCheck" name="example1" />
                      <label class="custom-control-label" for="customCheck">Remember Me</label>
                    </div>
                    <Link to="/forgot-password">
                      Forgot password?
                    </Link>
                  </div>

                  <button id="submit" type="submit" class="ibtn" onClick={Login}>Login</button>

                </div>
                <Link to="/register">
                  <div class="other-links text-center">
                    <span>Want a new account?</span><a class="text-warning"> Start it now</a>
                  </div>
                </Link>
              </div>
            </div>
            <div class="humer_footer d-none d-md-block">
              <img src="images/hammer-1.png" class="img-fluid" />
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}
export default Verify;