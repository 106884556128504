import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import config from "../../config/config";
import axios from "axios";
import { displayError, displaySuccess, getCustomerList, getEditProcess, getProcessList } from "../../Utils/utils";
import { useSelector, useDispatch } from 'react-redux';
import { I18n } from "react-redux-i18n";
import { useTranslation } from "react-i18next";
import './styles.css'

const AddNotesModal = (props) => {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state.userInfo.value)
  const [noteName, setNoteName] = useState('');
  const [noteDesc, setNoteDesc] = useState('');

  const handleClose = () => {
    props.setNotesModalShow(false)
  }
  // console.log(Math.round(props.currentSeek))
  const onFormSubmit = async () => {
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/notes/add-process-note/' + props.process_id, {
      user_id: userInfo.id,
      title: noteName,
      description: noteDesc,
      file_id: props.fileId,
      page: props.fileType == "pdf" ? props.pageNumber ? props.pageNumber : 1 : '',
      duration: props.fileType == "video" ? props.currentSeek ? Math.round(props.currentSeek) : 1 : '',
      file_type: props.fileType
    }, {
      headers: {
        'Content-Type': 'application/json', Authorization: `Bearer ${token}`
      },
    })
      .then((response) => {
        displaySuccess(t('Note has been added'));
        props.setNotesModalShow(false)
        if (props.getFilesList !== undefined) {
          props.getFilesList(props.fileId);
        } else {
          if (props.fileId !== null || props.fileId !== "") {
            props.noteListing(props.fileId)
          } else {
            props.processNoteListing(props.process_id)
          }
        }
      })
      .catch((error) => {
        displayError(error, t);
      });
  };
  return (
    <>
      <Modal show={props.notesModalShow}>
        <Modal.Header >
          <Modal.Title >
            <h4 className="font-weight-bold">{t('Add Note')}</h4>
          </Modal.Title>
          <div className="button accept" onClick={() => handleClose()}>
            <i class="far fa-times-circle"></i>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>{t('Title')} <span style={{ color: "red" }}>*</span></div>
            <input className="form-control mt-2 mb-2" type="text" placeholder={t('Note Name')} variant="dark" onChange={(e) => setNoteName(e.target.value)} />
            <div>{t('Description')} <span style={{ color: "red" }}>*</span></div>
            <textarea className="form-control mt-2 mb-2 note-modal" as="textarea" placeholder={t('Please enter your note')}
              rows={6}
              cols={8}
              onChange={(e) => setNoteDesc(e.target.value)} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="click">
            <Button className="button" variant="warning" style={{ color: "#ffff", alignItems: "center" }} onClick={onFormSubmit} >
              {t('Submit')}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default AddNotesModal;
