import React, { useState, useEffect, useRef, useCallback } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Topbar from "../components/Topbar/Topbar";
import Button from 'react-bootstrap/Button';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Navs from "../components/TopNav/TopNav";
import { Container, Row, Col, Image, Modal } from 'react-bootstrap';
import { getEditProfile } from "../Utils/utils";
import config from "../config/config";
import axios from "axios";
import { displayError, displaySuccess, } from "../Utils/utils";
import { Initial } from "react-initial";
import { useTranslation } from "react-i18next";
import ReactInputMask from 'react-input-mask';

const Profile = () => {

  const [upImg, setUpImg] = useState(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 / 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [imageData, setImageData] = useState('./images/index.jpeg');
  const previewCanvasRef = useRef(null);
  const [show, setShow] = useState(false);

  const [type, setType] = useState('');

  const [userId, setUserId] = useState('')
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNum, setMobileNum] = useState('');
  const [desc, setDesc] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  let [mask, setMask] = React.useState(null);
  const [mob2, setMob2] = useState('');
  const { t } = useTranslation()
  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setShow(true)
    }
  };

  const handleClose = () => {
    setShow(false);
    document.getElementById('profile_picture').value = null;
  }

  const getProfileData = async () => {
    var profile_var = await getEditProfile();
    setName(profile_var.name)
    setEmail(profile_var.email)
    setMobileNum(profile_var.phone_number)
    setDesc(profile_var.description)
    setUserId(profile_var.id)
    setImgUrl(profile_var?.media)
    setType(profile_var.role.role.name)
  }


  const onFormSubmit = async () => {
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/profile/update', {
      user_name: name,
      phone_number: mobileNum,
      description: desc,
      role: type
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        displaySuccess(t('Profile Updated Successfully'));
      })
      .catch((error) => {
        displayError(error, t);
      });

  };
  function generateDownload(canvas, crop) {
    if (!crop || !canvas) {
      return;
    }

    canvas.toBlob(
      (blob) => {
        var dataURL = new File([blob], "my_image.png", { type: "image/png", lastModified: new Date().getTime() })
        const data = new FormData();
        data.append('file', dataURL);
        data.append('user_id', userId)
        var token = localStorage.getItem('token');
        // props.toggleLoader(true);
        let path = config.siteUrl;
        axios.post(path + '/user/upload-profile-picture', data, {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        })
          .then(function (response) {
            displaySuccess(t('Profile Picture Uploaded Successfully'));
            setImgUrl(response.data.data)
            setShow(false)
            setUpImg(null);
          })
          .catch(function (error) {
            displayError(error, t);
          });
      },
      'image/png',
      1
    );
  }

  useEffect(() => {
    getProfileData()
  }, [])

  const mobHandle = ev => {
    let { value } = ev.target;
    let nums = value.replace(/[^0-9]/g, "");
    setMask("+55 99 99999-9999");
    setMobileNum(ev.target.value);
    setMob2(nums);
    if (nums.length < 3 && mask != null) {
      setMask(null);
      ev.target.value = nums;
    }
  };
  return (
    <>
      <div id="wrapper">
        <Navs title={'Update Profile'} />
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title={t('Update Profile')} />
            <div className="container mt-5">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-8 col-md-8 col-xl-8">
                      <div className="row">
                        <div className="col-sm-6">
                          <label>{t('User Name')} <span style={{ color: "red" }}>*</span></label>
                          <input className="form-control" type="Username" placeholder="Please enter your name"
                            onChange={e => setName(e.target.value)}
                            value={name}
                          />
                        </div>
                        <div className="col-sm-6">
                          <label>{t('Email Id')} <span style={{ color: "red" }}>*</span></label>
                          <input className="form-control" type="useremail" placeholder="Please enter your email"
                            // onChange={e => setUserEmail(e.target.value)}
                            value={email} readOnly
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-sm-6">
                          <label>{t('Mobile')} <span style={{ color: "red" }}>*</span></label>

                          <ReactInputMask
                            className="form-control mt-2 form-control-input"
                            mask={mask}
                            onChange={mobHandle}
                            maskChar="_"
                            value={mobileNum}
                            placeholder={'mobile no'}
                          />
                        </div>
                        <div className="col-sm-6">
                          <label>{t('Type')} <span style={{ color: "red" }}>*</span></label>
                          <select className="form-control" as="select" type="value" placeholder="Please select the type"
                            onChange={e => setType(e.target.value)}
                          >
                            <option value="Customer">{t('Customer')}</option>
                            <option value="Lawyer">{t('Lawyer')}</option>
                            <option value="Admin">{t('Admin')}</option>
                          </select>
                        </div>
                      </div>
                      <div className="notes mt-3">
                        <div>{t('Bio')}</div>
                        <textarea className="form-control mt-2 mb-2" as="textarea" placeholder="Please enter your Bio" rows={10} onChange={e => setDesc(e.target.value)}
                          value={desc} />
                      </div>
                      <Button variant="warning" className="button mt-4" type="submit"
                        onClick={onFormSubmit}
                      >
                        <span style={{ fontsize: '18px' }}>{t('Update Profile')}</span>
                      </Button>
                    </div>
                    <div className="col-sm-4 col-md-4 col-xl-4 ">
                      <h6 style={{ color: "#444444" }}>{t('PROFILE PICTURE')}</h6>
                      <div className="media-left mr-3">
                        {imgUrl !== null ?
                          <Image className="img-fluid" src={imgUrl.url} style={{ width: '150px', height: '150px' }} />
                          :
                          <Initial
                            name={name}
                            color="#0D5064"
                            seed={5}
                            fontSize={40}
                            height={100}
                            width={100}
                          // style={{ borderRadius: '20px' }}
                          />
                        }
                      </div>
                      <div className="media-body mt-2">
                        <div className="custom-file">
                          <input type="file" accept=".jpg,.jpeg,.png" name="profile_image" id="profile_picture" onChange={onSelectFile} />
                          <Modal size="lg" show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                              <Modal.Title>{t('Update Profile Picture')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                              <div className="row">
                                <div className="col-md-6 mt-3">
                                  {
                                    (upImg !== null) ? <>
                                      <div className="col-md-12 mt-3">
                                        <center>
                                          <ReactCrop
                                            src={upImg}
                                            onImageLoaded={onLoad}
                                            crop={crop}
                                            onChange={(c) => setCrop(c)}
                                            onComplete={(c) => setCompletedCrop(c)}
                                            className="react-crop-image"
                                          />
                                        </center>

                                      </div>


                                    </> : ""

                                  }
                                </div>
                                <div className="col-md-6 mt-3">
                                  {
                                    (upImg) ? <>
                                      <canvas
                                        ref={previewCanvasRef}
                                        style={{
                                          width: Math.round(completedCrop?.width ?? 0),
                                          height: Math.round(completedCrop?.height ?? 0)
                                        }}
                                      />

                                    </>
                                      : <>
                                      </>

                                  }
                                </div>
                              </div>

                            </Modal.Body>
                            <Modal.Footer>
                              <button
                                className="btn btn-warning mr-2"
                                type="button"
                                disabled={!completedCrop?.width || !completedCrop?.height}
                                onClick={() =>
                                  generateDownload(previewCanvasRef.current, completedCrop)
                                }
                              >
                                {t('Upload cropped image')}
                              </button>
                              <Button variant="secondary" onClick={handleClose}> {t('Close')} </Button>
                            </Modal.Footer>
                          </Modal>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}
export default Profile