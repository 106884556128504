import React, { useState, useEffect } from "react";
// import { Table, thead, tbody, tr, th, td } from 'react-super-responsive-table'
import rectangle261 from '../components/images/Rectangle 261.jpg';
// import CustomerModal from "../components/Modal/Modal";
import samplePdf1 from '../components/images/sample2.pdf'
import samplePdf2 from '../components/images/samplepdf2.pdf'
import { Document, Page, pdfjs } from 'react-pdf';
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom'
import moment from "moment";
import { Row, Col, Modal, Table } from 'react-bootstrap';
import CustomVideoPlayerControlPanel from "./CustomVideoPlayerControlPanel";
import { displayError, displaySuccess, getEditNotes, getNewProcessUserList, getNotesList, getProcessNotesList } from "../Utils/utils";
import Moment from "react-moment";
import Alert from 'react-bootstrap/Alert';
import { useTranslation } from "react-i18next";
import config from "../config/config";
import axios from "axios";
import AddNotesModal from "../components/Modal/AddNotesModal";
import ReactPaginate from "react-paginate";
import PdfModal from "../components/Modal/PdfModal";
import imgnew from '../components/images/data-not-found-2.svg'
import imgnew2 from '../components/images/data-not-found.svg'
import LoadingSpinner from "../components/LoadingSpinner";
import DatePicker from "react-datepicker";
import SweetAlert from "react-bootstrap-sweetalert";
import { Initial } from "react-initial";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const NoteTab = (props) => {
  const { t } = useTranslation();

  const [customerModalShow, setCustomerModalShow] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [view, setView] = useState(false);
  const [key, setKey] = useState('Note');
  const [noteList, setNoteList] = useState([]);
  const [notesModalShow, setNotesModalShow] = useState(false);
  const [copy, setCopy] = useState(false);
  const [copyIndex, setCopyIndex] = useState('');
  const [searchPdf, setSearchPdf] = useState(props.fileUrl);
  const [tempId, setTempId] = useState('');

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }
  const addNote = (pageNumber) => {
  }
  const [currentSeek, setCurrentSeek] = useState(0)
  const [isPlay, setIsPlay] = useState(false)
  const [volumeBar, setVolumeBar] = useState(1)
  const [volume, setVolume] = useState(1)
  const [totalDurationOfVideo, setTotalDurationOfVideo] = useState(0)
  const [muted, setMuted] = useState(false)
  const [notes, setNotes] = useState(10.30)
  const hostVideo = React.createRef()
  const userVideo = React.createRef()
  const [pageCount, setPageCount] = useState(1);
  const [current_page, setCurrentPage] = useState(1);
  const [video, setVideo] = useState('');
  const [pdfModal, setPdfModal] = useState(false)
  const [is_loading, setIsloading] = useState(false);
  const [isView, setIsView] = useState(false);

  const [is_delete, setIsDelete] = useState(false);
  const [startDate, setStartDate] = useState(new Date())
  const [Search, setSearch] = useState('');
  const [searchUser, setSearchUser] = useState('')
  const [modalshow, setModalShow] = useState(false);
  const [userList, setUserList] = useState([])
  const [userSelect, setUserSelect] = useState('');

  const onProgress = (e) => {
    setCurrentSeek(e.playedSeconds)
  }
  const handlePause = async (e) => {
    setIsPlay(false)
  }
  const handlePlay = async (e) => {
    if (totalDurationOfVideo === 0) {
      setTotalDurationOfVideo(hostVideo.current.getDuration())
      // setTotalDurationOfVideo(totalDurationOfVideo = this.hostVideo.current.getDuration())
    }
    setIsPlay(true)
  }
  const handleSeekChange = async (e) => {
    setCurrentSeek(e.target.value)
    hostVideo.current.seekTo(e.target.value)
  }

  const handleVolumeChange = async (e) => {
    setVolumeBar(e.target.value)
    setVolume(e.target.value / 100)
  }

  const noteListing = async (id) => {
    setIsloading(true)
    var var_notes = await getNotesList(id, Search, searchUser, '');
    if (var_notes !== null) {
      setNoteList(var_notes.data)
      setPageCount(var_notes.last_page)

    }
    setIsloading(false)
  }
  const processNoteListing = async (id) => {
    setIsloading(true)
    var res = await getProcessNotesList(id, current_page)
    if (res !== null) {
      setNoteList(res.data)
      setPageCount(res.last_page)
    }
    setIsloading(false)

  }
  const onFilterSubmit = async (date) => {
    setIsloading(true)
    var var_notes = await getNotesList(props.fileId, Search, userSelect, date);
    setNoteList(var_notes.data)
    setIsloading(false)
  }
  const onFilterClear = async () => {
    setIsloading(true)
    setSearchUser('')
    setSearch('')
    setUserSelect('')
    setStartDate(new Date())
    var var_notes = await getNotesList(props.fileId, '', '', '');
    setNoteList(var_notes.data)
    setIsloading(false)
  }
  useEffect(() => {
    if (props.isProcess == true) {
      processNoteListing(props.processId)
    } else {
      noteListing(props.fileId)
    }
    if (props.fileType == "pdf") {
      setView(true)
    } else {
      setView(false)
    }
  }, [props.noteArr])

  const downloadTxtFile = (title, desc) => {
    const element = document.createElement("a");
    element.innerHTML = desc;
    const file = new Blob(["Title: " + title + "\n" + "Description: " + desc],
      { type: 'text/plain;charset=utf-8' });
    element.href = URL.createObjectURL(file);
    element.download = "myFile.txt";
    document.body.appendChild(element);
    element.click();
  }
  const notesTxt = (list) => {
    var temparr = []
    list.map((data) => {
      temparr.push("Title: " + data.title + "\n" + "Description: " + data.description + "\n\n")
    })
    var lineConcat = temparr.join("\r\n");
    const element = document.createElement("a");
    const file = new Blob([lineConcat],
      { type: 'text/plain;charset=utf-8' });
    element.href = URL.createObjectURL(file);
    element.download = "myFile.txt";
    document.body.appendChild(element);
    element.click();


  }
  const copyToClipboard = (content) => {
    const el = document.createElement('textarea');
    el.value = content;
    document.body.appendChild(el);
    el.select();
    setCopy(true)
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  const handlePageClick = async (e) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected + 1;

    if (props.isProcess == true) {
      var res = await getProcessNotesList(props.processId, selectedPage)
      if (res !== null) {
        setNoteList(res.data)
        setPageCount(res.last_page)
      }
    } else {
      var var_notes = await getNotesList(props.fileId, selectedPage);
      if (var_notes !== null) {
        setNoteList(var_notes.data)
        setPageCount(res.last_page)
      }
    }
  };
  const onResumeClick = (pdf) => {
    setPdfModal(true)
  }

  const deleteNote = async (noteid) => {
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/notes/delete-process-note/' + noteid, {

    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(async (response) => {
        displaySuccess(t('Note Deleted Successfully'));
        noteListing(props.fileId)

      })
      .catch((error) => {
        displayError(error, t);
      });
  }

  const handleClose = () => {
    setModalShow(false)
  }
  const getProcessUser = async () => {
    var res = await getNewProcessUserList(props.processId)
    setUserList(res)
  }
  useEffect(() => {
    getProcessUser()
  }, [])
  return (
    <>
      <div className="row mt-3" >
        <div className="col-md-8">
          <Row className="w-100 mt-3">
            <Col md={2} xs={4}>
              <div className="infobutton">
                <DatePicker className="form-control "
                  selected={startDate}
                  onChange={(date) => setStartDate(date)} />
              </div>
            </Col>
            <Col md={3} xs={4}>
              <div className="infobutton">
                <input className="form-control" id="validationCustom01" type="text" placeholder={t("search")} required="" data-bs-original-title="" title=""
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </Col>
            <Col md={3} xs={4} className="p-0">
              <select className="form-control" id="validationCustom04"
                onChange={e => {
                  setUserSelect(e.target.value)
                }}
                value={userSelect}
              >
                <option value="">{t('Select User')}</option>
                {userList.map((state) =>
                  <option value={state?.user?.name}>{state?.user?.name}</option>
                )}
              </select>
              {/* <div className="infobutton">
                <input className="form-control" id="validationCustom01" type="text" placeholder={t("search User")} required="" data-bs-original-title="" title=""
                  value={searchUser}
                  onChange={(e) => setSearchUser(e.target.value)} />
              </div> */}
            </Col>


            <Col md={2} xs={6}>
              <div className="infobutton">

                <button className=" btn btn-light w-100"
                  style={{ background: "rgb(255, 175, 0)", color: "#fff", borderRadius: "12px", marginLeft: "5px" }}
                  onClick={() => {
                    if (moment(startDate).format("DD/MM/YYYY") === moment(new Date()).format("DD/MM/YYYY")) {
                      onFilterSubmit('')
                    } else {
                      onFilterSubmit(moment(startDate).format("YYYY-MM-DD"))
                    }
                  }}>{t('Submit')}</button>
              </div>
            </Col>
            <Col md={2} xs={6}>
              <div className="infobutton">

                <button className=" btn btn-light w-100"
                  style={{ background: "rgb(255, 175, 0)", color: "#fff", borderRadius: "12px", marginLeft: "5px" }}
                  onClick={() => { onFilterClear() }}
                >{t('Clear')}</button>
              </div>
            </Col>

          </Row>
          <div className="card shadow mt-3" style={{ borderRadius: "15px", height: 'auto' }}>
            <div className="card-body">
              <div className="row">
                <div className="col-md-10 col-10">
                  <span className="textPrcess mt-3 ml-3 mb-1" style={{ color: "rgba(0, 42, 223, 0.64)" }}>
                    {props.fileId === null || props.fileId == "" ? "" : props.fileName}</span>
                </div>
                <div className="col-md-2 col-2">

                  <div className="items-center ml-5 btn btn-md btn-outline-primary download-icon" onClick={() => notesTxt(noteList)}>
                    <i class="fas fa-download"></i>
                  </div>
                </div>

              </div>

              <Table className="table table-striped align-items-center mt-4" responsive >
                <thead>
                  <tr>
                    {/* <th>{t('Notes Description')}</th> */}
                    <th>{t('Title')}</th>
                    <th>{t('User')}</th>
                    <th>	{props.fileType === "pdf" ? t('Page') : t('Duration')}</th>
                    <th>{t('Date')}</th>
                    <th>{t('Actions')}</th>
                  </tr>
                </thead>
                <tbody style={{ width: '100%' }}>
                  {(noteList.length === 0) ? <tr>
                    <td colSpan="7">
                      {localStorage.getItem('langauge_selected') === "pt" ? <img src={imgnew2} className="no-data-img-2" /> :
                        <img src={imgnew} className="no-data-img-2" />}
                      {/* <img src={imgnew} className="no-data-img-2" /> */}
                    </td>
                  </tr> : ''}
                  {noteList.map((list, index) => (

                    <tr>
                      {/* <td className="cell-width">{list.description}</td> */}
                      <td >{list?.title}</td>
                      <td >{list?.user?.name}</td>
                      <td>{list?.page !== null ? list?.page : list?.duration}</td>
                      <td>	<Moment format="DD/MM/YYYY">
                        {list?.created_at}
                      </Moment></td>
                      <td style={{ width: '30%' }}>
                        <div className="row">
                          {copy === true ?
                            index == copyIndex ?
                              <div className="items-center mr-1 btn btn-md btn-warning" title={t("Copied")}>
                                <i class="far fa-copy"></i>
                              </div>
                              :
                              <div className="items-center mr-1 btn btn-md btn-outline-warning" title={t("Copy")}
                                onClick={() => {
                                  setCopyIndex(index)
                                  copyToClipboard(list.description)
                                }}
                              >
                                <i class="far fa-copy"></i>
                              </div>
                            : <div className="items-center mr-1 btn btn-md btn-outline-warning" title={t("Copy")}
                              onClick={() => {
                                setCopyIndex(index)
                                copyToClipboard(list.description)
                              }}
                            >
                              <i class="far fa-copy"></i>
                            </div>


                          }

                          <div className="items-center mr-1 btn btn-md btn-outline-primary" title={t("Download")}
                            onClick={() => downloadTxtFile(list?.title, list?.description)}>
                            <i class="fas fa-download"></i>


                          </div>
                          <div className="items-center mr-1 btn btn-md btn-outline-secondary" title={t("Go To Page")}
                            onClick={() => {
                              if (list?.file_type === "video") {
                                setView(false)
                                setVideo(list.process_file?.media?.url)
                                hostVideo.current?.seekTo(list?.duration, true)
                                // setFileName(list.process_file.media.original_name)
                              } else {
                                setView(true)
                                // setTempFileId(list.file_id)
                                setSearchPdf(list.process_file?.media?.url)
                                // setFileName(list.process_file.media.original_name)
                                setPageNumber(parseInt(list.page))
                              }
                            }}
                          >
                            <i class="fas fa-arrow-circle-right"></i>
                          </div>
                          <div className="items-center mr-1 btn btn-md btn-outline-danger"
                            title={t("Delete")}
                            onClick={() => {
                              setIsDelete(true)
                              setTempId(list.id)
                            }}
                          >
                            <i class="fas fa-trash"></i>
                          </div>
                          <div className="items-center mr-1 btn btn-md btn-outline-info"
                            title={t("View")}
                            onClick={() => {
                              setModalShow(true)
                              setTempId(list.id)
                            }}
                          >
                            <i class="fas fa-eye"></i>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}


                </tbody>
              </Table>

            </div>
            <div className="row">
              <div className="mx-auto d-block my-3">
                <ReactPaginate
                  previousLabel={t('prev')}
                  nextLabel={t('next')}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={0}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 search-pdf ">
          <div className="" style={{ borderRadius: "15px", height: 'auto' }}>
            <div className="">
              {view !== false ?
                <>
                  <h6 style={{
                    color: "#002ADF",
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }}>{props.fileName}</h6>

                  <Document
                    className="custom_pdf_document_row"
                    file={searchPdf == "" ? 'https://dutraeducation.codiflysoftware.com/api/pdfbase64?url=' + props.fileUrl : 'https://dutraeducation.codiflysoftware.com/api/pdfbase64?url=' + searchPdf}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page className="custom_pdf_page_row" pageNumber={pageNumber} width={400} height={300} scale={1.0} />
                  </Document>
                  <div className="row">
                    <div className="col-md-4 col-xs-6 mt-2 mb-2">
                      <p>{t("Page")} {pageNumber} {t('of')} {numPages}</p>
                    </div>
                    <div className="col-md-4 col-xs-6 mt-2 mb-2">
                      <div className="pdf-btn">
                        <i onClick={e => {
                          var temp_page_numer = pageNumber;

                          if (temp_page_numer <= numPages) {
                            setPageNumber(--temp_page_numer)
                          }
                        }} class="fas fa-chevron-circle-left pointer"></i>

                        <i onClick={e => {
                          var temp_page_numer = pageNumber;
                          if (temp_page_numer < numPages) {
                            setPageNumber(++temp_page_numer)
                          }
                        }} class="fas fa-chevron-circle-right pointer"></i>
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-6 mt-2 mb-2">
                      <div className="pdf-btn " onClick={() => onResumeClick(searchPdf)}>
                        <i class="fas fa-expand"></i>
                      </div>
                    </div>

                  </div>
                </>
                :
                <>
                  <h6 style={{ color: "#002ADF" }}>{props.fileName}</h6>
                  <div className='player' >
                    <ReactPlayer
                      url={props.fileUrl ? props.fileUrl : video}
                      width={'90%'}
                      height={300}
                      volume={volume}
                      muted={props.mute}
                      controls
                      // onError={this.handleError}
                      // onReady={(e) => this.handleVideoReady(e)}
                      ref={hostVideo}
                      playing={isPlay}
                      onProgress={(e) => onProgress(e)}
                    >
                    </ReactPlayer>
                  </div>

                </>

              }
              <div className="video-add-note btn btn-warning"
                onClick={() => {
                  setNotesModalShow(true)
                  setIsPlay(false)
                  // setFileId(list.id)
                  // setProcessId(list.id)
                }}
              >
                <div className="font-weight-bold">{t("Add Note +")}</div>
              </div>

            </div>
          </div>

          {notesModalShow === true ?
            <AddNotesModal
              notesModalShow={notesModalShow}
              setNotesModalShow={setNotesModalShow}
              process_id={props.processId}
              fileId={props.fileId}
              noteListing={() => noteListing(props.fileId)}
              pageNumber={pageNumber}
              currentSeek={currentSeek}
              fileType={props.fileType}
            // getFilesList={() => getFilesList(id)}
            />
            : null}
          {pdfModal === true ?
            <PdfModal
              pdfModal={pdfModal}
              setPdfModal={setPdfModal}
              pageNumber={pageNumber}
              pdfUrl={'https://dutraeducation.codiflysoftware.com/api/pdfbase64?url=' + searchPdf}
            />
            : null}
          {is_delete === true ? (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes"
              confirmBtnBsStyle="danger"
              title={t('Are you sure?')}
              onConfirm={(e) => {
                deleteNote(tempId)
                setIsDelete(false)
              }}
              onCancel={(e) => {
                setIsDelete(false)
                setTempId('')
              }}
              focusCancelBtn
            >
              {t("Do you want to delete this note")}?
            </SweetAlert>
          ) : ([]
          )}
          <Modal  {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered show={modalshow} onHide={handleClose} >
            <Modal.Header style={{ border: "none" }} >
              <Modal.Title>{t('Notes')}</Modal.Title>
              <div className="close" onClick={handleClose} closeButton>
                <i class="fas fa-arrow-alt-to-bottom"></i>
                <i class="far fa-times-circle"></i>
              </div>

            </Modal.Header>
            {/* <h6 style={{ marginLeft: "15px", color: "#A5A5A5" }}>{t('You have')} {totalNoteCount} {t('Notes')}</h6> */}
            <Modal.Body>
              <div className="card-body">
                {noteList.map((list) => (
                  <>
                    {list.id == tempId ?
                      <>
                        <div className="row " style={{ padding: "12px 0px", marginBottom: '10px', borderBottom: '1px solid #6cb9d5' }}>
                          <div className=" col-sm-1 col-md-3 col-xl-1 col-lg-1 col-3">
                            {list.user.media === null ?
                              <Initial
                                name={list?.user?.name}
                                color="#0D5064"
                                seed={5}
                                fontSize={25}
                                height={55}
                                width={55}
                                style={{ borderRadius: '50px', marginRight: '10px', marginBottom: '15px' }}
                              />
                              :
                              <img src={list.user?.media?.url} alt="" style={{
                                borderRadius: '65px',
                                width: '55px',
                                height: '55px'
                              }} />
                            }
                          </div>
                          <div className="col-sm-10 col-md-8 col-xl-10 col-lg-10 col-9">
                            <div className="row align-items-center " style={{ marginLeft: "15px", fontWeight: '500' }}>
                              <h6 style={{ fontWeight: 'bold' }}>{list.title}
                              </h6>
                            </div>
                            <div className="row align-items-center" style={{ marginLeft: "15px" }}>
                              <h6 >{list.description}
                              </h6>
                            </div>
                            <div className="row align-items-center" style={{ marginLeft: "15px", color: '#808080' }}>
                              <h6 >By {list.user.name}
                              </h6>

                            </div>
                          </div>
                        </div>
                      </>
                      : null}
                  </>
                ))}
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );

}
export default NoteTab;