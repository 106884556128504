import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import img from '../images/placeholder.png';
import { Initial } from "react-initial";
import { useTranslation } from "react-i18next";

const CustomerModal = (props) => {
  const [show, setShow] = useState(props.customerModalShow);
  const handleClose = () => props.setCustomerModalShow(false);
  const handleShow = () => setShow(true);
  const { t, i18n } = useTranslation();

  return (
    <>
      <Modal show={props.customerModalShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="alert-link">{t("Customer")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {props.customerList.map((list) => (
              <Row className="mt-2 mb-2">
                <Col xl={3} style={{ textAlign: 'end' }}>
                  {list?.user?.media !== null ?
                    <img
                      className="img-profile rounded-circle  d-none d-sm-block"
                      src={list?.user?.media?.url}
                      style={{ height: '40px', marginLeft: 'auto' }}
                    />
                    :
                    <Initial
                      name={list?.user?.name}
                      color="#0D5064"
                      seed={5}
                      fontSize={25}
                      height={40}
                      width={40}
                      style={{ borderRadius: '20px' }}
                    />
                  }
                  {/* <img src={img} className="rounded-circle" /> */}
                </Col>
                <Col xl={9}>
                  <div className="font-weight-bold">{list?.user?.name}</div>
                  <span className="font-weight-light" style={{ color: '#A5A5A5' }}>{list?.user?.email}</span>
                </Col>

              </Row>
            ))}
          </Container>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}
export default CustomerModal;
