import React, { useState } from "react";
import { Link } from "react-router-dom";
import img1 from '../images/placeholder.png';
import { Translate, I18n } from "react-redux-i18n";
import { useTranslation } from "react-i18next";
// import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import { Row, Col } from 'react-bootstrap';

import NewUser from "../Modal/NewUser";
import { Initial } from "react-initial";
import SweetAlert from "react-bootstrap-sweetalert";
import config from "../../config/config";
import axios from "axios";
import { displayError, displaySuccess } from "../../Utils/utils";
import defaultImg from "../../image/new-not-found-2.svg";

const UserList = (props) => {
  const { t } = useTranslation();
  const [newUserModal, setNewUserModal] = useState(false);
  const [is_delete, setIsDelete] = useState(false)
  const [tempId, setTempId] = useState('');

  const deleteUser = async (id) => {
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/user/delete-process-user/' + id, {
      process_id: props.processId,
      language: localStorage.getItem('langauge_selected'),
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        displaySuccess(t('user deleted successfully'));
        props.getDetail();
        setIsDelete(false)
      })
      .catch((error) => {
        // setIsloading(false)
        displayError(error);
        // props.toggleLoader(false);
      });
  }
  return (
    <>
      <div className="card mt-3 shadow " style={{ borderRadius: '15px', height: 'auto' }}>
        <div className="card-body">
          <Row>
            <Col md={6}>
              <div className="font-weight-bold text-dark" style={{ fontSize: '18px' }}>
                {t('Users / Access list')}</div>
            </Col>
            <Col md={3}>
              {props.processRole == "Customer" || props.processRole == "Contributor" ? null :
                <button className="headBox" onClick={() => setNewUserModal(!newUserModal)}>
                  <i class="fas fa-user-plus text-warning text-s"></i>
                  <span className="text-warning text-xs font-weight-bold ml-1">{t('New User')}</span>
                </button>
              }
            </Col>
            <Col md={3}>
              {props.processRole == "Customer" || props.processRole == "Contributor" ? null :
                <Link to="/user-list" style={{ textDecoration: 'none' }}>
                  <div className="subBox" >
                    {/* <i class="fas fa-user-plus text-warning ml-2 text-s"></i> */}
                    <span className="text-dark text-md font-weight-bold ml-3 ">{t('View All')}</span>
                  </div>
                </Link>
              }
            </Col>
          </Row>
          <Table borderless size="md" responsive className="user-table">
            <tbody>
              {(props.processUser.length === 0) ?
                <tr>
                  <td colspan="7" style={{ textAlign: 'center' }}>
                    {/* <Alert variant={'danger'} style={{ marginTop: '30px' }}>
                    {t('No Users')}
                  </Alert> */}
                    <img src={defaultImg} style={{ maxWidth: '420px' }} className="defaultImg" />
                  </td>
                </tr> : ''}
              {props.processUser.map((list) => (
                <tr>
                  <td>
                    <Row>
                      <Col md={3} xs={3}>
                        <Initial
                          name={list?.user?.name}
                          color="#0D5064"
                          seed={5}
                          fontSize={25}
                          height={40}
                          width={40}
                          style={{ borderRadius: '20px', marginRight: '10px', marginBottom: '15px' }}
                        />
                      </Col>

                      <Col md={9} xs={9}>
                        {list.user.name}
                      </Col>

                    </Row>
                  </td>
                  <td>
                    <span className="ml-2 text-primary">{list.process_role.name}</span>
                  </td>
                  {props.processRole === "Owner" ?
                    <td>
                      <i class="fas fa-trash-alt text-md ml-4 text-danger" style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setTempId(list.user_id)
                          setIsDelete(true)
                        }}></i>
                    </td>
                    : null}
                </tr>
              ))}



            </tbody>
          </Table>
        </div>
        {is_delete === true ?
          <SweetAlert
            danger
            showCancel
            confirmBtnText="Yes"
            confirmBtnBsStyle="danger"
            title={t('Are you sure?')}
            onConfirm={(e) => {
              deleteUser(tempId)
              // setIsDelete(false)
            }}
            onCancel={(e) => {
              setIsDelete(false)
            }}
            focusCancelBtn
          >
            {t("Do you want to delete user?")}
          </SweetAlert>
          : ""}
        {newUserModal === true ?
          <NewUser newUserModal={newUserModal} setNewUserModal={setNewUserModal} processId={props.processId}
            getDetail={() => props.getDetail()} />
          : null}
      </div>
    </>
  );
}
export default UserList