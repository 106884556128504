import React, { useState } from "react";
import CustomerModal from "../Modal/CustomerModal";
import { Link } from "react-router-dom";
import { Translate, I18n } from "react-redux-i18n";
import { Table, Row, Col } from 'react-bootstrap';

// import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import Alert from 'react-bootstrap/Alert';
import { useTranslation } from "react-i18next";
import { Initial } from "react-initial";
const CustomerList = (props) => {
  const [customerModalShow, setCustomerModalShow] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <div className="card  shadow " style={{ borderRadius: '15px', height: 'auto' }}>
        <div className="card-body">

          {/* <thead> */}
          {/* <tr> */}
          <Row>
            <Col md={6}>
              <div className="font-weight-bold text-dark" style={{ fontSize: '18px' }}>
                {t('Customer List')}</div>
            </Col>
            <Col md={3}>
              {props.processRole == "Customer" || props.processRole == "Contributor" ? null :
                <Link to='/addnewcustomer' >
                  <button className="headBox">
                    <i class="fas fa-user-plus text-warning ml-2 text-s"></i>
                    <span className="text-warning text-xs font-weight-bold ml-1">{t("New Customer")}</span></button>
                </Link>
              }
            </Col>
            <Col md={3}>
              {props.processRole == "Customer" || props.processRole == "Contributor" ? null :
                <Link to="/user-list" style={{ textDecoration: 'none' }}>
                  <div className="subBox" >
                    {/* <i class="fas fa-user-plus text-warning ml-2 text-s"></i> */}
                    <span className="text-dark text-md font-weight-bold ml-3 mt-4">{t('View All')}</span>
                  </div>
                </Link>
              }
            </Col>
          </Row>
          <Table borderless responsive>
            <tbody>
              {(props.processCustomer.length === 0) ? <tr>
                <td colspan="7" style={{ textAlign: 'center' }}>
                  <Alert variant={'danger'} style={{ marginTop: '30px' }}>
                    {t('No Customers')}
                  </Alert>
                </td>
              </tr> : ''}
              {props.processCustomer.map((list) => (
                <>
                  <tr className="customer-list">
                    <td>
                      <Row>
                        <Col md={3} xs={3}>
                          <Initial
                            name={list?.user?.name}
                            color="#0D5064"
                            seed={5}
                            fontSize={25}
                            height={40}
                            width={40}
                            style={{ borderRadius: '20px', marginRight: '10px', marginBottom: '15px' }}
                          />
                        </Col>

                        <Col md={9} xs={9}>
                          {list?.user?.name}
                        </Col>

                      </Row>

                    </td>
                    {/* <td >{list.user.name}</td> */}
                    {/* <td></td> */}
                    <td>
                      {
                        list.is_password == 0 ?

                          <Link to={"/edit-user/" + list.user_id}>
                            <i class="fas fa-user-plus text-primary  text-xs"></i>
                            <span className="ml-2 text-primary">Invite</span>
                          </Link>
                          : null}
                    </td>
                  </tr>

                </>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      {customerModalShow === true ?
        <CustomerModal customerModalShow={customerModalShow} setCustomerModalShow={setCustomerModalShow} />
        : null
      }
    </>
  );
}
export default CustomerList