import React, { useState } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Topbar from "../components/Topbar/Topbar";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import "../NewInvoice/NewInvoice.css";
import image1 from "../components/images/user2.png";
import image2 from "../components/images/user.png";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import Form from 'react-bootstrap/Form';


const NewInvoice = (props) => {
	const [date, setDate] = useState(new Date());
	const [modalshow, setModalShow] = useState(false);
	return (
		<>
			<div id="wrapper">
				<Sidebar />
				<div id="content-wrapper" className="d-flex flex-column">
					<div id="content">
						<Topbar title={'New Invoice'} />
						<div className="container-fluid full-width">
							<div className="row">
								<div className="col-md-6">
									<div className="card m-overflow-h">
										<div className="card-body m-p-0">
											<div className="row">
												<h4 className="maintittle">New Invoice : INV #081</h4>
												<div className="buttons">
													<button className="button1"> Draft</button>
													<button className="button2">
														<a className="items-center mr-1 btn btn-sm" >
															<i class="far fa-trash-alt"></i>
														</a>
													</button>
												</div>
											</div><hr />
											<div className="row">
												<div className="col-md-6">
													<h6 className="heading">From</h6>
													<div className="card shadow m-overflow-h">
														<div className="card-body m-p-0">
															<div className="row row-img">
																<img src={image1} alt="" />
																<h6 className="heading">Shoo Broo Tho .Inc<br /><p>+62 897 8986 5567</p></h6>
																<a className="text-items-center mr-1 btn btn-md" style={{ color: "#000" }} href>
																	<i class="far fa-edit"></i>
																</a>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-6">
													<h6 className="heading">Bill To</h6>
													<div className="card shadow m-overflow-h">
														<div className="card-body m-p-0">
															<div className="row row-img">
																<img src={image2} alt="" />
																<h6 className="heading">Mhar Yhem .Std<br /><p>+62 897 8986 5567</p></h6>
																<a className="text-items-center mr-1 btn btn-md" style={{ color: "#000" }} href>
																	<i class="far fa-edit"></i>
																</a>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="card2 mt-3">
												<div className="card  py-2" style={{ borderRadius: '15px', height: 'auto' }}>
													<div className="card-body">
														<Table className="table table-borderless align-items-center">
															<thead className="tablehead">
																<tr>
																	<th className="text-lg"><i class="fas fa-sort-amount-down"></i><span style={{ color: "#252A32" }}>File Description</span> </th>
																	<th><i class="far fa-star"></i><span style={{ color: "#252A32" }}>Qty</span></th>
																	<th><i class="far fa-star"></i><span style={{ color: "#252A32" }}>Rate</span></th>
																	<th><i class="far fa-credit-card"></i><span style={{ color: "#252A32" }}>Ammount</span></th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<span className="text-secondary">Design System</span>
																	</td>
																	<td >
																		<span className="text-secondary">2</span>
																	</td>
																	<td >
																		<span className="text-secondary">$80,00</span>
																	</td>
																	<td >
																		<span className="text-secondary">$160,00</span>
																	</td>
																</tr>
																<tr>
																	<td>
																		<span className="text-secondary" >Icon Designing</span>
																	</td>
																	<td>
																		<span className="text-secondary">2</span>
																	</td>
																	<td>
																		<span className="text-secondary">$80,00</span>
																	</td>
																	<td>
																		<span className="text-secondary">$160,00</span>
																	</td>
																</tr>
																<tr>
																	<td>
																		<span className="text-secondary" >WireFraming</span>
																	</td>
																	<td>
																		<span className="text-secondary">2</span>
																	</td>
																	<td>
																		<span className="text-secondary">$80,00</span>
																	</td>
																	<td>
																		<span className="text-secondary">$160,00</span>
																	</td>
																</tr>
																<tr>
																	<td>
																		<span className="text-secondary" >Design System</span>
																	</td>
																	<td>
																		<span className="text-secondary">2</span>
																	</td>
																	<td>
																		<span className="text-secondary">$80,00</span>
																	</td>
																	<td>
																		<span className="text-secondary">$160,00</span>
																	</td>
																</tr>
															</tbody>
														</Table>

														{/* icondesign wireframing */}
													</div>
													<div className="container">
														<div className="row">
															<div className="inputs">
																<input className="input1" type="text" placeholder="Add Description" />
																<input className="input2" type="text" placeholder="0" />
																<input className="input2" type="text" placeholder="0" />
																<input className="input3" type="text" placeholder="0 , 00" />
															</div></div>
														<div className="row">
															<h6 className="bottomtitle">Sub Total<br />Tax (10%)</h6>
															<h6 className="bottomtitle">$280,00<br />$28,00</h6>
															<h6 className="bottomtitle2">Total<br />$308,00</h6>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="card m-overflow-h">
										<div className="card-body m-p-0">

											<Form style={{ fontSize: "15px" }}>
												<h5 style={{ fontWeight: "bold" }}>Basic Info</h5>
												<Form.Label className="headtext">Invoice #</Form.Label>
												<input className="form-control" type="text" placeholder="Invoice #" variant="dark" />
												{/*  */}
												<Form.Label className="headtext">Invoice Date</Form.Label>
												<input className="form-control" type="text" placeholder="23 October 2020" variant="dark" />
												{/*  */}
												<Form.Label className="headtext">Due Date</Form.Label>
												<input className="form-control" placeholder="28 October 2020" type="date" onChange={(e) => setDate(e.target.value)} variant="dark" />
												<br /><hr />
												{/*  */}
												<Form.Label className="headtext">Send Copy To</Form.Label>
												<div className="row">
													<input className="form-control" style={{ width: "80%", marginLeft: "22px" }} type="email" placeholder="example@email.com" variant="dark" />
													<a className="items-center mr-1 btn btn-md" style={{ background: "#FFAF00", color: "#fff" }} href>
														<i class="fas fa-location-arrow"></i>
													</a>
												</div>
												<br /><hr />
												{/*  */}
												<Form.Label className="headtext">Payment Method</Form.Label><br />

												<a className="invoicebtn btn btn-md" href>Send Invoice
													<i class="fas fa-chevron-right"></i>
												</a>
												<div className="row1">
													<div className="buttons">
														<button className="push">Preview</button>
														<button className="push">Download</button>
													</div>
												</div>
											</Form>
										</div>

									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</>
	);
}

export default NewInvoice;
