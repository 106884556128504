import React, { useEffect, useState } from 'react';
import './style.css'
import Footer from "../components/Footer/index.js";
import 'react-rangeslider/lib/index.css'
import { displayError, displaySuccess, getDiscountList, getStoragePrices } from '../Utils/utils';
import Header from '../components/Header/Header';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Pricing = () => {
  const { t } = useTranslation();
  const [pageDiscArr, setPageDiscArr] = useState([])
  const [pageDiscRate, setPageDiscRate] = useState('');
  const [videoDiscArr, setVideoDiscArr] = useState([])
  const [videoDiscRate, setvideoDiscRate] = useState('');
  const [storageDiscArr, setStorageDiscArr] = useState([])
  const [storageDiscRate, setStorageDiscRate] = useState('');
  const [isPricing, setIsPricing] = useState(true);
  const [reload, setReload] = useState(false)
  const navigate = useNavigate();

  const getDiscounts = async () => {
    var res = await getDiscountList();
    setPageDiscArr(res.page)
    setPageDiscRate(res.page[0]?.percentage)
    setvideoDiscRate(res.video[0]?.percentage)
    setStorageDiscRate(res.storage[0]?.percentage)
    setVideoDiscArr(res.video)
    setStorageDiscArr(res.storage)
  }
  useEffect(() => {
    setReload(true)
    if (localStorage.getItem('token')) {
      setIsPricing(true)
    } else {
      setIsPricing(false)
    }
    getDiscounts()
  }, [])
  const gotoContact = () => {
    navigate(`/contact-us`)
  }
  return (
    <>
      <div id="legaltech">

        {/* <TheamHeader /> */}
        {reload &&
          <Header purchase={true} isPricing={isPricing} />}
        <div id="legaltech">
          {/* <div className="container"> */}
          <div className='price-top'>
            <div className='head-text'> {t('WHAT WE ARE')}</div>
            <div className='sub-text'>{t('A business approach to legal service')}</div>
          </div>
          <div className='second-part'>
            <div className='head-part'>{t("You can count on the undivided attention of a team that prizes relationships over profits")}.</div>
            <Row className='price-row  mt-3'>
              <Col md={4} xs={12} className="mt-4">
                <div className='price-card'>
                  <div className='card-top'>
                    <div className='yellow-circle'>
                      <i class="fas fa-trophy"></i>
                    </div>
                    <div className='card-cal'>{t('Storage Calculation')}</div>
                    <div className='card-text mt-2'>{t('Processing Pages')}</div>
                  </div>
                  <Row className='mt-4'>
                    <Col>
                      <div className='price-card-row'>
                        {t('Quantity')}
                      </div>
                    </Col>
                    <Col>
                      <div className='price-card-row'>
                        1000
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col>
                      <div className='price-card-row'>
                        {t('Unit Price')}
                      </div>
                    </Col>
                    <Col>
                      <div className='price-card-row'>
                        0.05
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col>
                      <div className='price-card-row'>
                        Per (%)
                      </div>
                    </Col>
                    <Col>
                      <div className='price-card-row'>
                        2%
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-4 mb-4'>
                    <Col>
                      <div className='price-card-row'>
                        {t('Discount')}
                      </div>
                    </Col>
                    <Col>
                      <div className='price-card-row'>
                        1.00
                      </div>
                    </Col>
                  </Row>
                  <div className='card-bottom'>
                    <Row className='mt-2 mb-2'>
                      <Col>
                        <div className='price-card-row-css'>
                          {t('Price')} (R$)
                        </div>
                      </Col>
                      <Col>
                        <div className='price-card-row-css'>
                          41.00
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col md={4} xs={12} className="mt-4">
                <div className='price-card'>
                  <div className='card-top'>
                    <div className='yellow-circle'>
                      <i class="fas fa-video"></i>
                    </div>
                    <div className='card-cal'>{t('Storage Calculation')}</div>
                    <div className='card-text mt-2'>{t('Processing Videos')}</div>
                  </div>
                  <Row className='mt-4'>
                    <Col>
                      <div className='price-card-row'>
                        {t('Quantity')}
                      </div>
                    </Col>
                    <Col>
                      <div className='price-card-row'>
                        1
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col>
                      <div className='price-card-row'>
                        {t('Unit Price')}
                      </div>
                    </Col>
                    <Col>
                      <div className='price-card-row'>
                        2.50
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col>
                      <div className='price-card-row'>
                        Per (%)
                      </div>
                    </Col>
                    <Col>
                      <div className='price-card-row'>
                        7%
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-4 mb-4'>
                    <Col>
                      <div className='price-card-row'>
                        {t('Discount')}
                      </div>
                    </Col>
                    <Col>
                      <div className='price-card-row'>
                        13.50
                      </div>
                    </Col>
                  </Row>
                  <div className='card-bottom'>
                    <Row className='mt-2 mb-2'>
                      <Col>
                        <div className='price-card-row-css'>
                          {t('Price')} (R$)
                        </div>
                      </Col>
                      <Col>
                        <div className='price-card-row-css'>
                          192.84
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col md={4} xs={12} className="mt-4">
                <div className='price-card'>
                  <div className='card-top'>
                    <div className='yellow-circle'>
                      <i class="fas fa-archive"></i>
                    </div>
                    <div className='card-cal'>{t('Storage Calculation')}</div>
                    <div className='card-text mt-2'>{t('Additional Storage')}</div>
                  </div>
                  <Row className='mt-4'>
                    <Col>
                      <div className='price-card-row'>
                        {t('Quantity')}
                      </div>
                    </Col>
                    <Col>
                      <div className='price-card-row'>
                        1
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col>
                      <div className='price-card-row'>
                        {t('Unit Price')}
                      </div>
                    </Col>
                    <Col>
                      <div className='price-card-row'>
                        79.91
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col>
                      <div className='price-card-row'>
                        Per (%)
                      </div>
                    </Col>
                    <Col>
                      <div className='price-card-row'>
                        7%
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-4 mb-4'>
                    <Col>
                      <div className='price-card-row'>
                        {t('Discount')}
                      </div>
                    </Col>
                    <Col>
                      <div className='price-card-row'>
                        5.59
                      </div>
                    </Col>
                  </Row>
                  <div className='card-bottom'>
                    <Row className='mt-2 mb-2'>
                      <Col>
                        <div className='price-card-row-css'>
                          {t('Price')} (R$)
                        </div>
                      </Col>
                      <Col>
                        <div className='price-card-row-css'>
                          79.91
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className='third-part'>
            <div className='third-text'>{t('Every Person Who Walks Through Our Door Is Important To Us')}.</div>
          </div>
          <div className='price-bottom'>
            {/* <div className='head-text'> WHAT WE ARE</div> */}
            <div className='sub-text'>{t('Committed to Helping Our Clients Succeed')}.</div>
            <div className='bottom-text'>
              Lorem Ipsum. Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auct
            </div>
            <div className='contact-box mt-5' onClick={() => gotoContact()} style={{ cursor: 'pointer' }}>
              <div className='contact-btn-text'>{t('Contact us')}</div>
            </div>
          </div>
          {/* </div> */}
        </div>

      </div>




      <Footer />
    </>
  );
}

export default Pricing;