import React from 'react';
// import Loader from 'react-loader-spinner'
// import { connect } from 'react-redux';
import { Audio, Oval } from 'react-loader-spinner'
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const LoadingSpinner = (props) => {
  return (
    <div className={(props.is_loading === true) ? 'loader_block' : ''}>
      <span>
        {/* <Loader
          type="Bars"
          color=" #26C9D3"
          height={100}
          width={100}
          visible={props.is_loading}
        /> */}
        <Oval
          height={100}
          width={100}
          color="#f3c22e"
          wrapperStyle={{}}
          wrapperClass=""
          visible={props.is_loading}
          ariaLabel='oval-loading'
          secondaryColor="#f3c22e"
          strokeWidth={2}
          strokeWidthSecondary={2}

        />
        {/* <Audio
          height="80"
          width="80"
          radius="9"
          color='green'
          ariaLabel='three-dots-loading'
          wrapperStyle
          wrapperClass
        /> */}
        {/* <Triangle
          height={100}
          width={100}
          color=" #26C9D3"
          ariaLabel="triangle-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={props.is_loading}
        /> */}
      </span>
    </div>
  )
};
// const mapStateToProps = (state) => {
//   return {
//     loader: state.loader,
//   }
// }
export default LoadingSpinner;