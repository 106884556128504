import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Topbar from "../components/Topbar/Topbar";
import Navs from "../components/TopNav/TopNav";
import { useTranslation } from "react-i18next";
import './style.css'
import { Col, Container, Row } from "react-bootstrap";
import userImg from '../components/images/ab.jpg'
import { Initial } from "react-initial";
import { getNotificationList } from "../Utils/utils";
import imgnew from '../components/images/data-not-found-2.svg'
import imgnew2 from '../components/images/data-not-found.svg'
import moment from "moment";
import ReactPaginate from 'react-paginate';
import { useNavigate } from "react-router-dom";

const Notification = (props) => {
  const { t } = useTranslation()
  const [list, setList] = useState([])
  const [pageCount, setPageCount] = useState(1);
  const [current_page, setCurrentPage] = useState(1);
  const navigate = useNavigate()

  const getNotifications = async () => {
    var res = await getNotificationList(current_page);
    setList(res.data)
    setCurrentPage(res.current_page)
    setPageCount(res.last_page)

  }
  useEffect(() => {
    getNotifications()
  }, [])

  const handlePageClick = async (e) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected + 1;
    var res = await getNotificationList(selectedPage);
    setCurrentPage(res.current_page)
    setList(res.data)
    setPageCount(res.last_page)

  };
  const onProcess = (slug) => {
    // console.log("hello")
    navigate(`/process-dashboard/${slug}`)
  }
  return (
    <>
      <div id="wrapper">
        <Navs title={t('User List')} />
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title={t('Notification')} />
            <div className="container-fluid">
              <div className="row justify-content-between my-5 mx-4">
                <div className="col-md-8">
                  <div className="d-flex justify-content-end">
                    <div className="infobutton" style={{ width: '200px' }}>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-12">
                  <div className="card-noti ">
                    <Container>


                      {list.length === 0 ?
                        <div className="no-data-noti">
                          {localStorage.getItem('langauge_selected') === "pt" ? <img src={imgnew2} className="no-data-img" /> :
                            <img src={imgnew} className="no-data-img" />}
                        </ div>
                        : null}

                      {/* {list && list.map((e, index) => ( */}
                      <>
                        {/* <Row className="mt-3"> */}
                        {/* {moment(new Date()).format("DD/MM/YYYY") === moment(new Date()).format("DD/MM/YYYY") ? */}
                        <>
                          {current_page == 1 ?
                            <div className="cell-box">
                              {t('Activity Today')}
                            </div>
                            : null}
                          {list && list.map((e, index) => (
                            <>
                              {moment(new Date()).format("DD/MM/YYYY") === moment(e.created_at).format("DD/MM/YYYY") ?
                                <Row className="mt-3">

                                  <Col md={2} xs={2}>
                                    <div className="img-col">

                                      <div className="img-border">
                                        <div className="img-circle">
                                          {e.sender?.media === null ?
                                            <Initial
                                              color="#0D5064"
                                              name={e?.sender?.name}
                                              seed={5}
                                              fontSize={25}
                                              height={40}
                                              width={40}
                                              style={{ borderRadius: '40px' }}
                                            />
                                            :
                                            <img src={e.sender?.media?.url} />
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={8} xs={10}>
                                    <div className="date-noti">{moment(e.created_at).format("DD/MM/YYYY hh:mm a")}</div>
                                    <div className="title-noti">{t(e.notification_groups?.title)}</div>
                                    <div className="desc-noti">{t(e.notification_groups?.description)} <span onClick={() => onProcess(e.process.slug)}>{e?.process?.name}</span></div>
                                  </Col>
                                </Row>
                                : null}
                            </>
                          ))}
                        </>

                        {/* : */}
                        <>
                          <div className="cell-box"  >
                            {t('Old Activity')}
                          </div>
                          {list && list.map((e, index) => (
                            <>
                              {moment(new Date()).format("DD/MM/YYYY") > moment(e.created_at).format("DD/MM/YYYY") ?
                                <Row className="mt-3">
                                  <Col md={2} xs={2}>
                                    <div className="img-col">

                                      <div className="img-border">
                                        <div className="img-circle">
                                          {e.sender?.media === null ?
                                            <Initial
                                              color="#0D5064"
                                              name={e?.sender?.name}
                                              seed={5}
                                              fontSize={25}
                                              height={40}
                                              width={40}
                                              style={{ borderRadius: '40px' }}
                                            />
                                            :
                                            <img src={e.sender?.media?.url} />
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={8} xs={10}>
                                    <div className="date-noti">{moment(e.created_at).format("DD/MM/YYYY hh:mm a")}</div>
                                    <div className="title-noti">{t(e.notification_groups?.title)}</div>
                                    <div className="desc-noti">{t(e.notification_groups?.description)} <span onClick={() => onProcess(e.process.slug)}>{e?.process?.name}</span></div>
                                  </Col>
                                </Row>
                                : null}
                            </>
                          ))}
                        </>
                        {/* } */}
                        {/* {moment(new Date()).format("DD/MM/YYYY") > moment(e.created_at).format("DD/MM/YYYY") ?
                            <div className="cell-box"  >
                              {t('Old Activity')}
                            </div>
                            :
                            null
                          } */}
                        {/* </Row> */}
                        {/* <Row className="mt-3">
                          <Col md={2} xs={2}>
                            <div className="img-col">

                              <div className="img-border">
                                <div className="img-circle">
                                  {e.sender?.media === null ?
                                    <Initial
                                      color="#0D5064"
                                      name={e?.sender?.name}
                                      seed={5}
                                      fontSize={25}
                                      height={40}
                                      width={40}
                                      style={{ borderRadius: '40px' }}
                                    />
                                    :
                                    <img src={e.sender?.media?.url} />
                                  }
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col md={8} xs={10}>
                            <div className="date-noti">{moment(e.created_at).format("YYYY/MM/DD hh:mm a")}</div>
                            <div className="title-noti">{t(e.notification_groups?.title)}</div>
                            <div className="desc-noti">{t(e.notification_groups?.description)} <span>{e?.process?.name}</span></div>
                          </Col>
                        </Row> */}
                      </>
                      {/* ))} */}


                    </Container>


                    <div className="row mt-3">
                      <div className="mx-auto1 mt-2 d-block">
                        <ReactPaginate
                          previousLabel={t('prev')}
                          nextLabel={t('next')}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={pageCount}
                          marginPagesDisplayed={0}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>

                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default Notification;
