import React from "react";
import './style.css';
import Sidebar from "../components/Sidebar/Sidebar";
import Topbar from "../components/Topbar/Topbar";
import Card from "../components/Card/Card";
import Navs from "../components/TopNav/TopNav";
import { Translate, I18n } from "react-redux-i18n";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();
  return (
    <>
      <div id="wrapper">
        <Navs title={t('Home')} />
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title={t('Home')} />
            <div className="container-fluid full-width">
              <Card />
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
export default Dashboard;