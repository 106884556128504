import React, { useState } from 'react';
// import './styles.css';
import OtpInput from 'react-otp-input';
import { useNavigate, useLocation } from "react-router-dom";
import config from '../config/config';
import axios from "axios";
import { displaySuccess, displayError, displayErrorString } from '../Utils/utils';
import { useTranslation } from 'react-i18next';
import image from '../components/images/logo_trans.png';

const LoginOtp = () => {
  const [otp, setOtp] = useState('');
  const location = useLocation();
  const { t } = useTranslation()
  const handleChange = (otp) => setOtp(otp);

  const OtpLogin = async (e) => {
    if (otp === "") {
      displayErrorString(t("Please enter OTP"))
    } else {

      let path = config.siteUrl;
      await axios.post(path + '/register/verify-otp-2fa', {
        otp: otp,
        email: location?.state?.email
      })
        .then((response) => {
          if (response.data.data === "success") {
            localStorage.setItem('token', localStorage.getItem('tempToken'))
            localStorage.removeItem('tempToken')
            setTimeout(() => window.location.href = "/dashboard", 3000);
            displaySuccess(t('Verified'));
          } else {
            displayErrorString(t("Please Enter Correct Otp"));
          }
        })
        .catch((error) => {
          displayError(error, t);
        });
    }

  };
  return (
    <>
      <div class="form-body">
        <div class="website-logo">
          <a>
            <div class="logo">
              <img class="logo-size" src="images/logo-light.svg" alt="" />
            </div>
          </a>
        </div>
        <div class="row">
          <div class="img-holder">
            <div class="bg"></div>
            <div class="info-holder" style={{ top: '70%' }}>
              <img src={image} />
            </div>
          </div>
          <div class="form-holder">
            <div class="form-content">
              <div class="form-items">
                <div className='mb-4'>{t("Please enter the OTP generated on your Authenticator App")}. <br /> {t("Ensure you submit the current one because it refreshes every 30 seconds")}.</div>
                <label className='mt-2'>{t("One Time Password")}</label>
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  separator={
                    <span style={{ width: '8px' }}>

                    </span>}
                  inputStyle={{
                    width: "50px",
                  }}
                  className="mt-2"
                />

                <button id="submit" type="submit" className="ibtn mt-4" onClick={OtpLogin}>{t("Verify")}</button>
                {/* </Link> */}
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default LoginOtp;