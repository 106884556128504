import React, { useState, useRef } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Topbar from "../components/Topbar/Topbar";
import Navs from "../components/TopNav/TopNav";
import Switch from "react-switch";
import OtpInput from 'react-otp-input';
import { useNavigate, useLocation } from "react-router-dom";
import config from '../config/config';
import axios from "axios";
import { displaySuccess, displayError, displayErrorString } from '../Utils/utils';
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next";

const SettingsOtp = () => {
  const userInfo = useSelector((state) => state.userInfo.value)

  const [otp, setOtp] = useState('');
  const handleChange = (otp) => setOtp(otp);
  const { t } = useTranslation();

  const TwoFactorOtp = async (e) => {
    if (otp === "") {
      displayErrorString(t("Please enter OTP"))
    } else {

      var token = localStorage.getItem('token');
      let path = config.siteUrl;
      await axios.post(path + '/register/verify-otp-2fa', {
        otp: otp,
        email: userInfo.email
      })
        .then((response) => {
          if (response.data.data === "success") {
            displaySuccess(t('Verified'));
            setTimeout(() => window.location.href = "/settings", 3000);
          } else {
            displayErrorString(t("Please Enter Correct Otp"));
          }
        })
        .catch((error) => {
          displayError(error, t);
        });
    }

  };
  return (
    <>
      <div id="wrapper">
        <Navs title={'Settings'} />
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title={'Settings'} />
            <div className="container">
              <div className="row">
                <div className="card border-radius-10 shadow h-100 py-2 mb-3 mt-4" style={{ borderRadius: '15px', marginLeft: '15%' }}>
                  <div className="card-body">
                    {/* <div className="row no-gutters align-items-center">
                      <h4>Two Factor Authentication</h4>
                      <Switch
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={48}
                        className="react-switch  ml-3 "
                        id="material-switch"
                        onChange={() => isTwoFactorEnabled()} checked={is_enabled}
                      />
                    </div> */}
                    {/* <div class="form-holder"> */}
                    {/* {is_enabled === true ? */}
                    {/* <div class="form-holder"> */}
                    <div class="form-content-css mt-3">
                      <div class="form-items">
                        <div className='mb-4'>{t('Please enter the')}  <strong>{t('OTP')}</strong> {t('generated on your Authenticator App')}. <br /> {t('Ensure you submit the current one because it refreshes every 30 seconds')}.</div>

                        <label className='mt-2'>{t('One Time Password')}</label>


                        <OtpInput
                          value={otp}
                          onChange={handleChange}
                          numInputs={6}
                          isInputNum={true}
                          shouldAutoFocus={true}
                          separator={
                            <span style={{ width: '8px' }}>

                            </span>}
                          inputStyle={{
                            width: "50px",
                            height: '50px'
                          }}
                          className="mt-2"
                        />

                        {/* <p>Access to the most powerfull tool in the entire design and web industry.</p> */}
                        {/* <QRCode value="https://www.nicesnippets.com/blog/laravel-9-google-2fa-authentication-tutorial" size={150} />
                < div className="mt-4">You must set up your Google Authenticator app before continuing. You will be unable to login otherwise</div> */}

                        {/* <Link to="/login"> */}
                        <button id="submit" type="submit" className="ibtn mt-4"
                          onClick={TwoFactorOtp}
                        >{t('Verify')}</button>
                        {/* </Link> */}
                      </div>
                    </div>

                  </div>
                  {/* : null} */}
                  {/* </div> */}
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SettingsOtp;