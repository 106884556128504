import React, { useEffect, useState } from 'react';
import "../Purchase/Purchase.css";
import TheamHeader from '../components/TheamHeader/TheamHeader';
import ProgressBar from 'react-bootstrap/ProgressBar';
import image1 from "../components/images/purchase-image.png";
import image2 from "../components/images/work-bg.jpg";
import ab from "../components/images/ab.jpg";
import Footer from "../components/Footer/index.js";
// import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import Topbar from '../components/Topbar/Topbar';
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import { displayError, displaySuccess, getDiscountList, getStoragePrices } from '../Utils/utils';
import Header from '../components/Header/Header';
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table'
import { Col, Row } from 'react-bootstrap';
import config from '../config/config';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const Purchase = () => {
	const { t } = useTranslation();
	const [pageValue, setPageValue] = useState(1000);
	const [videoValue, setVideoValue] = useState(1);
	const [storageValue, setStorageValue] = useState(1);
	const [prices, setPrices] = useState([]);
	const navigate = useNavigate()
	const [pageDiscArr, setPageDiscArr] = useState([])
	const [pageDiscRate, setPageDiscRate] = useState('');
	const [videoDiscArr, setVideoDiscArr] = useState([])
	const [videoDiscRate, setvideoDiscRate] = useState('');
	const [storageDiscArr, setStorageDiscArr] = useState([])
	const [storageDiscRate, setStorageDiscRate] = useState('');
	const [paymentType, setPaymentType] = useState(1);

	const handleChange = value => {
		setPageValue(value)
	};
	const handleChange1 = value => {
		setVideoValue(value)
	};
	const handleChange2 = value => {
		setStorageValue(value)
	};
	const getPrices = async () => {
		var res = await getStoragePrices();
		if (res !== null) {

			setPrices(res)
		}
	}

	useEffect(() => {
		getPrices()
	}, [])
	const totalValues = (val, price) => {
		var total = val * price;
		return total
	}
	const totalValues1 = (val, price) => {
		var total = val * price;
		return total
	}
	const totalValues2 = (val, price) => {
		var total = val * price;
		return total
	}
	const subTotal = (page, vdo, storage) => {
		// var total = totalValues(pageValue, prices && prices.length > 0 ? prices[0].price : null)
		// var total1 = totalValues1(videoValue, prices && prices.length > 0 ? prices[1].price : null)
		// var total2 = totalValues1(storageValue, prices && prices.length > 0 ? prices[2].price : null)
		var sub = parseInt(page) + parseInt(vdo) + parseInt(storage)
		return sub
	}
	const goToCheckout = async () => {

		var token = localStorage.getItem('token');
		let path = config.siteUrl;
		await axios.post(path + '/payment/initiate', {
			units: {
				processing_pages: {
					quantity: pageValue,
					rate: prices && prices.length > 0 ? prices[0].price : null,
					price: parseFloat(finalPagePrice(parseFloat(totalValues(pageValue, prices && prices.length > 0 ? prices[0].price : null)).toFixed(2), parseFloat(discountPrice(parseFloat(totalValues(pageValue, prices && prices.length > 0 ? prices[0].price : null)).toFixed(2), pageDiscRate)).toFixed(1))).toFixed(2),
					// price: parseFloat(totalValues(pageValue, prices && prices.length > 0 ? prices[0].price : null)).toFixed(2),
					discount_percentage: pageDiscRate,
					discount_amount: parseFloat(discountPrice(parseFloat(totalValues(pageValue, prices && prices.length > 0 ? prices[0].price : null)).toFixed(2), pageDiscRate)).toFixed(2)

				},
				processing_video: {
					quantity: videoValue,
					rate: prices && prices.length > 0 ? prices[1].price : null,
					price: parseFloat((finalPagePrice(parseFloat(totalValues1(videoValue, prices && prices.length > 0 ? prices[1].price : null)).toFixed(2), parseFloat(discountPrice(parseFloat(totalValues1(videoValue, prices && prices.length > 0 ? prices[1].price : null)).toFixed(2), videoDiscRate)).toFixed(1)))).toFixed(2),
					discount_percentage: videoDiscRate,
					discount_amount: parseFloat(discountPrice(parseFloat(totalValues1(videoValue, prices && prices.length > 0 ? prices[1].price : null)).toFixed(2), videoDiscRate)).toFixed(2)
				},
				processing_storage: {
					quantity: storageValue,
					rate: prices && prices.length > 0 ? prices[2].price : null,
					price: parseFloat(finalPagePrice(parseFloat(totalValues2(storageValue, prices && prices.length > 0 ? prices[2].price : null)).toFixed(2), parseFloat(discountPrice(parseFloat(totalValues2(storageValue, prices && prices.length > 0 ? prices[2].price : null)).toFixed(2), storageDiscRate)).toFixed(1))).toFixed(2),
					discount_percentage: storageDiscRate,
					discount_amount: parseFloat(discountPrice(parseFloat(totalValues2(storageValue, prices && prices.length > 0 ? prices[2].price : null)).toFixed(2), storageDiscRate)).toFixed(2)
				},
			},
			sub_total: parseFloat(subTotal(
				parseFloat(finalPagePrice(parseFloat(totalValues(pageValue, prices && prices.length > 0 ? prices[0].price : null)).toFixed(2), parseFloat(discountPrice(parseFloat(totalValues(pageValue, prices && prices.length > 0 ? prices[0].price : null)).toFixed(2), pageDiscRate)).toFixed(1))).toFixed(2)
				,
				parseFloat((finalPagePrice(parseFloat(totalValues1(videoValue, prices && prices.length > 0 ? prices[1].price : null)).toFixed(2), parseFloat(discountPrice(parseFloat(totalValues1(videoValue, prices && prices.length > 0 ? prices[1].price : null)).toFixed(2), videoDiscRate)).toFixed(1)))).toFixed(2)
				,
				parseFloat(finalPagePrice(parseFloat(totalValues2(storageValue, prices && prices.length > 0 ? prices[2].price : null)).toFixed(2), parseFloat(discountPrice(parseFloat(totalValues2(storageValue, prices && prices.length > 0 ? prices[2].price : null)).toFixed(2), storageDiscRate)).toFixed(1))).toFixed(2)

			)).toFixed(2),
			purchase_type: paymentType


		}, {
			headers: { 'content-Type': 'application/json', Authorization: `Bearer ${token}` },
		})
			.then((response) => {
				if (paymentType === "1" || paymentType === 1) {
					displaySuccess(t('Transaction Initiated'));
					setTimeout(() => window.location.href = `/checkout/${response.data.data.id}`, 3000);
				} else {
					displaySuccess(t('Purchase link generated successfully'));
					setTimeout(() => window.location.href = `/transaction-list`, 3000);
				}
			})
			.catch((error) => {
				displayError(error, t);
			});
	}

	const getDiscounts = async () => {
		var res = await getDiscountList();
		setPageDiscArr(res.page)
		setPageDiscRate(res.page[0]?.percentage)
		setvideoDiscRate(res.video[0]?.percentage)
		setStorageDiscRate(res.storage[0]?.percentage)
		setVideoDiscArr(res.video)
		setStorageDiscArr(res.storage)
	}
	useEffect(() => {
		getDiscounts()
	}, [])

	const discountPrice = (price, rate) => {
		var res = price * rate / 100
		return res
	}
	const finalPagePrice = (price, disc) => {
		var res = price - disc;
		return res
	}

	const pageDiscountPercent = (val) => {
	}

	useEffect(() => {
		pageDiscArr.map((e) => {
			if (e.from <= pageValue && e.to >= pageValue) {
				setPageDiscRate(e.percentage)
			}
		})
	}, [pageValue])
	useEffect(() => {
		videoDiscArr.map((e) => {
			if (e.from <= videoValue && e.to >= videoValue) {
				setvideoDiscRate(e.percentage)
			}
		})
	}, [videoValue])
	useEffect(() => {
		storageDiscArr.map((e) => {
			if (e.from <= storageValue && e.to >= storageValue) {
				setStorageDiscRate(e.percentage)
			}
		})
	}, [storageValue])
	return (
		<>
			<div id="legaltech">

				{/* <TheamHeader /> */}
				<Header purchase={true} />
				{/* <Topbar title={('Process List')} /> */}

				<div className="Purchase" id="legaltech">
					<div className="container">
						<div className="row">
							<div className="col-md-10">
								<h4 className="topheading1">{t('Purchase Pricing')}</h4>

								<div className="row">
									<div className="col-md-6">
										<div className="point ml-3">
											<div className="my-3">
												<input className="form-check-input boxA" id="radio1" type="radio" name="radio1" value="1"
													onChange={(e) => setPaymentType(e.target.value)} defaultChecked={true}
												/>
												<label className="form-check-label" for="radio1">{t('Acquire For me')}</label>
											</div>
											<div className="">
												<input className="form-check-input boxA" id="radio2" type="radio" name="radio1" value="2"
													onChange={(e) => setPaymentType(e.target.value)} />
												<label className="form-check-label" for="radio2">{t('Generate purchase link')}</label>
											</div>
										</div>
									</div>
									<div className="col-md-8 col-xs-6">
										<div className="progresscard mt-3">
											<div className="card-body">
												<h6>{t('Processing Pages')}</h6>
												<div className='slider'>
													<Slider
														min={pageDiscArr && pageDiscArr[0]?.from}
														max={pageDiscArr && pageDiscArr.slice(-1)[0]?.to}
														value={pageValue}
														tooltip={true}
														step={1000}
														onChange={handleChange}
													/>
												</div>
												<h6>{t('Processing Videos')}</h6>
												<div className='slider1'>
													<Slider
														min={videoDiscArr && videoDiscArr[0]?.from}
														max={videoDiscArr && videoDiscArr.slice(-1)[0]?.to}
														tooltip={true}
														step={1}
														value={videoValue}
														onChange={handleChange1}
													/>
												</div>
												<h6>{t('Additional Storage')}</h6>
												<div className='slider2'>
													<Slider
														min={storageDiscArr && storageDiscArr[0]?.from}
														max={storageDiscArr && storageDiscArr.slice(-1)[0]?.to}
														tooltip={true}
														step={1}
														value={storageValue}
														onChange={handleChange2}
													/>
												</div>
											</div>
										</div>
									</div>

								</div>
								{/*  */}
								<div className="card2 mt-5 mb-3">
									<div className="card " style={{ borderRadius: "none", height: 'auto', background: "#F1F6FA" }}>
										<div className="card-body">
											<Table className="table table-borderless align-items-center" responsive >
												<thead>
													<tr>
														<th>
															<span className="tablehead1" style={{ color: "#FCAD00" }}>{t('Storage Calculation')}</span>
														</th>
														<th><span className="headtable">{t('Quantity')}</span> </th>
														<th><span className="headtable">{t('Unit Price')}</span></th>
														<th><span className="headtable">%</span></th>
														<th><span className="headtable">{t('Discount Price')}</span></th>
														<th><span className="headtable">{t('Price')} (R$)</span></th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>
															<span className="text-Secondary">{t('Processing Pages')}</span>
														</td>
														<td >
															<div className="input-css">
																<input className="inputbtn" type="text" placeholder="5.0" value={pageValue} />
															</div>
														</td>
														<td >
															<span className="text-dark">{prices && prices.length > 0 ? prices[0].price : null}</span>
														</td>
														<td>
															{pageDiscRate}%
														</td>
														<td>
															{parseFloat(discountPrice(parseFloat(totalValues(pageValue, prices && prices.length > 0 ? prices[0].price : null)).toFixed(2), pageDiscRate)).toFixed(2)}
														</td>
														<td >
															<span className="text-dark">
																{parseFloat(finalPagePrice(parseFloat(totalValues(pageValue, prices && prices.length > 0 ? prices[0].price : null)).toFixed(2), parseFloat(discountPrice(parseFloat(totalValues(pageValue, prices && prices.length > 0 ? prices[0].price : null)).toFixed(2), pageDiscRate)).toFixed(1))).toFixed(2)}

															</span>
														</td>
													</tr>
													<tr>
														<td>
															<span className="text-Secondary">{t('Processing Videos')}</span>
														</td>
														<td >
															<div className="input-css">
																<input className="inputbtn" type="text" placeholder="5.0" value={videoValue} />
															</div>
														</td>
														<td >
															<span className="text-dark">{prices && prices.length > 0 ? prices[1].price : null}</span>
														</td>
														<td>
															{videoDiscRate}%
														</td>
														<td>
															{parseFloat(discountPrice(parseFloat(totalValues1(videoValue, prices && prices.length > 0 ? prices[1].price : null)).toFixed(2), videoDiscRate)).toFixed(2)}
														</td>
														<td >
															<span className="text-dark">
																{parseFloat((finalPagePrice(parseFloat(totalValues1(videoValue, prices && prices.length > 0 ? prices[1].price : null)).toFixed(2), parseFloat(discountPrice(parseFloat(totalValues1(videoValue, prices && prices.length > 0 ? prices[1].price : null)).toFixed(2), videoDiscRate)).toFixed(1)))).toFixed(2)}
																{/* {parseFloat(totalValues1(videoValue, prices && prices.length > 0 ? prices[1].price : null)).toFixed(2)} */}
															</span>
														</td>
													</tr>
													<tr>
														<td>
															<span className="text-Secondary">{t('Additional Storage')}</span>
														</td>
														<td >
															<div className="input-css">
																<input className="inputbtn" type="text" placeholder="5.0" value={storageValue} />
															</div>
														</td>
														<td >
															<span className="text-dark">{prices && prices.length > 0 ? prices[2].price : null}</span>
														</td>
														<td>
															{storageDiscRate}%
														</td>
														<td>
															{parseFloat(discountPrice(parseFloat(totalValues2(storageValue, prices && prices.length > 0 ? prices[2].price : null)).toFixed(2), storageDiscRate)).toFixed(2)}
														</td>
														<td >
															<span className="text-dark">
																{parseFloat(finalPagePrice(parseFloat(totalValues2(storageValue, prices && prices.length > 0 ? prices[2].price : null)).toFixed(2), parseFloat(discountPrice(parseFloat(totalValues2(storageValue, prices && prices.length > 0 ? prices[2].price : null)).toFixed(2), storageDiscRate)).toFixed(1))).toFixed(2)}
															</span>
														</td>
													</tr>
													<tr>
														<td>													</td>
														<td>													</td>
														<td>													</td>
														<td >
															<span className="text-warning">{t("Subtotal")} - R$</span>
														</td>
														<td >
														</td>
														<td >
															<span className="text-dark">
																{parseFloat(subTotal(
																	parseFloat(finalPagePrice(parseFloat(totalValues(pageValue, prices && prices.length > 0 ? prices[0].price : null)).toFixed(2), parseFloat(discountPrice(parseFloat(totalValues(pageValue, prices && prices.length > 0 ? prices[0].price : null)).toFixed(2), pageDiscRate)).toFixed(1))).toFixed(2)
																	,
																	parseFloat((finalPagePrice(parseFloat(totalValues1(videoValue, prices && prices.length > 0 ? prices[1].price : null)).toFixed(2), parseFloat(discountPrice(parseFloat(totalValues1(videoValue, prices && prices.length > 0 ? prices[1].price : null)).toFixed(2), videoDiscRate)).toFixed(1)))).toFixed(2)
																	,
																	parseFloat(finalPagePrice(parseFloat(totalValues2(storageValue, prices && prices.length > 0 ? prices[2].price : null)).toFixed(2), parseFloat(discountPrice(parseFloat(totalValues2(storageValue, prices && prices.length > 0 ? prices[2].price : null)).toFixed(2), storageDiscRate)).toFixed(1))).toFixed(2)

																)).toFixed(2)}</span>
														</td>
													</tr>

												</tbody>
											</Table>

										</div>
									</div>
								</div>
								<div className='check-btn-css'>
									<div className='btn btn-warning' onClick={goToCheckout}>
										{t('Checkout')}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<section className='benefits_section about-2'>
				<img src={image2} alr="" className="bg-Image" />

				<div className="bottomside container">
					<div className="mb-3 d-flex g-2">
						<img src={image1} alt="" />
						<h5 className="bottomheading ">{t('Benefits')}</h5>
					</div>

					<Row className='mb-1 align-items-center'>
						<Col md={4}>
							<div className='list_of'>
								<ul className='checklist'>
									<li>
										{t('User')}
									</li>
									<li>{t('Unlimited')}</li>
									<li>{t('Hours Saving')} - &nbsp; <strong> 64:18:00 </strong></li>
									<li>{t('Available Storage GB')} - &nbsp; <strong> 2,98 </strong></li>
									<li>{t('Work in a collaborative way')}</li>

								</ul>
							</div>
						</Col>
						<Col md={4}>
							<div className='list_of'>
								<ul className='checklist'>
									<li>{t('Do research in all files at the same time')}</li>
									<li>{t('Add Notes')}</li>
									<li>{t('Share with your customer')}</li>
									<li>{t('Safe Acess')}</li>
								</ul>
							</div>
						</Col>

						<Col md={4}>
							<img src={ab} alr="" className="img-fluid" />
						</Col>
					</Row>
				</div>
			</section>

			<Footer />
		</>
	);
}

export default Purchase;