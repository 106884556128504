import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Topbar from "../components/Topbar/Topbar";
// import { Table, thead, tbody, tr, th, td } from 'react-super-responsive-table'
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import ReactPaginate from 'react-paginate';
import { Modal, Table } from 'react-bootstrap';
import config from "../config/config";
import Button from 'react-bootstrap/Button';
import axios from "axios";
import { displayError, displaySuccess, getFileListDetail, processFileById } from "../Utils/utils";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import AddNotesModal from "../components/Modal/AddNotesModal";
import SweetAlert from 'react-bootstrap-sweetalert';
import LoadingSpinner from "../components/LoadingSpinner";
import Moment from "react-moment";
import defaultImg from "../image/new-not-found-2.svg";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import './FileList.css'
import Navs from "../components/TopNav/TopNav";

const FileList = (props) => {
  const { t } = useTranslation();
  const [is_loading, setIsloading] = useState(false);
  const [searchModalshow, setSearchModalShow] = useState(false);
  const [notesModalShow, setNotesModalShow] = useState(false);
  const [filedetail, setFileDetail] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [current_page, setCurrentPage] = useState(1);
  const [fileId, setFileId] = useState('');
  const navigate = useNavigate()
  const [is_delete, setIsDelete] = useState(false);
  const [temp_id, setTempId] = useState('');
  const [search, setSearch] = useState('');
  const [fileSearch, setFileSearch] = useState('');
  const [fileStatus, setFileStatus] = useState(false);
  const [user_role, setUserRole] = useState('');
  const [fileType, setFileType] = useState('');
  const [summaryModal, setSummaryModal] = useState(false)
  const [isSummary, setIsSummary] = useState(false);
  const [isProcess, setIsProcess] = useState(false);
  const [isGenerate, setIsGenerate] = useState(false);
  // const [id, setId] = useState([]);
  const { id } = useParams()
  const location = useLocation();
  const handleClose = () => {
    setSearchModalShow(false)
  }
  const handleCloseSummary = () => {
    setSummaryModal(false)
  }

  const getFilesList = async (id) => {
    setIsloading(true)
    var var_file = await getFileListDetail(id, current_page);
    setFileDetail(var_file.files.data)
    setUserRole(var_file.user_role)
    setPageCount(var_file.files.last_page)
    setIsloading(false)
  }

  useEffect(() => {
    getFilesList(id);
  }, [pageCount])

  const deleteFile = async (temp_id) => {
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/auth/files/delete/' + temp_id, {

    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(async (response) => {
        displaySuccess(t('File Delete Successfully'));
        getFilesList(id);

      })
      .catch((error) => {
        displayError(error, t);
      });
  }
  const handlePageClick = async (e) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected + 1;
    var var_file = await getFileListDetail(id, selectedPage);
    setFileDetail(var_file.files.data)
    setPageCount(var_file.files.last_page)
  };
  const goToNotes = (noteId) => {
    navigate(`/notes-list/${noteId}`, {
      state: {
        fileId: id
      }
    })
  }

  const searchApi = () => {
    if (isSummary === true) {
      navigate(`/video-reader/${id}?file_id=${fileId}&&summary=true`, {
        state: {
          search: search,
          key: 'Search'
        }
      })
    } else {

      navigate(`/video-reader/${id}?file_id=${fileId}`, {
        state: {
          search: search,
          key: 'Search'
        }
      })
    }

  }
  const summaryAPi = () => {
    navigate(`/video-reader/${id}?file_id=${fileId}&&summary=true`, {
      state: {
        search: search,
        key: 'Summary'
      }
    })
  }
  const onSummaryDowm = (event) => {
    if (event.keyCode == 13 && !event.shiftKey) {
      summaryAPi()
    }
  }
  const onKeyDown = (event) => {
    if (event.keyCode == 13 && !event.shiftKey) {
      searchApi()
    }
  }
  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const searchhandle = async (e) => {
    setIsloading(true)
    var var_file = await getFileListDetail(id, current_page, e);
    setFileDetail(var_file.files.data)
    // setPageCount(var_file.files.last_page)
    setIsloading(false)
  }
  const downloadAll = (urls) => {
    urls.map((data) => {
      var file = data.media.original_name;
      var csvData = 'data:application/mp4;charset=utf-8,' + encodeURIComponent(data);
      var downloadLink = document.createElement("a");
      downloadLink.href = csvData;
      downloadLink.download = file;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    })
  }
  const processFiles = async (idd) => {
    setIsloading(true)
    var var_process = await processFileById(idd);
    displaySuccess(t("File Processed Successfully"))
    getFilesList(id);
    setIsloading(false)
  }
  const goToAddFile = (id) => {
    navigate(`/add-files/${id}`, {
      state: {
        process_id: id
      }
    })
  }

  const getSummary = async (file) => {
    setIsloading(true)
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/auth/generate-summary', {
      process_file_id: file,
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setIsloading(false)
        displaySuccess(response?.data?.data)
        getFilesList(id);

      })
      .catch((error) => {
        displayError(error, t);
      });
  }
  return (
    <>
      <div id="wrapper">
        <Navs title={t('File List')} />

        <LoadingSpinner is_loading={is_loading} />
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title={t('File List')} />
            <div className="container-fluid full-width">
              <div className="d-sm-flex align-items-center justify-content-between mt-1 mb-2">
                <div className="col">
                </div>

                <div className="col-auto ml-auto d-none d-sm-inline-block d-block d-sm-none">
                  <div className="form-row">
                    <Link to="/process-list">
                      <div className=" col back-btn mt-2">
                        <div className="text-light back-txt" >
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width={20} stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                          </svg>
                          {t('Back')}</div>
                      </div>
                    </Link>
                    {localStorage.getItem('role_id') == 2 || localStorage.getItem('role_id') == 4 ? null :
                      <div className="infobutton mt-2" onClick={() => {
                        // downloadAll(filedetail)
                      }}>
                        <div className=" btn btn-light" style={{ background: "#ffaf00", color: "#fff", borderRadius: "12px", marginLeft: "5px" }} onClick={() => {
                          localStorage.removeItem('process_slug')
                          goToAddFile(id)
                        }}>
                          {t('Add File')}</div>
                      </div>}

                    <div className="col mt-2">
                      <div className="infobutton">
                        <input className="form-control" id="validationCustom01" type="text" placeholder={t("search")} required="" data-bs-original-title="" title=""
                          value={fileSearch}
                          onChange={(e) => {
                            setFileSearch(e.target.value)
                            searchhandle(e.target.value)
                          }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-12">
                  <div className="card m-overflow-h">
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <Table responsive>
                          <thead>
                            <tr style={{ textAlign: 'center' }}>
                              <th >{t('File Name')}</th>
                              <th >{t('Last Modified')}</th>
                              <th >{t('Status ')}</th>
                              {/* <th > {t('ChatGPT Summarised')}</th> */}
                              <th >{t('Notes')}</th>
                              <th>{t('File Size')}</th>
                              {/* <th >{t('ChatGPT Action')}</th> */}
                              <th >{t('Action')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(filedetail.length === 0) ? <tr>
                              <td colspan="7" style={{ textAlign: 'center' }}>
                                <img src={defaultImg} style={{ maxWidth: '420px' }} className="defaultImg" />
                              </td>
                            </tr> : ''}
                            {filedetail.map((list) => (
                              <tr style={{ textAlign: 'center' }}>
                                <td className="table-width">
                                  <div className="">
                                    {list.status === "processed" && list.summary == null ?
                                      <Link to={'/video-reader/' + id + '?file_id=' + list.id} className="pro-name">
                                        {list.media.original_name}
                                      </Link>
                                      :
                                      list.status === "processed" && list.summary !== null ?
                                        <Link to={'/video-reader/' + id + '?file_id=' + list.id + '&&summary=true'} className="pro-name">
                                          {list.media.original_name}
                                        </Link>
                                        :
                                        <div style={{ cursor: 'pointer' }} className="pro-name" onClick={() => setFileStatus(true)}>
                                          {list.media.original_name}
                                        </div>
                                    }
                                  </div></td>
                                <td>
                                  <Moment format="DD/MM/YYYY">
                                    {list.updated_at}
                                  </Moment></td>
                                {list.status === "pending" ?
                                  <td style={{ color: "#ff3b3b", fontWeight: "bold" }}>{t(capitalizeFirst(list.status))}</td>
                                  :
                                  list.status === "queued"
                                    ?
                                    <td style={{ color: "#FDE802", fontWeight: "bold" }}>{t(capitalizeFirst(list.status))}</td>
                                    :
                                    <td style={{ color: "#4CF610", fontWeight: "bold" }}>{t(capitalizeFirst(list.status))}</td>}

                                {/* <td>
                                  <div className="row">
                                    <div className="items-center mr-1" style={{ color: "#000", width: '100%', textAlign: 'center' }}>
                                      {list.summary === null ? t("No") : t("Yes")}
                                    </div>

                                  </div>
                                </td> */}
                                <td>
                                  <div className="row display-center">
                                    <div className="items-center mr-1" style={{ color: "#000", fontWeight: "bold" }}>
                                      {list.notes_count}
                                    </div>
                                    <div className="items-center mr-3 " style={{ color: "#6418C3", fontSize: "20px", cursor: 'pointer' }}
                                      onClick={() => goToNotes(list.id)} >
                                      <i class="far fa-comment-dots comment ml-1" title="View Notes"></i>
                                    </div>
                                  </div>
                                </td>
                                <td> {(list.media.size / 1048576).toFixed(2)} Mb</td>
                                {/* <td>
                                  <div className="row display-center">
                                    {list.status === "processed" && list.summary === null ?
                                      <div className="items-center mr-3 btn-outline-warning btn btn-sm "

                                        onClick={() => {
                                          setIsGenerate(true)

                                          setFileId(list.id)
                                          // setSummaryModal(true)
                                        }}
                                        title={t("Generate Summary")}
                                      >

                                        <i class="fas fa-sync"></i>
                                      </div>
                                      : null}
                                    {list.summary !== null ?

                                      <div className="items-center mr-3 btn-outline-info btn btn-sm"
                                        onClick={() => {
                                          setFileId(list.id)
                                          setSummaryModal(true)
                                        }}
                                        title={t("Search Summary")}
                                      >

                                        <i class="fas fa-list-alt"></i>
                                      </div>
                                      : null}
                                    {list.status === "processed" ?
                                      <div className="items-center mr-3  btn-outline-success btn btn-sm"
                                        onClick={() => {
                                          if (list.summary === null) {
                                            setIsSummary(false)
                                          } else {
                                            setIsSummary(true)
                                          }
                                          setFileId(list.id)
                                          setSearchModalShow(true)
                                        }}
                                        title={t("Search")}
                                      >

                                        <i class="fas fa-search"></i>
                                      </div>
                                      : null}


                                    {localStorage.getItem('role_id') == 2 ? null :

                                      list.status === "pending" ?
                                        <div className="items-center mr-1  btn-outline-dark btn btn-sm" title={t("Process Files")}
                                          onClick={() => {
                                            setFileId(list.id)
                                            setIsProcess(true)
                                          }}
                                        >
                                          <i class="fas fa-file-import"></i>
                                        </div>
                                        : null
                                    }
                                  </div>
                                </td> */}
                                <td>
                                  <div className="row display-center">
                                    {localStorage.getItem('role_id') == 2 ? null :

                                      list.status === "pending" ?
                                        <div className="items-center mr-1  btn-outline-dark btn btn-sm" title={t("Process Files")}
                                          onClick={() => {
                                            setFileId(list.id)
                                            setIsProcess(true)
                                          }}
                                        >
                                          <i class="fas fa-file-import"></i>
                                        </div>
                                        : null
                                    }

                                    {list.status === "processed" ?
                                      <div className="items-center mr-3  btn-outline-success btn btn-sm"
                                        onClick={() => {
                                          if (list.summary === null) {
                                            setIsSummary(false)
                                          } else {
                                            setIsSummary(true)
                                          }
                                          setFileId(list.id)
                                          setSearchModalShow(true)
                                        }}
                                        title={t("Search")}
                                      >

                                        <i class="fas fa-search"></i>
                                      </div>
                                      : null}
                                    {list.status === "processed" ?
                                      user_role === "Customer" || user_role === "Contributor" ? null :
                                        <a href={list.media.url} target='_blank' download={list.media.original_name}>
                                          <div className="items-center mr-3 btn-outline-primary btn btn-sm"
                                            title={t("Download")}
                                          >
                                            <i class="fas fa-download"></i>
                                          </div>
                                        </a>
                                      : null}
                                    <div className="items-center mr-1 btn-outline-secondary btn btn-sm" title={t("Add Note")}
                                      onClick={() => {
                                        setNotesModalShow(true)
                                        setFileId(list.id)
                                        setFileType(list.media.type)
                                        // setProcessId(list.id)
                                      }}
                                    >
                                      <i class="fas fa-sticky-note"></i>
                                    </div>
                                    {list.status === "pending" ?
                                      <div className="items-center mr-1 btn-outline-danger btn btn-sm" title={t("Delete File")}
                                        onClick={() => {
                                          setFileId(list.id)
                                          setFileType(list.media.type)
                                          setIsDelete(true)
                                          // setProcessId(list.id)
                                        }}
                                      >
                                        <i class="fas fa-trash"></i>
                                      </div>
                                      : null
                                    }
                                    {/* {localStorage.getItem('role_id') == 2 ? null :

                                      list.status === "pending" ?
                                        <div className="items-center mr-1  btn-outline-dark btn btn-sm" title={t("Process Files")}
                                          onClick={() => {
                                            processFiles(list.id)
                                          }}
                                        >
                                          <i class="fas fa-file-import"></i>
                                        </div>
                                        : null
                                    } */}
                                  </div>
                                </td>
                              </tr>

                            ))}

                          </tbody>
                        </Table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="mx-auto d-block my-3">
                        <ReactPaginate
                          previousLabel={t('prev')}
                          nextLabel={t('next')}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={pageCount}
                          marginPagesDisplayed={0}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          style={{ background: "#FF844B", color: "#fff", borderRadius: "15px" }}
                        />
                      </div>
                    </div>
                    {is_delete === true ? (
                      <SweetAlert
                        warning
                        showCancel
                        confirmBtnText={t("Yes")}
                        confirmBtnBsStyle="danger"
                        title={t('Are you sure?')}
                        onConfirm={(e) => {
                          deleteFile(fileId)
                          setIsDelete(false)
                          // window.location.reload(false)
                        }}
                        onCancel={(e) => {
                          setIsDelete(false)
                        }}
                        focusCancelBtn
                      >
                        {t("Do you want to delete this File?")}
                      </SweetAlert>
                    ) : ([]
                    )}
                    {fileStatus === true ? (
                      <SweetAlert
                        warning
                        // showCancel
                        confirmBtnText="OK"
                        confirmBtnBsStyle="warning"
                        title={('')}
                        onConfirm={(e) => {
                          setFileStatus(false)
                        }}
                      >
                        {t('This File is not processed yet . once it will processed you will be able to review.')}
                      </SweetAlert>
                    ) : ([]
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {searchModalshow === true ? */}
        <Modal show={searchModalshow} >
          <Modal.Header >
            <Modal.Title>{t('Search')}</Modal.Title>
            <div className="close" closeButton onClick={() => handleClose()}>

              <i class="far fa-times-circle"></i>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="process">
              {/* <Form> */}
              <input className="form-control" placeholder={t('Search')} style={{ borderRadius: '18px' }}
                value={search} onChange={(e) => setSearch(e.target.value)} onKeyDown={onKeyDown} />
              {/* </Form> */}
              <br />
              {/* <Link to="/video-reader"> */}
              <Button variant="warning" className="button" type="submit" style={{ borderRadius: "12px" }} onClick={() => searchApi()}>
                <span style={{ color: "#fff", marginRight: "12px", marginLeft: "12px" }}>{t('Submit Now')}</span>
              </Button>
              {/* </Link> */}
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={summaryModal} >
          <Modal.Header >
            <Modal.Title>{t('Search')}</Modal.Title>
            <div className="close" closeButton onClick={() => handleCloseSummary()}>

              <i class="far fa-times-circle"></i>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="process">
              {/* <Form> */}
              <input className="form-control" placeholder={t('Search')} style={{ borderRadius: '18px' }}
                value={search} onChange={(e) => setSearch(e.target.value)} onKeyDown={onSummaryDowm} />
              {/* </Form> */}
              <br />
              {/* <Link to="/video-reader"> */}
              <Row>
                <Col>
                  <Button variant="warning" className="button" type="submit" style={{ borderRadius: "12px" }} onClick={summaryAPi}>
                    <span style={{ color: "#fff", marginRight: "12px", marginLeft: "12px" }}>{t('Submit Now')}</span>
                  </Button></Col>
                <Col className="display-end">
                  <Button variant="warning" className="button " type="submit" style={{ borderRadius: "12px" }} onClick={summaryAPi}>
                    <span style={{ color: "#fff", marginRight: "12px", marginLeft: "12px" }}>{t('View Summary')}</span>
                  </Button>
                </Col>
              </Row>


              {/* </Link> */}
            </div>
          </Modal.Body>
        </Modal>

        {/* : null} */}

        {notesModalShow === true ?
          <AddNotesModal
            notesModalShow={notesModalShow}
            setNotesModalShow={setNotesModalShow}
            process_id={id}
            fileId={fileId}
            getFilesList={() => getFilesList(id)}
            fileType={fileType}
          />
          : null}
        {isProcess === true ? (
          <SweetAlert
            warning
            showCancel
            cancelBtnText={t('Cancel')}
            confirmBtnText={t("Yes")}
            confirmBtnBsStyle="warning"
            title={t('Are you sure?')}
            onConfirm={(e) => {
              processFiles(fileId)
              // deleteProduct(temp_id)
              setIsProcess(false)
              // window.location.reload(false)
            }}
            // onClick={() => {
            //   setIsProcess(true)
            //   setTempId('')
            // }}
            onCancel={(e) => {
              setIsProcess(false)
            }}
            focusCancelBtn
          >
            {t('Do you want to process this file?')}
          </SweetAlert>
        ) : ([]
        )}
        {isGenerate === true ? (
          <SweetAlert
            warning
            showCancel
            cancelBtnText={t('Cancel')}
            confirmBtnText={t("Yes")}
            confirmBtnBsStyle="warning"
            title={t('Are you sure?')}
            onConfirm={(e) => {
              getSummary(fileId)
              // processFiles(fileId)
              // deleteProduct(temp_id)
              setIsGenerate(false)
              // window.location.reload(false)
            }}
            // onClick={() => {
            //   setIsProcess(true)
            //   setTempId('')
            // }}
            onCancel={(e) => {
              setIsGenerate(false)
            }}
            focusCancelBtn
          >
            {t('Do you want to generate this file?')}
          </SweetAlert>
        ) : ([]
        )}
      </div >
    </>
  );
}

export default FileList;