import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import img1 from '../images/placeholder.png';
import { Translate, I18n } from "react-redux-i18n";
import { useTranslation } from "react-i18next";
import { latestNotesProcess } from "../../Utils/utils";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import Alert from 'react-bootstrap/Alert'


const Notes = (props) => {
  const { t } = useTranslation();
  const [notes, setNotes] = useState([]);
  const navigate = useNavigate()

  const gotoNotes = (list) => {
    navigate(`/notes-list/${list}`)
  }
  return (
    <>
      <div className="card mt-3 shadow " style={{ borderRadius: '15px', height: 'auto' }}>
        <div className="card-body">
          <div className="row">
            <div className="col">
              <span className="font-weight-bold" style={{ fontSize: '18px' }}>{t('Latest Notes')}</span>
            </div>
            <div className="col">
              {/* <Link to="/notes-list">
                <div className="subBox" style={{ marginLeft: '40%' }}>
                  <span className="text-dark text-md font-weight-bold ml-3 mt-4">{t('View all')}</span>
                </div>
              </Link> */}
            </div>


          </div>
          {props.notes.length == 0 ?
            <Tr>
              <Td colspan="7" style={{ textAlign: 'center', width: '600px' }}>
                <Alert variant={'danger'}>
                  <span className="text-light font-weight-bold mt-3">{t("No Notes")}</span>
                </Alert>
              </Td>
            </Tr> : null}
          {props.notes.map((list) => (

            <div className="row note mb-2 mt-2"  >
              <div className="col-xl-2 col-md-3">
                <div className="star-box">
                  <i class="fas fa-star star"></i>
                </div>
              </div>
              <div className="col-xl-8 col-md-6" onClick={() => gotoNotes(list.file_id)} style={{ cursor: 'pointer' }}>
                {/* <span className="text-s" style={{ color: '#A5A5A5' }} >kevinhard@mail.com</span> */}
                <div className="font-weight-bold text-s mb-1 mt-1" style={{ fontSize: '14px' }}>{list.title}</div>
                <div style={{ fontSize: '14px' }}>{list.description}</div>
              </div>
              {/* <div className="col-xl-2 col-md-3">
                <div>
                  <i class="mt-3 fas fa-clock text-lg" style={{ color: '#A5A5A5' }}></i>
                </div>
                <div>
                  <i class="fas fa-paperclip text-lg mt-3 text-primary" ></i>

                </div>

              </div> */}
            </div>
          ))}

        </div>
      </div>
    </>
  );
}
export default Notes