import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import config from "../../config/config";
import axios from "axios";
import { displayError, displaySuccess, getCustomerList, getEditProcess, getProcessList } from "../../Utils/utils";
import { Translate, I18n } from "react-redux-i18n";
import { useTranslation } from "react-i18next";

const EditProcessModal = (props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [process_name, setProcessName] = useState('')
  const [description, setDescription] = useState('');
  const [id, setId] = useState('');
  const handleClose = () => {
    props.setEditProcessModal(false)
  }
  const getDetail = async () => {
    var edit_var = await getEditProcess(props.processSlug);
    setId(edit_var.id);
    setDescription(edit_var.description);
    setProcessName(edit_var.name)
  }
  useEffect(() => {
    getDetail();
  }, [])

  const onFormSubmit = async () => {
    // props.toggleLoader(true);
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/process/edit-process/' + id, {
      name: process_name,
      description: description
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        displaySuccess(t('Process edited Successfully!'));
        props.setEditProcessModal(false)
        props.getProcess();
        // props.toggleLoader(false);
        // setTimeout(() => window.location.href = "/reset-password", 3000);
      })
      .catch((error) => {
        displayError(error, t);
        // props.toggleLoader(false);
      });
  };
  return (
    <>
      <Modal show={props.editProcessModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> {t('Edit Process')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="process">
            <div className="mb-3" controlId="formBasicEmail">
              <label>{t('Process Name')} <span style={{ color: "red" }}>*</span></label>
              <input className="form-control" type="text" placeholder="" onChange={e => setProcessName(e.target.value)}
                value={process_name} />
              <br />
              <label> {t('Process Description')}</label>
              <textarea className="form-control" type="text" placeholder="" onChange={e => setDescription(e.target.value)}
                value={description} />
            </div>
            <Button variant="warning" className="button" type="submit"
              onClick={onFormSubmit}
              style={{ borderRadius: "10px" }}>{t('Submit')}</Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default EditProcessModal;
