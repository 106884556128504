import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect, useState } from 'react';
import { getUserData } from './actions/userdata'
import './css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toggleLoader } from './actions/loader';
import { connect } from 'react-redux';
import { userLoggedIn } from './Utils/utils'
import { GoogleOAuthProvider } from '@react-oauth/google';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import LoadingSpinner from './components/LoadingSpinner';
import { useSelector, useDispatch } from 'react-redux'
import { update } from "./features/userInfoSlice";

import Login from "./Login/index";
import Register from "./Register/index";
import ForgotPassword from "./ForgotPassword/index";
import ResetPassword from "./ResetPassword/index";
import TermsOfServices from "../src/TermsOfServices/TermsOfServices";
import PrivacyPolicy from "../src/PrivacyPolicy/PrivacyPolicy";
import Home from "./Home/index";
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "./Dashboard";
import Verify from "./VerifyEmail";
import ProcessList from "./ProcessList";
import ProcessDashboard from "./ProcessDashboard";
import FileList from "./FileList";
import UserList from "./UserList";
import CustomerList from "./CustomerList";
import VideoReader from "./VideoReader";
import NotesList from "./NotesList";
import AddNewCustomer from './AddNewCustomer'
import AddFiles from "./AddFiles";
import Profile from "./Profile";
import NewInvoice from "./NewInvoice";
import Purchase from './Purchase';
import ContactUs from './ContactUs';
import Settings from './Settings';
import EditUser from './EditUser';
import TwoFactorProcessOne from "./TwoFactorProcessOne";
import TwoFactorProcessTwo from "./TwoFactorProcessTwo";
import LoginOtp from "./LoginOtp";
import SettingsOtp from "./SettingsOtp";
import UploadFiles from "./UploadFiles";
import "./I10n/i18n"
import { setLocaleWithFallback } from './actions/i18n'
import UserManagement from "./CustomerList";
import ChangePassword from "./ChangePassword";
import Checkout from "./Checkout";
import CheckoutPurchase from "./CheckoutPurchase";
import Transaction from "./Transaction";
import Notification from "./Notification";
import Pricing from "./Pricing";
<GoogleOAuthProvider clientId="<your_client_id>">...</GoogleOAuthProvider>;

function App(props) {
  const [default_language, setDefaultLanguage] = useState('pt');
  const dispatch = useDispatch()

  useEffect(async () => {
    // props.toggleLoader(true);
    // var auth_me = await userLoggedIn()
    // dispatch(update(auth_me))
    if (localStorage.getItem('langauge_selected') !== undefined && localStorage.getItem('langauge_selected') !== null) {
      setDefaultLanguage(localStorage.getItem('langauge_selected'));
      // props.setLocaleWithFallback(localStorage.getItem('langauge_selected'));
    } else {
      setDefaultLanguage("pt");
      // props.setLocaleWithFallback("pt");
      localStorage.setItem('langauge_selected', "pt");
    }
    document.dir = props.locale === "en" ? "en" : "pt";
  }, [props.locale])

  return (
    <>
      <GoogleOAuthProvider clientId="279195570031-p2hhk03au0k02j0fp6aqh6q3847o1u9c.apps.googleusercontent.com">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/" element={<Home />} />
          <Route path="/termsofservices" element={<TermsOfServices />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route exact path="/verify" element={<Verify />} />
          {/* <ProtectedRoute exact path="/dashboard" element={<Dashboard />} /> */}
          <Route exact path='/dashboard' element={<ProtectedRoute />}>
            <Route exact path='/dashboard' element={<Dashboard />} />
          </Route>
          <Route exact path='/process-list' element={<ProtectedRoute />}>
            <Route exact path='/process-list' element={<ProcessList />} />
          </Route>
          <Route exact path='/process-dashboard/:id' element={<ProtectedRoute />}>
            <Route exact path='/process-dashboard/:id' element={<ProcessDashboard />} />
          </Route>
          <Route exact path='/file-list/:id' element={<ProtectedRoute />}>
            <Route exact path='/file-list/:id' element={<FileList />} />
          </Route>
          <Route exact path='/customer-list' element={<ProtectedRoute />}>
            <Route exact path='/customer-list' element={< UserList />} />
          </Route>
          <Route exact path='/user-list' element={<ProtectedRoute />}>
            <Route exact path='/user-list' element={< UserManagement />} />
          </Route>
          {/* <Route exact path='/customer-list' element={<ProtectedRoute />}>
            <Route exact path='/customer-list' element={< CustomerList />} />
          </Route> */}
          <Route exact path='/notes-list/:id' element={<ProtectedRoute />}>
            <Route exact path='/notes-list/:id' element={< NotesList />} />
          </Route>
          <Route exact path='/video-reader/:id' element={<ProtectedRoute />}>
            <Route exact path='/video-reader/:id' element={< VideoReader />} />
          </Route>
          <Route exact path='/addnewcustomer' element={<ProtectedRoute />}>
            <Route exact path='/addnewcustomer' element={< AddNewCustomer />} />

          </Route>
          <Route exact path='/add-files/:id' element={<ProtectedRoute />}>
            <Route exact path='/add-files/:id' element={< AddFiles />} />
          </Route>
          <Route exact path='/profile' element={<ProtectedRoute />}>
            <Route exact path='/profile' element={< Profile />} />
          </Route>
          <Route exact path='/newinvoice' element={<ProtectedRoute />}>
            <Route exact path='/newinvoice' element={< NewInvoice />} />
          </Route>
          <Route exact path='/purchase' element={<ProtectedRoute />}>
            <Route exact path='/purchase' element={< Purchase />} />
          </Route>
          <Route exact path='/contact-us' element={< ContactUs />} />
          <Route exact path="/2fa-initiate" element={<TwoFactorProcessOne />} />
          <Route exact path="/2fa-verify" element={<TwoFactorProcessTwo />} />
          <Route exact path="/loginotp" element={<LoginOtp />} />
          <Route exact path='/settings' element={<ProtectedRoute />}>
            <Route exact path='/settings' element={< Settings />} />
          </Route>
          <Route exact path='/edit-user/:id' element={<ProtectedRoute />}>
            <Route exact path='/edit-user/:id' element={< EditUser />} />
          </Route>
          <Route exact path='/setting-otp' element={<ProtectedRoute />}>
            <Route exact path='/setting-otp' element={< SettingsOtp />} />
          </Route>
          <Route exact path='/uploadfiles' element={<ProtectedRoute />}>
            <Route exact path='/uploadfiles' element={< UploadFiles />} />
          </Route>
          <Route exact path='/changepassword' element={<ProtectedRoute />}>
            <Route exact path='/changepassword' element={< ChangePassword />} />
          </Route>
          <Route exact path='/checkout/:id' element={<ProtectedRoute />}>
            <Route exact path='/checkout/:id' element={< Checkout />} />
          </Route>
          <Route exact path='/payment/complete/:id' element={<ProtectedRoute />}>
            <Route exact path='/payment/complete/:id' element={< CheckoutPurchase />} />
          </Route>
          <Route exact path='/transaction-list' element={<ProtectedRoute />}>
            <Route exact path='/transaction-list' element={< Transaction />} />
          </Route>
          <Route exact path='/notification' element={<ProtectedRoute />}>
            <Route exact path='/notification' element={< Notification />} />
          </Route>
          {/* <Route exact path='/pricing' element={<ProtectedRoute />}> */}
          <Route exact path='/pricing' element={< Pricing />} />
          {/* </Route> */}
        </Routes >
        {/* <LoadingSpinner /> */}

      </GoogleOAuthProvider >
    </>
  )
}

const mapDispatchToProps = {
  getUserData,
  toggleLoader,
  // setLocaleWithFallback
}
const mapStateToProps = (state) => {
  return {
    userdata: state.userdata,
    // locale: state.i18n.locale
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);