import React, { useEffect, useState } from 'react';
import image1 from '../images/logo_main.png';
import image2 from '../images/logo_trans.png';
import '../../css/style.css';
import { userLoggedIn } from "../../Utils/utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Translate, I18n } from "react-redux-i18n";
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

const Header = (props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const userLoginFunction = async () => {
    var final_data = await userLoggedIn()
    if (final_data !== null) {
      setLoggedIn(true)
    } else {
      navigate('/')
      setLoggedIn(false)
    }
    // }
  }
  useEffect(() => {
    const handleScroll = (e) => {
      let currentScrollY = window.scrollY;
      const pledgeSection = document.getElementById("legaltech");
      if (currentScrollY + 80 > pledgeSection.offsetTop) {
        setShow(true);
      } else {
        setShow(false);
      }
    }

    if (props.isHeader === false || props.isPricing === true) {
      userLoginFunction();
    } else if (location.state !== null) {
      if (location.state?.isPricing === true) {
        userLoginFunction();
      }
    } else {
    }
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <header className={show === true || props.purchase == true ? 'sticky-header navigation fixed-top' : 'navigation fixed-top'}>
        <div class="container">
          {/* <!-- main nav --> */}
          <nav class="navbar navbar-expand-lg navbar-light">
            {/* <!-- logo --> */}
            <a class="navbar-brand logo">
              <img class="logo-default" src={image2} alt="logo" />
              {/* <img class="logo-white" src={image1} alt="logo" /> */}
            </a>
            {/* <!-- /logo --> */}
            {loggedIn === false ?
              <Link to="/login">
                <div class="navbar-toggler text-white" >
                  {/* <span class="navbar-toggler-icon"></span> */} {t('login/Register')}
                </div>
              </Link>
              :
              <Link to="/dashboard">
                <div class="navbar-toggler text-white" >
                  {/* <span class="navbar-toggler-icon"></span> */} {t('Dashboard')}
                </div>
              </Link>
            }

            {/* <li class="nav-item ">
              <Link class="nav-link" to="/login">Login</Link>
            </li> */}
            <div class="collapse navbar-collapse" id="navigation">
              <ul class="navbar-nav ml-auto text-center">
                {props.isHeader ? null :

                  <li class={window.location.pathname === "/" ? "nav-item active" : "nav-item"}>
                    <Link class="nav-link" to="/">{t('Home')}</Link>
                  </li>
                }
                {props.isHeader ? null :

                  <li class={window.location.pathname === "/pricing" ? "nav-item active" : "nav-item"}>
                    <Link to="/pricing" class="nav-link">{t('Pricing')}</Link>
                  </li>
                }
                {props.isHeader ? null :
                  <>
                    {loggedIn === false ? (
                      <li class={window.location.pathname === "/login" ? "nav-item active" : "nav-item"}>

                        <Link class="nav-link" to="/login">{t('Login')}</Link>
                      </li>
                    ) : (
                      <li class={window.location.pathname === "/dashboard" ? "nav-item active" : "nav-item"}>
                        <Link class="nav-link" to="/dashboard">{t('Dashboard')}</Link>
                      </li>
                    )}
                  </>
                }
                {props.isHeader ? <LanguageSwitcher /> : null}
              </ul>
            </div>
            {/* {props.isHeader ? null :

              <a href="tel" class="call_action"><i class="fa fa-solid fa-phone" style={{ backgroundColor: '#FEB84D;transform: rotate(100deg)', color: '#febd59' }}></i><span >  (+91) 123-4567-789 </span></a>} */}
          </nav>
          {/* <!-- /main nav --> */}
        </div>
      </header >
    </div >
  );
}

export default Header;