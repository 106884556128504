import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import config from "../../config/config";
import axios from "axios";
import { Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import Table from 'react-bootstrap/Table'
import { getProcessList, displayError, displaySuccess, getAssignUserList, getAssignedProcessList, displayErrorString, } from "../../Utils/utils";
import { useSelector, useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import LoadingSpinner from "../../components/LoadingSpinner";
import { useTranslation } from "react-i18next";

const AssignUserModal = (props) => {

  const [pageCount, setPageCount] = useState(1);
  const [current_page, setCurrentPage] = useState(1);
  const [processList, setProcessList] = useState([]);
  const [is_loading, setIsloading] = useState(false);
  const [process_slug, setProcessSlug] = useState('');
  const [type, setType] = useState('');
  const [listIndex, setListIndex] = useState('');
  const [isBtn, setIsBtn] = useState(true);

  const { t } = useTranslation();
  // console.log(props.getProcess())
  const handleClose = () => {
    props.setAssignListShow(false)
    // props.getProcess();
  };

  const userInfo = useSelector((state) => state.userInfo.value)

  const getProcessAssignedList = async (id) => {
    setIsloading(true)
    var var_process = await getAssignedProcessList(id);
    setProcessList(var_process.data)
    setIsloading(false)
  }

  useEffect(() => {
    getProcessAssignedList(props.customer_id)
  }, [])

  const assignUser = async (slug) => {
    if (type === "") {
      displayErrorString(t("Please Select Type"));
    } else {
      var token = localStorage.getItem('token');
      let path = config.siteUrl;
      await axios.post(path + '/process/assign-user/' + slug, {
        user_id: props.customer_id,
        process_role_id: type,
        language: localStorage.getItem('langauge_selected'),
      }, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      })
        .then(async (response) => {
          displaySuccess(t('Customer has been assigned to the process'));
          var var_process = await getAssignedProcessList(props.customer_id);
          setProcessList(var_process.data)
        })
        .catch((error) => {
          displayError(error, t);
        });
    }
  }

  const handlePageClick = async (e) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected + 1;
    var cust_list_var = await getAssignUserList(selectedPage);
    setProcessList(cust_list_var.data)
    setPageCount(cust_list_var.last_page)

  };
  return (
    <>
      <LoadingSpinner is_loading={is_loading} />
      <Modal show={props.assignListshow} onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered >
        <Modal.Header >
          <Modal.Title>{t('Assign To User')} : {props.customer_name}</Modal.Title>
          <div className="close" onClick={handleClose} closeButton>

            <i class="far fa-times-circle"></i>
          </div>
        </Modal.Header>
        <Modal.Body >
          <div className="card-body">
            <div className="table-responsive">
              <Table striped borderless >
                <Tbody>
                  {processList.map((list, index) => (
                    <Tr >
                      <Td>{list.name}</Td>
                      <Td>
                        {list.process_user.length !== 0 ? null :
                          <select className="form-control" as="select" type="value" placeholder={t('Please select the type')}
                            // value={type}
                            onChange={e => {
                              setIsBtn(false)
                              setListIndex(index)
                              setType(e.target.value)
                            }}
                          >
                            <option value={''}>{t('select Type')}</option>
                            {props.is_customer === true ?
                              <option value="1">Customer</option>
                              :
                              <>
                                <option value="1">Customer</option>
                                <option value="2">User</option>
                                <option value="3">Manager</option>
                                <option value="4">Contributor</option>
                              </>
                            }
                          </select>
                        }
                      </Td>
                      {list.process_user.length !== 0 ?
                        <Td>
                          <span className="text-success font-weight-bold"> {t("Assigned")}</span>
                        </Td>
                        :
                        <Td><button className=" btn btn-light" style={{ background: "#8FCFFF", color: "#000" }}
                          disabled={isBtn === false && listIndex === index ? false : true}
                          onClick={() => {
                            setProcessSlug(list.slug)
                            assignUser(list.slug)
                          }}>
                          {t("Assign")}</button></Td>
                      }
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </div>
        </Modal.Body>
        <div className="row">
          <div className="mx-auto d-block">
            <ReactPaginate
              previousLabel={t('prev')}
              nextLabel={t('next')}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={0}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AssignUserModal;
