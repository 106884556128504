import React, { useEffect, useState } from 'react';
import TheamHeader from '../components/TheamHeader/TheamHeader';
import '../TermsOfServices/TermsOfServices.css';
import image1 from '../components/images/image11.png';
import image2 from '../components/images/image13.jpg';
import Footer from '../components/Footer/index';
import { Translate, I18n } from "react-redux-i18n";
import { useTranslation } from 'react-i18next';
import Header from '../components/Header/Header';

const TermsOfServices = () => {
  const { t } = useTranslation();
  const [isPricing, setIsPricing] = useState(true);
  const [reload, setReload] = useState(false)
  useEffect(() => {
    setReload(true)
    if (localStorage.getItem('token')) {
      setIsPricing(true)
    } else {
      setIsPricing(false)
    }
  }, [])
  return (
    <div className="theamheader">
      {reload &&
        <Header purchase={true} isPricing={isPricing} />}
      {/* containerA */}
      <div className="containerA col-xxl-8 px-4 py-5">
        <div className="row flex-lg-row-reverse align-items-center g-5">
          <div className="col-10 col-sm-8 col-lg-6">
            <img src={image1} className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" loading="lazy" width={700} height={500} />
          </div>
          <div className="col-lg-6">
            <div className="column">
              <h1 className="display lh-1 mb-3" style={{ fontSize: '48px' }}>Termos de Uso
              </h1>
              {/* <p className="lead" style={{ fontSize: '18px' }}>{t('Practically every single website worth anything out there has a Terms of Service page that it can turn to whenever issues about Terms come up with users')}.</p> */}
            </div>
          </div>
        </div>
      </div>{/* containerB */}
      <div class="container">
        <div className="row">
          <div className="col-sm-1">
            {/* <i class="fas fa-check-square"></i> */}
          </div>
          <div className="col-sm-8">
            <h1 class="Text lh-1 mb-3 mt-4">TERMOS DE USO</h1>
            <p class="Textparagraph">Seja bem-vindo ao nosso website (“Site”). Este Site é controlado e operado por Dutra Gestão Empresarial (“Dutra Gestão”,) empresa com sede na Rua Tupi, nº 758, Terceiro Andar, Conjunto 50, Bairro Centro, Novo Hamburgo, RS, CEP: 93.336-010, Inscrito no CNPJ sob o nº 08.202.380.0001-59.</p>
            <p class="Textparagraph">
              Por favor, leia atentamente os termos e condições de uso abaixo estipulados (“Termos de Uso”) para que você possa usufruir de nosso Site e de todos os serviços oferecidos pela Dutra Gestão.

            </p>
            <p class="Textparagraph">
              O site tem por objetivo apresentar para os clientes da Dutra Gestão e do público em geral informações institucionais sobre nosso escritório de consultoria, bem como a conteúdo de informação produzido pelo escritório.

            </p>
            <p class="Textparagraph">
              Caso você não concorde com quaisquer dispositivos dos Termos de Uso, não acesse ou utilize o nosso Site. Seu acesso e utilização representa sua aceitação integral e incondicional aos Termos de Uso.
              Para fins destes Termos de Uso, “Usuários” ou “Vocês” são todas as pessoas que de qualquer forma interagem, acessam ou utilizam o Site ou os nossos serviços.
            </p>

            {/* another paragraph */}
            {/* <div className="image">
              <div className="container">
                <img src={image2} alt="" />
              </div>
            </div> */}
            {/* another paragraph */}
            <h1 class="Text lh-1 mb-3 mt-3">1. SOBRE O SITE</h1>
            <p class="Textparagraph">
              Esse Site pode apresentar informações, textos, dados, imagens, fotografias, vídeos, sons, ilustrações, nome, logos, marcas e informações diversas sobre a Dutra Gestão, suas práticas e informações gerais de certas áreas da Administração, que poderão ser fornecidas na forma de documentos ou outros aquivos de mídia (“Informações”).
            </p>
            <p class="Textparagraph">
              Salvo disposição expressa em contrário, as Informações não se destinam a, nem constituem, serviços ou recomendações legais, de gestão administrativa e de negócios, contábeis, econômicas, comerciais, financeiras, tributárias, negócios internacionais ou afins. As Informações são fornecidas apenas com o intuito informativo e não deverão ser consideradas como opiniões ou pareceres aplicáveis para determinada situação. Se você precisar de recomendações ou serviços sobre um assunto específico, entre em contato conosco.

            </p>
            <p class="Textparagraph">
              Embora procuramos garantir que as Informações contidas neste Site sejam precisas e atualizadas, não nos responsabilizaremos pelos resultados de qualquer ação tomada com base nas Informações disponibilizadas. Ademais, não garantimos que o Site ou qualquer das Informações sejam disponibilizadas de forma ininterrupta ou que quaisquer defeitos serão corrigidos.
            </p>

            <p class="Textparagraph">
              Envidaremos esforços razoáveis para incluir informações precisas e atualizadas no site. No entanto, não oferecemos qualquer garantia ou assumimos qualquer responsabilidade quanto à confiabilidade, exatidão, validade, atualidade, utilidade, integridade, pertinência, oportunidade ou abrangência de tais informações.

            </p>
            <p class="Textparagraph">
              Em nenhuma hipótese a Dutra Gestão será responsável por perdas indiretas ou quaisquer danos decorrentes da perda de uso, dados ou lucros, seja em contrato, ato ou de quaisquer outras formas, decorrentes de ou em conexão com o uso deste Site ou qualquer das Informações.

            </p>
            <p class="Textparagraph">
              A Dutra Gestão afasta todas as garantias e/ou responsabilidades, expressas ou implícitas, decorrentes ou relativas à utilização deste site e de seu conteúdo, incluindo garantias de possível adequação a uma finalidade específica.

            </p>
            <h1 class="Text lh-1 mb-3 mt-3">2. PROPRIEDADE INTELECTUAL
            </h1>
            <p class="Textparagraph">
              Salvo se diversamente informado, a Dutra Gestão é detentora dos direitos autorais de toda a informação disponibilizada no Site, bem como de todos os direitos de propriedade intelectual e direitos conexos, incluindo, mas não se limitando a direitos sobre o banco de dados, marcas, pedidos de registro, etc.
            </p>
            <p class="Textparagraph">
              Exceto se Você tiver a permissão expressa e por escrito da Dutra Gestão, Você não deverá utilizar o nome, marca ou logotipo pertencente à Dutra Gestão ou a ela cedidos, especialmente as que tenham sido acessadas a partir deste Site.

            </p>
            <p class="Textparagraph">
              Você tem permissão para fazer o download, imprimir, armazenar temporariamente, recuperar e exibir as Informações do Site na tela do computador ou dispositivo móvel, imprimir páginas (mas não copiar) e armazenar essas páginas em formato eletrônico no computador ou dispositivo móvel para uso pessoal ou para uso interno em sua organização, mas sempre preservando a informação acerca da autoria das Informações do Site.
            </p>
            <p class="Textparagraph">
              Você não tem permissão (exceto quando receber permissão expressa) para adaptar ou modificar as Informações neste Site ou qualquer parte dele e as Informações ou qualquer parte delas não podem ser copiadas, reproduzidas, republicadas, baixadas, postadas ou transmitidas de qualquer outra forma a terceiros para ganhos comerciais ou mesmo informativos, salvo prévia autorização expressa da empresa detentora da autoria da Informação.
            </p>
            <p class="Textparagraph">
              Nenhuma disposição será interpretada como concessão aos Usuários de qualquer direito, título ou interesse em qualquer propriedade intelectual da Dutra Gestão.
            </p>
            <h1 class="Text lh-1 mb-3 mt-3">3. LIMITAÇÃO DE RESPONSABILIDADE
            </h1>
            <p>
              Não fornecemos qualquer garantia com relação ao conteúdo e serviços disponibilizados no site. Sem qualquer limitação, todo o conteúdo e funcionalidades do site são disponibilizados sem garantias de qualquer espécie, sejam expressas ou implícitas, incluindo, entre outras, garantias de adequação para um objetivo em particular.

            </p>
            <p>
              A Dutra Gestão não é nem será responsável (a) pela qualidade da conexão do computador e/ou dispositivo móvel do usuário à internet em qualquer circunstância, (b) pela disponibilidade ou não dos serviços de acesso à internet prestados por terceiros, (c) pela impossibilidade de utilização de quaisquer informações ou serviços acima mencionados em razão da incompatibilidade da configuração técnica do computador e/ou dispositivo móvel dos usuários, (d) por quaisquer danos sofridos pelos usuários em razão da utilização do aplicativo, e (e) pela reparação de danos de qualquer natureza causados pela quebra de privacidade ou segurança de dados dos usuários durante a conexão de seu dispositivo móvel à internet através de terceiros.
            </p>
            <p>
              Qualquer tipo de informação enviada por você através do correio eletrônico ou do contato, disponível no Site, não será tratada como confidencial. Comunicações realizadas através do Site ou também via correio eletrônico não constituirão relação cliente – gestão.
            </p>
            <h1 class="Text lh-1 mb-3 mt-3">4. WEBSITES DE TERCEIROS
            </h1>
            <p>
              A Dutra Gestão poderá fornecer links para outros websites da internet ou outros recursos. Assim, estes links serão disponibilizados como uma conveniência ao Usuário e, como não temos controle sobre tais websites ou recursos externos, o Usuário reconhece e concorda que a Dutra Gestão não é responsável pela disponibilidade desses e não endossa ou se responsabiliza por qualquer conteúdo, propaganda, produtos, serviços ou outros materiais contidos ou disponibilizados por meio de tais websites ou recursos.
            </p>
            <p>
              O Usuário expressamente reconhece e concorda que a Dutra Gestão não será responsável, direta ou indiretamente, por quaisquer perdas e danos que sejam efetiva ou alegadamente causados por, ou em conexão, pela confiança depositada em tais Informações, bens e serviços disponíveis no ou através de tais websites de terceiros.
            </p>
            <h1 class="Text lh-1 mb-3 mt-3">
              5. POLÍTICA DE PRIVACIDADE
            </h1>
            <p>
              Quaisquer informações encaminhadas por nossos usuários serão protegidas de acordo com padrões rígidos de confidencialidade e apenas utilizadas para as finalidades às quais foram coletadas.

            </p>
            <p>
              O acesso às informações recebidas é restrito apenas a integrantes autorizados para o uso adequado dessas informações.

            </p>
            <p>
              O Site poderá conter links para websites externos cujas políticas de privacidade não são de responsabilidade do escritório.

            </p>
            <p>
              O Site não se utiliza de “cookies” para coletar informações pessoais ou de navegação. Nossos provedores poderão, contudo, coletar dados genéricos e com finalidades puramente estatísticas.

            </p>
            <h1 class="Text lh-1 mb-3 mt-3">
              6. ALTERAÇÕES AOS TERMOS DE USO
            </h1>
            <p>
              A Dutra Gestão se reserva ao direito de, a sua discrição, alterar qualquer parte do Site, das Informações ou dos presentes Termos de Uso. Caso estes Termos de Uso sejam alterados, a Dutra Gestão publicará a versão atualizada neste Site. Ao continuar a usar o Site, você concorda em estar vinculado aos Termos de Uso conforme alterados.

            </p>
            <h1 class="Text lh-1 mb-3 mt-3">
              7. DISPOSIÇÕES ILEGAIS, INVÁLIDAS OU INEXEQUÍVEIS
            </h1>
            <p>
              Se estes Termos de Uso ou qualquer parte deles forem considerados ilegais, inválidos ou inexequíveis sob as leis de qualquer estado ou país em que estes Termos de Uso se destinem a ser efetivos, então, na medida em que as disposições sejam ilegais, inválidas ou inexequíveis, tais disposições devem ser tratadas como apartadas e excluídas destes Termos de Uso e as demais disposições destes Termos de Uso devem ser mantidas, permanecer em pleno vigor e efeito e continuar a ser vinculativa e exequível nesse estado ou país.
            </p>
            <h1 class="Text lh-1 mb-3 mt-3">
              8. LEI APLICÁVEL
            </h1>
            <p>
              Esses Termos de Uso estão sujeitos a lei brasileira e jurisdição dos tribunais brasileiros.
            </p>

            {/* another paragraph */}
          </div>
        </div>
        <div className="col-sm-3"></div>
      </div>
      {/* containerB complete*/}
      <Footer />
    </div>
  )
}

export default TermsOfServices;


