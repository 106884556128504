import React, { useState } from 'react';
// import './styles.css';
import { Link } from "react-router-dom";
import config from '../config/config';
import axios from "axios";
import { displaySuccess, displayError } from '../Utils/utils';
import { useNavigate } from "react-router-dom";
import { Translate, I18n } from "react-redux-i18n";
import { useTranslation } from 'react-i18next';
import image from '../components/images/logo_trans.png';

const Register = () => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [secretKey, setSecretKey] = useState('');
  const navigate = useNavigate()

  const Register = async (e) => {
    e.preventDefault();
    let path = config.siteUrl;
    await axios.post(path + '/register/user', {
      email: email,
      name: name,
      password: password,
      password_confirmation: confirmPassword,
      language: localStorage.getItem('langauge_selected')
    })
      .then((response) => {
        displaySuccess(t('User registered succesfully . Please verify your email now'));
        navigate(`/2fa-initiate`, {
          state: {
            google2fa_secret: response.data.data.user.google2fa_secret,
            qr_url: response.data.data.image_url,
            email: email
          }
        })
      })
      .catch((error) => {
        displayError(error, t);
      });
  };
  return (
    <>
      <div class="form-body">
        <div class="website-logo">
          <a>
            <div class="logo">
              <img class="logo-size" src="images/logo-light.svg" alt="" />
            </div>
          </a>
        </div>
        <div class="row">
          <div class="img-holder">
            <div class="bg"></div>
            <div class="info-holder" style={{ top: '70%' }}>
              <img src={image} />

            </div>
          </div>
          <div class="form-holder">
            <div class="form-content">
              <div class="form-items">
                <h3>{t('Get more things done with Login platform')}.</h3>
                <p>{t('Access to the most powerfull tool in the entire design and web industry')}.</p>

                <form onSubmit={Register}>
                  <label>{t('Full Name')}</label>
                  <input class="form-control" type="text" name="username" placeholder={t('Full Name')} required onChange={e => setName(e.target.value)} />

                  <label>{t('Email Address')}</label>
                  <input class="form-control" type="text" name="username" placeholder={t('Email Address')} required
                    onChange={e => setEmail(e.target.value)} />

                  <label>{t('Password')}</label>
                  <input class="form-control" type="password" name="password" placeholder={t('Password')} required
                    onChange={e => setPassword(e.target.value)} />

                  <label>{t('Confirm Password')}</label>
                  <input class="form-control" type="password" name="password" placeholder={t('Confirm Password')} required
                    onChange={e => setConfirmPassword(e.target.value)} />
                  <button id="submit" type="submit" class="ibtn">{t('Register')}</button>
                </form>
                <Link to="/login">

                  <div class="other-links text-center">
                    <span>{t('You have account')}?</span><a class="text-warning"> {t('Login now')}</a>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;