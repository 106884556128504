import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Moment from 'react-moment';
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";
import { Translate, I18n } from "react-redux-i18n";
import { useTranslation } from "react-i18next";
import { getFileListDetail, getNotesList } from "../../Utils/utils";
import Alert from 'react-bootstrap/Alert'
import { Textarea } from "react-bootstrap-icons";
import SweetAlert from "react-bootstrap-sweetalert";
import { Initial } from "react-initial";

const DocumentList = (props) => {
  const { t } = useTranslation();

  const [modalshow, setModalShow] = useState(false);
  const [customerModalShow, setCustomerModalShow] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [current_page, setCurrentPage] = useState(1);
  const [fileDetail, setFileDetail] = useState([]);
  const [fileId, setFileId] = useState('');
  const [notes, setNotes] = useState([])
  const [totalNoteCount, setTotalNoteCount] = useState('');
  const [fileSearch, setFileSearch] = useState('');
  const [fileStatus, setFileStatus] = useState(false);

  const handleClose = () => {
    setModalShow(false)
  }
  const handlePageClick = async (e) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected + 1;
    var var_file = await getFileListDetail(props.processId, selectedPage);
    setFileDetail(var_file.files.data)
    setPageCount(var_file.files.last_page)

  };
  const getProcessFiles = async () => {
    // setIsloading(true)
    var var_file = await getFileListDetail(props.processId, current_page);
    if (var_file !== null) {
      setFileDetail(var_file.files.data)
      setPageCount(var_file.files.last_page)
    }
    // setIsloading(false)
  }
  useEffect(() => {
    getProcessFiles()
  }, [props.processId])
  const noteList = async (id) => {
    var var_notes = await getNotesList(id, "", "", '');
    setNotes(var_notes.data)
  }
  const searchhandle = async (e) => {
    // setIsloading(true)
    var var_file = await getFileListDetail(props.processId, current_page, e);
    setFileDetail(var_file.files.data)
    setPageCount(var_file.files.last_page)
    // setIsloading(false)
  }
  // const copyToClipboard = (e) => {
  //   var textField = document.createElement('textarea')
  //   textField.innerText = 'foo bar baz'
  //   document.body.appendChild(textField)
  //   textField.select()
  //   document.execCommand('copy')
  //   textField.remove()
  // }
  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return (
    <>
      <div className="row justify-content-between">
        <span className="textPrcess mt-3 ml-3 mb-1 font-weight-bold text-lg">{t('Document list')}</span>
        <div className="col-md-8 text-right">
          {props.processRole === "Customer" || props.processRole === "Contributor" ? null :
            <div className="btn btn-warning">
              <Link to={"/add-files/" + props.processId} style={{ textDecoration: 'none' }}>
                <span className="text-white">{t('Add File')}</span>
              </Link>
            </div>
          }
          <div className="searchboxx">
            <input id="validationCustom01" className="w-100" type="text" placeholder={t("search")} required="" data-bs-original-title="" title="" style={{ borderStyle: 'none' }}
              value={fileSearch}
              onChange={(e) => {
                setFileSearch(e.target.value)
                searchhandle(e.target.value)
              }} />
          </div>
        </div>
      </div>
      <div className="card mt-4 shadow py-2" style={{ borderRadius: '15px', height: 'auto' }}>
        <div className="card-body">
          <Table responsive>
            <thead>
              <tr>
                <th className="text-lg">{t('File Name')}</th>
                <th>{t('Last Modified')}</th>
                <th>{t('status')} </th>
                <th>{t('Notes')}</th>
                {/* <th>File Size</th> */}
                {/* <th>{t('Action')}</th> */}
              </tr>
            </thead>
            <tbody>
              {(fileDetail.length === 0) ?
                <tr>
                  <td colspan="7" style={{ textAlign: 'center' }}>
                    <Alert variant={'danger'}>
                      <span className="text-dark font-weight-bold">{t("No Files")}</span>
                    </Alert>
                  </td>
                </tr> : ''}
              {fileDetail.map((list) => (

                <tr>
                  <td>
                    <div className="row">
                      {list.media.type == "pdf" ? <i class="far fa-file-pdf pdf-icon mr-2"></i> :
                        <i class="fas fa-video video-icon mr-2"></i>}
                      {/* <i class="far fa-file file-icon mr-2"></i> */}
                      {/* */}

                      <span style={{ fontSize: '15px', width: '75%' }}>
                        <div className="pro-name">
                          {list.status === "processed" && list.summary == null ?
                            <Link to={'/video-reader/' + props.processId + '?file_id=' + list.id} className="pro-name">
                              {list.media.original_name}
                            </Link>
                            :
                            list.status === "processed" && list.summary !== null ?
                              <Link to={'/video-reader/' + props.processId + '?file_id=' + list.id + '&&summary=true'} className="pro-name">
                                {list.media.original_name}
                              </Link>
                              :
                              <div style={{ cursor: 'pointer' }} className="pro-name"
                                onClick={() => setFileStatus(true)}
                              >
                                {list.media.original_name}
                              </div>
                          }
                          {/* {list.status === "processed" ?
                            <Link to={'/video-reader/' + props.processId + '?file_id=' + list.id}>
                              {list.media.original_name}
                            </Link>
                            :
                            <div style={{ cursor: 'pointer' }} onClick={() => setFileStatus(true)}>
                              {list.media.original_name}
                            </div>
                          } */}
                        </div>
                      </span>
                    </div>
                  </td>
                  <td>
                    <span className="time"> <Moment format="DD/MM/YYYY">
                      {list.updated_at}
                    </Moment></span>
                  </td>
                  <td>
                    {list.status === "pending" ?
                      <span className="text-danger">{t(capitalizeFirst(list.status))}</span>
                      :
                      list.status === "queued"
                        ?
                        <span className="text-warning">{t(capitalizeFirst(list.status))}</span>
                        :
                        <span className="text-success">{t(capitalizeFirst(list.status))}</span>}
                  </td>
                  <td>
                    <div className="row">
                      <span className="font-weight-bold mr-1">{list.notes_count}</span>
                      <i class="far fa-comment-dots comment ml-1" onClick={() => {
                        // setFileId(list.id)
                        noteList(list.id)
                        setTotalNoteCount(list.notes_count)
                        setModalShow(true)
                      }}></i>
                    </div>
                  </td>
                  {/* <td>
                  <span className="time">24,476 Mb</span>
                </td> */}
                  {/* <td>
                    <div className="dotbox">
                      <i class="fas fa-ellipsis-v dot"></i>
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="row mt-1">
            <div className="mx-auto1 mt-2 d-block">
              <ReactPaginate
                previousLabel={t('prev')}
                nextLabel={t('next')}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={0}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
            <Link to={"/file-list/" + props.processId} style={{ marginLeft: "auto", textDecoration: 'none' }}>
              <div className="listall-btn mb-3 mr-3">
                <div className="text-dark text-md font-weight-bolder mt-1 ml-2">{t('View All')}</div>
              </div>
            </Link>
            {fileStatus === true ? (
              <SweetAlert
                warning
                // showCancel
                confirmBtnText="OK"
                confirmBtnBsStyle="warning"
                title={('')}
                onConfirm={(e) => {
                  setFileStatus(false)
                }}
              >
                {t("This File is not processed yet . once it will processed you will be able to review.")}
              </SweetAlert>
            ) : ([]
            )}
          </div>
        </div>
      </div>

      <Modal  {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered show={modalshow} onHide={handleClose} >
        <Modal.Header style={{ border: "none" }} >
          <Modal.Title>{t('Notes')}</Modal.Title>
          <div className="close" onClick={handleClose} closeButton>
            <i class="fas fa-arrow-alt-to-bottom"></i>
            <i class="far fa-times-circle"></i>
          </div>

        </Modal.Header>
        <h6 style={{ marginLeft: "15px", color: "#A5A5A5" }}>{t('You have')}{totalNoteCount} {t('Notes')}</h6>
        <Modal.Body>
          <div className="card-body">
            {notes.map((list) => (

              <div className="row " style={{ padding: "12px 0px", marginBottom: '10px', borderBottom: '1px solid #6cb9d5' }}>
                <div className=" col-sm-1 col-md-3 col-xl-1 col-lg-1">
                  {list.user.media === null ?
                    <Initial
                      name={list?.user?.name}
                      color="#0D5064"
                      seed={5}
                      fontSize={25}
                      height={55}
                      width={55}
                      style={{ borderRadius: '50px', marginRight: '10px', marginBottom: '15px' }}
                    />
                    :
                    <img src={list.user?.media?.url} alt="" style={{
                      borderRadius: '65px',
                      width: '55px',
                      height: '55px'
                    }} />
                  }
                </div>
                <div className="col-sm-10 col-md-8 col-xl-10 col-lg-10">
                  {/* <div className="row" style={{ marginLeft: "15px" }}>
                    <h6 style={{ marginRight: "15px", fontWeight: "bold" }}> Rahul Thate
                      <h6 style={{ color: "#A5A5A5" }}>5h {t('ago')}</h6></h6>
                  </div> */}
                  <div className="row align-items-center " style={{ marginLeft: "15px", fontWeight: '500' }}>
                    <h6 style={{ fontWeight: 'bold' }}>{list.title}
                    </h6>

                  </div>
                  <div className="row align-items-center" style={{ marginLeft: "15px" }}>
                    <h6 >{list.description}
                    </h6>

                  </div>
                  <div className="row align-items-center" style={{ marginLeft: "15px", color: '#808080' }}>
                    <h6 >By {list.user.name}
                    </h6>

                  </div>
                </div>
                <div className=" col-sm-1 col-md-1 col-xl-1 col-lg-1">
                  <div className="icons" style={{ listStyleType: "none" }}>
                    {/* <li><i class="fas fa-paperclip text-lg mt-6 mr-3 text-primary" ></i></li> */}
                    <li><i class="far  text-lg fa-copy"></i></li>
                  </div>
                </div>
              </div>
            ))}


          </div>
        </Modal.Body>
      </Modal>
    </>

  );
}
export default DocumentList