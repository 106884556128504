import React, { useEffect, useState } from 'react';
import "../Purchase/Purchase.css";
import { displayError, displaySuccess, getCities, getCountry, getCreditCardRates, getPayment, getPaymentDetails, getStates } from '../Utils/utils';
import Header from '../components/Header/Header';
import { Link, useParams } from 'react-router-dom';
import Cards from 'react-credit-cards';
import ReactInputMask from 'react-input-mask';
import './style.css';
import 'react-credit-cards/es/styles-compiled.css';
import { formatCreditCardNumber, formatExpirationDate, formatCVC } from './CardUtils';
import course_image from './img/images.png'
import Payment from 'payment';
import { Col, Row } from 'react-bootstrap';
import LoadingSpinner from '../components/LoadingSpinner';
import Toast from 'react-bootstrap/Toast';
import config from '../config/config';
import axios from 'axios';
import Table from 'react-bootstrap/Table'
import { useTranslation } from 'react-i18next';

const CheckoutPurchase = (props) => {
  const [is_loading, setIsloading] = useState(false);
  let [mask, setMask] = React.useState(null);
  const [number, setCardNumber] = useState('');
  const [name, setCardName] = useState('');
  const [cvc, setCardSecurityCode] = useState('');
  const [expiry, setCardValidity] = useState('');
  const [focus, setCardFocused] = useState();
  const [issuer, setCardIssuer] = useState('');

  const [card_holder_name, setCardHolderName] = useState('');
  const [card_no, setCardNo] = useState('');
  const [securityCode, setSecurityCode] = useState('');
  const [validThru, setValidThru] = useState('');
  const [card_rate, setCardRate] = useState('0');
  const [emailReadOnly, setEmailReadOnly] = useState(false);

  const [install, setInstall] = useState('');

  const [cpf, setCpf] = useState('');
  const [cpf_inter, setCpfInter] = useState('');
  const [zip_inter, setZipInter] = useState('');
  const [zip_code, setZipCode] = useState('');
  let [masked, setMasked] = React.useState(null);

  const [payment_type, setPaymentType] = useState(3);
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [country_id, setCountryId] = useState(30);
  const [state_id, setStateId] = useState('');
  const [email, setEmail] = useState('');
  const [city_id, setCityId] = useState('');
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);

  const [code2, setCode2] = useState('');
  const [showA, setShowA] = useState(true);
  const [showB, setShowB] = useState(false);
  const [code, setCode] = useState('');
  const { id } = useParams();
  const [paymentId, setPaymentId] = useState('');
  const [subTotalAmt, setSubTotalAmt] = useState('');
  const [discountValue, setDiscountValue] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [isHeader, setIsHeader] = useState(true)
  const [pageObj, setPageObj] = useState('')
  const [videoObj, setVideoObj] = useState('')
  const [storageObj, setStorageObj] = useState('')
  const { t } = useTranslation()
  let [phoneMask, setPhoneMask] = React.useState(null);
  const [phoneTrunc, setPhoneTrunc] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [phoneInter, setPhoneInter] = useState('');

  const creditSelect = () => {
    setPaymentType(3);
  }
  const boletoSelect = () => {
    setPaymentType(1);
    setInstall(1);
  }

  const carddata = [];
  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    var isRun = 0;
    if (e.target.name === "number") {
      e.target.value = formatCreditCardNumber(e.target.value);
      setCardNumber(e.target.value);
      carddata[e.target.name] = e.target.value;
      const issuer = Payment.fns.cardType(e.target.value);
      setCardIssuer(issuer);
      if (issuer !== null) {
        internalCreditCardRates();
      } else {
        var creditCard_rate = await getCreditCardRates();
        // setBoletoRate(creditCard_rate.taxaBoleto);
      }
    } else if (e.target.name === "expiry") {
      e.target.value = formatExpirationDate(e.target.value);
      setCardValidity(e.target.value);
      carddata[e.target.name] = e.target.value;
    } else if (e.target.name === "cvc") {
      e.target.value = formatCVC(e.target.value);
      setCardSecurityCode(e.target.value);
      carddata[e.target.name] = e.target.value;
    } else if (e.target.name === "name") {
      setCardName(e.target.value);
      carddata[e.target.name] = e.target.value;
    }
  }
  const internalCreditCardRates = async () => {
    setCardRate(0)
    // if (issuer === 'visa') {
    //   var creditCard_rate = await getCreditCardRates();
    //   setCardRate(creditCard_rate.taxaTotal.Visa.credit["1"]);

    // } else if (issuer === 'mastercard') {
    //   var creditCard_rate = await getCreditCardRates();
    //   setCardRate(creditCard_rate.taxaTotal.MasterCard.credit["1"]);
    // } else if (issuer === 'discover') {
    //   var creditCard_rate = await getCreditCardRates();
    //   setCardRate(creditCard_rate.taxaTotal.Discover.credit["1"]);
    // }
    // else if (issuer === 'amex') {
    //   var creditCard_rate = await getCreditCardRates();
    //   setCardRate(creditCard_rate.taxaTotal["American Express"].credit["1"]);
    // }
  }

  useEffect(async () => {
    fetchStates(30);
    setIsloading(true)
    var country_option = await getCountry();
    setCountries(country_option)
    setIsloading(false);
  }, [])

  const fetchStates = async (country_id) => {
    setIsloading(true);
    setStateId('')
    var states_options = await getStates(country_id);
    setStates(states_options);
    setCountryId(country_id)
    setIsloading(false);
  }
  const fetchCity = async (state_id) => {
    setIsloading(true);
    setCityId('');
    var city_options = await getCities(state_id);
    setCities(city_options);
    setStateId(state_id)
    setIsloading(false);
  }
  const handleInputFocus = (e) => {
    setCardFocused({ focus: e.target.name });
  }

  const handleChange = ev => {
    let { value } = ev.target;
    let nums = value.replace(/[^0-9]/g, "");
    setMask("999.999.999-99");
    setCpf(ev.target.value);
    setCpfInter(nums);
    if (nums.length < 3 && mask != null) {
      setMask(null);
      ev.target.value = nums;
    }
  };
  const zipHandle = ev => {
    let { value } = ev.target;
    let nums = value.replace(/[^0-9]/g, "");
    setMasked("99999-999");
    setZipCode(value);
    setZipInter(nums);
    if (nums.length < 3 && mask != null) {
      setMask(null);
      ev.target.value = nums;
    }
  };
  useEffect(() => {
    setIsloading(true)
    if (issuer != "" && issuer != null) {
      internalCreditCardRates();
    }
    setIsloading(false)
  }, [issuer])

  const applyCoupon = async () => {

    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/payment/apply-coupon', {
      payment_id: paymentId,
      coupon_code: code
    }, {
      headers: { 'content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        displaySuccess(t("Coupon applied successfulyy"))
        setShowB(true);
        setCode2(code);
        setCode("");
        if (response.data.data.sub_total_amount < 0) {
          setDiscountedPrice(0)
        } else {
          setDiscountedPrice(response.data.data.sub_total_amount)
        }
        setDiscountValue(response.data.data.discount_amount)
      })
      .catch((error) => {
        displayError(error, t);
      });
  }
  const removeCoupon = async () => {

    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/payment/remove-coupon', {
      payment_id: paymentId,

    }, {
      headers: { 'content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        displaySuccess(t("Coupon removed successfully"))
        setDiscountValue(0)
        setDiscountedPrice(response.data.data.sub_total_amount)
      })
      .catch((error) => {
        displayError(error, t);
      });
  }

  const toggleShowA = () => {
    setShowB(false);
    setCode2("");
    setCode("");
  }
  const subtotal = (rate, price) => {
    if (rate == 0) {
      return price
    } else {
      var res = parseInt(price) * rate / 100
      var total = parseInt(price) + res
      return total
    }

  }

  const PaymentSales = async () => {
    setIsloading(true)
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    var res = await axios.post(path + '/payment/no-auth-checkout/' + id, {
      payment_type: payment_type,
      installments: 1,
      credit_card_rate: parseFloat(card_rate).toFixed(2),
      boleto_rate: 0,
      amount: parseFloat(subTotalAmt).toFixed(2),
      paid_amount: payment_type === 1 ? parseFloat(discountedPrice).toFixed(2) : parseFloat(subtotal(card_rate, discountedPrice)).toFixed(2),
      card_holder_name: card_holder_name,
      card_number: card_no,
      card_security_code: securityCode,
      card_validity: validThru,
      cpf: cpf,
      address: address,
      zip_code: zip_inter,
      // state_id: parseInt(state_id),
      // city_id: city_id,
      email: email,
      boleto_rate: 0,
      first_name: first_name,
      last_name: last_name,
      // country_id: 30,
      phone: phoneTrunc,
      country_id: country_id,
      state_id: parseInt(state_id),
      city_id: city_id,
    }, {
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => {
        if (res.status === 200) {
          setIsloading(false)
          displaySuccess(t("Payment made succesfully"))
          setTimeout(() => window.location.href = "/", 3000);
        } else {
          displayError(t("some error occured"))
        }
      })
      .catch((error) => {
        setIsloading(false)
        displayError(error, t)
      });
  }
  const getPurchaseDetails = async () => {
    var res = await getPaymentDetails(id)
    setPaymentId(res.id)
    setSubTotalAmt(res.total_amount)
    if (res.sub_total_amount < 0) {
      setDiscountedPrice(0)
    } else {
      setDiscountedPrice(res.sub_total_amount)
    }
    if (res?.coupon !== null) {
      setCode2(res.coupon.code)
      setShowB(true)
      setDiscountValue(res.discount_amount)
    }
    if (res.payment_information !== null) {
      setPageObj(res.payment_information[0])
      setVideoObj(res.payment_information[1])
      setStorageObj(res.payment_information[2])
    }

  }

  useEffect(() => {
    getPurchaseDetails()
  }, [])

  const phoneHandle = ev => {
    let { value } = ev.target;
    let nums = value.replace(/[^0-9]/g, "");
    setPhoneMask("+55 99 99999-9999");
    var str = 1437203995000;
    str = nums.toString();
    str = str.slice(2, 13);
    str = parseInt(str);
    setPhoneTrunc(str)
    setPhoneNo(ev.target.value);
    setPhoneInter(nums);
    if (nums.length < 3 && mask != null) {
      setMask(null);
      ev.target.value = nums;
    }
  };
  return (
    <>
      <div id="legaltech">
        <LoadingSpinner is_loading={is_loading} />
        {/* <TheamHeader /> */}
        <Header purchase={true} isHeader={isHeader} />
        <main>
          <div className="overflow-hidden">
            <div className="container">
              <div className="row mt-5">
                <div className="course__title">
                  {/* <h4>
                    <br />
                  </h4> */}
                </div>
                <div className="card  col-md-12" style={{ marginTop: '5%' }}>
                  <div className="card " style={{ borderRadius: "none", height: 'auto', background: "#F1F6FA" }}>
                    <div className="card-body">
                      <Table className="table table-borderless align-items-center" responsive >
                        <thead>
                          <tr>
                            <th>
                              <span className="tablehead1" style={{ color: "#FCAD00" }}>{t('Storage Calculation')}</span>
                            </th>
                            <th><span className="headtable">{t("Quantity")}</span> </th>
                            <th><span className="headtable">{t("Unit Price")}</span></th>
                            <th><span className="headtable">%</span></th>
                            <th><span className="headtable">{t('Discount Price')}</span></th>
                            <th><span className="headtable">{t('Price')} (R$)</span></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span className="text-Secondary">{t('Processing Pages')}</span>
                            </td>
                            <td >
                              {pageObj.quantity}
                            </td>
                            <td >
                              <span className="text-dark">
                                {pageObj.rate}
                              </span>
                            </td>
                            <td>
                              {pageObj.discount_percentage}%
                            </td>
                            <td >
                              {pageObj.discount_amount}
                            </td>
                            <td>
                              <span className="text-dark">
                                {pageObj.amount}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="text-Secondary">{t('Processing Videos')}</span>
                            </td>
                            <td >
                              {videoObj.quantity}
                            </td>
                            <td >
                              <span className="text-dark">
                                {videoObj.rate}
                              </span>
                            </td>
                            <td>
                              {videoObj.discount_percentage}%
                            </td>
                            <td>
                              {videoObj.discount_amount}
                            </td>
                            <td >
                              <span className="text-dark">
                                {videoObj.amount}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="text-Secondary">{t('Additional Storage')}</span>
                            </td>
                            <td >
                              {storageObj.quantity}
                            </td>
                            <td >
                              <span className="text-dark">
                                {storageObj.rate}
                              </span>
                            </td>
                            <td>
                              {storageObj.discount_percentage}%
                            </td>
                            <td>
                              {storageObj.discount_amount}
                            </td>
                            <td >
                              <span className="text-dark">
                                {storageObj.amount}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>													</td>
                            <td>													</td>
                            <td>													</td>
                            <td >
                              <span className="text-warning">{t('Subtotal')} - R$</span>
                            </td>
                            <td >
                            </td>
                            <td >
                              <span className="text-dark">
                                {subTotalAmt}</span>
                            </td>
                          </tr>

                        </tbody>
                      </Table>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row ">
                <div className="col-md-8 pl-md-0 mb-5">
                  <div className="course__title">
                    <h4>
                      {/* {title} */}
                      <br />
                      {/* <small className="disabale">{('By')}:- {instructor_name}</small> */}
                    </h4>
                  </div>
                  {/* {grandTotal <= 0 ? "" : */}
                  <div className="card_details mt-3">
                    <div className='container'>
                      <form>
                        <label class="radio-inline" style={{ marginRight: '20px', cursor: 'pointer' }}>
                          <input type="radio" name="optradio"
                            onClick={creditSelect}
                            style={{ cursor: 'pointer' }}
                            checked={payment_type === 3 ? true : false}
                          /> {t('Credit Card')}
                        </label>
                        <label class="radio-inline" style={{ cursor: 'pointer' }}>
                          <input type="radio" name="optradio"
                            onClick={boletoSelect} style={{ cursor: 'pointer' }} checked={payment_type === 1 ? true : false}
                          /> {t('Boleto')}
                        </label>
                      </form>
                    </div>
                    {payment_type === 1 ? "" :
                      < div id="PaymentForm" >
                        <div className='container'>
                          <div className="row">
                            <div className="col-md-5 p-0">
                              <Cards
                                cvc={cvc}
                                expiry={expiry}
                                focused={focus}
                                name={name}
                                number={number}
                              />
                            </div>
                            <div className="col-md-7 ">
                              <form>
                                <div className="row no-gutters">
                                  <div className="col-md-6 mt-2 pr-md-2">
                                    <label className="label-control">{t('Card Number')}</label>
                                    <input
                                      type="tel"
                                      name="number"
                                      className="form-control"
                                      placeholder={("Card Number")}
                                      pattern="[\d| ]{16,22}"
                                      required
                                      onChange={e => {
                                        setCardNo(e.target.value)
                                        handleInputChange(e)
                                      }}
                                      onBlur={e => {
                                        setCardNo(e.target.value)
                                        handleInputChange(e)
                                      }}
                                      onFocus={handleInputFocus}
                                      id="cardnumber"
                                      value={card_no}
                                    />
                                  </div>
                                  <div className="col-md-6 mt-2 pr-md-2">
                                    <label className="label-control">{t('Card Holder Name')}</label>
                                    <input
                                      type="text"
                                      name="name"
                                      className="form-control"
                                      placeholder={("Name")}
                                      required
                                      onChange={e => {
                                        setCardHolderName(e.target.value)
                                        handleInputChange(e)
                                      }}
                                      onFocus={handleInputFocus}
                                    />
                                  </div>
                                  <div className="col-md-6 mt-2 pr-md-2">
                                    <label className="label-control">{t('CVC')}</label>
                                    <input
                                      type="tel"
                                      name="cvc"
                                      className="form-control"
                                      placeholder={("CVC")}
                                      pattern="\d{3,4}"
                                      required
                                      onChange={e => {
                                        setSecurityCode(e.target.value)
                                        handleInputChange(e)
                                      }}
                                      onFocus={handleInputFocus}
                                    />
                                  </div>
                                  <div className="col-md-6 mt-2 pr-md-2">
                                    <label className="label-control">{t('Card Validity')}</label>
                                    <input
                                      type="tel"
                                      name="expiry"
                                      className="form-control"
                                      placeholder={("Valid Thru")}
                                      pattern="\d\d/\d\d"
                                      required
                                      onChange={e => {
                                        setValidThru(e.target.value)
                                        handleInputChange(e)
                                      }}
                                      onFocus={handleInputFocus}
                                    />
                                    <input type="hidden" name="issuer" />
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>

                      </div>
                    }
                  </div>
                  <div className="row mt-4">
                    <div className="col-sm-4 mb-4">
                      <label>{t('CPF')}</label>
                      <ReactInputMask
                        className="form-control"
                        mask={mask}
                        onChange={handleChange}
                        maskChar="_"
                        value={cpf}
                        placeholder={t("CPF")}
                        {...props}
                      />
                    </div>

                    <div className="col-sm-4 mb-4">
                      <label>{t('Email Address')}</label>
                      <input type="text" className="form-control" placeholder={t("Email Address")}
                        value={email} readOnly={emailReadOnly} onChange={e => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="col-md-4 col-xl-4 mb-4">
                      <label>{t('ZipCode')}</label>
                      <ReactInputMask
                        className="form-control"
                        mask={masked}
                        onChange={zipHandle}
                        maskChar="_"
                        value={zip_code}
                        placeholder={t("ZipCode")}
                        {...props}
                      />
                    </div>

                    <div className='col-sm-12'>
                      <hr />
                    </div>
                    <>
                      <div className='col-sm-6'>
                        <label>{t('First Name')}</label>
                        <input type="text" className="form-control" placeholder={t("First Name")}
                          value={first_name}
                          onChange={e => setFirstName(e.target.value)}
                        />
                      </div>
                      <div className='col-sm-6'>
                        <label>{t('Last Name')}</label>
                        <input type="text" className="form-control" placeholder={t("Last Name")}
                          value={last_name}
                          onChange={e => setLastName(e.target.value)}
                        />
                      </div>
                    </>

                    <div className="col-md-6 mb-4 mt-3">
                      <label>{t('Address')}</label>
                      <input type="text" className="form-control" placeholder={t("Address")}
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 mb-4 mt-3">
                      <label>{t('Phone number')}</label>
                      <ReactInputMask
                        className="form-control"
                        mask={phoneMask}
                        onChange={phoneHandle}
                        maskChar="_"
                        value={phoneNo}
                        placeholder={t("Phone number")}
                        {...props}
                      />
                    </div>
                    <div className="col-md-4 col-xl-4 mb-4">
                      <label>{t('Country')}</label>
                      <select
                        className="form-control"
                        id="validationCustom04"
                        required=""
                        onChange={e => {
                          fetchStates(e.target.value)
                          setCountryId(e.target.value)
                        }}
                      >
                        <option >{t('Select Country')}</option>
                        {countries.map((country) =>
                          <option value={country.id} selected={(country.id == country_id) ? true : false}>{country.name}</option>
                        )}
                      </select>
                    </div>
                    <div className="col-md-4 col-xl-4 mb-4">
                      <label>{t('State')}</label>
                      <select className="form-control" id="validationCustom04"
                        onChange={e => {
                          fetchCity(e.target.value)
                          setStateId(e.target.value)
                        }}
                      >
                        <option  >{t('Select State')}</option>
                        {states.map((state) =>
                          <option value={state.id} selected={(state.id == state_id) ? true : false}>{state.name}</option>
                        )}
                      </select>
                    </div>
                    <div className="col-md-4 col-xl-4 mb-4">
                      <label>{t('City')}</label>
                      <select className="form-control" id="validationCustom04"
                        onChange={e => {
                          // consol/e.log(e.target.value)
                          setCityId(e.target.value)
                        }}
                      >
                        <option  >{t('Select City')}</option>
                        {cities.map((city) =>
                          <option value={city.id} selected={(city.id == city_id) ? true : false}>{city.name}</option>
                        )}
                      </select>
                    </div>

                  </div >

                </div >
                <div className="col-lg-4 before">
                  <div className="order-summary__section">
                    <div className="order-summary__section__content">
                      <table className="product-table">
                        <tbody>
                          <tr className="product">
                            <td className="product__image">
                              <div className="product-thumbnail ">
                                <div className="product-thumbnail__wrapper">
                                  <img className="product-thumbnail__image"
                                    src={course_image}
                                  />
                                </div>
                              </div>
                            </td>
                            <th className="product__description">
                              <span className="product__description__name"></span>
                            </th>
                            <td className="product__price">
                              <span className="order-summary__emphasis">R$ {subTotalAmt}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="5">
                              <hr />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="order-summary">
                      <table className="total-line-table">
                        <tbody>

                          {/* : ""} */}
                          {/* <tr>
                            <th className="total-line__name-disc">
                              <span>
                                {('Apply  ')}
                              </span>
                            </th>
                          </tr> */}
                          {/* <tr >
                            <td className="coupon-check">
                              <label class="radio-inline" style={{ marginRight: '180px', cursor: 'pointer' }}>
                                <input type="checkbox" name="optradio" style={{ cursor: 'pointer' }}
                                // onClick={enterCode}
                                /> {'coupon Code'}
                              </label>
                            </td>
                          </tr> */}
                          {/* {is_coupon_check === false ? "" : */}
                          <tr>
                            <Row>
                              <Col className='p-0' xl={8} md={8} xs={8} sm={8}>
                                {/* <span> */}
                                <input
                                  type="text" className="form-control" placeholder={t("Coupon Code")}
                                  required
                                  style={{ width: '100%' }}
                                  onChange={e => setCode(e.target.value)}
                                  // onKeyPress={(event) => {
                                  //   if (!/[A-Z0-9]/.test(event.key)) {
                                  //     event.preventDefault();
                                  //   }
                                  //   if (event.target.value.length > 10) {
                                  //     event.preventDefault();
                                  //   }
                                  // }}
                                  value={code}
                                />
                                {/* </span> */}
                              </Col>
                              <Col className='p-0' xl={4} md={4} xs={4} sm={4}>
                                <div className="apply-coupon-box row mt-2"
                                  onClick={applyCoupon}
                                  style={{ cursor: 'pointer' }}>
                                  <span className="apply-code">{t('Apply')}</span>
                                </div>
                              </Col>
                            </Row>

                          </tr>
                          {showB === true ?
                            <tr>
                              <Toast show={showA}
                                onClose={(e) => {
                                  removeCoupon()
                                  toggleShowA();
                                  setShowB(false);
                                }}
                                style={{ marginTop: "10px", marginLeft: '-15px' }}>
                                <Toast.Header>
                                  <strong className="me-auto">{code2}</strong>
                                </Toast.Header>
                              </Toast>
                            </tr>
                            : ""}
                          <tr>
                            <td colSpan="3">
                              <hr />
                            </td>
                          </tr>
                          {/* {showB === true ? */}
                          <>
                            <tr>
                              <th className="total-line__name">
                                <span>
                                  {t('Price')}
                                </span>
                              </th>
                              <td className="total-line__price">
                                <span className="order-summary__small-text">
                                  {subTotalAmt}
                                  {/* {Object.keys(parsed).length > 0 ? 'R$ ' + parseFloat(price_after_cross).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                      : 'R$ ' + parseFloat(price).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}


                                </span>
                              </td>
                            </tr>
                            {/* {discount_percentage !== null ? */}
                            <tr>
                              <th className="total-line__name">
                                <span>
                                  {/* {('Voucher Discount')} (-{parseFloat(discount_percentage).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%) */}
                                </span>
                              </th>
                              <td className="total-line__price">
                                <span className="order-summary__small-text">
                                  {/* R$ {parseFloat(percentage_price).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                                </span>
                              </td>
                            </tr>
                            {/* : */}
                            <tr>
                              <th className="total-line__name">
                                <span>
                                  {t('Voucher Discount')}(-)
                                </span>
                              </th>
                              <td className="total-line__price">
                                <span className="order-summary__small-text">
                                  {discountValue}
                                </span>
                              </td>
                            </tr>
                            {/* } */}

                            <tr>
                              <td colSpan="3">
                                <hr />
                              </td>
                            </tr>
                          </>

                          {/* {installment_quantity === 0 || installment_quantity === 1 ?  */}
                          <tr>
                            <th className="total-line__name">
                              <span>{t('Discounted Price')}
                              </span>
                            </th>
                            <td className="total-line__price">
                              <span className="order-summary__small-text">
                                {discountedPrice}
                                {/* R$ {parseFloat(price_after_discount).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} */}
                              </span>
                            </td>
                          </tr>

                          {/* {payment_type === 1 ?
                            <tr>
                              <th className="total-line__name">
                                <span>
                                  {t('Boleto rates (+)')}
                                </span>
                              </th>
                              <td className="total-line__price">
                                <span className="order-summary__small-text">
                                  0%
                                </span>
                              </td>
                            </tr>
                            :
                            <tr>
                              <th className="total-line__name">
                                <span>
                                  {t('Credit card rate(+)')}
                                </span>
                              </th>
                              <td className="total-line__price">
                                <span className="order-summary__small-text">
                                  {card_rate}%
                                </span>
                              </td>
                            </tr>
                          } */}
                          <tr>
                            <th className="total-line__name">{('Subtotal')}</th>
                            <td className="total-line__price">
                              <span className="order-summary">
                                R$   {payment_type === 1 ? discountedPrice : parseFloat(subtotal(card_rate, discountedPrice)).toFixed(2)}
                              </span>
                            </td>
                          </tr>
                        </tbody>

                        <tfoot className='bg-gray-totoal'>
                          <tr className="total-line">
                            <th className="payment-due-label">
                              <span className="payment-due-label__total">{t('Grand Total')}</span>
                            </th>

                            <td className="total-line__price">
                              <span className="payment__currency" style={{ fontSize: '16px' }}>
                              </span>
                              <span className="payment__currency">R$</span>
                              <span className="payment-due__price">
                                {payment_type === 1 ? discountedPrice : parseFloat(subtotal(card_rate, discountedPrice)).toFixed(2)}
                              </span>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                      <div className="apply-coupon-box row mt-4 mb-4 ml-0"
                        onClick={PaymentSales}
                        style={{ cursor: 'pointer' }}>
                        <span className="apply-code">{t('Purchase')}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div >
            </div >
          </div >
        </main >
        {/* <Footer /> */}

      </div>

    </>
  );
}

export default CheckoutPurchase;