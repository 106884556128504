import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Topbar from "../components/Topbar/Topbar";
import Table from 'react-bootstrap/Table';
import { Link, useParams } from "react-router-dom";
import config from "../config/config";
import axios from "axios";
import { displayError, displaySuccess, getCustomerList } from "../Utils/utils";
import './style.css'
import Navs from "../components/TopNav/TopNav";
import AssignUserModal from "../components/Modal/AssignUserModal";
import LoadingSpinner from "../components/LoadingSpinner";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from "react-i18next";
import imgnew from '../components/images/data-not-found-2.svg'
import imgnew2 from '../components/images/data-not-found.svg'

const UserList = () => {
  const { t, i18n } = useTranslation();
  const [assignListshow, setAssignListShow] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [is_loading, setIsloading] = useState(false);
  const [is_delete, setIsDelete] = useState(false);
  const [temp_id, setTempId] = useState('');
  const [search, setSearch] = useState('');
  const [current_page, setCurrentPage] = useState(1);
  const [processSlug, setProcessSlug] = useState('');
  const [customer_name, setCustomerName] = useState('');
  const [userId, setUserId] = useState('');

  const getCustomer = async () => {
    setIsloading(true)
    var cust_list_var = await getCustomerList();
    setCustomerList(cust_list_var.data)
    setIsloading(false)
  }

  const deleteProduct = async (id) => {
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/user/delete/' + id, {

    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(async (response) => {
        displaySuccess(t('User Delete Successfully'));
        setIsloading(true)
        var cust_list_var = await getCustomerList();
        setCustomerList(cust_list_var.data)
        setIsloading(false)
      })
      .catch((error) => {
        displayError(error, t);
      });
  };

  const searchhandle = async (e) => {
    setIsloading(true)
    var cust_list_var = await getCustomerList(current_page, e);
    setCustomerList(cust_list_var.data)
    setIsloading(false)
  }

  useEffect(() => {
    getCustomer();
  }, [])
  const CapitalizeFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  };

  return (
    <>
      <div id="wrapper">
        <LoadingSpinner is_loading={is_loading} />
        <Navs title={t('Customer List')} />
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title={t('Customer List')} />
            <div className="container-fluid full-width">
              <div className="row justify-content-between my-3">
                <div className="col-md-6">
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-end">
                    <div className="infobutton" >
                      <input className="form-control" id="validationCustom01" type="text" placeholder={t("search")} required="" data-bs-original-title="" title=""
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value)
                          searchhandle(e.target.value)
                        }} />
                    </div>
                    <div className="button2 ml-2">
                      <Link to="/addnewcustomer">
                        <div className=" btn btn-light" style={{ background: "#FFAF00", color: "#fff", borderRadius: "10px" }}>
                          <i class="fas fa-user-plus mr-1"></i>{t('New customer')}</div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4" >
                  <div className="row ml-5" >
                    <div className="col-md-3 buttonA">
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-12">
                  <div className="card m-overflow-h">
                    <div className="card-body m-p-0 p-0">
                      <div className="table-responsive">
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>{t('Name')}</th>
                              <th>{t('Email')}</th>
                              <th>{t('Phone')}</th>
                              {/* <th>{t('Status')}</th> */}
                              <th>{t('Action')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(customerList.length === 0) ? <tr>
                              <td colSpan="7">
                                {localStorage.getItem('langauge_selected') === "pt" ? <img src={imgnew2} className="no-data-img-2" /> :
                                  <img src={imgnew} className="no-data-img-2" />}
                              </td>
                            </tr> :
                              customerList.map((list) =>
                                <tr>
                                  {list.user_customer !== null ?
                                    <td>{CapitalizeFirst(list.user_customer.name)}</td>
                                    : <td>N/A</td>}
                                  {list.user_customer !== null ?
                                    <td>{(list.user_customer.email)}</td>
                                    : <td>N/A</td>}

                                  <td>{list?.user_customer?.phone_number}</td>
                                  <td>
                                    <div className="row">
                                      <Link to={"/edit-user/" + list.customer_id}>
                                        <a className="items-center mr-1 btn-outline-info btn btn-sm" href>
                                          <i class="far fa-edit"></i>
                                        </a></Link>
                                      <div className="items-center mr-1 btn-outline-danger btn btn-sm"

                                        onClick={() => {
                                          setIsDelete(true)
                                          setTempId(list.customer_id)
                                        }}>

                                        <i class="far fa-trash-alt" ></i>
                                      </div>
                                      <div className="items-center mr-1 btn-outline-warning btn btn-sm"
                                        title={t("Assign to user")}
                                        onClick={() => {
                                          // setUserId(list.user_id)
                                          setTempId(list.customer_id)
                                          setProcessSlug(list.slug)
                                          setAssignListShow(true)
                                          setCustomerName(list.user_customer.name)
                                        }}>
                                        <i class="fas fa-user-circle"></i>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            }

                          </tbody>
                        </Table>

                      </div>
                    </div>
                    {is_delete === true ? (
                      <SweetAlert
                        warning
                        showCancel
                        confirmBtnText={t("Yes")}
                        confirmBtnBsStyle="danger"
                        title={t('Are you sure?')}
                        onConfirm={(e) => {
                          deleteProduct(temp_id)
                          setIsDelete(false)
                          // window.location.reload(false)
                        }}
                        onCancel={(e) => {
                          setIsDelete(false)
                        }}
                        focusCancelBtn
                      >
                        {t('Do you want to delete this user')}?
                      </SweetAlert>
                    ) : ([]
                    )}
                    {assignListshow === true ?
                      <AssignUserModal assignListshow={assignListshow} setAssignListShow={setAssignListShow}
                        customer_id={temp_id} customer_name={customer_name}
                        is_customer={true}
                      /> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div >
    </>
  );
}
export default UserList
