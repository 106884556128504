import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Topbar from "../components/Topbar/Topbar";
import Navs from "../components/TopNav/TopNav";
import config from '../config/config';
import axios from "axios";
import { displaySuccess, displayError } from '../Utils/utils';
import { useTranslation } from "react-i18next";
import password from "../image/change-password.jpg";
import { Button } from "react-bootstrap";
const ChangePassword = () => {
  const { t } = useTranslation();
  const [old, setOld] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confirm, setConfirm] = useState('');

  const onFormSubmit = async () => {
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/auth/change-password', {
      password: newPass,
      password_confirmation: confirm,
      old_password: old
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (response.status === 200) {
          displaySuccess(response.data.message)
          setTimeout(() => window.location.href = "/dashboard", 3000);
        }
      })
      .catch((error) => {
        displayError(error, t);
      });

  }
  return (
    <>
      <div id="wrapper">
        <Navs title={t('Change Password')} />
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title={t('Change Password')} />
            <div className="container-fluid my-4">
              <div className="row">
                <div className="col-md-6">
                  <div className="card border-radius-10 shadow h-100 py-2 mb-3" style={{ borderRadius: '15px' }}>
                    <div className="card-body">
                      <div className="col ">
                        <label>{t('Current Password')} <span style={{ color: "red" }}>*</span></label>
                        <input className="form-control" type="password" placeholder={t('Current Password')}
                          onChange={e => setOld(e.target.value)}
                          value={old}
                        />
                      </div>
                      <div className="col mt-3">
                        <label>{t('New Password')} <span style={{ color: "red" }}>*</span></label>
                        <input className="form-control" type="password" placeholder={t('New Password')}
                          onChange={e => setNewPass(e.target.value)}
                          value={newPass}
                        />
                      </div>
                      <div className="col mt-3">
                        <label>{t('Confirm Password')} <span style={{ color: "red" }}>*</span></label>
                        <input className="form-control" type="password" placeholder={t('Confirm Password')}
                          onChange={e => setConfirm(e.target.value)}
                          value={confirm}
                        />
                      </div>
                      <Button variant="warning" className=" col button mt-5" type="submit"
                        onClick={onFormSubmit}
                      >
                        <span style={{ fontsize: '18px' }}>{t('Change Password')}</span>
                      </Button>
                      {/* <di
                    v class="form-holder"> */}

                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card border-radius-10 shadow h-100 py-2 mb-3" style={{ borderRadius: '15px' }}>
                    <div className="card-body text-center">
                      <img src={password} className="defaultImg img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ChangePassword;