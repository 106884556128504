import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import apiUrl from "../config/apiPath";
import config from "../config/config";
import { useTranslation } from 'react-i18next';
// import { Translate, I18n } from "react-redux-i18n"

export const displayErrorString = (error) => {
  toast.configure({
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: false,
  })
  toast.error(error);
}
export const displayError = (error, t) => {
  toast.configure({
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: false,
  })
  if (error.response.status === 422) {
    var row = [];
    Object.keys(error.response.data.errors).forEach((key, i) => {
      row.push(error.response.data.errors[key][0]);
    })
    const Msg = ({ closeToast, toastProps }) => (
      row.map((char, i) => {
        var res = char.replace(/\./g, '')
        return (
          <li>
            {t(res)}
          </li>
        );
      })
    )
    toast.error(Msg);
  } else if (error.response.status === 400 || error.response.status === 401) {
    toast.error(t(error.response.data.message));
  } else {
    toast.error(t(error.message));
  }
}
export const displaySuccess = (success) => {
  toast.configure({
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: false,
  })
  toast.success(success);
}


export const categoryList = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var result = await axios.get(path + "/common/categories", {
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      return null
    });
  return result;
}

export const verifyEmail = async (token) => {
  let path = config.siteUrl;
  //var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/auth/verify/" + token, {
    headers: { 'Content-Type': 'application/json' },
  })
    .then(function (response) {
      return true;
    })
    .catch(function (error) {
      return false;
    });
  return response_final;
}

export const userLoggedIn = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/auth/me", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`, 'Accept': 'application/json' },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      // if (error.response.status === 401) {
      //   window.location.href = '/'
      // }
      // else {
      return null
      // }
    });
  return response_final;
}

export const getProcessList = async (page = 1, search = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/process/process-list?page=" + page + "&search=" + search, {
  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}

export const getDashboardProcessList = async (page = 1, search = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/process/dashboard-process-list?page=" + page + "&search=" + search, {
  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getCustomerList = async (page = 1, search = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/user/customer-list?search=" + search, {

  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      // if (error.response.status === 401) {
      // window.location.href = '/';
      // } else {
      return null
      // }
    });
  return response_final;
}
export const getFileListDetail = async (id, page = 1, search = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/process/files/" + id + "?page=" + page + "&search=" + search, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      // if (error.response.status === 401) {
      //   window.location.href = '/';
      // } else {
      return null
      // }
    });
  return response_final;
}

export const getRolesList = async (page = 1) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/user/customer-list", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getEditProcess = async (slug) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/process/view-process/" + slug, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getRoles = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/view-roles", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getNotesList = async (id, search = '', user = '', date) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/auth/files/view-file-notes/" + id + "?keyword=" + search + "&user_name=" + user + "&date=" + date, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getEditNotes = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/notes/view-note-details/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getEditProfile = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/profile/edit", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const ProcessStatus = async (id, status) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/process/change-process-status/" + id, {
    status: status
  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}

export const getEditUser = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/user/edit/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getAssignUserList = async (id, page = 1) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/user/assigned-user-list/" + id + "?page=" + page, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}

export const getExistingCustomerList = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/user/existing-customer-list", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      // if (error.response.status === 401) {
      // window.location.href = '/';
      // } else {
      return null
      // }
    });
  return response_final;
}
export const getAssignedProcessList = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/process/assigned-process-list/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      // if (error.response.status === 401) {
      //   window.location.href = '/';
      // } else {
      return null
      // }
    });
  return response_final;
}
export const getProcessDetail = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/process/view-process-detail/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      // if (error.response.status === 401) {
      //   window.location.href = '/';
      // } else {
      return null
      // }
    });
  return response_final;
}
export const getDashboardDetail = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/user/processed-files-details", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}

export const getUserList = async (page = 1, search = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/user/user-list?page=" + page + "&search=" + search, {

  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      // if (error.response.status === 401) {
      // window.location.href = '/';
      // } else {
      return null
      // }
    });
  return response_final;
}
export const getUserListNew = async (page = 1, search = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/user/user-list-new?page=" + page + "&search=" + search, {

  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      // if (error.response.status === 401) {
      // window.location.href = '/';
      // } else {
      return null
      // }
    });
  return response_final;
}
export const processAllFiles = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/process/process-uploaded-files/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      // if (error.response.status === 401) {
      //   window.location.href = '/';
      // } else {
      return null
      // }
    });
  return response_final;
}

export const processFileById = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/process/process-file-by-id", {
    file_id: id
  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      // if (error.response.status === 401) {
      //   window.location.href = '/';
      // } else {
      return null
      // }
    });
  return response_final;
}
export const latestNotesDashboard = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/notes/view-latest-notes", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      // if (error.response.status === 401) {
      //   window.location.href = '/';
      // } else {
      return null
      // }
    });
  return response_final;
}
export const latestNotesProcess = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/notes/view-latest-process-notes/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      // if (error.response.status === 401) {
      //   window.location.href = '/';
      // } else {
      return null
      // }
    });
  return response_final;
}

export const getProcessRole = async (page = 1) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/process-roles", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getUserDetailss = async (id, pro) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/user/edit-process-user/" + id + "?process_id=" + pro, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getStoragePrices = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/get-unit-prices", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getCreditCardRates = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/payment/card/rates", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      // if (error.response.status === 401) {
      //   window.location.href = '/';
      // } else {
      return null
      // }
    });
  return response_final;
}
export const getCountry = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var result = await axios.get(path + "/get-country", {
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      // if (error.response.status === 401) {
      //   window.location.href = '/';
      // } else {
      return null
      // }
    });
  return result;
}
export const getStates = async (country_id = null) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var result = await axios.get(path + "/get-state/" + country_id, {
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      // if (error.response.status === 401) {
      //   window.location.href = '/';
      // } else {
      return null
      // }
    });
  return result;
}

export const getCities = async (state_id = null) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var result = await axios.get(path + "/get-city/" + state_id, {
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      // if (error.response.status === 401) {
      //   window.location.href = '/';
      // } else {
      return null
      // }
    });
  return result;
}
export const getPayment = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var result = await axios.get(path + "/payment/get-payment/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      // if (error.response.status === 401) {
      //   window.location.href = '/';
      // } else {
      return null
      // }
    });
  return result;
}
export const getDiscountList = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var result = await axios.get(path + "/get-discounts", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      // if (error.response.status === 401) {
      //   window.location.href = '/';
      // } else {
      return null
      // }
    });
  return result;
}
export const getProcessNotesList = async (id, page = 1) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/notes/view-process-notes/" + id + "?page=" + page, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getPaymentList = async (page = 1, type = '', status = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/payment/get-payments-list?page=" + page + "&payment_type=" + type + "&status=" + status, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getPaymentDetails = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/payment/get-payment-by-slug/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      // if (error.response.status === 401) {
      //   window.location.href = '/';
      // } else {
      return null
      // }
    });
  return response_final;
}
export const getBillingDetails = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/payment/get-billing-details", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}

export const getPaymentStatus = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/get-payment-status", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getNotificationList = async (page = 1) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/notifications?page=" + page, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getNewProcessUserList = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/process/all-process-users/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getNotificationCount = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/get-notification-count", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getUserCards = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/payment/get-user-cards", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}