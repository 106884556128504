import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import img from '../images/placeholder.png'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import config from "../../config/config";
import axios from "axios";
import { displayError, displaySuccess, getCustomerList, getExistingCustomerList, getRoles } from "../../Utils/utils";
import Select from 'react-select';
import LoadingSpinner from "../LoadingSpinner";
import { useTranslation } from "react-i18next";
import ReactInputMask from 'react-input-mask';

const NewProcessModal = (props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(true);
  const [processName, setProcessName] = useState('');
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userType, setType] = useState(1);
  const [customer, setCustomer] = useState('');
  const [val, setVal] = useState(0);
  const [customerList, setCustomerList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [is_loading, setIsloading] = useState(false)
  const handleClose = () => props.setProcessModalShow(false);
  const handleShow = () => setShow(true);
  let [mask, setMask] = React.useState(null);
  const [mob2, setMob2] = useState('');
  const onFormSubmit = async () => {
    setIsloading(true)
    var token = localStorage.getItem('token');
    let path = config.siteUrl;

    if (show) {
      var data1 = {
        process_name: processName,
        old_user_id: customer,
        existing_user: 1,
        language: localStorage.getItem('langauge_selected')
      };
    } else {
      var data1 = {
        language: localStorage.getItem('langauge_selected'),
        existing_user: 0,
        process_name: processName,
        user_name: userName,
        user_email: userEmail,
        type: userType,
        mobile_number: val,
      };
    }
    await axios.post(path + '/process/add-process', data1, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setIsloading(false)
        displaySuccess(t('New process has been added'));
        props.getProcess();
        props.setProcessModalShow(false)
      })
      .catch((error) => {
        setIsloading(false)
        displayError(error, t);
      });
  };
  const getCustomer = async () => {
    var cust_list_var = await getExistingCustomerList();
    var temp_arr = []
    cust_list_var.map((list) => (
      temp_arr.push({ value: list.customer_id, label: list.user_customer.name + " (" + list.user_customer.email + ")" })
    ))
    setCustomerList(temp_arr)
  }
  const getType = async () => {
    var roles_var = await getRoles();
    setRoles(roles_var)
  }
  useEffect(() => {
    getCustomer()
    getType();
  }, [])
  const mobHandle = ev => {
    let { value } = ev.target;
    let nums = value.replace(/[^0-9]/g, "");
    setMask("+55 99 99999-9999");
    setVal(ev.target.value);
    setMob2(nums);
    if (nums.length < 3 && mask != null) {
      setMask(null);
      ev.target.value = nums;
    }
  };
  return (
    <>
      <LoadingSpinner is_loading={is_loading} />

      <Modal show={props.processModalshow} onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('Add New Process')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="process">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <label>{t('Process Name')} <span style={{ color: "red" }}>*</span></label>
              <Form.Control type="text" placeholder="" onChange={e => setProcessName(e.target.value)} />
            </Form.Group>

            {/* <div className="container"> */}
            <div className="row ml-0">
              <div className="col-12">
                <input className="form-check-input boxA" id="radio1" type="radio" name="radio1" value="option1"
                  onClick={() => setShow(true)} checked={show ? true : false} />
                <label className="form-check-label" for="radio1">{t('Existing Customer')}</label>
              </div>
              <div className="col-12">
                <input className="form-check-input boxB" id="radio2" type="radio" name="radio1" value="option1"
                  onClick={() => setShow(false)} checked={show ? false : true} />
                <label className="form-check-label" for="radio2">{t('New Customer')}</label>
              </div>
            </div>
            {/* </div> */}
            <br />
            {show === true ?
              <div className="mb-3" controlId="formBasicPassword">
                <label>{t('Customer')} <span style={{ color: "red" }}>*</span></label>

                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  // defaultValue={colourOptions[0]}
                  // isRtl={isRtl}
                  isSearchable={true}
                  name="color"
                  options={customerList}
                  onChange={e => {
                    setCustomer(e.value)
                  }}
                />
              </div>
              :
              <div className="userdetail">
                {/* <div className="container"> */}
                <div className="row">
                  <div className="col-sm-6">
                    <label>{t('Full Name')} <span style={{ color: "red" }}>*</span></label>
                    <input
                      className="form-control" type="Username" placeholder={t('Please enter your name')} onChange={e => setUserName(e.target.value)} />
                  </div>
                  <div className="col-sm-6">
                    <label>{t('Email Address')} <span style={{ color: "red" }}>*</span></label>
                    <input
                      className="form-control" type="useremail" placeholder={t('Please enter your email')} onChange={e => setUserEmail(e.target.value)} />
                    {/* <div className="text-muted">
                      ( We'll never share your email with anyone else. )
                    </div> */}
                  </div>
                </div>
                {/* </div> */}
                {/* <div className="container"> */}
                <div className="row mt-2">
                  <div className="col-sm-6">
                    <label>{t('Mobile')} </label>
                    <ReactInputMask
                      className="form-control mt-2 form-control-input"
                      mask={mask}
                      onChange={mobHandle}
                      maskChar="_"
                      value={val}
                      placeholder={'mobile no'}
                    // {...props}
                    />
                    {/* <input
                      className="form-control"
                      // type="text"
                      pattern="[0-9]*"
                      maxlength="10"
                      // value={val}
                      onChange={(e) =>
                        setVal((v) => (e.target.validity.valid ? e.target.value : v))} placeholder={t('Please enter your contact no')} /> */}
                  </div>
                  <div className="col-sm-6">
                    <label>{t('User Type')} <span style={{ color: "red" }}>*</span></label>
                    <select className="form-control" as="select" type="value" placeholder="Please select the type" onChange={e => setType(e.target.value)} >
                      <option value="">{t('Select User Type')}</option>
                      {/* {roles.map((list) => ( */}
                      <option value="2">Customer</option>
                      {/* ))} */}
                    </select>
                  </div>
                </div>
                {/* </div> */}
              </div>
            }
            <br />
            <Button variant="warning" className="button" type="submit"
              onClick={onFormSubmit}
            >
              <span style={{ fontsize: '18px' }}>{t('Submit')}</span>
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default NewProcessModal;