import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Topbar from "../components/Topbar/Topbar";
import Navs from "../components/TopNav/TopNav";
import Switch from "react-switch";
import { Link, useNavigate } from "react-router-dom";
import Tooltip from 'react-bootstrap/Tooltip';
import googlePlay from '../components/images/playStore.png';
import appStore from '../components/images/appStore.png';
import Overlay from 'react-bootstrap/Overlay';
import Button from 'react-bootstrap/Button';
import { useSelector, useDispatch } from 'react-redux'
import './style.css'
import config from '../config/config';
import axios from "axios";
import { displaySuccess, displayError } from '../Utils/utils';
import { useTranslation } from "react-i18next";
const Settings = () => {
	const userInfo = useSelector((state) => state.userInfo.value)
	const navigate = useNavigate()
	const { t } = useTranslation();

	const [is_enabled, setIsEnabled] = useState(false)
	const [skipFactor, setSkipFactor] = useState(true);
	const handleChange = () => {
		setSkipFactor(!skipFactor)
	}
	const [show, setShow] = useState(false);
	const target = useRef(null);
	const [img_url, setImgUrl] = useState('');
	const [secretKey, setSecretKey] = useState('');

	const handle = () => {
		setShow(!show)
	}
	const isTwoFactorEnabled = async () => {
		setIsEnabled(!is_enabled)
		if (is_enabled) {
			let path = config.siteUrl;
			await axios.post(path + '/register/disable-2fa', {
				is_2fa_enabled: false,
				email: userInfo.email
			})
				.then((response) => {
					displaySuccess(t('Two factor authentication disabled'));
				})
				.catch((error) => {
					displayError(error, t);
				});
		} else {
			var token = localStorage.getItem('token');
			let path = config.siteUrl;
			await axios.post(path + '/register/enable-2fa', {
				is_2fa_enabled: true,
			}, {
				headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
			})
				.then((response) => {
					setSecretKey(response.data.data.user.google2fa_secret)
					setImgUrl(response.data.data.image_url)
				})
				.catch((error) => {
					displayError(error, t);
				});
		}
	}

	const completeRegistration = () => {
		navigate(`/setting-otp`, {
			state: {
				email: userInfo.email
			}
		})
	}
	useEffect(() => {
		if (userInfo.is_2fa_enabled === 1 && userInfo.google2fa_secret !== "") {
			setShow(true)
			setIsEnabled(true)
		} else if (userInfo.is_2fa_enabled === 0 && userInfo.google2fa_secret === "") {
			setIsEnabled(false)
			setShow(false)
		}
	}, [userInfo.is_2fa_enabled])
	return (
		<>
			<div id="wrapper">
				<Navs title={t('Settings')} />
				<Sidebar />
				<div id="content-wrapper" className="d-flex flex-column">
					<div id="content">
						<Topbar title={t('Settings')} />
						<div className="container my-4">
							<div className="row">
								<div className="col-md-8">
									<div className="card border-radius-10 shadow h-100 py-2 mb-3" style={{ borderRadius: '15px' }}>
										<div className="card-body">
											<div className="row no-gutters align-items-center justify-content-between">
												<h4>{t('Two Factor Authentication')}</h4>
												<Button ref={target} onClick={handle} style={{ borderColor: 'white' }}>
													<Switch
														onColor="#86d3ff"
														onHandleColor="#2693e6"
														handleDiameter={30}
														uncheckedIcon={false}
														checkedIcon={false}
														boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
														activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
														height={20}
														width={48}
														className="react-switch  ml-3 "
														id="material-switch"
														onChange={() => isTwoFactorEnabled()}
														checked={is_enabled}
													/>
												</Button>
												<div>
													<Overlay target={target.current} show={show} placement="right">
														<Tooltip id="overlay-example" >
															{t('If you skip two factor authentication you might lose all your documents')}.
														</Tooltip>
													</Overlay>
												</div>
											</div>
											{/* <div class="form-holder"> */}
											{secretKey !== '' ?
												<div class="form-content-css">
													<div class="form-items">
														<h3>{t('Set up google authenticator')}.</h3>
														<div className='mt-2 mb-4'>{t('Set up your two factor authentication by scanning the barcode below Alternatively you can use the code')} <strong>ADGT23DSSWE#444</strong></div>
														{/* <img src={img} /> */}
														<p>{t('Access to the most powerfull tool in the entire design and web industry')}.</p>
														<img src={img_url} />
														< div className="mt-4">{t('You must set up your Google Authenticator app before continuing You will be unable to login otherwise')}</div>


														<Link to="/setting-otp">
															<button id="submit" type="submit" className="ibtn mt-3" onClick={completeRegistration}>{t('Complete Registration')}</button>
														</Link>

														<div className='row mt-5 ml-2'>
															{t('To scan QR code you can download authenticator apps from below links')}:
														</div>
														<div className='row'>
															<a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US" target="_blank">
																<img src={googlePlay} className="playStore-img mt-2 ml-2" />
															</a>
															<a href="https://apps.apple.com/in/app/google-authenticator/id388497605" target="_blank">
																<img src={appStore} className="playStore-img mt-2 ml-2" />
															</a>
														</div>
													</div>
												</div>
												: is_enabled === true ?
													<div>
														<span className="text-success font-weight-bold">{t('Verified')}  <i class="fas fa-check-circle text-success text-lg"></i></span>
													</div>
													: null}
											{/* </div> */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default Settings;