import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Topbar from "../components/Topbar/Topbar";
import './style.css';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Navs from "../components/TopNav/TopNav";
import Search from "./search";
import NoteTab from "./Note";
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import Summary from "./summary";
import Chat from "./chat";
const VideoReader = () => {
	const location = useLocation();

	const queryParams = new URLSearchParams(window.location.search);
	const fileId = queryParams.get('file_id');
	const { id } = useParams();
	const { t } = useTranslation();
	const [key, setKey] = useState(location?.state?.key);
	const [file_id, setFileId] = useState('');
	const [file_url, setFileUrl] = useState('');
	const [fileType, setFileType] = useState('');
	const [fileName, setFileName] = useState('');
	const [noteArr, setNoteArr] = useState([])
	const [processName, setProcessName] = useState('');
	const [isProcess, setIsProcess] = useState(false)
	const [isMute, setIsMute] = useState(false)
	const [muteNote, setMuteNote] = useState(true);

	const navigate = useNavigate()

	const summary = queryParams.get('summary')
	const goBack = () => {
		if (fileId == null) {
			navigate(`/process-list`)
		} else {
			navigate(`/file-list/${id}`)
		}
	}
	return (
		<>
			<div id="wrapper">
				<Navs title={t('File / Video reader')} />
				<Sidebar />
				<div id="content-wrapper" className="d-flex flex-column">
					<div id="content">
						<Topbar title={t('File / Video reader')} />

						<div className="container-fluid full-width">
							{/* <Link to="/file-list"> */}
							<div className="row justify-content-end">
								<div className="col-auto">
									<div className="back-btn mt-2 ml-auto mb-3 btn btn-secondary" style={{ cursor: 'pointer' }} onClick={goBack}>
										<div className="text-light back-txt" ><i class="fas fa-long-arrow-alt-left"></i> &nbsp;
											{t("Back")}
										</div>
									</div>
								</div>
							</div>

							{/* </Link> */}
							<Tabs
								id="controlled-tab-example"
								activeKey={key}
								onSelect={(k) => {
									setIsMute(!isMute)
									setMuteNote(!muteNote)
									setKey(k)
								}}
							>
								<Tab eventKey="Search" title={t("Search")}>
									<Search
										search={location?.state?.search}
										fileId={fileId}
										processId={id}
										processName={(e) => setProcessName(e)}
										noteArr={(e) => setNoteArr(e)}
										file_id={(e) => setFileId(e)}
										fileUrl={(e) => setFileUrl(e)}
										fileType={(e) => setFileType(e)}
										fileName={(e) => setFileName(e)}
										isProcess={(e) => setIsProcess(e)}
										mute={isMute}
									// muteNote={muteNote}

									/>
								</Tab>
								<Tab eventKey="Note" title={t("Notes")}>
									{key === "Note" &&
										<NoteTab
											fileId={fileId}
											processId={id}
											noteArr={noteArr}
											fileUrl={file_url}
											fileType={fileType}
											fileName={fileName}
											processName={processName}
											isProcess={isProcess}
											// mute={isMute}
											muteNote={muteNote}
										/>
									}
								</Tab>
								<Tab eventKey="Summary" title={t("Summary")}>
									{key === "Summary" &&
										<Summary
											search={location?.state?.search}
											fileId={fileId}
											processId={id}
											processName={processName}
											fileUrl={file_url}
											noteArr={noteArr}
											fileType={fileType}
											fileName={fileName}
											isProcess={isProcess}
											mute={isMute}

										/>
									}
								</Tab>

								<Tab eventKey="Chat" title={t('Artificial Intelligence')}>
									<Chat />
								</Tab>


							</Tabs>
						</div>

					</div>
				</div>
			</div>
		</>
	);
}
export default VideoReader;