import React from 'react';
import image1 from '../images/last-1.png';
import '../WhyChooseUs/WhyChooseUs.css'
import { Translate, I18n } from "react-redux-i18n";
import { useTranslation } from 'react-i18next';

const WhyChooseUs = () => {
  const { t } = useTranslation()
  return (
    <div>
      <section class="about-2 section" id="about">
        <div class="container">
          <div class="row">

            <div class="col-md-6 col-lg-5 align-self-center">
              <div class="about_section">
                <h2 class="mb-3">{t('Why choose us')}</h2>
                <p>{t('The union between technology and experience Our system born from real case scenarios and years of experience in court')}.</p>

              </div>
              <ul class="checklist">
                <li>{t('Experience')}</li>
                <li>{t('Reputation')}</li>
                <li>{t('Easy to use')} </li>
                <li>{t('Commitment to security')}</li>
                <li>{t('Commitment to quality')}</li>
                <li>{t('Constant evolution')}</li>
              </ul>

            </div>
            <div class="col-md-6 col-lg-5 offset-lg-1">
              <img src={image1} class="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>


    </div>
  );
}

export default WhyChooseUs