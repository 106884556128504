import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Topbar from "../components/Topbar/Topbar";
// import { Table, thead, tbody, tr, th, td } from 'react-super-responsive-table'
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import ReactPaginate from 'react-paginate';
import { Modal, Table } from 'react-bootstrap';
import Navs from "../components/TopNav/TopNav";
import { getCustomerList, getUserList, getUserListNew } from "../Utils/utils";
import LoadingSpinner from "../components/LoadingSpinner";
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { Translate, I18n } from "react-redux-i18n";
import { useTranslation } from "react-i18next";
import NewUser from "../components/Modal/NewUser";
import imgnew from '../components/images/data-not-found-2.svg'
import imgnew2 from '../components/images/data-not-found.svg'
import AssignUserModal from "../components/Modal/AssignUserModal";


const UserManagement = (props) => {
	const { t } = useTranslation();
	const [userList, setUserList] = useState([]);
	const [is_loading, setIsloading] = useState(false)
	const [newUserModal, setNewUserModal] = useState(false);
	const [user_id, setUserId] = useState('');
	const [search, setSearch] = useState('');
	const [pageCount, setPageCount] = useState(1);
	const [current_page, setCurrentPage] = useState(1);
	const [process_id, setProcessId] = useState('');
	const [temp_id, setTempId] = useState('');
	const [processSlug, setProcessSlug] = useState('');
	const [customer_name, setCustomerName] = useState('');
	const [assignListshow, setAssignListShow] = useState(false);

	const getUsers = async () => {
		setIsloading(true)
		var user_list_var = await getUserListNew();
		setUserList(user_list_var.data)
		setPageCount(user_list_var.last_page)
		setIsloading(false)
	}
	useEffect(() => {
		getUsers()
	}, [])
	const searchhandle = async (e) => {
		setIsloading(true)
		var user_list_var = await getUserListNew(current_page, e);
		setUserList(user_list_var.data)
		setPageCount(user_list_var.last_page)
		setIsloading(false)
	}

	const handlePageClick = async (e) => {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
		const selectedPage = e.selected + 1;
		var user_list_var = await getUserListNew(selectedPage);
		setUserList(user_list_var.data)
		setPageCount(user_list_var.last_page)
	};
	return (
		<>
			<div id="wrapper">
				<LoadingSpinner is_loading={is_loading} />
				<Navs title={t('User List')} />
				<Sidebar />
				<div id="content-wrapper" className="d-flex flex-column">
					<div id="content">
						<Topbar title={t('User List')} />
						<div className="container-fluid full-width">
							<div className="row justify-content-between my-3">
								<div className="col-auto">
									{/* <h3>User List</h3> */}
								</div>
								<div className="col-md-8">
									<div className="d-flex justify-content-end">
										<div className="infobutton" style={{ width: '200px' }}>
											<input className="form-control" id="validationCustom01" type="text" placeholder={t("search")} required="" data-bs-original-title="" title=""
												value={search}
												onChange={(e) => {
													setSearch(e.target.value)
													searchhandle(e.target.value)
												}} />
											{/* <input className="form-control" id="validationCustom01" type="text" placeholder={t('search')} required="" data-bs-original-title="" title="" /> */}
										</div>
									</div>
								</div>
							</div>
							<div className="row mb-4">
								<div className="col-md-12">
									<div className="card m-overflow-h">
										<div className="card-body m-p-0 p-0 mb-3">
											<div className="table-responsive">
												<Table className="table table-striped align-items-center">
													<thead>
														<tr>
															<th>{t('Name')}</th>
															<th>{t('Email')}</th>
															<th>{t('Phone')}</th>
															<th>{t('Action')}</th>
														</tr>
													</thead>
													<tbody>
														{(userList.length === 0) ? <tr>
															<td colSpan="7">
																{localStorage.getItem('langauge_selected') === "pt" ? <img src={imgnew2} className="no-data-img-2" /> :
																	<img src={imgnew} className="no-data-img-2" />}
																{/* <img src={imgnew} className="no-data-img-2" /> */}
															</td>
														</tr> : ''}
														{userList.map((list) => (

															<tr>
																{/* <td>{CapitalizeFirst(list.user_customer.name)}</td>
																<td>{CapitalizeFirst(list.user_customer.email)}</td> */}
																<td>{list.name}</td>
																<td>{list.email}</td>
																<td>{list.phone_number}</td>
																<td>
																	<div className="row">
																		{/* <div className="items-center mr-1 btn btn-md btn-outline-primary "
																			style={{ color: "rgba(29, 70, 246, 0.64)" }} title={t("Edit")}
																			onClick={() => {
																				setUserId(list.id)
																				setNewUserModal(true)
																				// setProcessId(list.process_id)
																			}}>
																			<i class="far fa-edit"></i>
																		</div> */}
																		<div className="items-center mr-1 btn-outline-warning btn btn-sm"
																			title={t("Assign to user")}
																			onClick={() => {
																				// setUserId(list.user_id)
																				setTempId(list.id)
																				setProcessSlug(list.slug)
																				setAssignListShow(true)
																				setCustomerName(list.name)
																			}}
																		>
																			<i class="fas fa-user-circle"></i>
																		</div>
																		{/* <div className="items-center mr-1 btn btn-md btn-outline-danger" style={{ color: "#FF1F1F" }} title="Delete">
																			<i class="far fa-trash-alt"></i>
																		</div> */}
																	</div>
																</td>
															</tr>
														))}

													</tbody>
												</Table>
											</div>
										</div>
										<div className="row">
											<div className="mx-auto d-block my-3">
												<ReactPaginate
													previousLabel={t('prev')}
													nextLabel={t('next')}
													breakLabel={"..."}
													breakClassName={"break-me"}
													pageCount={pageCount}
													marginPagesDisplayed={0}
													pageRangeDisplayed={3}
													onPageChange={handlePageClick}
													containerClassName={"pagination"}
													subContainerClassName={"pages pagination"}
													activeClassName={"active"}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							{assignListshow === true ?
								<AssignUserModal
									assignListshow={assignListshow}
									setAssignListShow={setAssignListShow}
									customer_id={temp_id}
									customer_name={customer_name}
								/> : null}
						</div>
					</div>
				</div>
				{newUserModal === true ?
					<NewUser newUserModal={newUserModal} setNewUserModal={setNewUserModal} userId={user_id} process_id={process_id}
						getUsers={() => getUsers()} />
					: null}
			</div>
		</>
	);
}

export default UserManagement;
