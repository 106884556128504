import React, { useState, useRef, useEffect, useCallback } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Topbar from "../components/Topbar/Topbar";
import Button from 'react-bootstrap/Button';
import config from "../config/config";
import axios from "axios";
import Form from 'react-bootstrap/Form';
import { displayError, displaySuccess, getRoles, getEditUser } from "../Utils/utils";
import Navs from "../components/TopNav/TopNav";
import { Link, useParams } from "react-router-dom";
import { Translate, I18n } from "react-redux-i18n";

import ReactInputMask from 'react-input-mask';
import ReactCrop from 'react-image-crop';
import { Container, Row, Col, Image, Modal } from 'react-bootstrap';
import 'react-image-crop/dist/ReactCrop.css';
import { useTranslation } from "react-i18next";
import { Initial } from "react-initial";
const EditUser = (props) => {
  const { t } = useTranslation();

  const [upImg, setUpImg] = useState(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 / 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [imageData, setImageData] = useState('./images/index.jpeg');
  const previewCanvasRef = useRef(null);
  const [show, setShow] = useState(false);

  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userType, setType] = useState('');
  const [val, setVal] = useState(0);
  const [desc, setDesc] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [roles, setRoles] = useState([]);
  const [file, setFile] = useState();
  const { id } = useParams()
  let [mask, setMask] = React.useState(null);
  const [mob2, setMob2] = useState('');
  const fileInput = useRef();
  const selectFile = () => {
    fileInput.current.click();
  }
  //for upload image 
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const getEditData = async () => {
    var edit_var = await getEditUser(id);
    setUserName(edit_var.name)
    setUserEmail(edit_var.email)
    setVal(edit_var.phone_number)
    setDesc(edit_var.description)
    setType(edit_var.role.role_id)
    setImgUrl(edit_var?.media)
  }


  const onFormSubmit = async () => {
    let path = config.siteUrl;
    await axios.post(path + '/user/update/' + id, {
      user_name: userName,
      user_email: userEmail,
      type: userType,
      phone_number: val,
      description: desc,
      language: localStorage.getItem('langauge_selected')
    }, {
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        displaySuccess(t('User Edit Successfully'));
        setTimeout(() => window.location.href = "/customer-list", 3000);
      })
      .catch((error) => {
        displayError(error, t);
      });

  };

  const getType = async () => {
    var roles_var = await getRoles();
    setRoles(roles_var)
  }
  useEffect(() => {
    getEditData();
    getType();
  }, [])
  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setShow(true)
    }
  };

  const handleClose = () => {
    setShow(false);
    document.getElementById('profile_picture').value = null;
  }
  function generateDownload(canvas, crop) {
    if (!crop || !canvas) {
      return;
    }

    canvas.toBlob(
      (blob) => {
        var dataURL = new File([blob], "my_image.png", { type: "image/png", lastModified: new Date().getTime() })
        const data = new FormData();
        data.append('file', dataURL);
        data.append('user_id', id)
        var token = localStorage.getItem('token');
        // props.toggleLoader(true);
        let path = config.siteUrl;
        axios.post(path + '/user/upload-profile-picture', data, {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        })
          .then(function (response) {
            displaySuccess(t('Profile Picture Uploaded Successfully'));
            setImgUrl(response.data.data)
            setUpImg(null);
            setShow(false)
          })
          .catch(function (error) {
            displayError(error, t);
          });
      },
      'image/png',
      1
    );
  }
  const mobHandle = ev => {
    let { value } = ev.target;
    let nums = value.replace(/[^0-9]/g, "");
    setMask("+55 99 99999-9999");
    setVal(ev.target.value);
    setMob2(nums);
    if (nums.length < 3 && mask != null) {
      setMask(null);
      ev.target.value = nums;
    }
  };
  return (
    <>
      <div id="wrapper">
        <Navs title={t('Add New Customer')} />
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title={t('Edit Customer')} />
            <div className="container-fluid full-width">
              <div className="card">
                <div className="card-header">
                  <Link to="/user-list">
                    <div className="back-btn mt-2 mr-4 mb-3" style={{ marginLeft: "auto" }}>
                      <div className="text-light back-txt" ><i class="fas fa-long-arrow-alt-left"></i> {t('Back')}</div>
                    </div>
                  </Link>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-8 col-md-8 col-xl-8">
                      <div className="row">
                        <div className="col-sm-6">
                          <label>{t('Full Name')} <span style={{ color: "red" }}>*</span></label>
                          <input className="form-control" type="Username" placeholder={t('Please enter your name')} onChange={e => setUserName(e.target.value)} value={userName} />
                        </div>
                        <div className="col-sm-6">
                          <label>{t('Email Address')} <span style={{ color: "red" }}>*</span></label>
                          <input className="form-control" type="useremail" placeholder={t('Please enter your email')} value={userEmail}
                            onChange={(e) => setUserEmail(e.target.value)} />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-sm-6">
                          <label>{t('Mobile')} <span style={{ color: "red" }}>*</span></label>
                          <ReactInputMask
                            className="form-control mt-2 form-control-input"
                            mask={mask}
                            onChange={mobHandle}
                            maskChar="_"
                            value={val}
                            placeholder={'mobile no'}
                          // {...props}
                          />
                        </div>
                        <div className="col-sm-6">
                          <label value="">{t('Type')}<span style={{ color: "red" }}>*</span></label>
                          <select className="form-control" as="select" type="value" placeholder={t('Please select the type')} onChange={e => setType(e.target.value)} value={userType} disabled >

                            {roles.map((list) => (
                              <option value={list.id} selected={(list.id === userType) ? true : false}>{list.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="notes mt-3">
                        <div>{t('Note')}</div>
                        <textarea className="form-control mt-2 mb-2" as="textarea" placeholder={t('Please select the note')} rows={10} value={desc} onChange={(e) => setDesc(e.target.value)} />
                      </div>
                      <Button variant="warning" className="button" type="submit"
                        onClick={onFormSubmit}
                      >
                        <span style={{ fontsize: '18px' }}>{t('Update')}</span>
                      </Button>
                    </div>
                    <div className="col-sm-4 col-md-4 col-xl-4 ">
                      <h6 style={{ color: "#444444" }}>{t('PROFILE PICTURE')}</h6>
                      {imgUrl !== null ?
                        <Image className="img-fluid profile_img" src={imgUrl.url} />
                        :
                        <Initial
                          name={userName}
                          color="#0D5064"
                          seed={5}
                          fontSize={40}
                          height={100}
                          width={100}
                        // style={{ borderRadius: '20px' }}
                        />
                      }
                      <div className="media-body mt-2">
                        <div className="custom-file">
                          <input type="file" accept=".jpg,.jpeg,.png" name="profile_image" id="profile_picture" onChange={onSelectFile} />
                          <Modal size="lg" show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                              <Modal.Title>Update Profile Picture</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                              <div className="row">
                                <div className="col-md-6 mt-3">
                                  {
                                    (upImg !== null) ? <>
                                      <div className="col-md-12 mt-3">
                                        <center>
                                          {/* hiii */}
                                          <ReactCrop
                                            src={upImg}
                                            onImageLoaded={onLoad}
                                            crop={crop}
                                            onChange={(c) => setCrop(c)}
                                            onComplete={(c) => setCompletedCrop(c)}
                                            className="react-crop-image"
                                          />
                                        </center>

                                      </div>


                                    </> : ""

                                  }
                                </div>
                                <div className="col-md-6 mt-3">
                                  {
                                    (upImg) ? <>
                                      <canvas
                                        ref={previewCanvasRef}
                                        style={{
                                          width: Math.round(completedCrop?.width ?? 0),
                                          height: Math.round(completedCrop?.height ?? 0)
                                        }}
                                      />
                                    </>
                                      : <>
                                      </>

                                  }
                                </div>
                              </div>

                            </Modal.Body>
                            <Modal.Footer>
                              <button
                                className="btn btn-warning mr-2"
                                type="button"
                                disabled={!completedCrop?.width || !completedCrop?.height}
                                onClick={() =>
                                  generateDownload(previewCanvasRef.current, completedCrop)
                                }
                              >
                                Upload cropped image
                              </button>
                              <Button variant="secondary" onClick={handleClose}> Close </Button>
                            </Modal.Footer>
                          </Modal>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditUser;