import React, { useEffect, useState } from "react";
import './style.css';
import { Link } from "react-router-dom";
import config from '../config/config';
import axios from "axios";
import { displaySuccess, displayError } from '../Utils/utils'
import { useSelector, useDispatch } from 'react-redux'
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { useNavigate, useLocation } from "react-router-dom";
import { update } from "../features/userInfoSlice";
import { useTranslation } from "react-i18next";
import image from '../components/images/logo_trans.png';

const Login = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const userInfo = useSelector((state) => state.userInfo.value)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useGoogleOneTapLogin({

    onSuccess: credentialResponse => {
      GoogleLoginApi(credentialResponse)
    }
  });
  const Login = (e) => {
    e.preventDefault();
    // toggleLoader(true);
    let path = config.siteUrl;
    path = path + '/auth/login';
    axios.post(path, {
      email: email,
      password: password,
      language: localStorage.getItem('langauge_selected')
    })
      .then(function (response) {
        displaySuccess(t('Login Successfull'));
        localStorage.setItem('role_id', response.data.data.role.role_id)
        dispatch(update(response.data.data))
        if (response.data.data.is_2fa_enabled === 1 && response.data.data.google2fa_secret !== "") {
          localStorage.setItem('tempToken', response.data.data.access_token.access_token);

          navigate(`/loginotp`, {
            state: {
              email: response.data.data.email
            }
          })
        } else {
          localStorage.setItem('token', response.data.data.access_token.access_token);
          if (response.data.data.role.role_id == 2) {
            navigate(`/process-list`)
          } else {
            navigate(`/dashboard`)
          }
        }

      })
      .catch((error) => {
        displayError(error, t);
      });
  };

  const GoogleLoginApi = async (e) => {
    let path = config.siteUrl;
    path = path + '/auth/google-login';
    axios.post(path, {
      id_token: e.credential
    })
      .then(async (response) => {
        dispatch(update(response.data.data))
        if (response.data.data.is_2fa_enabled == 1 && response.data.data.google2fa_secret == "") {
          var token = response.data.data.access_token.access_token;
          let path = config.siteUrl;
          await axios.post(path + '/register/enable-2fa', {
            is_2fa_enabled: true,
          },
            {
              headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
            })
            .then((response) => {
              displaySuccess(t('Two factor is enabled'));
              navigate(`/2fa-initiate`, {
                state: {
                  google2fa_secret: response.data.data?.user?.google2fa_secret,
                  qr_url: response.data.data.image_url,
                  email: response.data.data?.user?.email
                }
              })
            })

        } else if (response.data.data.is_2fa_enabled == 1 && response.data.data.google2fa_secret !== "") {
          localStorage.setItem('tempToken', response.data.data.access_token.access_token);

          navigate(`/loginotp`, {
            state: {
              email: response.data.data.email
            }
          })
        }
        else {
          localStorage.setItem('token', response.data.data.access_token.access_token);
          navigate(`/dashboard`)
        }
      })
      .catch((error) => {
        displayError(error, t);
      });
  };

  return (
    <>
      <div class="form-body">
        <div class="website-logo">
          <a >
            <div class="logo">
              <img class="logo-size" src="images/logo-light.svg" alt="" />
            </div>
          </a>
        </div>
        <div class="row">
          <div class="img-holder">
            <div class="bg"></div>
            <div class="info-holder" style={{ top: '70%' }}>
              <img src={image} />
            </div>
          </div>
          <div class="form-holder">
            <div class="form-content">
              <div class="form-items">
                <h3>{t('Welcome')}</h3>
                <p>{t('Login into your account')}</p>
                <div class="page-links">
                  <a class="active">{t('Login')}</a>
                </div>
                <form>
                  <label>{t('Email Address')}</label>
                  <input class="form-control" type="text" name="username" placeholder={t('Email Address')} required
                    onChange={e => setEmail(e.target.value)} />

                  <label>{t('Password')}</label>
                  <input class="form-control" type="password" name="password" placeholder={t('Password')} required
                    onChange={e => setPassword(e.target.value)} />

                  <div className="row mt-4 mb-2">
                    <div class="form-button d-flex justify-content-left">
                      <Link to="/forgot-password">{t("Forgot password ?")}</Link>
                    </div>
                    <div class="form-button d-flex justify-content-right" style={{ marginLeft: "auto" }}>
                      <Link to="/"> {t("Go To Home ?")}</Link>
                    </div>
                  </div>
                  <button id="submit" type="submit" class="ibtn" onClick={Login}>{t('Login')}</button>

                </form>
                <Link to="/register">
                  <div class="other-links text-center">
                    <span>{t('Want a new account')}?</span><a class="text-warning"> {t('Start it now')}</a>
                  </div>
                </Link>
                <div className="gmail_login">
                  <GoogleLogin
                    onSuccess={credentialResponse => {
                      GoogleLoginApi(credentialResponse)
                    }}
                    onError={() => {
                      displayError("unable to process")
                    }}
                  />
                </div>
              </div>
            </div>
            <div class="humer_footer d-none d-md-block">
              <img src="images/hammer-1.png" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Login;


