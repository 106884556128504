import React, { useEffect, useState, useRef } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import rectangle261 from '../components/images/Rectangle 261.jpg';
import CustomerModal from "../components/Modal/CustomerModal";
import samplePdf1 from '../components/images/sample2.pdf'
import samplePdf2 from '../components/images/samplepdf2.pdf'
import { Document, Page, pdfjs } from 'react-pdf';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import ReactPlayer from 'react-player'
import axios from "axios";
import config from "../config/config";
import { displayError, getNotesList, getProcessNotesList } from "../Utils/utils";
import Highlighter from "react-highlight-words";
import CustomVideoPlayerControlPanel from "./CustomVideoPlayerControlPanel";
// import PDFViewer from "pdf-viewer-reactjs";
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';

// Import styles
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import AddNotesModal from "../components/Modal/AddNotesModal";
import PdfModal from "../components/Modal/PdfModal";
import { useTranslation } from "react-i18next";
import imgnew from '../components/images/data-not-found-2.svg'
import imgnew2 from '../components/images/data-not-found.svg'
import LoadingSpinner from "../components/LoadingSpinner";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const Search = (props) => {
  const { t } = useTranslation();

  // const fullScreenPluginInstance = fullScreenPlugin(FullScreenPluginProps);
  const [customerModalShow, setCustomerModalShow] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [view, setView] = useState(false);
  const [key, setKey] = useState('Note');
  const [search, setSearch] = useState(props.search)
  const [searchList, setSearchList] = useState([]);
  const [searchPdf, setSearchPdf] = useState('');
  const [path, setPath] = useState(config.siteUrl);
  const [fileName, setFileName] = useState('');
  const [video, setVideo] = useState('');
  const [processSearch, setProcessSearch] = useState([])
  const [tempProArr, setTempProArr] = useState([])
  const [notFound, setNotFound] = useState(false);

  const [volume, setVolume] = useState(1)
  const [totalDurationOfVideo, setTotalDurationOfVideo] = useState(0)
  const [muted, setMuted] = useState(false)
  const [notes, setNotes] = useState(10.30)
  const hostVideo = useRef()
  const p = useRef()
  const [isPlay, setIsPlay] = useState(false)
  const [volumeBar, setVolumeBar] = useState(1)
  const [currentSeek, setCurrentSeek] = useState(0)
  const [notesModalShow, setNotesModalShow] = useState(false);
  const [tempFileId, setTempFileId] = useState('');
  const [fileType, setFileType] = useState('');
  const [pdfModal, setPdfModal] = useState(false)
  const [is_loading, setIsloading] = useState(false);

  var total = 0


  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }
  const onLoadError = (e) => {
    console.log(e)
  }

  const getSearch = async () => {
    setIsloading(true)
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/auth/file-search', {
      file_id: props.fileId,
      text: search
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (response.data.data.length === 0) {
          setIsloading(false)
          setNotFound(true)

        } else {
          setIsloading(false)
          setNotFound(false)
          setSearchList(response.data.data)
          props.file_id(response.data.data?.[0]?.file_id)
          props.fileUrl(response.data.data?.[0]?.process_file?.media?.url)
          props.fileType(response.data.data?.[0]?.type)
          props.fileName(response.data.data?.[0]?.process_file?.media?.original_name)
          setTempFileId(response.data.data?.[0]?.file_id)
          setFileType(response.data.data?.[0]?.type)
          if (response.data.data?.[0]?.type === "pdf") {
            setView(false)
            setSearchPdf(response.data.data?.[0]?.process_file?.media?.url)
            setFileName(response.data.data?.[0]?.process_file?.media?.original_name)
            // setPageNumber(response.data.data?.[0]?.indicator)
          } else {
            setView(true)
            setFileName(response.data.data?.[0]?.process_file?.media?.original_name)
            setVideo(response.data.data?.[0]?.process_file?.media?.url)
          }
        }
      })
      .catch((error) => {
        setIsloading(false)
        displayError(error, t);
      });
  }
  var temp_process_arr = []

  const getProcessSearch = async () => {
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/auth/process-search', {
      process_id: props.processId,
      text: search
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (response.data.data.process?.detail?.files.length == 0) {
          setNotFound(true)
        } else {
          setNotFound(false)
          response.data.data.process?.detail?.files.map((list) => {
            temp_process_arr.push({ key: list, value: response.data.data.process[list] })
          })
        }
        var temp_arr = []
        temp_process_arr.map((item) => (
          temp_arr.push(item.value)
        ))
        temp_arr[0].map((list) => {
          setTempFileId(list?.process_file.id)
          setFileType(list?.process_file?.media?.type)
          if (list?.type === "pdf") {
            setView(false)

            setSearchPdf(list?.process_file?.media?.url)
            setFileName(list?.process_file?.media?.original_name)
          } else {
            setView(true)
            setFileName(list?.process_file?.media?.original_name)
            setVideo(list?.process_file?.media?.url)
          }
        })
        setTempProArr(temp_arr)
      })
      .catch((error) => {
        displayError(error, t);
      });
  }
  useEffect(() => {
    if (props.fileId === "" || props.fileId === null) {
      props.isProcess(true)
      getProcessSearch()
    } else {
      props.isProcess(false)
      getSearch()
    }
  }, [search])

  const onProgress = (e) => {
    setCurrentSeek(e.playedSeconds)
  }
  const handlePause = async (e) => {
    setIsPlay(false)
  }

  const handlePlay = async (e) => {
    if (totalDurationOfVideo === 0) {
      setTotalDurationOfVideo(hostVideo.current.getDuration())
    }
    setIsPlay(true)
  }
  const handleSeekChange = async (e) => {
    setCurrentSeek(e.target.value)
    hostVideo.current.seekTo(e.target.value)
  }

  const handleVolumeChange = async (e) => {
    setVolumeBar(e.target.value)
    setVolume(e.target.value / 100)
  }
  const stringCount = (text, word) => {
    if (word !== undefined) {
      var text = text.toLowerCase().split(word.toLowerCase()).length - 1
      return text
    } else {
      return 0
    }

  }
  const totalStringCount = (texts, words) => {
    if (words !== undefined) {
      var text = texts.toLowerCase().split(words.toLowerCase()).length - 1
      total = total + text
      return total
    } else {
      return 0
    }

  }
  const onResumeClick = (pdf) => {
    setPdfModal(true)
  }

  const noteListing = async (id) => {
    var var_notes = await getNotesList(id);
    if (var_notes !== null) {
      props.noteArr(var_notes.data)
    }
  }
  const processNoteListing = async (id) => {
    var res = await getProcessNotesList(id)
    if (res !== null) {
      props.noteArr(res.data)
    }
  }
  useEffect(() => {
    if (props.fileId === null) {
      processNoteListing(props.processId)
    } else {
      noteListing(props.fileId)
    }
  }, [])

  return (
    <>
      <div className="row my-5" >
        {/* <LoadingSpinner is_loading={is_loading} /> */}

        <div className="col-md-8 search-result">
          <div className="card shadow " style={{ borderRadius: "15px", height: 'auto' }}>
            <div className="card-body">

              <div className="row">
                <div className="search-box">
                  <input type="text" placeholder={t("search")} className="searchinput" value={search} onChange={(e) => setSearch(e.target.value)} />
                  <i class="fas fa-search text-lg"></i>
                </div>
              </div>
              {props.fileId === "" || props.fileId === null ?

                <>
                  {notFound === true ?
                    <div className="notFound">
                      {/* {t("No Search Result Found")} */}
                      {localStorage.getItem('langauge_selected') === "pt" ? <img src={imgnew2} className="no-data-img" /> :
                        <img src={imgnew} className="no-data-img" />}
                      {/* <img src={imgnew} className="no-data-img" /> */}

                    </div> :
                    <>
                      {
                        tempProArr.map((item) => (
                          <><div
                            style={{
                              color: '#002ADFA3', marginLeft: '17px',
                              fontSize: '18px',
                              marginTop: '10px'
                            }}> {item[0].process_file.media.original_name}</div>
                            {item.map((data) => (

                              <div className=" ml-3 mt-3">


                                <div className="row mt-2 ml-1">
                                  <div className="result ml-3">{search === "" ? 0 : totalStringCount(data.text, search)} {t("results in this file")} - {data.indicator == null ? data.from_seconds + " sec  -" + data.to_seconds + " sec" : t("Page") + " " + data.indicator} - {search === "" ? 0 : stringCount(data.text, search)} {t("results")} </div>
                                  <div className="button-go btn"
                                    onClick={() => {
                                      props.file_id(data.file_id)
                                      props.fileUrl(data.process_file.media.url)
                                      props.fileType(data.type)
                                      props.fileName(data.process_file.media.original_name)
                                      setFileType(data.type)
                                      if (data.type === "video") {
                                        setView(true)
                                        setVideo(data.process_file.media.url)
                                        setFileName(data.process_file.media.original_name)
                                      } else {
                                        hostVideo.current?.seekTo(data.from_seconds, true)
                                        setView(false)
                                        setTempFileId(data.file_id)
                                        setSearchPdf(data.process_file.media.url)
                                        setFileName(data.process_file.media.original_name)
                                        setPageNumber(parseInt(data.indicator))
                                      }
                                    }}>
                                    <div className="goto" >{t("Go To")}</div>
                                  </div>
                                </div>
                                <div className="row mt-2 ml-1">
                                  <p className="ml-3 para">
                                    <Highlighter
                                      highlightClassName="YourHighlightClass"
                                      searchWords={[search]}
                                      autoEscape={true}
                                      // caseSensitive={true}
                                      textToHighlight={data.text}
                                    />
                                  </p>

                                </div>
                                <hr />

                              </div>
                            ))}

                          </>
                        ))
                      }</>
                  }

                </>

                // ))
                :

                <>
                  {notFound === true ?
                    <div className="notFound">
                      {t("No Search Result Found")}

                    </div>
                    :
                    <>
                      <div
                        className="textPrcess"
                        style={{
                          color: '#002ADFA3', marginLeft: '17px',
                          fontSize: '20px',
                          marginTop: '10px'
                        }}> {fileName}</div>
                      {
                        searchList.map((list) => (

                          <div className=" ml-3 mt-3 ">
                            <div className="row mt-2 ml-1">
                              <div className="result ml-3">{search === "" ? 0 : totalStringCount(list.text, search)} {("results in this file")} - {list.indicator == null ? list.from_seconds + " sec  -" + list.to_seconds + " sec" : "Page " + list.indicator} - {search === "" ? 0 : stringCount(list.text, search)} {t("results")} </div>
                              <div className="button-go btn" onClick={() => {
                                setFileType(list.type)
                                setTempFileId(list.file_id)
                                if (list.type === "pdf") {
                                  setView(false)
                                  setSearchPdf(list.process_file.media.url)
                                  setFileName(list.process_file.media.original_name)
                                  setPageNumber(parseInt(list.indicator))
                                } else {
                                  hostVideo.current?.seekTo(list.from_seconds, true)
                                  setView(true)
                                  setVideo(list.process_file.media.url)
                                  setFileName(list.process_file.media.original_name)
                                }

                              }}>
                                <div className="goto" >{t("Go To")}</div>
                              </div>
                            </div>
                            <div className="row mt-2 ml-1">
                              <p className="ml-3 para">
                                <Highlighter
                                  highlightClassName="YourHighlightClass"
                                  searchWords={[search]}
                                  autoEscape={true}
                                  textToHighlight={list.text}
                                />
                              </p>

                            </div>
                            <hr />
                          </div>

                        ))}
                    </>}
                </>

              }


            </div>
          </div>
        </div>
        <div className="col-md-4 search-pdf">
          <div className="sticky" style={{ borderRadius: "15px" }}>
            <div className="">
              {notFound === true ?
                <>
                </> :
                <>
                  {view !== true ?
                    <>
                      <h6 style={{
                        color: "#002ADF", textOverflow: 'ellipsis',
                        overflow: 'hidden'
                      }}>{fileName}</h6>

                      <Document
                        className="custom_pdf_document_row"
                        file={'https://dutraeducation.codiflysoftware.com/api/pdfbase64?url=' + searchPdf}
                        onLoadError={onLoadError}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        <Page className="custom_pdf_page_row" pageNumber={pageNumber} width={400} height={300} scale={1.0} />
                      </Document>

                      <div className="row">
                        <div className="col-md-4 col-xs-6 mt-2 mb-2">
                          <p>{t("Page")} {pageNumber} {t("of")} {numPages}</p>
                        </div>
                        <div className="col-md-4 col-xs-6 mt-2 mb-2">
                          <div className="pdf-btn">
                            <i onClick={e => {
                              var temp_page_numer = pageNumber;
                              if (temp_page_numer <= numPages) {
                                setPageNumber(--temp_page_numer)
                              }
                            }} class="fas fa-chevron-circle-left pointer"></i>

                            <i onClick={e => {
                              var temp_page_numer = pageNumber;
                              if (temp_page_numer < numPages) {
                                setPageNumber(++temp_page_numer)
                              }
                            }} class="fas fa-chevron-circle-right pointer"></i>
                          </div>
                        </div>
                        <div className="col-md-4 col-xs-6 mt-2 mb-2">
                          <div className="pdf-btn " onClick={() => onResumeClick(searchPdf)}>
                            <i class="fas fa-expand"></i>
                          </div>
                        </div>

                      </div>
                    </>
                    :
                    <>
                      <h6 style={{ color: "#002ADF" }}>{fileName}</h6>

                      <div className='player' >
                        <ReactPlayer
                          url={video}
                          width={'90%'}
                          height={300}
                          volume={volume}
                          muted={props.mute}
                          controls
                          ref={hostVideo}
                          playing={isPlay}
                          onProgress={(e) => onProgress(e)}
                        >
                        </ReactPlayer>

                      </div>
                    </>

                  }

                  <div className="row">
                    <div className="col-md-8 col-6 col-sm-6  mt-2 mb-2">
                      <div className="video-add-note btn btn-warning mt-2"
                        onClick={() => {
                          setNotesModalShow(true)
                          setIsPlay(false)
                        }}
                      >
                        <div className="font-weight-bold">{t("Add Note +")}</div>
                      </div>
                    </div>

                    <div className="col-md-4 col-6 mt-2 mb-2">
                      {view !== true ? null :
                        <div className="pdf-btn mt-2 " >
                          <i class="fas fa-expand"></i>
                        </div>}
                    </div>

                  </div>

                </>
              }
            </div>
          </div>
        </div>


        {notesModalShow === true ?
          <AddNotesModal
            notesModalShow={notesModalShow}
            setNotesModalShow={setNotesModalShow}
            process_id={props.processId}
            fileId={tempFileId}
            fileType={fileType}
            processNoteListing={() => processNoteListing(props.processId)}
            noteListing={() => noteListing(tempFileId)}
            pageNumber={pageNumber}
            currentSeek={currentSeek}
          // getFilesList={() => getFilesList(id)}
          />
          : null}
        {pdfModal === true ?
          <PdfModal
            pdfModal={pdfModal}
            setPdfModal={setPdfModal}
            pageNumber={pageNumber}
            pdfUrl={'https://dutraeducation.codiflysoftware.com/api/pdfbase64?url=' + searchPdf}
          />
          : null}
      </div>
    </>
  );

}
export default Search;