import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table';
import config from "../../config/config";
import axios from "axios";
import { displayError, displaySuccess, getCustomerList, getEditProcess, getPayment, getProcessList } from "../../Utils/utils";
import { useSelector, useDispatch } from 'react-redux';
import { I18n } from "react-redux-i18n";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PurchaseModal = (props) => {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state.userInfo.value)

  const [pageObj, setPageObj] = useState('')
  const [videoObj, setVideoObj] = useState('')
  const [storageObj, setStorageObj] = useState('')
  const [subTotal, setSubTotal] = useState('');
  const [credit, setCredit] = useState('');
  const [discount, setDiscount] = useState('');
  const [grand, setGrand] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');

  const handleClose = () => {
    props.setIsPurchaseModal(false)
  }

  const getDetails = async () => {
    var res = await getPayment(props.transId)
    if (res.payment_information !== null) {
      setPageObj(res.payment_information[0])
      setVideoObj(res.payment_information[1])
      setStorageObj(res.payment_information[2])
    }
    setSubTotal(res.sub_total_amount)
    setCredit(res.credit_card_rate)
    setDiscount(res.discount_amount)
    setGrand(res.paid_amount)
    setPaymentStatus(res.status)
  }
  useEffect(() => {
    getDetails()
  }, [])
  return (
    <>
      <div className="pdf-modal">


        <Modal
          // style={{ maxWidth: '700px' }}
          size="lg"
          show={props.isPurchaseModal}
          onHide={() => handleClose()}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            {t("Transaction Details")}
          </Modal.Header>
          <Modal.Body>
            <div className="card  col-md-12">
              <div className="card " style={{ borderRadius: "none", height: 'auto', background: "#F1F6FA" }}>
                <div className="card-body">
                  <Table className="table table-borderless align-items-center" responsive >
                    <thead>
                      <tr>
                        <th>
                          <span className="tablehead1" style={{ color: "#FCAD00" }}>{t("Storage Calculation")}</span>
                        </th>
                        <th><span className="headtable">{t("Quantity")}</span> </th>
                        <th><span className="headtable">{t('Unit Price')}</span></th>
                        <th><span className="headtable">%</span></th>
                        <th><span className="headtable">{t("Discount Price")}</span></th>
                        <th><span className="headtable">{t('Price')} (R$)</span></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span className="text-Secondary">{t('Processing Pages')}</span>
                        </td>
                        <td >
                          {pageObj.quantity}
                        </td>
                        <td >
                          <span className="text-dark">
                            {pageObj.rate}
                          </span>
                        </td>
                        <td>
                          {pageObj.discount_percentage}%
                        </td>
                        <td >
                          {pageObj.discount_amount}
                        </td>
                        <td>
                          <span className="text-dark">
                            {pageObj.amount}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-Secondary">{t('Processing Videos')}</span>
                        </td>
                        <td >
                          {videoObj.quantity}
                        </td>
                        <td >
                          <span className="text-dark">
                            {videoObj.rate}
                          </span>
                        </td>
                        <td>
                          {videoObj.discount_percentage}%
                        </td>
                        <td>
                          {videoObj.discount_amount}
                        </td>
                        <td >
                          <span className="text-dark">
                            {videoObj.amount}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-Secondary">{t('Additional Storage')}</span>
                        </td>
                        <td >
                          {storageObj.quantity}
                        </td>
                        <td >
                          <span className="text-dark">
                            {storageObj.rate}
                          </span>
                        </td>
                        <td>
                          {storageObj.discount_percentage}%
                        </td>
                        <td>
                          {storageObj.discount_amount}
                        </td>
                        <td >
                          <span className="text-dark">
                            {storageObj.amount}
                          </span>
                        </td>
                      </tr>
                      <tr className="bottom-hr" />
                      <tr>
                        {paymentStatus === "initiated" ? <td></td> :
                          <td >
                            <span className="text-warning font-weight-bold">{t('Discount')} (-)</span>
                          </td>
                        }
                        <td>													</td>
                        {paymentStatus === "initiated" ? <td></td> :

                          <td >
                            <span className="text-dark font-weight-bold">
                              {discount === null ? "0" : "R$ " + discount}
                            </span>
                          </td>
                        }
                        <td >
                          <span className="text-warning font-weight-bold">{t("Subtotal")}</span>
                        </td>
                        <td >
                        </td>
                        <td >
                          <span className="text-dark font-weight-bold">
                            R$ {subTotal}
                          </span>
                        </td>
                      </tr>
                      {paymentStatus === "initiated" ? null :
                        <tr>
                          <td >
                            <span className="text-warning font-weight-bold">{t('Credit Card Rate')} (+)</span>
                          </td>
                          <td>													</td>
                          <td >
                            <span className="text-dark font-weight-bold">
                              0%
                            </span>
                          </td>
                          <td >
                            <span className="text-warning font-weight-bold">{t("Grand Total")} </span>
                          </td>
                          <td >
                          </td>
                          <td >
                            <span className="text-dark font-weight-bold">
                              R$ {grand}
                            </span>
                          </td>
                        </tr>}
                      {/* {paymentStatus === "initiated" ? null :
                        <tr>
                          <td>													</td>
                          <td>													</td>
                          <td>													</td>
                          <td >
                            <span className="text-warning">Credit Card Rate (+)</span>
                          </td>
                          <td >
                          </td>
                          <td >
                            <span className="text-dark">
                              {credit} %
                            </span>
                          </td>
                        </tr>}
                      {paymentStatus === "initiated" ? null :
                        <tr>
                          <td>													</td>
                          <td>													</td>
                          <td>													</td>
                          <td >
                            <span className="text-warning">Grand Total</span>
                          </td>
                          <td >
                          </td>
                          <td >
                            <span className="text-dark">
                              R$ {grand}
                            </span>
                          </td>
                        </tr>
                      } */}

                    </tbody>
                  </Table>

                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
export default PurchaseModal;
