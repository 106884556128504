import React, { useEffect, useState } from 'react';
import TheamHeader from '../components/TheamHeader/TheamHeader';
import '../PrivacyPolicy/PrivacyPolicy.css';
import image1 from '../components/images/image11.png';
import Footer from '../components/Footer/index';
import Shape1 from '../components/images/Shape1.png';
import Shape2 from '../components/images/Shape2.png';
import Shape3 from '../components/images/Shape3.png';
import { Translate, I18n } from "react-redux-i18n";
import { useTranslation } from 'react-i18next';
import Header from '../components/Header/Header';
const PrivacyPolicy = () => {
  const [isPricing, setIsPricing] = useState(true);
  const [reload, setReload] = useState(false)
  const { t } = useTranslation();

  useEffect(() => {
    setReload(true)
    if (localStorage.getItem('token')) {
      setIsPricing(true)
    } else {
      setIsPricing(false)
    }
  }, [])
  return (
    <div className="privacypolicy">
      {/* <TheamHeader /> */}
      {reload &&
        <Header purchase={true} isPricing={isPricing} />}
      {/* containerA */}
      <div className="containerA col-xxl-8 px-4 py-5">
        <div className="row flex-lg-row-reverse align-items-center g-5">
          <div className="col-10 col-sm-8 col-lg-6">
            <img src={image1} className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" loading="lazy" width={700} height={500} />
          </div>
          <div className="col-lg-6">
            <div className="column">
              <h1 className="display lh-1 mb-3" style={{ fontSize: '48px' }}>{t('Privacy')} <span>{t('policy')}</span> </h1>
              <p className="lead" style={{ fontSize: '18px' }}>{t('Practically every single website worth anything out there has a Privacy Policy page that it can turn to whenever issues about privacy come up with users')}.</p>
            </div>
          </div>
        </div>
      </div>
      {/* containerB */}
      <div class="containerB ">
        <div className="row">
          <div className="col-sm-3">
            {/* <i class="fa-solid fa-square-check"></i>
            <i class="fa-light fa-square-check"></i>
            <i class="fal fa-check-square"></i> */}


          </div>
          <div className="col-sm-6">
            <h1 class="Text lh-1 mb-3">{t('How Can I Use A Privacy Policy')}?</h1>
            <p class="Textparagraph">{t('Usually privacy policy templates are pretty straightforward You can get one from a reputable source copy the template and then paste it on your privacy page Many of the relevant details will either be left blank or will need to be replaced Either way you need to be careful about the information that you put in the page')}.</p>
            <p class="Textparagraph">{t('You want to focus on company name or website name the name of the site owner company CEO, or web manager and a few important details about purchases service charges and so on Other than a few tweaks a good template would not require much from you')}.</p>
            {/* another paragraph */}
            <h1 class="Text lh-1 mb-3">{t('What is A Privacy Policy')}</h1>
            <p class="Textparagraph">{t('Usually privacy policy templates are pretty straightforward You can get one from a reputable source copy the template and then paste it on your privacy page Many of the relevant details will either be left blank or will need to be replaced Either way you need to be careful about the information that you put in the page')}.</p>
            <p class="Textparagraph">{t('You want to focus on company name or website name the name of the site owner company CEO, or web manager and a few important details about purchases service charges and so on Other than a few tweaks a good template would not require much from you')}.</p>
            {/* another paragraph */}

            <div class="container marketing">
              <div class="row">
                <div class="col-sm-4">
                  <img class="rounded" src={Shape1} />
                  <h2>{t('Experience')}</h2>
                  <p>{t('Our extensive resources and commitment to client service satisfy the needs of a diverse clientele across multiple industries')}.</p>
                </div>
                <div class="col-sm-4">
                  <img class="rounded" src={Shape2} />
                  <h2>{t('Team')}</h2>
                  <p>{t('Our extensive resources and commitment to client service satisfy the needs of a diverse clientele across multiple industries')}.</p>
                </div>
                <div class="col-sm-4">
                  <img class="rounded" src={Shape3} />
                  <h2>{t('Budget')}</h2>
                  <p>{t('Our extensive resources and commitment to client service satisfy the needs of a diverse clientele across multiple industries')}.</p>
                </div>
              </div>
            </div>

            {/* another paragraph */}
            <h1 class="Text lh-1 mb-3">{t('How Can I Use A Privacy Policy')}?</h1>
            <p class="Textparagraph">{t('Usually privacy policy templates are pretty straightforward You can get one from a reputable source copy the template and then paste it on your privacy page Many of the relevant details will either be left blank or will need to be replaced Either way you need to be careful about the information that you put in the page')}.</p>
            <p class="Textparagraph">{t('You want to focus on company name or website name the name of the site owner company CEO, or web manager and a few important details about purchases service charges and so on Other than a few tweaks a good template would not require much from you')}.</p>
            {/* another paragraph */}
            <h1 class="Text lh-1 mb-3">{t('What is A Privacy Policy')}</h1>
            <p class="Textparagraph">{t('Usually privacy policy templates are pretty straightforward You can get one from a reputable source copy the template and then paste it on your privacy page Many of the relevant details will either be left blank or will need to be replaced Either way you need to be careful about the information that you put in the page')}.</p>
            <p class="Textparagraph">{t('You want to focus on company name or website name the name of the site owner company CEO, or web manager and a few important details about purchases service charges and so on Other than a few tweaks a good template would not require much from you')}.</p>
          </div>
          {/*  */}
        </div>
        <div className="col-sm-3"></div>
      </div>
      <Footer />
    </div>
  )
}

export default PrivacyPolicy;