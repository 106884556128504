import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal'
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfModal = (props) => {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state.userInfo.value)
  const [noteName, setNoteName] = useState('');
  const [noteDesc, setNoteDesc] = useState('');

  const handleClose = () => {
    props.setPdfModal(false)
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }
  const onLoadError = (e) => {
    // console.log(e)
  }
  const [pageNumber, setPageNumber] = useState(props.pageNumber);
  const [numPages, setNumPages] = useState(null);

  return (
    <>
      <div className="pdf-modal">


        <Modal
          size="lg"
          show={props.pdfModal}
          onHide={() => handleClose()}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>

          </Modal.Header>
          <Modal.Body>
            <div className="pdf-modal">

              <Document
                className="custom_pdf_document_row"
                file={props.pdfUrl}
                onLoadError={onLoadError}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page className="custom_pdf_page_row" pageNumber={pageNumber} width={480} height={350} scale={1.0} />
              </Document>
            </div>

            <div className="row">
              <div className="col-md-4 col-xs-6 mt-2 mb-2">
                <p>{t("Page")} {pageNumber} {t("of")} {numPages}</p>
              </div>
              <div className="col-md-4 col-xs-6 mt-2 mb-2">
                <div className="pdf-btn">
                  <i onClick={e => {
                    var temp_page_numer = pageNumber;

                    if (temp_page_numer >= numPages) {
                      setPageNumber(--temp_page_numer)
                    }
                  }} class="fas fa-chevron-circle-left pointer"></i>

                  <i onClick={e => {
                    var temp_page_numer = pageNumber;
                    if (temp_page_numer < numPages) {
                      setPageNumber(++temp_page_numer)
                    }
                  }} class="fas fa-chevron-circle-right pointer"></i>
                </div>
              </div>


            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
export default PdfModal;
