import React, { useState, useEffect } from "react";
import './style.css'
import Dotted from './vector.png';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Table from 'react-bootstrap/Table'
import CustomerModal from "../Modal/CustomerModal";
import NewProcessModal from "../Modal/NewProcessModal";
import { getDashboardDetail, getDashboardProcessList, getProcessList, latestNotesDashboard } from "../../Utils/utils";
import Moment from 'react-moment';
import ReactPaginate from 'react-paginate';
import Alert from 'react-bootstrap/Alert'
import { Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import Spinner from 'react-bootstrap/Spinner';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import imgnew from '../images/data-not-found-2.svg'
import imgnew2 from '../images/data-not-found.svg'
import moment from "moment";

ChartJS.register(ArcElement, Tooltip, Legend);

const Card = () => {
  const navigate = useNavigate()
  const [customerModalShow, setCustomerModalShow] = useState(false);
  const [processModalshow, setProcessModalShow] = useState(false);
  const [processList, setProcessList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [current_page, setCurrentPage] = useState(1);
  const [customerList, setCustomerList] = useState([]);
  const [chartData, setChartData] = useState([30, 30, 60]); // for donutcolorvalue
  const [chartData2, setChartData2] = useState([]);
  const [chartData3, setChartData3] = useState([]);
  const [chartData4, setChartData4] = useState([40, 30, 20, 60]);
  const [chartData5, setChartData5] = useState([]);
  const [chartData6, setChartData6] = useState([]);
  const [donutvalue1, setDonutValue1] = useState(''); // for donutpercentvalue
  const [donutvalue2, setDonutValue2] = useState('');
  const [search, setSearch] = useState('');
  const [is_loading, setIsloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalFiles, setTotalFiles] = useState('');

  const [pendingPercent, setPendingPercent] = useState('');
  const [videoCount, setVideoCount] = useState('');
  const [pdfCount, setPdfCount] = useState('');
  const [videoSize, setVideoSize] = useState('');
  const [pdfSize, setPdfSize] = useState('');
  const [totalSize, setTotalSize] = useState('');
  const [pagesUsed, setPagesUsed] = useState('');
  const [pagesAvailable, setPagesAvailable] = useState('');
  const [videoUsed, setVideoUsed] = useState('');
  const [videoAvailable, setVideoAvailable] = useState('');
  const [notes, setNotes] = useState([])
  const [plugin2, setPlugin2] = useState([{
    beforeDraw: function (chart) {
      var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;
      ctx.restore();
      var fontSize = (height / 130).toFixed(2);
      ctx.font = fontSize + "em sans-serif";
      ctx.textBaseline = "top";
      var text = `20%`,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      ctx.fillText(text, textX, textY);
      ctx.save();
    }
  }])
  // const chartData = [70, 50, 40, 20, 30];
  const showData = chartData[0] + "%";

  const [processSlug, setProcessSlug] = useState('')
  // const [is_loading, setIsloading] = useState(false);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    getLatestNotes()
    // setDonutValue1(30)
    getProcess();
    getDashboard()
  }, [])
  const data = {
    // labels: ['Red', 'Blue'],
    datasets: [
      {
        label: '# of Votes',
        cutout: '80%',
        data: chartData,
        backgroundColor: [
          // '#FF606B',
          // '#F1F6FA',
          '#f1f6fa',
          '#3CC2E0',
          '#7D6AEB'
        ],
      },
    ],
  };
  const options1 = {
    responsive: true,
    legend: {
      display: false,
      position: "bottom",
      labels: {
        fontSize: 18,
        fontColor: "#6D7278",
        fontFamily: "kanit light"
      }
    }
  };
  const data2 = {
    // labels: ['Pending'],
    datasets: [
      {
        label: '# of Votes',
        data: chartData2,
        // cutout: '80%',
        backgroundColor: [
          '#46E30F',
          '#FFAF00'
        ],
        text: '50%'
      },
    ],
  };
  const options2 = {
    responsive: true,
    legend: {
      display: false,
      position: "bottom",
      labels: {
        fontSize: 18,
        fontColor: "#6D7278",
        fontFamily: "kanit light"
      },
    },

  };

  const pendingData = (item) => {
    setPlugin2([{
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 130).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = `${item}%`,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    }])
  }

  const data5 = {
    url: "../images/Vector2.png",
    datasets: [
      {
        label: '# of Votes',
        cutout: '80%',
        data: chartData5,
        backgroundColor: [
          '#6418C3',
          '#F1F6FA'
        ],
      },
    ],
  };
  const options5 = {
    responsive: true,
    legend: {
      display: false,
      position: "bottom",
      labels: {
        fontSize: 18,
        fontColor: "#6D7278",
        fontFamily: "kanit light"
      },
    },

  };
  const data6 = {
    url: "../images/Vector2.png",
    datasets: [
      {
        label: '# of Votes',
        cutout: '80%',
        data: chartData6,
        backgroundColor: [
          '#6418C3',
          '#F1F6FA'
        ],
      },
    ],
  };
  const options6 = {
    responsive: true,
    legend: {
      display: false,
      position: "bottom",
      labels: {
        fontSize: 18,
        fontColor: "#6D7278",
        fontFamily: "kanit light"
      },
    },

  };
  const plugins5 = [{
  }]

  const searchhandle = async (e) => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 500)
    var var_process = await getDashboardProcessList(current_page, e);
    setProcessList(var_process.data)
    setPageCount(var_process.last_page)
    // setIsloading(false)
  }
  const pendingFiles = (item) => {
    var total = 100;
    var pending = total - item;
    setChartData3([...item, ...pending])
  }


  const getDashboard = async () => {
    var var_dash = await getDashboardDetail();
    if (var_dash !== null) {


      setPendingPercent(var_dash.files.pending_percent)
      setDonutValue1(var_dash.files.processed_percent)
      setDonutValue2(var_dash.files.pending_percent)
      var total = 100;
      setChartData2([var_dash.files.processed_percent, var_dash.files.pending_percent])

      setChartData([var_dash.storage.remaining_percent, var_dash.storage.total_video_percent, var_dash.storage.total_pdf_percent])
      setTotalFiles(var_dash.files.total_files)
      setPdfCount(var_dash.storage.pdf_count)
      setVideoCount(var_dash.storage.video_count)
      setVideoSize(var_dash.storage.video_size_mb)
      setPdfSize(var_dash.storage.pdf_size_mb)
      setTotalSize(var_dash.storage.total_storage)
      setPagesAvailable(var_dash.storage.pages.pages_available)
      setPagesUsed(var_dash.storage.pages.pages_used)
      setChartData5([var_dash.storage.pages.pages_used, var_dash.storage.pages.pages_available])
      setChartData6([var_dash.storage.video.duration_used, var_dash.storage.video.duration_available])
      setVideoAvailable(var_dash.storage.video.duration_available)
      setVideoUsed(var_dash.storage.video.duration_used)
    }
  }
  const getProcess = async () => {
    var var_process = await getDashboardProcessList(current_page);
    setProcessList(var_process.data)
    setPageCount(var_process.last_page)
    // setChartData3([50, 50])
  }

  const handlePageClick = async (e) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected + 1;
    var var_process = await getDashboardProcessList(selectedPage);
    setProcessList(var_process.data)
    setPageCount(var_process.last_page)
  };

  const getLatestNotes = async () => {
    var res = await latestNotesDashboard();
    setNotes(res)
  }
  const gotoNotes = (list) => {
    navigate(`/notes-list/${list}`)
  }
  const gotoPurchase = () => {
    navigate(`/purchase`, {
      state: {
        isPricing: true
      }
    })
  }
  return (
    <>
      <div className="row my-4">
        <div className="col-xl-8 col-md-6 mb-4">
          <div className="row mt-4">
            <div className="col-sm-4 col-12" >
              <span className="textPrcess mt-3 mb-1">{t('List of process')}</span>
            </div>
            <div className="col-sm-4 col-6">
              <div className="addBox btn" onClick={() => setProcessModalShow(true)} style={{ cursor: 'pointer' }}>
                <i class="far fa-plus-square"></i>
                <span className="addNew">{t('Add New')}</span>
              </div>
            </div>
            <div className="col-sm-4 col-6">
              <div className="infobutton m-1" style={{ width: '90%' }}>
                <input className="form-control" id="validationCustom01" type="text" placeholder={t("search")} required="" data-bs-original-title="" title=""
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value)
                    searchhandle(e.target.value)
                  }} />
              </div>
              {/* <div className="searchbox row">
                <input class="form-control" placeholder={t("search")} style={{ borderStyle: 'none', background: 'transparent', marginTop: '-6%' }}
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value)
                    searchhandle(e.target.value)
                  }} />
              </div> */}
            </div>


          </div>
          <div className="card mt-2 shadow" style={{ borderRadius: '14px' }}>
            <div className="card-body p-0">
              {
                loading ?
                  <div class="d-flex justify-content-center">
                    <Spinner animation="border" loading={loading} variant="secondary"
                      style={{ width: "3rem", height: "3rem", marginBottom: "15px" }} />
                  </div>
                  :
                  <Table striped borderless size="md" responsive>
                    <thead>
                      <tr>
                        <th>{t('Process Name')}</th>
                        {/* <th></th> */}
                        <th>{t('Customer Name')}</th>
                        <th></th>
                        <th>{t('Last Updated')}</th>
                        <th>{t('Documents')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(processList.length === 0) ?
                        <tr>
                          <td colSpan="6">
                            {localStorage.getItem('langauge_selected') === "pt" ? <img src={imgnew2} className="no-data-img-3" /> :
                              <img src={imgnew} className="no-data-img-3" />}
                          </td>
                        </tr>
                        : ''}
                      {processList.map((list) => (
                        <tr >
                          <td style={{ fontSize: '16px' }}>
                            <div className="pro-name" style={{ width: "150px" }}>
                              {list.status == 0 ? list.name :
                                <Link to={'/process-dashboard/' + list.slug}>
                                  {list.name}
                                </Link>
                              }
                            </div>
                          </td>

                          <td style={{ fontSize: '14px' }}>{list.process_customers[0]?.user?.name}</td>
                          <td className="row">
                            {list?.process_customers.map((ac, index) => index < 4 && (
                              <div className="circle-c" style={{ marginLeft: '-7px' }}></div>
                            ))}
                            {list.process_customers_count === 0 ? null :
                              <div className="circle-c" style={{ marginLeft: '-7px', backgroundColor: '#E328AF', cursor: 'pointer' }} onClick={() => {
                                setCustomerList(list.process_customers)
                                setCustomerModalShow(!customerModalShow)
                              }}>
                                <div className="count-circle">{list.process_customers_count}+</div>
                              </div>}
                          </td>
                          {/* <td className="row">
                            {list?.process_customers.map((ac, index) => index < 4 && (
                              <div className="circle-c" style={{ marginLeft: '-7px' }}></div>
                            ))}
                            {list.process_customers_count === 0 ? null :
                              <div className="circle-c" style={{ marginLeft: '-7px', backgroundColor: '#E328AF', cursor: 'pointer' }} onClick={() => {
                                setCustomerList(list.process_customers)
                                setCustomerModalShow(!customerModalShow)
                              }}>
                                <div className="count-circle">{list.process_customers_count}+</div>
                              </div>}
                          </td> */}
                          <td style={{ fontSize: '14px' }}> <Moment format="DD/MM/YYYY">
                            {list.updated_at}
                          </Moment></td>
                          <td>
                            {list.status == 0 ? null :
                              <Link to={"/file-list/" + list.id}>
                                <div className="document-btn">
                                  <i className="fas fa-eye"></i> {t("Documents")}
                                </div>
                              </Link>
                            }
                          </td>
                        </tr>
                      ))}

                    </tbody>

                  </Table>
              }
              <div className="row justify-content-between">
                <div className="col-sm-6">
                  <div className="mx-auto1 mt-2 d-block">
                    <ReactPaginate
                      previousLabel={t('prev')}
                      nextLabel={t('next')}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      marginPagesDisplayed={0}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
                <div className="col-auto">
                  <Link to="/process-list">
                    <div className="listall-btn btn mb-3 mr-3">
                      <div className="" >{t("See All")}</div>
                    </div>
                  </Link>
                </div>


              </div>

              <div className="row">

              </div>

            </div>

          </div>
          <Row>
            <Col md={6}>
              <div className="card shadow mb-4 mt-3" style={{ borderRadius: '14px', height: '100%' }}>
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className=" mr-2">
                      <div className="row">
                        <div className="col-xl-6 col-md-4">
                          <div className="text-lg font-weight-bold   mb-1 text-size">
                            {t('Files Processing Monitor')}
                          </div>
                        </div>
                        <div className="col-xl-6 col-md-8">
                          <div className="row ml-5">
                            <span className="circle"></span>
                            <span className="ml-1 mr-2">{t('Pending')}</span>
                          </div>
                          <div className="row ml-5 mt-2">
                            <span className="circle2"></span>
                            <span className="ml-1 mr-2">{t('Processed')}</span>
                          </div>

                        </div>
                      </div>


                      <div className="row justify-content-between">
                        {totalFiles === 0 ?

                          localStorage.getItem('langauge_selected') === "pt" ? <img src={imgnew2} className="no-data-img" /> :
                            <img src={imgnew} className="no-data-img" />
                          :
                          <>
                            <div className="col-lg-6 col-md-6 col-sm-4 col-6">

                              <Doughnut data={data2} options={options2} />
                            </div>

                            <div className="col-lg-6 col-md-6 col-6">
                              <div className="row mt-1 present">
                                <div className="textNum font-weight-bold mt-3 textFile mb-1">{totalFiles}</div>
                                <i class="fas fa-arrow-circle-up upIcon" style={{ marginTop: '30px' }}></i>
                              </div>
                              <div className="text-s mt-1 textFile-2">{t('Present Files')}</div>
                            </div>
                          </>
                        }
                      </div>

                    </div>
                    <div className="col-auto">
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6}>
              {notes && notes.length === 0 ?
                <div className="card no-record-card  shadow  mt-3" style={{ borderRadius: '15px' }}>
                  <div className="card-body">
                    <div className="text-lg font-weight-bolder   mb-1">
                      {t('Latest Notes')}
                    </div>


                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">

                        <div className="no-record">{t("No records")}</div>
                        {/* <span className="text-md   " style={{ color: '#F2994A' }}>No Records</span> */}
                        {/* <div className="text-md   mt-1 mb-1" style={{ fontSize: '14px' }}>{list.description} </div> */}
                        {/* <div className="row mt-1">
                            <span className="mr-2 text-xs ml-3" style={{ color: '#787878' }}>{moment(list.created_at).format('YYYY/MM/DD')}</span>

                          </div> */}
                      </div>

                    </div>

                  </div>
                </div>
                :
                <div className="card  shadow  mt-3" style={{ borderRadius: '15px' }}>
                  <div className="card-body">
                    <div className="text-lg font-weight-bolder   mb-1">
                      {t('Latest Notes')}
                    </div>
                    {notes && notes.map((list) => (

                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2" style={{ cursor: 'pointer' }} onClick={() => gotoNotes(list.file_id)}>


                          <span className="text-md   " style={{ color: '#F2994A' }}>{list.title}</span>
                          <div className="text-md   mt-1 mb-1" style={{ fontSize: '14px' }}>{list.description} </div>
                          <div className="row mt-1">
                            <span className="mr-2 text-xs ml-3" style={{ color: '#787878' }}>{moment(list.created_at).format('YYYY/MM/DD')}</span>

                          </div>
                        </div>

                      </div>
                    ))}
                  </div>
                </div>}
            </Col>
          </Row>


        </div>
        <div className="col-xl-4 col-md-6 mb-4">
          <div className="card border-radius-10 shadow  py-2 first-card" style={{ borderRadius: '15px' }}>
            <div className="card-body storage-card">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className=" text-lg font-weight-bold">
                    {t("Storage")}
                  </div>
                  <Row>
                    <Col md={6} xs={8} className="pl-0">
                      <Doughnut data={data} options={options1} />
                    </Col>
                    <Col md={6} className="p-0">
                      <div className="row mt-3 w-100">
                        <div className="col-md-3 col-3 p-0">
                          <div className="iconBox">
                            <i class="far fa-file icon"></i>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 pl-0">
                          <div className="storage-font font-weight-bold  mb-1 w-100">{t('Total Storage')}</div>
                          <div className="text-xs mb-1">{totalFiles} {t('Files')} </div>
                        </div>
                        <div className="col-md-3 col-3 p-0">
                          <div className="text-s font-weight-bolder  size  mb-1">{(totalSize / 1024).toFixed()} GB</div>
                        </div>
                      </div>
                      <div className="row mt-3 w-100">
                        <div className="col-3 p-0">
                          <div className="iconBox">
                            <i class="fas fa-video icon" style={{ color: " #3CC2E0", fontSize: '20px' }}></i>
                          </div>
                        </div>
                        <div className="col-6 pl-0">
                          <div className="storage-font text-s font-weight-bold  mb-1 ">{t('Video')}</div>
                          <div className="text-xs mb-1">{videoCount} Files</div>
                        </div>
                        <div className="col-3 p-0">
                          <div className="text-s font-weight-bolder size  mb-1" style={{ color: " #3CC2E0" }}>{videoSize} MB</div>
                        </div>
                      </div>
                      <div className="row mt-3 w-100">
                        <div className="col-3 p-0">
                          <div className="iconBox">
                            <i class="far fa-file-pdf icon" style={{ color: " #7D6AEB" }}></i>
                          </div>
                        </div>
                        <div className="col-6 pl-0">
                          <div className=" storage-font text-s font-weight-bold mb-1 w-100" >{t('PDF')} </div>
                          <div className="text-xs mb-1">{pdfCount} {t('Files')} </div>
                        </div>
                        <div className="col-3 p-0">
                          <div className="text-s font-weight-bolder size  mb-1" style={{ color: " #7D6AEB" }}>{pdfSize} MB</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <div className="row mt-3 w-100">
                  <div className="col-md-3 col-3 p-0">
                    <div className="iconBox">
                      <i class="far fa-file icon"></i>
                    </div>
                  </div>
                  <div className="col-md-6 col-6 pl-0">
                    <div className="storage-font font-weight-bold  mb-1 w-100">{t('Total Storage')}</div>
                    <div className="text-xs mb-1">{totalFiles} {t('Files')} </div>
                  </div>
                  <div className="col-md-3 col-3 p-0">
                    <div className="text-s font-weight-bolder  size  mb-1">{(totalSize / 1024).toFixed()} GB</div>
                  </div>
                </div>
                <div className="row mt-3 w-100">
                  <div className="col-3 p-0">
                    <div className="iconBox">
                      <i class="fas fa-video icon" style={{ color: " #3CC2E0", fontSize: '20px' }}></i>
                    </div>
                  </div>
                  <div className="col-6 pl-0">
                    <div className="storage-font text-s font-weight-bold  mb-1 ">{t('Video')}</div>
                    <div className="text-xs mb-1">{videoCount} Files</div>
                  </div>
                  <div className="col-3 p-0">
                    <div className="text-s font-weight-bolder size  mb-1" style={{ color: " #3CC2E0" }}>{videoSize} MB</div>
                  </div>
                </div>
                <div className="row mt-3 w-100">
                  <div className="col-3 p-0">
                    <div className="iconBox">
                      <i class="far fa-file-pdf icon" style={{ color: " #7D6AEB" }}></i>
                    </div>
                  </div>
                  <div className="col-6 pl-0">
                    <div className=" storage-font text-s font-weight-bold mb-1 w-100" >{t('PDF')} </div>
                    <div className="text-xs mb-1">{pdfCount} {t('Files')} </div>
                  </div>
                  <div className="col-3 p-0">
                    <div className="text-s font-weight-bolder size  mb-1" style={{ color: " #7D6AEB" }}>{pdfSize} MB</div>
                  </div>
                </div> */}
                <div className=" row box mt-3">
                  <div className="text-s font-weight-bold textStore  mb-1 w-100">{t('Upgrade your Storage')}</div>
                  <div className="planBox">
                    <div className="text-s font-weight-bolder textPlan" style={{ cursor: 'pointer' }} onClick={() => gotoPurchase()}>{t('View plan')}
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width={25} stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card  shadow mt-3  mb-2" style={{ borderRadius: '15px' }}>
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="row">
                    <div className="col-xl-6 col-md-6 pr-0">

                      <span className="mt-2 number">{pagesUsed}</span>
                      <div className="page">{t('Page processing used')}</div>
                      <div className="page2">{t('Processed of ')}{pagesAvailable} {t('available')}</div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-6">
                      <Doughnut data={data5} options={options5} plugins={plugins5} />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="card  shadow mt-3" style={{ borderRadius: '15px' }}>
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="row">
                    <div className="col-xl-6 col-md-6 pr-0">

                      <span className="mt-2 number">{videoUsed}</span>
                      <div className="page">{t('Video processing used')}</div>
                      <div className="page2">{t('Processed of ')}{videoAvailable} {t('available')}</div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-6">
                      <Doughnut data={data6} options={options6} plugins={plugins5} />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        {customerModalShow === true ?
          <CustomerModal customerModalShow={customerModalShow} setCustomerModalShow={setCustomerModalShow} customerList={customerList} />
          : null
        }
        {processModalshow === true ?
          <NewProcessModal processModalshow={processModalshow} setProcessModalShow={setProcessModalShow} getProcess={() => searchhandle()} /> : null
        }
      </div>
    </>
  );
}

export default Card;