import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import config from "../../config/config";
import axios from "axios";
import { displayError, displaySuccess, getCustomerList, getExistingCustomerList, getProcessRole, getRoles, getUserDetailss } from "../../Utils/utils";
import { useTranslation } from "react-i18next";
import ReactInputMask from 'react-input-mask';

const NewUser = (props) => {
  const { t } = useTranslation();
  const handleClose = () => props.setNewUserModal(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('')
  const [roles, setRoles] = useState([]);
  const [type, setType] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  let [mask, setMask] = React.useState(null);
  const [mob2, setMob2] = useState('');
  const getType = async () => {
    // setIsloading(true)
    var roles_var = await getProcessRole();
    setRoles(roles_var)
    // setIsloading(false)
  }
  useEffect(() => {
    getType();
  }, [])
  const onFormSubmit = async () => {
    // setIsloading(true)
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    var data1 = {
      user_name: name,
      user_email: email,
      phone_number: phoneNumber,
      process_role_id: type,
      description: '',
      process_id: props.processId,
      language: localStorage.getItem('langauge_selected')
    };
    await axios.post(path + '/user/create-process-user', data1, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        displaySuccess(t('New user has been added'));
        props.getDetail();
        props.setNewUserModal(false)
      })
      .catch((error) => {
        // setIsloading(false)
        displayError(error, t);
        // props.toggleLoader(false);
      });
  };
  const getUserDetail = async (id, pro) => {
    var res = await getUserDetailss(id, pro);
    setEmail(res.email)
    setName(res.name)
    setPhoneNumber(res.phone_number)
    setType(res.process_user.process_role_id)
  }
  useEffect(() => {
    if (props.userId) {
      getUserDetail(props.userId, props.process_id)
    }
  }, [])
  const onUpdate = async () => {
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    var data1 = {
      user_name: name,
      phone_number: phoneNumber,
      description: '',
      process_id: props.process_id,
    };
    await axios.post(path + '/user/update-process-user/' + props.userId, data1, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        displaySuccess(t('New user has been updated'));
        props.getUsers();
        props.setNewUserModal(false)
      })
      .catch((error) => {
        displayError(error, t);
      });
  };
  const mobHandle = ev => {
    let { value } = ev.target;
    let nums = value.replace(/[^0-9]/g, "");
    setMask("+55 99 99999-9999");
    setPhoneNumber(ev.target.value);
    setMob2(nums);
    if (nums.length < 3 && mask != null) {
      setMask(null);
      ev.target.value = nums;
    }
  };
  return (
    <>
      {/* <LoadingSpinner is_loading={is_loading} /> */}

      <Modal show={props.newUserModal} onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('New User')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="process">
            <div className="row">
              <div className="col-sm-6">
                <label>{t('Full Name')} <span style={{ color: "red" }}>*</span></label>
                <input
                  className="form-control" type="Username" placeholder={t('Please enter your name')} onChange={e => setName(e.target.value)} value={name} />
              </div>
              <div className="col-sm-6">
                <label>{t('Email Address')} <span style={{ color: "red" }}>*</span></label>
                <input
                  className="form-control" type="useremail" placeholder={t('Please enter your email')} onChange={e => setEmail(e.target.value)} value={email} disabled={props.userId !== undefined ? true : false} />
              </div>
            </div>
            <div className="row mt-4 mb-3">
              <div className="col-sm-6">

                <label>{t('Phone Number')} <span style={{ color: "red" }}>*</span></label>
                <ReactInputMask
                  className="form-control mt-2 form-control-input"
                  mask={mask}
                  onChange={mobHandle}
                  maskChar="_"
                  value={phoneNumber}
                  placeholder={'mobile no'}
                // {...props}
                />
                {/* <input
                  className="form-control" type="Username" placeholder={t('Please enter your name')} onChange={e => setPhoneNumber(e.target.value)} value={phoneNumber} /> */}
              </div>
              <div className="col-sm-6">
                <label>{t('Type')} <span style={{ color: "red" }}>*</span></label>
                <select className="form-control" as="select" type="value" placeholder={t('Please select the type')}
                  onChange={e => setType(e.target.value)} value={type} disabled={props.userId !== undefined ? true : false}>
                  <option value={''}>{t('select Type')}</option>
                  {roles.map((list) => (
                    <option value={list.id} selected={(list.id === type) ? true : false}>{list.name}</option>
                  ))}
                </select>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <Button variant="warning" className="button ml-3 mt-4" type="submit"
                    onClick={() => {
                      if (props.userId !== undefined) {
                        onUpdate()
                      } else {
                        onFormSubmit()
                      }
                    }}
                  >
                    <span style={{ fontsize: '18px' }}>{t('Submit')}</span>
                  </Button>
                </div>
              </div>

            </div>

          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default NewUser;