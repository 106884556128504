import React from 'react';
import { Comment, Discuss } from 'react-loader-spinner'
import './ChatSpinner.css'

const ChatSpinner = (props) => {
  return (
    <div className={(props.is_loading === true) ? 'chat_block' : ''}>
      <span>
        <Discuss
          visible={props.is_loading}
          height="100"
          width="100"
          ariaLabel="comment-loading"
          wrapperStyle={{}}
          wrapperClass="comment-wrapper"
          color="#ffaf00"
          colors={['#ffaf00', '#ffaf00',]}
          backgroundColor="#ffaf00"
        />
        {/* <Comment
          visible={props.is_loading}
          height={100}
          width={100}
          ariaLabel="comment-loading"
          wrapperStyle={{}}
          wrapperClass="comment-wrapper"
          color="#fff"
          backgroundColor="#0237e2"
        /> */}

      </span>
    </div>
  )
}
export default ChatSpinner;