import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { displaySuccess, displayError } from '../Utils/utils';
import axios from 'axios';
import config from '../config/config';
import { useNavigate } from "react-router-dom";
import { Translate, I18n } from "react-redux-i18n";
import { useTranslation } from 'react-i18next';
import image from '../components/images/logo_trans.png';

const ForgotPassword = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const navigate = useNavigate()
  const ForgotPass = async () => {
    // props.toggleLoader(true);
    let path = config.siteUrl;
    await axios.post(path + '/forget-password', {
      email: email,
      language: localStorage.getItem('langauge_selected')

    }, {
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        displaySuccess(t('Password reset email has been sent'));
        // props.toggleLoader(false);
        // setTimeout(() => window.location.href = "/reset-password", 3000);
        navigate(`/reset-password`, {
          state: {
            email: email
          }
        })
      })
      .catch((error) => {
        displayError(error, t);
        // props.toggleLoader(false);
      });
  };

  return (
    <>
      <div class="form-body">
        <div class="website-logo">
          <a >
            <div class="logo">
              <img class="logo-size" src="images/logo-light.svg" alt="" />
            </div>
          </a>
        </div>
        <div class="row">
          <div class="img-holder">
            <div class="bg"></div>
            <div class="info-holder" style={{ top: '70%' }}>
              <img src={image} /></div>
          </div>
          <div class="form-holder">
            <div class="form-content">
              <div class="form-items">
                <h2>{t('Forgot Password')}</h2>
                <p>{t('Enter your email address')}</p>
                <div>
                  <label>{t('Email Address')}</label>
                  <input class="form-control" type="text" name="username" placeholder={t('Enter email your address')} required
                    onChange={e => setEmail(e.target.value)} />
                  {/* <Link to="/reset-password"> */}
                  <button id="submit" type="submit" class="ibtn" onClick={ForgotPass}>{t('RESET PASSWORD')}</button>
                  {/* </Link> */}
                </div>
                <Link to="/login">
                  <div class="other-links text-center">
                    <span>{t('Remember Password'
                    )}?</span><a class="text-warning"> {t('Login now')}</a>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword;